import React from 'react';

const StatsIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.32 77.32">
        <path
          fill={color}
          d="M51.52,29.45a1,1,0,0,1-.8-.4,1,1,0,0,1,.2-1.4l8.2-6.17a1,1,0,0,1,1.21,1.6l-8.2,6.17A1.08,1.08,0,0,1,51.52,29.45Z"
        />
        <path fill={color} d="M46,30.6h-.16l-8-1.33a1,1,0,0,1-.82-1.15,1,1,0,0,1,1.15-.82l8,1.32a1,1,0,0,1-.17,2Z" />
        <path
          fill={color}
          d="M23.37,41.63a1,1,0,0,1-.67-.26A1,1,0,0,1,22.63,40l9.62-10.6a1,1,0,0,1,1.48,1.35L24.11,41.3A1,1,0,0,1,23.37,41.63Z"
        />
        <path fill={color} d="M37.38,53.29H13a1,1,0,0,1-1-1V18.21a1,1,0,0,1,2,0V51.29h23.4a1,1,0,0,1,0,2Z" />
        <path
          fill={color}
          d="M44.13,57.21a1,1,0,0,1-1-.85,13.26,13.26,0,0,1,7.15-13.69,1,1,0,0,1,.9,1.79,11.13,11.13,0,0,0-6.18,10,10.58,10.58,0,0,0,.11,1.6,1,1,0,0,1-.85,1.13Z"
        />
        <path
          fill={color}
          d="M56.21,70A15.63,15.63,0,0,1,42.8,62.36,3.34,3.34,0,0,1,44,57.77a3.37,3.37,0,0,1,2.54-.36,3.4,3.4,0,0,1,2,1.55,8.85,8.85,0,1,0,7.63-13.33,3.36,3.36,0,1,1,0-6.71,15.56,15.56,0,0,1,0,31.11ZM45.69,59.31a1.39,1.39,0,0,0-.69.18,1.37,1.37,0,0,0-.48,1.86A13.56,13.56,0,1,0,56.21,40.92a1.36,1.36,0,1,0,0,2.71A10.85,10.85,0,1,1,46.85,60a1.32,1.32,0,0,0-.82-.62A1.37,1.37,0,0,0,45.69,59.31Z"
        />
        <path
          fill={color}
          d="M21.42,46.55a3.82,3.82,0,1,1,3.82-3.81A3.82,3.82,0,0,1,21.42,46.55Zm0-5.63a1.82,1.82,0,1,0,1.82,1.82A1.83,1.83,0,0,0,21.42,40.92Z"
        />
        <path
          fill={color}
          d="M35,31.58a3.82,3.82,0,1,1,3.81-3.81A3.82,3.82,0,0,1,35,31.58ZM35,26a1.82,1.82,0,1,0,1.81,1.82A1.82,1.82,0,0,0,35,26Z"
        />
        <path
          fill={color}
          d="M49.28,34a3.82,3.82,0,1,1,3.81-3.82A3.83,3.83,0,0,1,49.28,34Zm0-5.64a1.82,1.82,0,1,0,1.81,1.82A1.83,1.83,0,0,0,49.28,28.32Z"
        />
        <path
          fill={color}
          d="M62.21,24.23A3.82,3.82,0,1,1,66,20.41,3.82,3.82,0,0,1,62.21,24.23Zm0-5.64A1.82,1.82,0,1,0,64,20.41,1.82,1.82,0,0,0,62.21,18.59Z"
        />
      </svg>
    </React.Fragment>
  );
};

export default StatsIcon;
