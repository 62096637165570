import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const LowIGDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10308_11055)">
        <path
          d="M29.3 13.4699C28.75 10.1799 25.88 7.66992 22.43 7.66992C18.58 7.66992 15.47 10.7899 15.47 14.6299V21.1699C15.47 25.0199 18.59 28.1299 22.43 28.1299C26.28 28.1299 29.39 25.0099 29.39 21.1699V18.6699H22.43"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.98999 7.66016V28.1302"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.6299 30.8398L39.7499 38.9598"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.75 31.1992V38.9592H31.99"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.66 23.4609C41.71 23.4609 46.61 28.3609 46.61 34.4109C46.61 40.4609 41.71 45.3609 35.66 45.3609C29.61 45.3609 24.71 40.4609 24.71 34.4109"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M5.44995 7.66016H10.53" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.44995 28.1309H10.53" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10308_11055">
          <rect width="50.4" height="50.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LowIGDietIcon;
