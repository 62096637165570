import { call, put, select } from '@redux-saga/core/effects';

import SupportActions from '@redux/ToCheck/support/support';
import authSelectors from '@selectors/authSelectors';

export function* getSupportQuestions(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getSupportQuestions, `Bearer ${token}`);
  if (response.ok) {
    yield put(SupportActions.getSupportQuestionsSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      SupportActions.getSupportQuestionsFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* submitContactForm(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const { firstName, email, language, message, onSuccess, onError } = action;
  const response = yield call(api.submitContactForm, firstName, email, language, message, `Bearer ${token}`);
  if (response.ok) {
    onSuccess();
    yield put(SupportActions.submitContactFormSuccess(response.data));
  } else {
    onError(response);
    if (response.data && response.data.errors) {
      yield put(SupportActions.submitContactFormFailure(response.data.errors));
    } else {
      yield put(SupportActions.submitContactFormFailure(response));
    }
  }
}
