import React from 'react';

const ForwardIcon = () => {
  return (
    <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L11 11.4992L1 22" stroke="#E0325F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ForwardIcon;
