import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';

import TrainingDataActions, { trainingDataSelectors } from '@redux/Fitney2.0/Trainings/trainingData';

const mapStateToProps = (state: any) => ({
  trainingFilters: trainingDataSelectors.getTrainingFilters(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  resetTrainingDataFilters: () => dispatch(TrainingDataActions.resetTrainingDataFilters()),
  getTrainings: (page: any, per_page: any, query: any, filters: any) =>
    dispatch(TrainingDataActions.fetchTrainingDataRequest(page, per_page, query, filters))
});

const withTrainingsFilters = (Component: any, path: any) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ trainingFilters, getTrainings, resetTrainingDataFilters, ...props }: any) => {
    const hasFilters =
      trainingFilters &&
      !!Object.entries(trainingFilters)
        .map((filter) => filter[1])
        .flat().length;

    const handleChangeRoute = (location: any) => {
      if (hasFilters && !location.pathname.startsWith(path)) {
        getTrainings(1, props.isMobile ? 10 : 12, '', '');
        resetTrainingDataFilters();
      }
      return true;
    };

    return (
      <>
        <Prompt message={handleChangeRoute} />
        <Component hasFilters={hasFilters} {...props} />
      </>
    );
  });

export default withTrainingsFilters;
