import React from 'react';

import colors from '@consts/colors';

const AnkleBandIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M10.4877 10.5988C10.4877 9.35352 12.9556 8.34428 16.0007 8.34428C19.0442 8.34428 21.5122 9.35352 21.5122 10.5988M10.4877 10.5988V28.7455M10.4877 10.5988C10.4877 9.35514 8.58841 7.62214 6.24374 6.73173C3.89908 5.84458 2 6.13107 2 7.37634V25.5233C2 26.7685 3.89908 28.5005 6.24374 29.3893C8.58841 30.2797 10.4877 29.9908 10.4877 28.7455M21.5122 10.5988V28.7455M21.5122 10.5988C21.5122 9.35514 23.4116 7.62214 25.7563 6.73173C28.1009 5.84458 30 6.13107 30 7.37634V25.5233C30 26.7685 28.1009 28.5005 25.7563 29.3893C23.4116 30.2797 21.5122 29.9908 21.5122 28.7455M10.4877 28.7455C10.4877 29.9908 12.9556 31 16.0007 31C19.0442 31 21.5122 29.9908 21.5122 28.7455M20.7257 4.04289C20.7257 2.91482 18.6112 2 16.0002 2C13.3908 2 11.2747 2.91482 11.2747 4.04289M20.7257 4.04289C20.7257 2.91482 24.0249 2.64802 26.0341 3.45378C28.045 4.26117 30 5.83687 30 6.96494M20.7257 4.04289L20.7249 5.45597M11.2747 4.04289C11.2747 2.91482 7.97511 2.64802 5.96587 3.45378C3.95498 4.26117 2 5.83687 2 6.96494M11.2747 4.04289L11.2751 5.45597" />
    </g>
  </svg>
);

// AnkleBandIcon.propTypes = {
//   size: PropTypes.number,
//   color: PropTypes.string,
//   opacity: PropTypes.number,
// };
//
// AnkleBandIcon.defaultProps = {
//   size: 32,
//   color: colors.pink,
//   opacity: 100,
// };

export default AnkleBandIcon;
