import React from 'react';

const StrengthTrainingIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7013 37.0183H10.1513C9.16133 37.0183 8.36133 36.2183 8.36133 35.2283V14.9603C8.36133 13.9723 9.16133 13.1703 10.1513 13.1703H14.7013C15.6893 13.1703 16.4913 13.9723 16.4913 14.9603V35.2283C16.4913 36.2183 15.6893 37.0183 14.7013 37.0183Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25391 27.8319V22.3579"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4882 37.0183H40.0382C41.0282 37.0183 41.8282 36.2183 41.8282 35.2283V14.9603C41.8282 13.9723 41.0282 13.1703 40.0382 13.1703H35.4882C34.5002 13.1703 33.6982 13.9723 33.6982 14.9603V35.2283C33.6982 36.2183 34.5002 37.0183 35.4882 37.0183Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.9355 27.8319V22.3579"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M29.8109 22.358H20.3789" stroke="#E0325F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.8109 27.8319H20.3789"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StrengthTrainingIcon;
