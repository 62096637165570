import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isFetching } from '@selectors/helperSelectors';
import FooterLanding from '@components/footers/FooterLanding/FooterLanding';
import styles from './LandingLayout.module.scss';

class LandingLayout extends Component<any, any> {
  render() {
    const { props } = this;

    return (
      <div id="landing" className={styles.landingWrapper}>
        {props.children}
        <FooterLanding isMobile={props.isMobile} pathInfo={props.pathInfo} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  fetching: isFetching(state),
  isMobile: state.appStatus.isMobile,
  pathInfo: state.router.location
});

export default connect(mapStateToProps)(LandingLayout);
