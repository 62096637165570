import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateQuestionnaireRequest: ['value'],
  updateQuestionnaireSuccess: [],
  updateQuestionnaireFailure: ['error']
});

export const UpdateQuestionnaireTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Reducers ------------- */

export const requestQuestionnaireData = (state: any) => ({ ...state, fetching: true, error: null });

export const successQuestionnaireData = () => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_QUESTIONNAIRE_REQUEST]: requestQuestionnaireData,
  [Types.UPDATE_QUESTIONNAIRE_SUCCESS]: successQuestionnaireData,
  [Types.UPDATE_QUESTIONNAIRE_FAILURE]: failure
});
