import { put, call } from '@redux-saga/core/effects';

import StatsAction from '@redux/Fitney2.0/Stats/stats';
import ChartStatsAction from '@redux/Fitney2.0/Stats/chartStats';
import TrainersStatsAction from '@redux/Fitney2.0/Stats/trainersStats';

export function* getStats(api: any): any {
  try {
    const response = yield call(api.getStats);
    if (response.ok) {
      yield put(StatsAction.fetchStatsSuccess(response.data));
    } else {
      yield put(StatsAction.fetchStatsFailure(response.data.errors));
    }
  } catch (e) {
    console.log('reguestForRecipes saga error: ', e);
  }
}

export function* getOverallUserStats(api: any): any {
  try {
    const response = yield call(api.getOverallUserStats);
    if (response.ok) {
      yield put(StatsAction.fetchOverallUserStatsSuccess(response.data));
    } else {
      yield put(StatsAction.fetchOverallUserStatsFailure(response.data.errors));
    }
  } catch (e) {
    console.log('reguestForRecipes saga error: ', e);
  }
}

export function* getTrainingPlanCompletion(api: any): any {
  try {
    const response = yield call(api.getTrainingPlanCompletion);
    if (response.ok) {
      yield put(StatsAction.fetchTrainingPlanCompletionSuccess(response.data));
    } else {
      yield put(StatsAction.fetchTrainingPlanCompletionFailure(response.data.errors));
    }
  } catch (e) {
    console.log('reguestForRecipes saga error: ', e);
  }
}

export function* getTrainersStats(api: any): any {
  try {
    const response = yield call(api.getTrainersStats);
    if (response.ok) {
      yield put(TrainersStatsAction.fetchTrainersStatsSuccess(response.data));
    } else {
      yield put(TrainersStatsAction.fetchTrainersStatsFailure(response.data.errors));
    }
  } catch (e) {}
}

export function* getChartStats(api: any, action: any): any {
  const { startDate, endDate } = action;
  try {
    const response = yield call(api.getChartStats, startDate, endDate);
    if (response.ok) {
      yield put(ChartStatsAction.fetchChartStatsSuccess(response.data));
    } else {
      yield put(ChartStatsAction.fetchChartStatsFailure(response.data.errors));
    }
  } catch (e) {}
}

export function* logEventToBackend(api: any, action: any): any {
  const { eventName } = action;
  try {
    if (process.env.NODE_ENV === 'production') {
      yield call(api.logEventToBackend, eventName);
    }
  } catch (e) {
    console.log('reguestLogEvent saga error: ', e);
  }
}
