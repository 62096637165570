export const clearExtraData = () => {
  localStorage.removeItem('sidng');
  localStorage.removeItem('aid');
  localStorage.removeItem('var4');
  localStorage.removeItem('PCTX');
  localStorage.removeItem('var3');
  localStorage.removeItem('utm_source');
  localStorage.removeItem('utm_medium');
  localStorage.removeItem('utm_campaign');
  localStorage.removeItem('utm_referrer');
  localStorage.removeItem('utm_expid');
  localStorage.removeItem('flows');
  localStorage.removeItem('ref-lang');
  localStorage.removeItem('extraData');
  localStorage.removeItem('af_id');
};

export const getFlows = () => {
  const flows = localStorage.getItem('flows');
  if (flows) {
    return flows.split(',');
  }
  return ['undefined'];
};

export const getAfId = () => {
  const afId = localStorage.getItem('af_id');
  if (afId) {
    return afId;
  }
  return null;
};
//todo check is needed at all
//
// export const serialize = ( obj ) => {
// 	return '?' + Object.keys(obj).reduce(function (a, k) {
// 		a.push(k + '=' + encodeURIComponent(obj[k]));
// 		return a;
// 	}, []).join('&');
// };

export const serializeArticleCategories = (categoriesArray: null | Array<any> = null) => {
  if (!categoriesArray) {
    return '';
  }
  return (
    '?filter[category_name_in]=' +
    categoriesArray
      .map((a) => {
        return a;
      })
      .join('|')
  );
};
