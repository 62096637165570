import React, { useEffect } from 'react';
import qs from 'qs';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import AppStatusActions, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import messages_en from '@translations/en.json';
import messages_es from '@translations/es.json';
import messages_pl from '@translations/pl.json';

const messages: any = {
  pl: messages_pl,
  en: messages_en,
  es: messages_es
};

const InternationalizationProvider = ({ children, language, setLanguage }: any) => {
  moment.locale(language);

  useEffect(() => {
    const { lang } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (lang) {
      setLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (state: any) => ({
  language: appStatusSelectors.language(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: any) => dispatch(AppStatusActions.setLanguage(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternationalizationProvider);
