import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingExerciseDataRequest: ['trainingId'],
  fetchTrainingExerciseDataSuccess: ['training'],
  fetchTrainingExerciseDataFailure: ['error'],
  resetTrainingExerciseData: []
});

export const TrainingExerciseDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  training: null
});

/* ------------- Selectors ------------- */

export const trainingExerciseDataSelectors = {
  getTrainingExerciseData: (state: any) => state.trainingExercise.training,
  isFetching: (state: any) => !!state.trainingExercise.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingExerciseData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchTrainingExerciseData = (state: any, action: any) => {
  const { training } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    training,
    timestamp: new Date().getTime()
  };
};

export const resetTrainingExerciseData = (state: any) => ({
  ...state,
  training: null
});

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_EXERCISE_DATA_REQUEST]: requestFetchTrainingExerciseData,
  [Types.FETCH_TRAINING_EXERCISE_DATA_SUCCESS]: successFetchTrainingExerciseData,
  [Types.RESET_TRAINING_EXERCISE_DATA]: resetTrainingExerciseData,
  [Types.FETCH_TRAINING_EXERCISE_DATA_FAILURE]: failure
});
