import React, { createContext, useContext, useEffect, useState } from 'react';

export const IpContext = createContext({});

export const IpProvider = ({ children }: any) => {
  const [ipData, setIpData] = useState(null);

  const getClientIp = async () => {
    try {
      const response = await fetch('https://backend.fitney.co/api/ipDetails', { method: 'get' });
      if (!response.ok) return;
      return await response.json();
    } catch (error) {
      return 'Unknown';
    }
  };

  useEffect(() => {
    getClientIp().then((data) => {
      setIpData(data);
    });
  }, []);

  if (ipData === null) {
    return null;
  }

  return <IpContext.Provider value={{ ipData }}>{children}</IpContext.Provider>;
};

export const useIpContext = () => useContext(IpContext);
