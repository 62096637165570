import React from 'react';

import colors from '@consts/colors';

const TimeIcon = ({ size = 34, color = colors.white, opacity = 100 }: any) => (
  <svg
    width={size}
    height={size}
    opacity={opacity / 100}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M11.9997 3.76318C7.4577 3.76318 3.7627 7.45818 3.7627 12.0002C3.7627 16.5422 7.4577 20.2372 11.9997 20.2372C16.5417 20.2372 20.2367 16.5422 20.2367 12.0002C20.2367 7.45818 16.5417 3.76318 11.9997 3.76318ZM11.9997 21.7372C6.6307 21.7372 2.2627 17.3692 2.2627 12.0002C2.2627 6.63118 6.6307 2.26318 11.9997 2.26318C17.3687 2.26318 21.7367 6.63118 21.7367 12.0002C21.7367 17.3692 17.3687 21.7372 11.9997 21.7372Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M16.4382 15.508C16.3032 15.508 16.1672 15.471 16.0432 15.395L11.6042 12.637C11.3832 12.501 11.2502 12.259 11.2502 12V6.33496C11.2502 5.92096 11.5852 5.58496 12.0002 5.58496C12.4142 5.58496 12.7502 5.92096 12.7502 6.33496V11.583L16.8342 14.121C17.1872 14.34 17.2942 14.802 17.0762 15.154C16.9342 15.383 16.6892 15.508 16.4382 15.508Z"
    />
  </svg>
);

export default TimeIcon;
