import { RootReducersKeys } from '@redux/types';

export type AvailableLocalStoreKeys = RootReducersKeys.appStatus | RootReducersKeys.auth | RootReducersKeys.userData;

interface LocalStorageDataObject {
  key: AvailableLocalStoreKeys;
  stringedData: string;
}

type ArrayOfLocalStorageDataObject = Array<LocalStorageDataObject>;

type SetLocalStorageProps = ArrayOfLocalStorageDataObject;

type GetLocalStorageProps = Array<AvailableLocalStoreKeys>;

type ClearLocalStorageProps = Array<AvailableLocalStoreKeys>;

const setData = (data: SetLocalStorageProps) => {
  data.forEach((item) => {
    localStorage.setItem(item.key, item.stringedData);
  });
};

const getData = (keys: GetLocalStorageProps) => {
  let data: ArrayOfLocalStorageDataObject = [];

  keys.forEach((item) => {
    const stringedData = localStorage.getItem(item);
    if (typeof stringedData === 'string') {
      data = [
        ...data,
        {
          key: item,
          stringedData
        }
      ];
    }
  });

  return data;
};

const clearData = (keys: ClearLocalStorageProps) => {
  keys.forEach((item) => localStorage.removeItem(item));
};

const validationData = () => {
  const validationDataKey = 'isStructureValid';
  const isValid = localStorage.getItem(validationDataKey);

  if (!isValid) {
    localStorage.clear();
    localStorage.setItem(validationDataKey, JSON.stringify(true));
    window.location.reload();
  }
};

export const localeStoreHelpers = {
  setData,
  getData,
  clearData,
  validationData
};
