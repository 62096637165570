import { defineMessages } from 'react-intl';

const translatedAppNavLinks = (intl: any) => {
  const messages: any = defineMessages({
    dailyWorkout: {
      id: 'fitney-2_0.daily-workout',
      defaultMessage: 'Daily workout',
      description: 'fitney 2 - internal navigation links'
    },
    diets: {
      id: 'fitney-2_0.diets',
      defaultMessage: 'Diet plans',
      description: 'fitney 2 - internal navigation links'
    },
    trainings: {
      id: 'fitney-2_0.training-plans',
      defaultMessage: 'Trainings',
      description: 'fitney 2 - internal navigation links'
    },
    statistics: {
      id: 'fitney-2_0.statistics',
      defaultMessage: 'Statistics',
      description: 'fitney 2 - internal navigation links'
    },
    liveTrainings: {
      id: 'fitney-2_0.live-trainings',
      defaultMessage: 'Live',
      description: 'fitney 2 - internal navigation links'
    },
    shop: {
      id: 'fitney-2_0.shop',
      defaultMessage: 'Fitney shop',
      description: 'fitney 2 - internal navigation links'
    }
  });
  Object.keys(messages).forEach((key) => {
    messages[key] = intl.formatMessage(messages[key]);
  });
  return messages;
};

export default translatedAppNavLinks;
