import React from 'react';

const MaleIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3384 38.0658C23.6024 41.8018 17.5444 41.8018 13.8064 38.0658C10.0704 34.3298 10.0704 28.2738 13.8064 24.5358C17.5444 20.7998 23.6024 20.7998 27.3384 24.5358C31.0744 28.2738 31.0744 34.3298 27.3384 38.0658Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3384 24.5365L40.8684 11.0045"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.561 11.0051H40.867V23.3131"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MaleIcon;
