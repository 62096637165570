import { createActions, createReducer } from 'reduxsauce';
import { RootReducerStateProps } from '@redux/types';
import { ILiveTrainingListItem } from '@models/LiveTraining/LiveTrainingListItem';
import { LiveTrainingsReducerProps } from '@redux/Fitney2.0/Trainings/types';
import Error from '@models/Error';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchLiveTrainingsRequest: [],
  fetchLiveTrainingsSuccess: ['liveTrainings'],
  fetchLiveTrainingsFailure: ['error']
});

export const LiveTrainingsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE: LiveTrainingsReducerProps = {
  fetching: null,
  error: null,
  data: []
};

/* ------------- Selectors ------------- */

type liveTrainingsSelectorsType = {
  getLiveTrainingsData: (state: RootReducerStateProps) => ILiveTrainingListItem[];
  isFetching: (state: RootReducerStateProps) => boolean | null;
};

export const liveTrainingsSelectors: liveTrainingsSelectorsType = {
  getLiveTrainingsData: (state) => state?.liveTrainings?.data || INITIAL_STATE.data,
  isFetching: (state) => state?.liveTrainings?.fetching || INITIAL_STATE.fetching
};

/* ------------- Reducers ------------- */

export const requestLiveFetchTrainings = (state: LiveTrainingsReducerProps) => {
  return { ...state, fetching: true, error: null };
};

export const successLiveFetchTrainings = (
  state: LiveTrainingsReducerProps,
  action: { liveTrainings: ILiveTrainingListItem[] }
) => {
  const { liveTrainings } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: liveTrainings
  };
};

export const failureLiveFetchTrainings = (state: LiveTrainingsReducerProps, action: { error: Error }) => ({
  ...state,
  fetching: false,
  error: action.error
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_LIVE_TRAININGS_REQUEST]: requestLiveFetchTrainings,
  [Types.FETCH_LIVE_TRAININGS_SUCCESS]: successLiveFetchTrainings,
  [Types.FETCH_LIVE_TRAININGS_FAILURE]: failureLiveFetchTrainings
});
