import React from 'react';

const DietTypeIcon = () => {
  return (
    <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5215 9.76184C19.9285 9.21434 25.891 11.7523 24.265 19.5568C23.3125 24.1213 21.103 27.6148 17.5975 28.9693C15.6745 29.7118 13.444 29.4253 12.6445 29.4253"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7673 9.76184C5.3603 9.21434 -0.602197 11.7523 1.0238 19.5568C1.9763 24.1213 4.1858 27.6148 7.6913 28.9693C9.6143 29.7118 11.8448 29.4253 12.6443 29.4253"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6357 10.7723C12.6957 6.25133 15.4332 4.24583 17.4567 3.42383"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3598 2.97098C13.7683 4.90298 13.1623 7.74548 13.1623 7.74548C13.1623 7.74548 10.2703 7.45448 8.86182 5.52248C7.45332 3.59198 8.05932 0.750977 8.05932 0.750977C8.05932 0.750977 10.9513 1.04048 12.3598 2.97098Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22949 12.588C11.322 14.0985 14.166 14.2725 15.8985 12.588"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DietTypeIcon;
