import React from 'react';
import { connect } from 'react-redux';

import colors from '@consts/colors';
import renderCurrentYear from '@helpers/general/renderCurrentYear';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import FitneyLogoIcon from '@components/Icons/FitneyLogoIcon/FitneyLogoIcon';
import styles from './FooterApp.module.scss';

const FooterApp = () => (
  <footer className={styles.footerWrapper}>
    <div>
      <FitneyLogoIcon size={20} color={colors.greyDark} />
      <span className={styles.copyright}>&copy;{`${renderCurrentYear({ dot: false })} Fitney`}</span>
    </div>
    <div className="d-flex flex-row">
      {/* <Link to={routes.support.path} className={styles.supportLink}>
        Support
      </Link> */}
      {/* {isMultiLang && (
        <div className={styles.langDropdownWrapper}>
          <LanguageDropdown />
        </div>
      )} */}
    </div>
  </footer>
);

const mapStateToProps = (state: any) => ({
  isMultiLang: userDataSelectors.isMultiLang()
});

export default connect(mapStateToProps)(FooterApp);
