import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingItemsRequest: ['trainingId', 'scheduleItemId'],
  fetchTrainingItemsSuccess: ['trainingItems'],
  fetchTrainingItemsFailure: ['error'],
  resetTrainingItems: [],
  fetchTrainingItemViewsRequest: ['trainingId'],
  fetchTrainingItemViewsSuccess: ['data'],
  fetchTrainingItemViewsFailure: ['error'],
  markVideoWatched: ['data']
});

export const TrainingItemsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const trainingItemsSelectors = {
  getTrainingItems: (state: any) => state.trainingItems.data,
  isFetching: (state: any) => state.trainingItems.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingItems = (state: any) => ({
  ...state,
  fetching: true,
  error: null
});

export const successFetchTrainingItems = (state: any, action: any) => {
  const { trainingItems } = action;
  const sortedItems = trainingItems.sort((a: any, b: any) => a.position - b.position);

  return {
    fetching: false,
    error: null,
    data: sortedItems,
    timestamp: new Date().getTime()
  };
};

export const resetTrainingItems = () => INITIAL_STATE;

export const fetchItemViewsRequest = (state: any) => ({
  ...state,
  error: null
});

export const fetchItemViewsSuccess = (state: any, action: any) => {
  const { data } = action;
  const completedVideosIds = data.map((item: any) => item.viewedEntityUuid);
  const newItems = state.data.map((item: any) => {
    return {
      ...item,
      completed: completedVideosIds.includes(item.id)
    };
  });
  return {
    ...state,
    data: newItems
  };
};

export const fetchItemViewsFailure = (state: any, action: any) => ({ ...state, error: action.error });

export const markVideoWatched = (state: any) => {
  return state;
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_ITEMS_REQUEST]: requestFetchTrainingItems,
  [Types.FETCH_TRAINING_ITEMS_SUCCESS]: successFetchTrainingItems,
  [Types.RESET_TRAINING_ITEMS]: resetTrainingItems,
  [Types.FETCH_TRAINING_ITEM_VIEWS_REQUEST]: fetchItemViewsRequest,
  [Types.FETCH_TRAINING_ITEM_VIEWS_FAILURE]: fetchItemViewsFailure,
  [Types.FETCH_TRAINING_ITEM_VIEWS_SUCCESS]: fetchItemViewsSuccess,
  [Types.MARK_VIDEO_WATCHED]: markVideoWatched
});
