import React from 'react';

const FitnessFocusIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5373 13.7158C26.5373 13.7158 20.7668 21.2338 13.6493 21.2338C6.53027 21.2338 0.759766 13.7158 0.759766 13.7158C0.759766 13.7158 6.53027 6.19629 13.6493 6.19629C20.7668 6.19629 26.5373 13.7158 26.5373 13.7158Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6089 13.7159C17.6089 15.9029 15.8344 17.6759 13.6489 17.6759C11.4619 17.6759 9.69043 15.9029 9.69043 13.7159C9.69043 11.5289 11.4619 9.75586 13.6489 9.75586C15.8344 9.75586 17.6089 11.5289 17.6089 13.7159Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5096 7.09164L26.4961 3.86664C26.4886 2.15814 25.1011 0.77964 23.3941 0.78714L20.2471 0.80064"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.788086 7.09164L0.801586 3.86664C0.809086 2.15814 2.19659 0.77964 3.90509 0.78714L7.05059 0.80064"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5096 20.3398L26.4961 23.5648C26.4886 25.2718 25.1011 26.6503 23.3941 26.6428L20.2471 26.6308"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.788086 20.3398L0.801586 23.5648C0.809086 25.2718 2.19659 26.6503 3.90509 26.6428L7.05059 26.6308"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FitnessFocusIcon;
