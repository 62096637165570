import * as React from 'react';

import styles from './CircleLoader.module.scss';

interface IProps {
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  type?: 'light' | 'dark';
  className?: string;
}

const CircleLoader = ({ size = 'medium', type = 'light', className }: IProps) => (
  <div className={`${styles.loaderRing} ${styles[size]} ${styles[type]} ${className ? className : ''}`}>
    <div className={styles.loaderRingItem} />
    <div className={styles.loaderRingItem} />
  </div>
);

export default CircleLoader;
