import DietDishRecipe, { IDietDishRecipes, IDietDishRecipesDTO } from './DietDishRecipe';

export interface IDietDaysSnackRecipesDTO {
  id: string;
  weekday: string;

  [key: string]: string | IDietDishRecipes;
}

export interface IDietDaysSnackRecipes {
  id: string;
  weekday: string;
  elements: [string, IDietDishRecipes][];
}

class DietDaysRecipe implements IDietDaysSnackRecipes {
  constructor(public id: string, public weekday: string, public elements: [string, IDietDishRecipes][]) {}

  static build = (data: IDietDaysSnackRecipesDTO): IDietDaysSnackRecipes => {
    let arr: [string, IDietDishRecipesDTO][] = Object.entries(data).filter(
      (key): key is [string, IDietDishRecipesDTO] => {
        return key[0] !== 'id' && key[0] !== 'weekday' && key[1] != null;
      }
    );

    return new DietDaysRecipe(
      data.id,
      data.weekday,
      arr.map((dietDay) => {
        return [dietDay[0], DietDishRecipe.build(dietDay[1])];
      })
    );
  };
}

export default DietDaysRecipe;
