import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { RootReducerStateProps } from '@redux/types';
import { AuthReducer } from './types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  logInRequest: ['username', 'password', 'extra_data'],
  logInFormStart: [],
  logInFacebookMobileStart: [],
  logInFacebookRequest: ['token'],
  signUpFacebookRequest: ['token', 'marketing_agreement', 'questionnaireData'],
  signUpFacebookRequestNoRedirect: ['token', 'marketing_agreement', 'questionnaireData'],
  signUpFacebookRequestViaLanding: [
    'token',
    'marketing_agreement',
    'referrer',
    'landing_trainer_id',
    'training_type_id',
    'onSuccess',
    'onError'
  ],
  logInSuccess: ['authData'],
  logInFailure: ['error'],
  logInFacebookFailure: ['error'],
  logOutRequest: [],
  logOutNoRedirectRequest: [],
  logOutSuccess: [],
  forgotPasswordRequest: ['email'],
  forgotPasswordSuccess: ['message'],
  forgotPasswordFailure: ['error'],
  checkToken: [],
  refreshToken: [],
  refreshTokenFailure: [],
  registerRequest: ['email', 'password', 'password_confirmation', 'phone_number', 'marketing_agreement'],
  registerFacebookRequest: [
    'token',
    'phone',
    'marketing_agreement',
    'questionnaireData',
    'trainingsQuestionnaireData',
    'dietsQuestionnaireData'
  ],
  registerRequestViaLanding: [
    'email',
    'password',
    'password_confirmation',
    'marketing_agreement',
    'referrer',
    'landing_trainer_id',
    'training_type_id',
    'onSuccess',
    'onError'
  ],
  clearErrors: [],
  clearFetching: []
});

export const LogInTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  facebookLoginError: null,
  message: null,
  authData: null,
  updated: null,
  showResetPassword: null
});

/* ------------- Selectors ------------- */

export const authSelectors = {
  isLoggedIn: (state: RootReducerStateProps) => !!state.auth?.authData
};

/* ------------- Reducers ------------- */

export const logInRequest = (state: AuthReducer) => ({
  ...state,
  fetching: true,
  authData: null,
  showResetPassword: false,
  error: null,
  facebookLoginError: null,
  message: null
});

export const logInFormStart = (state: AuthReducer) => ({
  ...state,
  fetching: false,
  error: null,
  facebookLoginError: null,
  showResetPassword: false,
  message: null
});

export const logInFacebookMobileStart = (state: AuthReducer) => ({
  ...state,
  fetching: true
});

export const successLogOut = () => INITIAL_STATE;

export const successLogIn = (state: AuthReducer, action: any) => {
  const { authData } = action;

  return {
    ...state,
    error: null,
    facebookLoginError: null,
    authData: authData,
    updated: new Date()
  };
};

export const clearFetching = (state: AuthReducer, action: any) => {
  return {
    ...state,
    fetching: false
  };
};

export const failure = (state: AuthReducer, action: any) => {
  return {
    ...state,
    fetching: false,
    error: action.error,
    facebookLoginError: null,
    authData: null,
    showResetPassword: action.error ? !action.error.errors : true
  };
};

export const failureLogInFacebook = (state: AuthReducer, action: any) => {
  return {
    ...state,
    fetching: false,
    error: null,
    facebookLoginError: action.error,
    authData: null,
    showResetPassword: action.error ? !action.error.errors : true
  };
};

export const requestForgotPassword = (state: AuthReducer, action: any) => ({
  ...state,
  error: action.error,
  facebookLoginError: null,
  showResetPassword: false,
  fetching: true,
  message: null
});

export const successForgotPassword = (state: AuthReducer, action: any) => ({
  ...state,
  error: null,
  facebookLoginError: null,
  message: action.message,
  showResetPassword: false,
  fetching: false
});

export const failureForgotPassword = (state: AuthReducer, action: any) => {
  return {
    ...state,
    error: action.error,
    facebookLoginError: null,
    showResetPassword: false,
    fetching: false
  };
};

export const requestRegister = (state: AuthReducer, action: any) => ({
  ...state,
  fetching: true,
  authData: null,
  showResetPassword: false,
  error: null,
  facebookLoginError: null,
  message: null
});

export const refreshToken = (state: AuthReducer) => {
  return state;
};

export const refreshTokenFailure = () => INITIAL_STATE;

export const clearErrors = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOG_IN_REQUEST]: logInRequest,
  [Types.LOG_IN_FORM_START]: logInFormStart,
  [Types.LOG_OUT_SUCCESS]: successLogOut,
  [Types.LOG_IN_SUCCESS]: successLogIn,
  [Types.LOG_IN_FAILURE]: failure,
  [Types.FORGOT_PASSWORD_REQUEST]: requestForgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: successForgotPassword,
  [Types.FORGOT_PASSWORD_FAILURE]: failureForgotPassword,
  [Types.REGISTER_REQUEST]: requestRegister,
  [Types.REGISTER_REQUEST_VIA_LANDING]: requestRegister,
  [Types.CLEAR_ERRORS]: clearErrors,
  [Types.CLEAR_FETCHING]: clearFetching,
  [Types.REFRESH_TOKEN]: refreshToken,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure
});
