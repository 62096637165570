import React from 'react';

const JumpingRopeIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 36 36">
        <path
          fill={color}
          d="M7.9,30H3.56a1,1,0,0,1-1-1.09l1-11.29a1,1,0,0,1,1-.91H6.9a1,1,0,0,1,1,.91l1,11.29A1,1,0,0,1,7.9,30ZM4.65,28H6.8L6,18.66H5.47Z"
        />
        <path
          fill={color}
          d="M31.9,33.18H27.56a1,1,0,0,1-1-1.09l1-11.29a1,1,0,0,1,1-.91H30.9a1,1,0,0,1,1,.91l1,11.29a1,1,0,0,1-1,1.09Zm-3.24-2h2.15L30,21.89h-.51Z"
        />
        <path
          fill={color}
          d="M17.64,32a4.89,4.89,0,0,1-4.89-4.89V8.13a3,3,0,1,0-6,0V14.4a1,1,0,1,1-2,0V8.13a5,5,0,1,1,10,0V27.06a2.89,2.89,0,1,0,5.77,0V17.44a5.11,5.11,0,0,1,10.21,0,1,1,0,0,1-2,0,3.11,3.11,0,0,0-6.21,0v9.62A4.89,4.89,0,0,1,17.64,32Z"
        />
      </svg>
    </React.Fragment>
  );
};

export default JumpingRopeIcon;
