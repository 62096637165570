import React from 'react';

const FemaleIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3706 16.3704C16.3706 11.0844 20.6546 6.80237 25.9366 6.80237C31.2226 6.80237 35.5046 11.0844 35.5046 16.3704C35.5046 21.6544 31.2226 25.9364 25.9366 25.9364C20.6546 25.9364 16.3706 21.6544 16.3706 16.3704Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25.937 25.937V45.071" stroke="#E0325F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.7146 36.599H19.1606" stroke="#E0325F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FemaleIcon;
