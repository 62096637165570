import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchCategoriesRequest: [],
  fetchCategoriesSuccess: ['categories'],
  fetchCategoriesFailure: ['error']
});

export const CategoriesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const topArticlesSelectors = {
  //
};

/* ------------- Reducers ------------- */

export const setFetching = (state: any) => {
  return { ...state, fetching: true, error: null };
};

export const saveCategories = (state: any, action: any) => {
  const { categories } = action;
  return { ...state, fetching: false, error: null, data: categories };
};

export const setError = (state: any, action: any) => {
  const { error } = action;
  return { ...state, fetching: false, error: error };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CATEGORIES_REQUEST]: setFetching,
  [Types.FETCH_CATEGORIES_SUCCESS]: saveCategories,
  [Types.FETCH_CATEGORIES_FAILURE]: setError
});
