import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import NewsletterActions, { newsletterSelectors } from '@redux/ToCheck/newsletter/newsletter';
import AppStatusActions, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import supportActions from '@redux/ToCheck/support/support';
import renderCurrentYear from '@helpers/general/renderCurrentYear';
import { LANGUAGES } from '@consts/consts';
import Logo from '@components/UIKit/Logo';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import ExternalLinks from '@components/Landing/Navigation/ExternalLinks/ExternalLinks';
import InternalLinks from '@components/Landing/Navigation/InternalLinks/InternalLinks';
import translatedTexts from '@containers/landing/Landing.lang';
import styles from './FooterLanding.module.scss';

const FooterLanding = ({
  goToSupport,
  isEmailSubscribed,
  isMultiLang,
  isMobile,
  intl,
  cleanNewsletter,
  saveForNewsletter,
  isEmailSending,
  error,
  language
}: any) => {
  const [emailValue, setEmailValue] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    cleanNewsletter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isEmailSubscribed ? setEmailValue(`👍 ${translatedTexts(intl).newsletterSuccess}`) : setEmailValue('');
  }, [intl, isEmailSubscribed]);

  useEffect(() => {
    if (error?.message) {
      setEmailErrorMessage(error.message);
    }
  }, [error]);

  const validateEmail = (e: any) => {
    const email = e.target.value;
    const emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    setEmailValue(email);
    !emailPattern.test(email) ? setIsEmailValid(false) : setIsEmailValid(true);
  };

  const adjustEmailErrorMessage = () =>
    !isEmailValid ? setEmailErrorMessage(translatedTexts(intl).newsletterError) : setEmailErrorMessage('');

  const submitEmail = (e: any) => {
    e.preventDefault();
    adjustEmailErrorMessage();

    if (isEmailValid && !!emailValue) {
      saveForNewsletter(emailValue);
    }
  };

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.contentWrapper}>
        <div>
          <div className={styles.logoAndDropdownWrapper}>
            <Logo />
            {isMultiLang && <LanguageDropdown alignRight={isMobile} />}
          </div>
          <form className={styles.newsletterWrapper} onSubmit={submitEmail} noValidate>
            <div>
              <label>
                <span>Newsletter:</span>
                <input
                  type="email"
                  name="user_email"
                  placeholder={translatedTexts(intl).newsletterPlaceholder}
                  onChange={validateEmail}
                  value={emailValue}
                />
              </label>
              <button disabled={!isEmailValid || isEmailSending || !emailValue}>
                <i
                  className={`${!isEmailValid || isEmailSending || !emailValue ? styles.buttonDisabled : ''} ${
                    isEmailSubscribed && !isEmailSending && emailValue ? `icon-tick` : 'icon-arrow-forward'
                  }`}
                />
              </button>
            </div>
            {emailErrorMessage && <p className={styles.errorMessage}>{emailErrorMessage}</p>}
            {language !== LANGUAGES.ES && (
              <p className={styles.newsletterSubtitle}>{translatedTexts(intl).newsletterSubtitle}</p>
            )}
          </form>
          {!isMobile && (
            <p className={styles.copyright}>
              {translatedTexts(intl).copyright}
              {renderCurrentYear({ dot: true })}
              {translatedTexts(intl).rightsReserved}
            </p>
          )}
        </div>
        <div className={styles.links}>
          <div className={styles.linksWrappers}>
            <ul>
              <span className={styles.linksGroupTitle}>Fitney</span>
              <InternalLinks />
              <li onClick={goToSupport}>{translatedTexts(intl).contact}</li>
            </ul>
            <ul>
              <span className={styles.linksGroupTitle}>{translatedTexts(intl).externalLinksTitle}</span>
              <ExternalLinks />
              <li className={styles.partnerProgramTooltip}>
                {translatedTexts(intl).partnerProgram}
                <div className={styles.tooltip}>
                  <span>
                    <FormattedMessage
                      id="coronasummer.footer.affiliateTitle"
                      defaultMessage="Let's be partners"
                      description="coronasummer landing, footer, affiliate title"
                    />
                  </span>
                  <br />
                  <span>
                    <FormattedMessage
                      id="coronasummer.footer.affiliate"
                      defaultMessage="Do you want to promote Fitney?{break} Become an affiliate: {email}"
                      description="coronasummer landing, footer, affiliate text"
                      values={{
                        break: <br />,
                        email: (
                          <a className={styles.emailLink} href="mailto:affiliate@fitney.co">
                            affiliate@fitney.com
                          </a>
                        )
                      }}
                    />
                  </span>
                  <div className={styles.tooltipArrow} />
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.socialMediaLinks}>
            <span className={styles.followUs}>{translatedTexts(intl).socialMediaLinksTitle}</span>
            <a
              href={
                language === LANGUAGES.ES
                  ? 'https://www.facebook.com/Fitney-Latam-102939598668572'
                  : 'https://www.facebook.com/Fitney.co/'
              }
              className={styles.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-fb-circle" />
              {!isMobile && <span>Facebook</span>}
            </a>
            <a
              href={
                language === LANGUAGES.ES
                  ? 'https://www.instagram.com/fitney_latam/'
                  : 'https://www.instagram.com/fitney.official/'
              }
              className={styles.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-instagram-circle" />
              {!isMobile && <span>Instagram</span>}
            </a>
          </div>
          {isMobile && (
            <p className={styles.copyright}>
              {translatedTexts(intl).copyright}
              {renderCurrentYear({ dot: true })}
              {translatedTexts(intl).rightsReserved}
            </p>
          )}
        </div>
      </div>
    </footer>
  );
};
const mapStateToProps = (state: any) => ({
  language: appStatusSelectors.language(state),
  isMultiLang: userDataSelectors.isMultiLang(),
  isEmailSubscribed: newsletterSelectors.isEmailSubscribed(state),
  isEmailSending: newsletterSelectors.isFetching(state),
  error: newsletterSelectors.error(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  goToSupport: () => dispatch(supportActions.goToSupport()),
  setLanguage: (language: any) => dispatch(AppStatusActions.setLanguage(language)),
  saveForNewsletter: (email: any) => dispatch(NewsletterActions.saveEmailForNewsletterRequest(email)),
  cleanNewsletter: () => dispatch(NewsletterActions.clean())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FooterLanding));
