export const validateLocationChange = (history: any) => () => (next: any) => (action: any) => {
  if (
    action.type === '@@router/CALL_HISTORY_METHOD' &&
    action.payload.method === 'push' &&
    action.payload.args &&
    action.payload.args[0] &&
    action.payload.args[0] === history.location.pathname
  ) {
    return;
  }
  return next(action);
};
