import React from 'react';

const HeightIcon = () => {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.787109 20.4421V2.53513C0.787109 1.56913 1.56861 0.786133 2.53461 0.786133H10.2536C11.2181 0.786133 12.0011 1.56913 12.0011 2.53513V20.4421"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6406 9.22852H21.4666C22.4311 9.22852 23.2126 10.01 23.2126 10.976V20.4425"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.787109 6.30664H5.19261"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.787109 13.374H5.19261"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.787109 20.4424H5.19261"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6406 13.6611H17.6176"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6406 19.5146H17.6176"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2125 20.4424C23.2125 23.5384 20.703 26.0479 17.607 26.0479C14.5095 26.0479 12 23.5384 12 20.4424"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 20.4424C12.0001 23.5384 14.5096 26.0479 17.6056 26.0479H6.39463C3.29713 26.0479 0.786133 23.5384 0.786133 20.4424"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeightIcon;
