import React from 'react';

import colors from '@consts/colors';

const NutsIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10308_11056)">
      <path
        d="M43.2899 19.5198C43.8799 30.1898 29.2699 43.8098 25.1899 43.8098C21.1099 43.8098 6.87994 30.1698 7.09994 19.5198C7.30994 9.36984 15.1999 6.58984 25.1999 6.58984C35.1999 6.58984 42.7399 9.37984 43.2999 19.5198H43.2899Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.38 10.9395L35.41 11.4195L32.64 14.2895L28.7 13.5995L25.2 15.4795L21.69 13.5995L17.76 14.2895L14.99 11.4195L11.02 10.9395"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1899 43.8096C21.9499 41.3496 17.1399 27.8596 17.1399 24.0996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.21 43.8096C28.45 41.3496 33.26 27.8596 33.26 24.0996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10308_11056">
        <rect width="50.4" height="50.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NutsIcon;
