import {get} from "@services/api";
import {IApiBuilder} from "@services/types";

const TrainingsUserApi = (props: IApiBuilder) => {
    const { client, buildUrl } = props;

    const fetchUserData = async (id: string) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/user/${id}` }),
        });

    return {
        fetchUserData
    }
}

export default TrainingsUserApi;