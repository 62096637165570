import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainersStatsRequest: [],
  fetchTrainersStatsSuccess: ['data'],
  fetchTrainersStatsFailure: ['error']
});

export const TrainersStatsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: null
});

/* ------------- Selectors ------------- */

export const trainersStatsSelectors = {
  getTrainersStats: (state: any) => state.trainersStats.data,
  fetching: (state: any) => state.trainersStats.fetching
};

/* ------------- Reducers ------------- */

export const fetchTrainersStatsRequest = (state: any) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const fetchTrainersStatsSuccess = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const fetchTrainersStatsFailure = (state: any, action: any) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINERS_STATS_REQUEST]: fetchTrainersStatsRequest,
  [Types.FETCH_TRAINERS_STATS_SUCCESS]: fetchTrainersStatsSuccess,
  [Types.FETCH_TRAINERS_STATS_FAILURE]: fetchTrainersStatsFailure
});
