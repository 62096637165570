import React from 'react';

const TooltipInfoIcon = ({ size = 16 }: any) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7568 8.25351C15.7568 12.3975 12.3978 15.7565 8.25376 15.7565C4.10976 15.7565 0.749756 12.3975 0.749756 8.25351C0.749756 4.10851 4.10976 0.749512 8.25376 0.749512C12.3978 0.749512 15.7568 4.10851 15.7568 8.25351Z"
        fill="#DFDFDF"
      />
      <path
        d="M8.25366 7.28857V11.9416"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25366 4.95385V4.89185"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default TooltipInfoIcon;
