import React from 'react';

const FiveTimesPerWeekIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5176 12.9644C37.4536 13.7624 42.0176 15.8384 45.8156 18.8764C41.1636 25.0344 43.1256 22.7584 39.5256 27.2204C37.8296 25.2144 36.0296 23.6144 34.1456 22.3744"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8167 18.9936C14.9787 19.4436 10.0467 21.1896 5.34668 23.6636C9.23868 18.6576 14.7767 14.9376 21.2747 13.3876"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7015 34.73C31.6155 37.744 29.1015 40.118 26.0855 40.032C23.0735 39.946 20.6975 37.434 20.7835 34.418C20.8435 32.318 23.8455 20.064 25.6595 12.826C25.9835 11.544 27.8175 11.596 28.0655 12.894C29.4635 20.224 31.7615 32.628 31.7015 34.73Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FiveTimesPerWeekIcon;
