import { serializeArticleCategories } from '@helpers/general/extraData';
import {get} from "@services/api";
import {IApiBuilder} from "@services/types";

const ArticlesApi = (props: IApiBuilder) => {
  const {client, buildUrl} = props;

  const getArticles = (categories: any) => get({
    client: client,
    url: buildUrl({ version: false, url: `/daftblog/articles${serializeArticleCategories(categories)}` })
  });

  const getArticle = (articleId: any) => get({
    client: client,
    url: buildUrl({ version: false, url: `/daftblog/article/${articleId}` })
  });

  const getTopArticles = (categories: any) => get({
    client: client,
    url: buildUrl({ version: false, url: `/daftblog/articles/top${serializeArticleCategories(categories)}` })
  });

  const incrementArticleViews = (articleId: any) => get({
    client: client,
    url: buildUrl({ version: false, url: `/daftblog/article/incrementViews/${articleId}` })
  });

  const getCategories = () => get({
    client: client,
    url: buildUrl({ version: false, url: `/daftblog/categories` })
  });

  return {
    getArticles,
    getArticle,
    getTopArticles,
    incrementArticleViews,
    getCategories
  };
}

export default ArticlesApi;