import RouteActions from '@redux/route/routeActions';
import store from '../store';
import buildClient, {buildRawUrl} from '@services/api';
import {API_CLIENT_TYPES, IApiBuilderProps} from "@services/types";

const config = {
  baseURL: process.env.REACT_APP_TRAININGS_API_URL as string,
  defaultApiVersion: process.env.REACT_APP_TRAININGS_API_URL_DEFAULT_VERSION as string,
  apiUrlSuffix: process.env.REACT_APP_TRAININGS_API_URL_SUFFIX as string,
  cookie: {
    amount: 30,
    unit: 'minutes'
  }
};

const monitors = (response: any) => {
  switch (response.status) {
    case 403:
      store.dispatch(RouteActions.noActiveSubscriptionError());
      break;
  }
};

export const buildUrl = (props: Omit<IApiBuilderProps, 'config'>) => {
  return buildRawUrl({config, ...props});
};

const apiClient = {client: buildClient({config, monitors}), buildUrl, type: API_CLIENT_TYPES.TRAININGS};

export default apiClient;