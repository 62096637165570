import React from 'react';

import { PORTALS } from '@consts/consts';
import heroImage from '@assets/images/agnieszka_matczak.png';
import FooterSignUpSignIn from '@components/footers/FooterSignUpSignIn/FooterSignUpSignIn';
import Logo from '@components/UIKit/Logo';
import styles from './LoginLayout.module.scss';

const LoginLayout = ({ children, isMobile }: any) => {
  return (
    <div className={styles.main}>
      <div className={styles.scrollableWrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Logo />
            <div id={PORTALS.LOGIN_HEADER} />
          </div>
          {children}
          <FooterSignUpSignIn />
        </div>
        {!isMobile && (
          <div className={styles.hero}>
            <img
              className={styles.imageHero}
              src={heroImage}
              loading="lazy"
              alt="Fit girl in white top and pink pants"
            />
            <div id={PORTALS.LOGIN_HERO_CTA} className={styles.heroActionButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginLayout;
