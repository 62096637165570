import React from 'react';

import colors from '@consts/colors';

const GlowIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M8.21004 12.5048C2.03658 11.4853 5.71126 19.6407 11.2968 21.3883M10.2678 15.2719C8.50398 12.9418 7.47507 9.44662 7.62205 7.55341C8.21 2.31068 12.7666 1 17.1762 1C22.1738 1 27.3181 2.31068 27.9061 7.55341C28.347 11.1942 27.3181 16.4369 23.0555 21.3884M21.8797 31H12.6195C12.0316 31 11.4436 30.5631 11.4436 29.8349V24.301C11.4436 23.7184 11.8846 23.1359 12.6195 23.1359H21.8797C22.4677 23.1359 23.0556 23.5728 23.0556 24.301V29.8349C23.0556 30.4175 22.4677 31 21.8797 31Z" />
    </g>
  </svg>
);

export default GlowIcon;
