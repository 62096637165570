import * as React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import LogInActions from '@redux/Fitney2.0/Auth/auth';
import { mainRoutes } from '@routes/mainRoutes';
import Loader from '@components/UIKit/Loader/Loader';
import logo from '@assets/logo/logo_sygnet.png';
import styles from './autologin.module.scss';

const mapDispatchToProps = (dispatch: any) => ({
  logInSuccess: (authData: any) => dispatch(LogInActions.logInSuccess(authData))
});

const autologin = (Component: any) =>
  connect(
    null,
    mapDispatchToProps
  )(({ location, logInSuccess, ...props }: any) => {
    const router = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);

    const getData = React.useCallback(
      async (url) =>
        await fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data: any) => data),
      []
    );

    const getUrlParams = React.useCallback(() => {
      const { redirectPath, autologin } = qs.parse(location?.search, { ignoreQueryPrefix: true });
      return { redirectPath, autologin };
    }, [location]);

    React.useEffect(() => {
      (async () => {
        const { redirectPath, autologin } = getUrlParams();

        if (!!autologin && !!redirectPath) {
          setIsLoading(true);

          try {
            const authData = await getData(atob(autologin.toString()));

            logInSuccess(authData);
            router.replace(redirectPath.toString());
          } catch (e) {
            router.replace(mainRoutes.main.path);
          }

          setIsLoading(false);
        }
      })();
    }, [getUrlParams, getData, logInSuccess, router]);

    return (
      <>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <img src={logo} className={styles.loaderLogo} alt="Logo Fitney" />
            <Loader variant="primary" />
          </div>
        )}

        <Component {...props} />
      </>
    );
  });

export default autologin;
