import jwt_decode from 'jwt-decode';
import { isArray } from 'lodash';
import { RootReducersKeys, RootReducerStateProps } from '@redux/types';

const token = (state: RootReducerStateProps) => state?.[RootReducersKeys.auth]?.authData?.access_token || null;

const tokenExpiresIn = (state: RootReducerStateProps) => state?.[RootReducersKeys.auth]?.authData?.expires_in || 0;

const isTokenExpired = (token: string, expirationMargin: number = 0): boolean => {
  try {
    const decodedToken: { exp: number } = jwt_decode(token);
    return Date.now() + expirationMargin >= decodedToken.exp * 1000;
  } catch {
    return true;
  }
};

const hasPremiumAccess = (state: RootReducerStateProps) => {
  const accessToken = token(state);

  if (accessToken) {
    const decodedToken: { roles?: Array<string> } = jwt_decode(accessToken);

    const roles = decodedToken.roles;

    return isArray(roles) && roles.includes('subscribed');
  }

  return false;
};

const authSelectors = {
  token,
  tokenExpiresIn,
  isTokenExpired,
  hasPremiumAccess
};

export default authSelectors;
