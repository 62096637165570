import React from 'react';

import FooterSignUpSignIn from '@components/footers/FooterSignUpSignIn/FooterSignUpSignIn';
import Logo from '@components/UIKit/Logo';
import styles from './SubscriptionPlansLayout.module.scss';

const SubscriptionPlansLayout = ({ children }: any) => {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Logo />
      </div>
      {children}
      <FooterSignUpSignIn />
    </div>
  );
};

export default SubscriptionPlansLayout;
