import { call, put } from '@redux-saga/core/effects';
import TrainingsUserActions from '@redux/Fitney2.0/TrainingsUser/trainingsUser';
import TrainingsUser from '@models/TrainingsUser/TrainingsUser';

export function* getTrainingUser(trainingsUserAPI: any, action: any): any {
  const { id } = action;
  const response = yield call(trainingsUserAPI.fetchUserData, id);

  if (response.ok) {
    yield put(TrainingsUserActions.getTrainingsUserSuccess(TrainingsUser.build(response.data)));
  } else {
    yield put(TrainingsUserActions.getTrainingsUserFailure('error'));
  }
}
