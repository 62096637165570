import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
// @ts-ignore
import { compose } from 'redux';

import {ACCESS, LANGUAGES} from '@consts/consts';
import authSelectors from '@selectors/authSelectors';
import routes from '@routes';
import { withChatbot } from '@HOCs/index';
import DynamicPageLoader from '@components/DynamicPageLoader';
import {appStatusSelectors} from "@redux/Fitney2.0/AppStatus/appStatus";
import {goToExternalPage} from "@helpers/general/landingHelpers";

const Auth = ({
  layout: Layout,
  path,
  redirectTo = routes.login.path,
  access,
  language,
  languages = [LANGUAGES.EN, LANGUAGES.PL, LANGUAGES.AE, LANGUAGES.ES, LANGUAGES.SA],
  token,
  auth,
  chat,
  layoutProps,
  ...routeProps
}: any) => {
  const location = useLocation();
  const { pageName } = routeProps;
  const hasAccess = access === ACCESS.ALL || token;

  return (
    <>
      {hasAccess ? (
        <Route
          {...routeProps}
          path={path}
          render={(routerProps) => {
            return languages.includes(language) ? (
                <Layout {...layoutProps}>
                  <DynamicPageLoader pathToPage={pageName} {...routeProps} {...routerProps} />
                </Layout>
            ) : (
                goToExternalPage()
            );
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { cameFrom: location }
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  language: appStatusSelectors.language(state) as keyof typeof LANGUAGES,
  token: authSelectors.token(state)
});

export default compose(connect(mapStateToProps), withChatbot)(Auth);
