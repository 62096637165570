import { put, select, take } from '@redux-saga/core/effects';

import UserDataActions, { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import RouteRedirectsActions from '@redux/route/routeRedirects';
import SnackBarActions from '@redux/Fitney2.0/Common/snackBar/snackBar';
import { authSelectors } from '@redux/Fitney2.0/Auth/auth';
import LogInActions from '@redux/Fitney2.0/Auth/auth';
import ArticleActions from '@redux/ToCheck/blog/articles';
import dietsQuestionnaireActions from '@redux/Fitney2.0/Diet/dietQuestionnaire';
import trainingsQuestionnaireActions from '@redux/Deprecated/questionnaire/trainingQuestionnaireForm';
import questionnaireActions from '@redux/Fitney2.0/Trainings/trainingQuestionnaire';
import { clearExtraData } from '@helpers/general/extraData';
import authSelector from '@selectors/authSelectors';

export function* userLoggedInAction() {
  yield put(UserDataActions.getLoggedInUserDataRequest());
  yield take('GET_LOGGED_IN_USER_DATA_SUCCESS');
  // check from db permissions where can user go
  yield put(RouteRedirectsActions.goToDailyWorkout());
  yield put(LogInActions.clearFetching());
}

export function* userLoggedOutAction() {
  yield put(RouteRedirectsActions.goToLogin());
  clearExtraData();
}

export function* userRegisteredAction(api: any, action: any): any {
  const { trainingsQuestionnaireData } = action;

  yield put(UserDataActions.getLoggedInUserDataRequest());
  yield take('GET_LOGGED_IN_USER_DATA_SUCCESS');

  if (trainingsQuestionnaireData) {
    yield put(questionnaireActions.proceedQuestionnaireRequest(trainingsQuestionnaireData));
  } else {
    const isFreemium = yield select(userDataSelectors.isFreemium);

    if (isFreemium) {
      yield put(RouteRedirectsActions.goToTrainingPlans());
    } else {
      yield put(RouteRedirectsActions.goToChooseSubscriptionPlan());
    }
  }

  yield put(LogInActions.clearFetching());
}

export function* userPasswordResetedAction() {
  yield put(RouteRedirectsActions.goToResetPasswordSuccess());
}

export function* questionnaireProceededAction(): any {
  const isFreemium = yield select(userDataSelectors.isFreemium);

  if (isFreemium) {
    yield put(RouteRedirectsActions.goToDailyWorkout());
  } else {
    yield put(RouteRedirectsActions.goToChooseSubscriptionPlan());
  }
}

export function* changedTrainingAction() {
  yield put(SnackBarActions.showSnackBar('Current training plan updating success.'));
}

export function* dailyWorkoutChosenAction() {
  yield put(RouteRedirectsActions.goToDailyWorkout());
}

export function* subscriptionCreatedAction(api: any, action: any): any {
  const { paymentPlanName } = action;

  if (paymentPlanName) {
    const temp: any = window;
    temp.dataLayer.push({ event: 'sign-up', 'subsciption-plan': paymentPlanName });
  }

  yield put(UserDataActions.getLoggedInUserDataRequest());
  yield take('GET_LOGGED_IN_USER_DATA_SUCCESS');

  if (paymentPlanName) {
    const temp: any = window;
    temp.dataLayer.push({ event: 'success' });
  }

  yield put(RouteRedirectsActions.goToThankYou());
}

export function* supportLinkClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToSupport());
}

export function* hasNoPermission(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToLogin());
}

export function* userIsNotLoggedIn(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToLogin());
}

export function* emailNotVerifiedError(api: any, action: any): any {}

export function* noActiveSubscriptionError(api: any, action: any): any {
  const isFreemium = yield select(userDataSelectors.isFreemium);
  const isSubscribed = yield select(authSelector.hasPremiumAccess);

  if (isFreemium) {
    yield put(RouteRedirectsActions.goToHome());
  } else if (!isSubscribed) {
    yield put(RouteRedirectsActions.goToChooseSubscriptionPlan());
  }
}

export function* questionnaireNotCompletedError(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToQuestionnaire());
}

export function* noActiveDailyWorkoutError(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToQuestionnaire());
}

export function* userAlreadySubscribed(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToHome());
}

export function* signUpButtonClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToRegister());
}

export function* loginButtonClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToLogin());
}

export function* logoClicked(api: any, action: any): any {
  if (window.location.hostname === 'fitney.co/app') {
    if (yield select(authSelectors.isLoggedIn)) {
      yield put(RouteRedirectsActions.goToHome());
    } else {
      window.location.href = `https://fitney.co/`;
    }
  } else {
    // let pathname = window.location.pathname

    // if (pathname.substring(0, 9) === "/blog") {
    // 	pathname = '/blog'
    // }

    // switch (pathname) {
    // 	case '/blog':

    // window.location.href = '/blog'
    // break
    // default:
    // 	window.location.href = '/'
    // 	break
    // }

    window.location.href = `/`;
  }
}

// eslint-disable-next-line require-yield
export function* navbarTrainingClicked(api: any, action: any): any {
  // const {trainingPlanId} = action

  window.location.href = `https://app.fitney.co/`;
}

export function* skipQuestionnaireAction(api: any, action: any): any {
  const temp: any = window;
  temp.dataLayer.push({ event: 'questionnaire_skip' });
  if (yield select(authSelectors.isLoggedIn)) {
    yield put(RouteRedirectsActions.goToTrainingPlans());
  } else {
    yield put(dietsQuestionnaireActions.setDietQuestionnaireSkipped());
    yield put(trainingsQuestionnaireActions.setTrainingQuestionnaireSkipped());
    yield put(RouteRedirectsActions.goToRegister());
  }
}

export function* logOutAction(api: any, action: any): any {
  yield put(LogInActions.logOutRequest());
}

export function* logOutActionNoRedirect(api: any, action: any): any {
  yield put(LogInActions.logOutNoRedirectRequest());
}

export function* trainingPlansLinkClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToTrainingPlans());
}

export function* dailyWorkoutLinkClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToDailyWorkout());
}

export function* dietPlanLinkClicked(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToDietPlan());
}

export function* changeDailyWorkoutAction(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToTrainingPlans());
}

export function* openTrainingPlanAction(api: any, action: any): any {
  const { trainingPlanId } = action;
  yield put(RouteRedirectsActions.goToTrainingPlan(trainingPlanId));
}

export function* chooseSubscriptionPlanAction(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToChooseSubscriptionPlan());
}

export function* articleClicked(api: any, action: any): any {
  const { articleId } = action;
  yield put(RouteRedirectsActions.goToArticle(articleId));
}

export function* articleVisited(api: any, action: any): any {
  const { articleId } = action;
  yield put(ArticleActions.incrementArticleViewsRequest(articleId));
}

export function* shopPurchaseSucceeded(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToShopPurchaseSuccess());
}

export function* shopPromoPurchaseSucceeded(api: any, action: any): any {
  yield put(RouteRedirectsActions.goToPromoPurchaseSuccess());
}

export function* openRecipeAction(api: any, action: any): any {
  const { recipeId, ratio } = action;
  yield put(RouteRedirectsActions.goToRecipe(recipeId, ratio));
}
