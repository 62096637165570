// @ts-ignore
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as AppStatusReducer } from '@redux/Fitney2.0/AppStatus/appStatus';
import { reducer as AppUIReducer } from '@redux/Fitney2.0/AppStatus/appUI';
import { LogInTypes, reducer as authReducer } from '@redux/Fitney2.0/Auth/auth';
import { reducer as userDataReducer } from '@redux/Fitney2.0/UserData/userData';
import { reducer as creditCardReducer } from '@redux/Fitney2.0/Payment/Strall/creditCard';
import { reducer as kryptonimPaymentPlansReducer } from '@redux/Fitney2.0/Payment/Kryptonim/paymentPlan';
import { reducer as kryptonimCreateWalletAddressReducer } from '@redux/Fitney2.0/Payment/Kryptonim/walletAddress';
import { reducer as paymentPlansReducer } from '@redux/Fitney2.0/Payment/Strall/paymentPlan';
import { reducer as subscriptionReducer } from '@redux/Fitney2.0/Payment/Strall/subscription';
import { reducer as couponReducer } from '@redux/Fitney2.0/Payment/Strall/coupon';
import { reducer as resetPasswordFormReducer } from '@redux/Fitney2.0/Auth/resetPassword';
import { reducer as trainingQuestionnaireReducer } from '@redux/Fitney2.0/Trainings/trainingQuestionnaire';
import { reducer as dietQuestionnaireReducer } from '@redux/Fitney2.0/Diet/dietQuestionnaire';
import { reducer as updateFullNameReducer } from '@redux/Fitney2.0/Settings/updateFullName';
import { reducer as updatePasswordReducer } from '@redux/Fitney2.0/Settings/updatePassword';
import { reducer as updateBirthDateReducer } from '@redux/Fitney2.0/Settings/updateBirthDate';
import { reducer as uploadImageReducer } from '@redux/Fitney2.0/Settings/uploadImage';
import { reducer as updateDifficultyLevelReducer } from '@redux/Fitney2.0/Settings/updateDifficultyLevel';
import { reducer as updateHeightReducer } from '@redux/Fitney2.0/Settings/updateHeight';
import { reducer as updateWeightReducer } from '@redux/Fitney2.0/Settings/updateWeight';
import { reducer as updateGenderReducer } from '@redux/Fitney2.0/Settings/updateGender';
import { reducer as updateAllergiesReducer } from '@redux/Fitney2.0/Settings/updateAllergies';
import { reducer as updateMealsPerDayReducer } from '@redux/Fitney2.0/Settings/updateMealsPerDay';
import { reducer as updateBodyPartsReducer } from '@redux/Fitney2.0/Settings/updateBodyParts';
import { reducer as updateTrainingGoalReducer } from '@redux/Fitney2.0/Settings/updateTrainingGoal';
import { reducer as updateWeeklyIntensityReducer } from '@redux/Fitney2.0/Settings/updateWeeklyIntensity';
import { reducer as updateWorkoutTypeReducer } from '@redux/Fitney2.0/Settings/updateWorkoutType';
import { reducer as deleteSubscriptionReducer } from '@redux/Fitney2.0/Settings/deleteSubscription';
import { reducer as regeneratePlanReducer } from '@redux/Fitney2.0/Settings/regeneratePlan';
import { reducer as updateDietTypeReducer } from '@redux/Fitney2.0/Settings/updateDietType';
import { reducer as updateLanguageReducer } from '@redux/Fitney2.0/Settings/updateLanguage';
import { reducer as updateQuestionnaireReducer } from '@redux/Fitney2.0/Questionnaire/updateQuestionnaire';
import { reducer as dietReducer } from '@redux/Fitney2.0/Diet/diet';
import { reducer as recipeReducer } from '@redux/Fitney2.0/Diet/recipes';
import { reducer as authorReducer } from '@redux/Fitney2.0/Trainings/authorData';
import { reducer as bodyPartReducer } from '@redux/Fitney2.0/Trainings/bodyPartData';
import { reducer as trainingDurationRangesReducer } from '@redux/Fitney2.0/Trainings/trainingDurationRangesData';
import { reducer as difficultyReducer } from '@redux/Fitney2.0/Trainings/difficultyData';
import { reducer as trainingReducer } from '@redux/Fitney2.0/Trainings/trainingData';
import { reducer as liveTrainingsReducer } from '@redux/Fitney2.0/Trainings/liveTrainings';
import { reducer as trainingEquipmentReducer } from '@redux/Fitney2.0/Trainings/trainingEquipmentData';
import { reducer as trainingExerciseReducer } from '@redux/Fitney2.0/Trainings/trainingExerciseData';
import { reducer as workoutTypeReducer } from '@redux/Fitney2.0/Trainings/workoutTypeData';
import { reducer as trainingBasicItems } from '@redux/Fitney2.0/Trainings/trainingBasicItems';
import { reducer as trainingPlanReducer } from '@redux/Fitney2.0/Trainings/trainingPlan';
import { reducer as latestTrainings } from '@redux/Fitney2.0/Trainings/latestTrainingData';
import { reducer as statsReducer } from '@redux/Fitney2.0/Stats/stats';
import { reducer as snackBarReducer } from '@redux/Fitney2.0/Common/snackBar/snackBar';
import { reducer as trainersStatsReducer } from '@redux/Fitney2.0/Stats/trainersStats';
import { reducer as chartStatsReducer } from '@redux/Fitney2.0/Stats/chartStats';
import { reducer as suggestedTrainingsReducer } from '@redux/Fitney2.0/Trainings/suggestedTrainings';
import { reducer as notificationsReducer } from '@redux/Fitney2.0/Notifications/notifications';
import { reducer as trainingItemsReducer } from '@redux/Fitney2.0/Trainings/trainingItems';
import { reducer as trainingsUserReducer } from '@redux/Fitney2.0/TrainingsUser/trainingsUser';
import { reducer as SupportTypes } from './ToCheck/support/support';
import { reducer as userViewsReducer } from './views/views';
import { reducer as articlesReducer } from './ToCheck/blog/articles';
import { reducer as topArticlesReducer } from './ToCheck/blog/topArticles';
import { reducer as categoriesReducer } from './ToCheck/blog/categories';
import { reducer as subscribeForNewsletterReducer } from './ToCheck/newsletter/newsletter';
import { reducer as mobilePacketReducer } from '@redux/Fitney2.0/Payment/Mobile/packet';
import { reducer as mobilePaymentReducer } from '@redux/Fitney2.0/Payment/Mobile/payment';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import { AppStatusReducerProps } from '@redux/Fitney2.0/AppStatus/types';
import { AuthReducer } from '@redux/Fitney2.0/Auth/types';
import { UserDataReducer } from '@redux/Fitney2.0/UserData/types';
import { RootReducersKeys, RootReducerStateProps } from './types';
import { history } from '../history';
import { removeCookie } from '@services/cookiesService';
import { unidentifiedCookieName, utmCookieName } from '@HOCs/utmParams/utmParams';

const rootReducers = combineReducers<RootReducerStateProps>({
  [RootReducersKeys.router]: connectRouter(history),
  [RootReducersKeys.appStatus]: AppStatusReducer,
  [RootReducersKeys.appUI]: AppUIReducer,
  [RootReducersKeys.articles]: articlesReducer,
  [RootReducersKeys.auth]: authReducer,
  [RootReducersKeys.author]: authorReducer,
  [RootReducersKeys.bodyPart]: bodyPartReducer,
  [RootReducersKeys.trainingDurationRanges]: trainingDurationRangesReducer,
  [RootReducersKeys.categories]: categoriesReducer,
  [RootReducersKeys.chartStats]: chartStatsReducer,
  [RootReducersKeys.diet]: dietReducer,
  [RootReducersKeys.dietQuestionnaire]: dietQuestionnaireReducer,
  [RootReducersKeys.difficulty]: difficultyReducer,
  [RootReducersKeys.coupon]: couponReducer,
  [RootReducersKeys.creditCard]: creditCardReducer,
  [RootReducersKeys.deleteSubscription]: deleteSubscriptionReducer,
  [RootReducersKeys.latestTrainings]: latestTrainings,
  [RootReducersKeys.liveTrainings]: liveTrainingsReducer,
  [RootReducersKeys.newsletter]: subscribeForNewsletterReducer,
  [RootReducersKeys.notifications]: notificationsReducer,
  [RootReducersKeys.kryptonimWallets]: kryptonimCreateWalletAddressReducer,
  [RootReducersKeys.kryptonimPaymentPlans]: kryptonimPaymentPlansReducer,
  [RootReducersKeys.paymentPlans]: paymentPlansReducer,
  [RootReducersKeys.recipes]: recipeReducer,
  [RootReducersKeys.regeneratePlan]: regeneratePlanReducer,
  [RootReducersKeys.resetPasswordForm]: resetPasswordFormReducer,
  [RootReducersKeys.snackBar]: snackBarReducer,
  [RootReducersKeys.stats]: statsReducer,
  [RootReducersKeys.subscription]: subscriptionReducer,
  [RootReducersKeys.suggestedTrainings]: suggestedTrainingsReducer,
  [RootReducersKeys.support]: SupportTypes,
  [RootReducersKeys.topArticles]: topArticlesReducer,
  [RootReducersKeys.trainersStats]: trainersStatsReducer,
  [RootReducersKeys.training]: trainingReducer,
  [RootReducersKeys.trainingBasicItems]: trainingBasicItems,
  [RootReducersKeys.trainingEquipment]: trainingEquipmentReducer,
  [RootReducersKeys.trainingExercise]: trainingExerciseReducer,
  [RootReducersKeys.trainingItems]: trainingItemsReducer,
  [RootReducersKeys.trainingPlan]: trainingPlanReducer,
  [RootReducersKeys.trainingQuestionnaire]: trainingQuestionnaireReducer,
  [RootReducersKeys.trainingsUser]: trainingsUserReducer,
  [RootReducersKeys.updateAllergies]: updateAllergiesReducer,
  [RootReducersKeys.updateBirthDate]: updateBirthDateReducer,
  [RootReducersKeys.updateBodyParts]: updateBodyPartsReducer,
  [RootReducersKeys.updateDietType]: updateDietTypeReducer,
  [RootReducersKeys.updateDifficultyLevel]: updateDifficultyLevelReducer,
  [RootReducersKeys.updateFullName]: updateFullNameReducer,
  [RootReducersKeys.updateGender]: updateGenderReducer,
  [RootReducersKeys.updateHeight]: updateHeightReducer,
  [RootReducersKeys.updateLanguage]: updateLanguageReducer,
  [RootReducersKeys.updateMealsPerDay]: updateMealsPerDayReducer,
  [RootReducersKeys.updatePassword]: updatePasswordReducer,
  [RootReducersKeys.updateTrainingGoal]: updateTrainingGoalReducer,
  [RootReducersKeys.updateWeeklyIntensity]: updateWeeklyIntensityReducer,
  [RootReducersKeys.updateWeight]: updateWeightReducer,
  [RootReducersKeys.updateQuestionnaire]: updateQuestionnaireReducer,
  [RootReducersKeys.updateWorkoutType]: updateWorkoutTypeReducer,
  [RootReducersKeys.uploadUserImage]: uploadImageReducer,
  [RootReducersKeys.userData]: userDataReducer,
  [RootReducersKeys.userViews]: userViewsReducer,
  [RootReducersKeys.workoutType]: workoutTypeReducer,
  [RootReducersKeys.paymentPacket]: mobilePacketReducer,
  [RootReducersKeys.paymentMobile]: mobilePaymentReducer
} as { [key in RootReducersKeys]: any });

const reducer = (state: RootReducerStateProps, action: any) => {
  const { getData, clearData } = localeStoreHelpers;
  if (!state.auth?.authData) {
    const authRawData = getData([RootReducersKeys.auth])?.[0]?.stringedData;
    if (authRawData) {
      const auth: AuthReducer = JSON.parse(authRawData);
      state = { ...state, auth };
    }
  }

  if (
    action.type === LogInTypes.LOG_IN_REQUEST ||
    action.type === LogInTypes.LOG_IN_FACEBOOK_REQUEST ||
    action.type === LogInTypes.LOG_OUT_SUCCESS
  ) {
    const { appStatus, router } = state;
    state = {
      appStatus,
      router
    };
  }

  if (action.type === LogInTypes.LOG_OUT_SUCCESS) {
    clearData([RootReducersKeys.auth]);
    removeCookie(utmCookieName);
    removeCookie(unidentifiedCookieName);
  }

  if (!state.appStatus) {
    const appStatusRawData = getData([RootReducersKeys.appStatus])?.[0]?.stringedData;
    if (appStatusRawData) {
      const appStatus: AppStatusReducerProps = JSON.parse(appStatusRawData);
      state = { ...state, appStatus };
    }
  }

  if (!state.userData) {
    const userDataRawData = getData([RootReducersKeys.userData])?.[0]?.stringedData;
    if (userDataRawData) {
      const userData: UserDataReducer = JSON.parse(userDataRawData);
      state = { ...state, userData };
    }
  }

  return rootReducers(state, action);
};

export default reducer;
