import React from 'react';

const ReplayIcon = ({ size, color }: any) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
      <path
        d="M10.8821 23C4.83953 22.3808 0.441412 16.9677 1.0576 10.9075C1.67644 4.84988 7.07503 0.439904 13.1176 1.05775C19.1616 1.67693 23.5597 7.09141 22.9422 13.1503C22.6387 16.12 21.1877 18.6937 19.0754 20.4728M22.921 21.0575L18.4248 21.1465L18.336 16.6395"
        stroke={color}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplayIcon;
