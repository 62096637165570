import React from 'react';

import colors from '@consts/colors';

const FatIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.575"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M5.44254 18.92C3.26285 17.7199 1.78564 15.3973 1.78564 12.7271C1.78564 8.82847 4.93688 5.66797 8.82305 5.66797C10.473 5.66797 11.9889 6.23707 13.1877 7.18837" />
      <path d="M11.5327 15.3249C12.1891 11.1732 15.7748 7.99805 20.0997 7.99805C24.8893 7.99805 28.7724 11.8925 28.7724 16.6973C28.7724 21.501 24.8893 25.3965 20.0997 25.3965" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4473 23.408C17.4473 26.3218 15.0918 28.6843 12.1876 28.6843C9.28135 28.6843 6.92578 26.3218 6.92578 23.408C6.92578 20.4953 9.28135 18.1328 12.1876 18.1328C15.0918 18.1328 17.4473 20.4953 17.4473 23.408Z"
      />
      <path d="M12.7749 3.79218C13.3109 2.19723 14.9954 0.516178 17.2411 0.823828C19.4762 1.13148 21.0414 3.19788 20.7357 5.44068" />
    </g>
  </svg>
);

export default FatIcon;
