import { SnackBarDataProps } from '@redux/Fitney2.0/types';
import { MiddlewareAction, MiddlewareState } from './types';

export const notificationMiddleware = (state: MiddlewareState) => (next: any) => (action: MiddlewareAction) => {
  if (action?.error && action.error.status !== 422) {
    let data: SnackBarDataProps = {
      type: 'error',
      message: !!action.error.message ? action.error.message : 'Connection error.',
      errors: action?.error?.errors
    };
    state.dispatch({ type: 'ENQUEUE_SNACKBAR', payload: data });
  }
  return next(action);
};
