import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const HoneyIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => (
  <svg
    opacity={opacity / 100}
    width={size}
    height={size}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10308_11059)">
      <path
        d="M17.2902 22.4099C20.1179 22.4099 22.4102 20.1176 22.4102 17.2899C22.4102 14.4622 20.1179 12.1699 17.2902 12.1699C14.4625 12.1699 12.1702 14.4622 12.1702 17.2899C12.1702 20.1176 14.4625 22.4099 17.2902 22.4099Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.27 28.8711C40.67 31.7911 40.17 35.3711 37.78 37.7711C35.39 40.1711 31.9 40.6511 29.01 39.3311"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2701 18.2209C43.2701 22.3709 40.7301 25.7309 35.4601 25.7309C30.1901 25.7309 22.3701 18.2209 22.3701 18.2209C22.3701 18.2209 30.1901 10.7109 35.4601 10.7109C40.7301 10.7109 43.2701 14.0709 43.2701 18.2209Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.22 43.2711C22.37 43.2711 25.73 40.7311 25.73 35.4611C25.73 30.1911 18.22 22.3711 18.22 22.3711C18.22 22.3711 10.71 30.1911 10.71 35.4611C10.71 40.7311 14.07 43.2711 18.22 43.2711Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4901 15.5211C11.1501 14.4311 8.88007 13.4411 5.82007 14.3111"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5201 12.4903C14.4301 11.1503 13.4401 8.88031 14.3101 5.82031"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4301 27.4707L27.6101 29.2907"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4501 29.2402L29.3701 35.3102"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.01 24.1094L24.24 24.8694"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10308_11059">
        <rect width="50.4" height="50.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HoneyIcon;
