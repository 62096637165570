import React from 'react';

import colors from '@consts/colors';

const HumanIcon = ({ size = 36, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7 10.5996H4.70005C4.20005 10.5996 3.80005 10.9996 3.80005 11.4996V12.3996C3.80005 12.9996 4.20005 13.3996 4.80005 13.4996L14 15.1996L12.3 32.9996C12.2 33.8996 13.5 34.1996 13.9 33.3996L18 22.3996L22.1 33.3996C22.4 34.2996 23.7001 33.9996 23.7001 32.9996L22.1 15.1996L31.3 13.4996C31.9 13.3996 32.3 12.8996 32.3 12.3996V11.4996C32.3 10.9996 31.9 10.5996 31.4 10.5996H23.6"
      stroke={color}
      opacity={opacity}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0001 10.0002C19.6017 10.0002 20.9001 8.70182 20.9001 7.1002C20.9001 5.49857 19.6017 4.2002 18.0001 4.2002C16.3985 4.2002 15.1001 5.49857 15.1001 7.1002C15.1001 8.70182 16.3985 10.0002 18.0001 10.0002Z"
      stroke={color}
      opacity={opacity}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HumanIcon;
