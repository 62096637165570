import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteSubscriptionRequest: ['modalRef'],
  deleteSubscriptionSuccess: [],
  deleteSubscriptionFailure: ['error']
});

export const SubscriptionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {});
