import React from 'react';

const ChangeTrainingPlanIcon = () => {
  return (
    <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5078 42C84.5078 65.1958 65.7036 84 42.5078 84C19.3129 84 0.507812 65.1958 0.507812 42C0.507812 18.8033 19.3129 0 42.5078 0C65.7036 0 84.5078 18.8033 84.5078 42Z"
        fill="#F4F4F4"
      />
      <path
        d="M33.781 58.6306H22.478C21.109 58.6306 20 57.5216 20 56.1516V25.3676C20 23.9996 21.109 22.8906 22.478 22.8906H23.78"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.3174 22.8906H49.6024C50.9714 22.8906 52.0814 23.9996 52.0814 25.3686V36.2506"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0001 19.9042C38.0271 19.7712 38.0431 19.6342 38.0431 19.4952C38.0431 18.3882 37.1461 17.4922 36.0401 17.4922C34.9351 17.4922 34.0381 18.3882 34.0381 19.4952C34.0381 19.6342 34.0541 19.7712 34.0811 19.9042H27.3301V25.2232H44.7511V19.9042H38.0001Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4432 32.7969L29.6272 36.6139L27.6572 34.6439"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4432 45.1797L29.6272 48.9957L27.6572 47.0267"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.182 41.0938H41.551C40.281 41.0938 39.251 42.1227 39.251 43.3937V61.1178C39.251 62.3888 40.281 63.4177 41.551 63.4177H61.182C62.452 63.4177 63.482 62.3888 63.482 61.1178V43.3937C63.482 42.1227 62.452 41.0938 61.182 41.0938Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.5508 56.0242V48.4912C48.5508 47.8072 49.3218 47.4082 49.8798 47.8022L55.2078 51.5692C55.6828 51.9052 55.6828 52.6102 55.2078 52.9462L49.8798 56.7122C49.3218 57.1072 48.5508 56.7082 48.5508 56.0242Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39.252 34.707H43.93" stroke="#E0325F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChangeTrainingPlanIcon;
