import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import { RootReducersKeys, RootReducerStateProps } from '@redux/types';
import { LANGUAGES } from '@consts/consts';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import { defaultLanguage, supportedLanguage } from '@helpers/language';
import { AppStatusReducerProps } from './types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  isMobile: ['isMobile'],
  skipQuestionnaireModalShow: ['skipQuestionnaireModalShow'],
  setLanguage: ['language'],
  setNewUserModalStatus: ['newUserModalStatusBoolean']
});

export const AppStatusTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  language: null,
  skipQuestionnaireModalShow: false,
  newUserModalStatus: false,
  isMobile: false
});

/* ------------- Selectors ------------- */

export const appStatusSelectors = {
  language: (state: RootReducerStateProps) =>
    userDataSelectors.isMultiLang()
      ? state?.appStatus?.language
        ? state?.appStatus.language
        : defaultLanguage
      : LANGUAGES.EN,
  skipQuestionnaireModalShow: (state: RootReducerStateProps) => state.appStatus?.skipQuestionnaireModalShow,
  isMobile: (state: RootReducerStateProps) => state.appStatus?.isMobile,
  selectNewUserModalStatus: (state: RootReducerStateProps) => false //state.appStatus.newUserModalStatus
};

/* ------------- Reducers ------------- */

export const setLanguageReducer = (state: AppStatusReducerProps, action: any) => {
  const { language } = action;
  const validatedLanguage = supportedLanguage.includes(language) ? language : LANGUAGES.EN;

  localeStoreHelpers.setData([
    {
      key: RootReducersKeys.appStatus,
      stringedData: JSON.stringify({ language: validatedLanguage })
    }
  ]);
  return {
    ...state,
    language: validatedLanguage
  };
};

export const isMobile = (state: AppStatusReducerProps, action: any) => {
  const { isMobile } = action;
  return { ...state, isMobile };
};

export const skipQuestionnaireModalShow = (state: AppStatusReducerProps, action: any) => {
  const { skipQuestionnaireModalShow } = action;
  return {
    ...state,
    skipQuestionnaireModalShow
  };
};

export const setNewUserModalStatusReducer = (state: AppStatusReducerProps, action: any) => {
  const { newUserModalStatusBoolean } = action;
  return { ...state, newUserModalStatus: newUserModalStatusBoolean };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.IS_MOBILE]: isMobile,
  [Types.SET_LANGUAGE]: setLanguageReducer,
  [Types.SKIP_QUESTIONNAIRE_MODAL_SHOW]: skipQuestionnaireModalShow,
  [Types.SET_NEW_USER_MODAL_STATUS]: setNewUserModalStatusReducer
});
