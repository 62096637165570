import React from 'react';

const KettlebellIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg width={size} height={size} viewBox="0 0 32 38">
        <path
          fill="transparent"
          stroke={color}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M30.8023 25.2958C30.8023 17.3638 24.1483 10.9358 15.9363 10.9358C7.72631 10.9358 1.07031 17.3638 1.07031 25.2958C1.07031 28.8338 2.40031 32.0698 4.59831 34.5698C5.51031 35.6058 6.81231 36.2178 8.19231 36.2178H23.6943C24.9603 36.2178 26.2023 35.7418 27.0603 34.8098C29.3863 32.2758 30.8023 28.9478 30.8023 25.2958Z"
        />
        <path
          fill="transparent"
          stroke={color}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.0677 21.1551C22.0677 24.5411 19.3237 27.2871 15.9377 27.2871C12.5497 27.2871 9.80566 24.5411 9.80566 21.1551C9.80566 17.7711 12.5497 15.0251 15.9377 15.0251C19.3237 15.0251 22.0677 17.7711 22.0677 21.1551Z"
        />
        <path
          fill="transparent"
          stroke={color}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.9371 1.65515H9.00309C5.11709 1.65515 2.56309 5.71515 4.24909 9.21715C4.24909 9.21715 5.55309 11.7432 5.13309 15.4712"
        />
        <path
          fill="transparent"
          stroke={color}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.9375 1.65515H22.8695C26.7575 1.65515 29.3115 5.71515 27.6235 9.21715C27.6235 9.21715 26.3215 11.7432 26.7395 15.4712"
        />
      </svg>
    </React.Fragment>
  );
};

export default KettlebellIcon;
