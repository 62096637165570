import React from 'react';

import colors from '@consts/colors';

const BarbellIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M31 18.5732V14.427M7.25378 14.4258H4.05781M7.25378 18.5717H4.05781M1.43 14.4258H1M1.43 18.5717H1M16.3269 29H12.9068C12.0866 29 11.4225 28.3602 11.4225 27.5684V5.43163C11.4225 4.63984 12.0866 4 12.9068 4H16.3269C17.147 4 17.8145 4.63984 17.8145 5.43163V27.5684C17.8145 28.3602 17.147 29 16.3269 29ZM22.8673 25.5308H26.2874C27.1076 25.5308 27.7717 24.891 27.7717 24.0992V8.90145C27.7717 8.10965 27.1076 7.46981 26.2874 7.46981H22.8673C22.0471 7.46981 21.3797 8.10965 21.3797 8.90145V24.0992C21.3797 24.891 22.0471 25.5308 22.8673 25.5308Z" />
    </g>
  </svg>
);

export default BarbellIcon;
