import {get, post} from "@services/api";
import {IApiBuilder} from "@services/types";

const TrainingsApi = (props: IApiBuilder) => {
    const { client, buildUrl } = props;

    const getAuthorData = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/assignedTrainer` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getBodyPartData = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/assignedBodyPart` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getTrainingDurationRangesData = () =>
      post({
        client: client,
        url: buildUrl({ version: 'v2', url: `/assignedTrainingDurationRanges` }),
        data: {}
      });

    const getDifficultyData = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/assignedDifficulty` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getTrainingData = (page: any, per_page: any, query: any, filters: any, token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingData?page=${page}&per_page=${per_page}&query=${query}&${filters}` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getTrainingEquipmentData = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/assignedTrainingEquipment` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getTrainingExerciseData = (id: any, token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingData/${id}` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getTrainingExerciseBasicList = (id: any, page: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingData/items/basic/${id}?page=${page}` }),
        });

    const getTrainingItemsData = (id: any, token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingData/items/${id}` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getWorkoutTypeData = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/assignedWorkoutType` }),
            axiosConfig: { headers: { Authorization: token } }
        });


    const getDifficultyLevels = (token: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/difficultyLevels` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const getLatestTrainings = (token: any) =>
        get({
          client: client,
          url: buildUrl({ version: 'v2', url: `/lastTrainingData` }),
          axiosConfig: { headers: { Authorization: token } }
        });

    const getNextTrainingPlanItems = (date: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/schedule/items/next` }),
            params: { cursorDate: date }
        });

    const getPrevTrainingPlanItems = (date: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/schedule/items/previous` }),
            params: { cursorDate: date }
        });

    const getRegeneratedTrainingPlan = () =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/schedule/regenerate` }),
        });


    const getItemsViews = (id: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/schedule/item/views/${id}` }),
        });

    const markScheduleVideoWatched = (data: any) =>
        post({
            client: client,
            url: buildUrl({ version: 'v2', url: `/schedule/item/view` }),
            data: { ...data }
        });

    const markTrainingVideoWatched = (data: any) =>
        post({
            client: client,
            url: buildUrl({ version: 'v2', url: `/exercise/view` }),
            data: { ...data }
        });

  const markTrainingAsStarted = (id: string) =>
    post({
      client: client,
      url: buildUrl({ version: 'v2', url: `/trainingData/start` }),
      data: { trainingData: id }
    });

    const markTrainingAsCompleted = (id: string) =>
        post({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingData/complete` }),
            data: { trainingData: id }
        });

    const getLiveTrainings = (token: string) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: `/trainingsLive` }),
            axiosConfig: { headers: { Authorization: token } }
        });

    return {
        getAuthorData,
        getBodyPartData,
        getTrainingDurationRangesData,
        getDifficultyData,
        getTrainingData,
        getTrainingEquipmentData,
        getTrainingExerciseData,
        getTrainingExerciseBasicList,
        getTrainingItemsData,
        getWorkoutTypeData,
        getDifficultyLevels,
        getLatestTrainings,
        getNextTrainingPlanItems,
        getPrevTrainingPlanItems,
        getRegeneratedTrainingPlan,
        getItemsViews,
        markScheduleVideoWatched,
        markTrainingVideoWatched,
        markTrainingAsStarted,
        markTrainingAsCompleted,
        getLiveTrainings
    };
}

export default TrainingsApi;
