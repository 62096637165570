import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updatePasswordRequest: ['oldPassword', 'newPassword', 'retypeNewPassword'],
  updatePasswordSuccess: [],
  updatePasswordFailure: ['error'],
  cleanPasswordError: []
});

export const UpdatePasswordTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  isFinished: false
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const requestPasswordDate = (state: any) => ({ ...state, fetching: true, error: null, isFinished: false });

export const successPasswordDate = () => ({ fetching: false, error: null, isFinished: true });

export const failure = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error,
  isFinished: false
});

export const cleanError = (state: any) => ({ ...state, error: null, isFinished: false });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_PASSWORD_REQUEST]: requestPasswordDate,
  [Types.UPDATE_PASSWORD_SUCCESS]: successPasswordDate,
  [Types.UPDATE_PASSWORD_FAILURE]: failure,
  [Types.CLEAN_PASSWORD_ERROR]: cleanError
});
