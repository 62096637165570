import React from 'react';

import colors from '@consts/colors';

const RubberBandIcon = ({ size = 20, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M15.7049 3.50244C15.7049 6.55144 14.3699 6.55144 14.3699 9.60044C14.3699 12.6484 15.7049 12.6484 15.7049 15.6974" />
      <path d="M15.7049 15.6978C15.7049 17.2698 14.4299 18.5448 12.8569 18.5448C11.2849 18.5448 10.0109 17.2698 10.0109 15.6978C10.0109 14.1748 10.7099 14.1748 10.7099 12.6518C10.7099 11.1278 10.0109 11.1278 10.0109 9.60276C10.0109 8.07876 10.7099 8.07876 10.7099 6.55276C10.7099 5.02776 10.0109 5.02776 10.0109 3.50276C10.0109 1.92976 11.2849 0.655762 12.8569 0.655762C14.4299 0.655762 15.7049 1.92976 15.7049 3.50276" />
      <path d="M15.7049 15.6978C15.7049 17.2698 14.4299 18.5448 12.8569 18.5448H4.34287C2.76987 18.5448 1.49487 17.2698 1.49487 15.6978C1.49487 14.1748 2.19487 14.1748 2.19487 12.6518C2.19487 11.1278 1.49487 11.1278 1.49487 9.60276C1.49487 8.07876 2.19487 8.07876 2.19487 6.55276C2.19487 5.02776 1.49487 5.02776 1.49487 3.50276C1.49487 1.92976 2.76987 0.655762 4.34287 0.655762H12.8569C14.4299 0.655762 15.7049 1.92976 15.7049 3.50276" />
      <path d="M2.19507 12.6519H4.58207" />
      <path d="M10.7098 6.55322H6.55377" />
    </g>
  </svg>
);

export default RubberBandIcon;
