import React from 'react';

import colors from '@consts/colors';

const MilkIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M9.37646 3H22.6242M10.8136 6.73853C10.8136 9.98034 7 11.8478 7 20.1038V27.0643C7 28.6852 8.4094 30 10.1476 30H21.8524C23.5906 30 25 28.6852 25 27.0643V20.1038C25 11.8478 21.1857 9.98034 21.1857 6.73853M18.9953 21.331C18.9953 23.0164 17.6543 24.3827 15.9991 24.3827C14.3472 24.3827 13.0045 23.0164 13.0045 21.331C13.0045 19.6473 15.9991 15.8254 15.9991 15.8254C15.9991 15.8254 18.9953 19.6473 18.9953 21.331Z" />
    </g>
  </svg>
);

export default MilkIcon;
