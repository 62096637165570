import React from 'react';

const TBCIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7369 31.2201C39.7369 23.4081 33.1809 17.0781 25.0949 17.0781C17.0089 17.0781 10.4509 23.4081 10.4509 31.2201C10.4509 34.7041 11.7629 37.8901 13.9269 40.3541C14.8249 41.3741 16.1089 41.9781 17.4669 41.9781H32.7349C33.9809 41.9781 35.2069 41.5081 36.0489 40.5921C38.3429 38.0961 39.7369 34.8181 39.7369 31.2201Z"
        stroke="#E0325F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1334 27.142C31.1334 30.478 28.4294 33.182 25.0934 33.182C21.7594 33.182 19.0554 30.478 19.0554 27.142C19.0554 23.808 21.7594 21.104 25.0934 21.104C28.4294 21.104 31.1334 23.808 31.1334 27.142Z"
        stroke="#E0325F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0943 7.93506H18.2663C14.4383 7.93506 11.9223 11.9351 13.5843 15.3851C13.5843 15.3851 14.8663 17.8711 14.4543 21.5431"
        stroke="#E0325F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0942 7.93506H31.9222C35.7522 7.93506 38.2662 11.9351 36.6042 15.3851C36.6042 15.3851 35.3222 17.8711 35.7342 21.5431"
        stroke="#E0325F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TBCIcon;
