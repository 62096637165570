import React from 'react';

import colors from '@consts/colors';

const NoEquipmentIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M26.6073 26.6077C20.7486 32.4654 11.2521 32.4654 5.39451 26.6077C-0.464185 20.7499 -0.464185 11.252 5.39451 5.3943C11.2521 -0.463465 20.7486 -0.463465 26.6073 5.3943C32.4649 11.252 32.4649 20.7499 26.6073 26.6077Z" />
      <path d="M10 22L22 10" />
      <path d="M10 10L22 22" />
    </g>
  </svg>
);

export default NoEquipmentIcon;
