import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isFetching } from '@selectors/helperSelectors';
import styles from './webViewLayout.module.scss';

class WebviewLayout extends Component<any, any> {
  render() {
    const { props } = this;
    return (
      <div className={`${styles.container} d-flex flex-column`}>
        <main className="flex-grow-1">{props.children}</main>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  fetching: isFetching(state),
  isMobile: state.appStatus.isMobile
});

export default connect(mapStateToProps)(WebviewLayout);
