import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';

import { providerConfig } from '@components/Snackbar/providerConfig';
import { InternationalizationProvider } from './providers';
import { IpProvider } from '@providers/IpProvider';
import { CompanyProvider } from '@providers/CompanyProvider';
import App from '@containers/app';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { history } from './history';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://eab8edfec195468fbc8271504c00a45b@sentry.io/1885849',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    normalizeDepth: 10,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0
  });
}

registerServiceWorker();

const target = document.querySelector('#root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <InternationalizationProvider>
        <IpProvider>
          <CompanyProvider>
            <SnackbarProvider {...providerConfig}>
              <App />
            </SnackbarProvider>
          </CompanyProvider>
        </IpProvider>
      </InternationalizationProvider>
    </ConnectedRouter>
  </Provider>,
  target
);
