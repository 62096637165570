import React from 'react';

const LevelBegginerIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.146 21.7162V41.6202C20.146 42.2082 20.622 42.6842 21.21 42.6842H27.74C28.33 42.6842 28.806 42.2082 28.806 41.6202V17.2362C28.806 16.6582 28.176 16.3002 27.678 16.5962L20.664 20.8022C20.342 20.9962 20.146 21.3422 20.146 21.7162Z"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41162 29.9466V41.6206C6.41162 42.2086 6.88762 42.6846 7.47562 42.6846H13.6716C14.2596 42.6846 14.7356 42.2086 14.7356 41.6206V25.6686C14.7356 25.0886 14.1056 24.7326 13.6096 25.0306L6.92962 29.0326C6.60962 29.2266 6.41162 29.5726 6.41162 29.9466Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7474 8.16544L34.7314 12.3714C34.4114 12.5614 34.2134 12.9094 34.2134 13.2834V41.6214C34.2134 42.2074 34.6914 42.6854 35.2814 42.6854H41.8094C42.3974 42.6854 42.8734 42.2074 42.8734 41.6214V8.80344C42.8734 8.22544 42.2434 7.86944 41.7474 8.16544Z"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LevelBegginerIcon;
