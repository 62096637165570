import DocsLayout from '@components/layouts/DocsLayout';
import LoginLayout from '@components/layouts/LoginLayout';
import QuestionnaireLayout from '@components/layouts/QuestionnaireLayout';
import { ACCESS } from '@consts/consts';

export const utilsRoutes = {
  termsOfService: {
    exact: true,
    path: '/terms-of-service',
    pageName: 'TermsOfService',
    layout: DocsLayout,
    access: ACCESS.ALL
  },
  billingPolicy: {
    exact: true,
    path: '/billing-policy',
    pageName: 'BillingPolicy',
    layout: DocsLayout,
    access: ACCESS.ALL
  },
  privacyPolicy: {
    exact: true,
    path: '/privacy-policy',
    pageName: 'PrivacyPolicy',
    layout: DocsLayout,
    access: ACCESS.ALL
  },
  termsOfUse: {
    exact: true,
    path: '/terms-of-use',
    pageName: 'TermsOfUse',
    layout: DocsLayout,
    access: ACCESS.ALL
  },
  support: {
    exact: true,
    path: '/support',
    pageName: 'Support',
    layout: DocsLayout,
    access: ACCESS.ALL
  },
  passwordReset: {
    exact: true,
    path: '/password/reset/:token',
    pageName: 'PasswordReset',
    layout: LoginLayout,
    access: ACCESS.ALL
  },
  passwordResetSuccess: {
    exact: true,
    path: '/password/reset-success',
    pageName: 'PasswordResetSuccess',
    layout: LoginLayout,
    access: ACCESS.ALL
  },
  thankYou: {
    path: '/thank-you',
    pageName: 'ThankYouPage',
    layout: QuestionnaireLayout,
    access: ACCESS.LOGGED_IN,
    exact: true
  }
};
