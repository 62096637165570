import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  uploadImageRequest: ['image'],
  rejectImage: ['error'],
  uploadImageSuccess: [],
  uploadImageFailure: ['error']
});

export const UploadImageTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const requestUploadImage = (state: any) => ({ ...state, fetching: true, error: null });

export const rejectImage = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

export const successUploadImage = () => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPLOAD_IMAGE_REQUEST]: requestUploadImage,
  [Types.UPLOAD_IMAGE_SUCCESS]: successUploadImage,
  [Types.UPLOAD_IMAGE_FAILURE]: failure,
  [Types.REJECT_IMAGE]: rejectImage
});
