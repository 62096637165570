import React from 'react';

const ArrowForwardIcon = ({ style }: any) => {
  return (
    <svg style={style} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path d="M1 6.0542H10.509" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M5.4541 1L10.5091 6.054L5.4541 11.109"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ArrowForwardIcon;
