import { get, post } from '@services/api';
import { IApiBuilder } from '@services/types';

const PaymentApi = (props: IApiBuilder) => {
  const { client, buildUrl } = props;

  const addCreditCardWithSubscription = (creditCardData: any, browserInfo: any, token: any) =>
    post({
      client: client,
      url: buildUrl({ version: false, url: '/straal/createSubscriptionWithUser' }),
      data: {
        ...creditCardData,
        ...browserInfo
      },
      axiosConfig: { headers: { Authorization: token } }
    });

  const getKryptonimPaymentPlans = (token: any) =>
    get({
      client: client,
      url: buildUrl({ version: 'v3', url: '/kryptonim/plan-prices' }),
      axiosConfig: { headers: { Authorization: token } }
    });

  const getPaymentUrl = (token: any, currency: any, planUuid: any, promoCode: any) =>
    post({
      client: client,
      url: buildUrl({ version: 'v3', url: '/kryptonim/payment-url' }),
      data: {
        currency,
        planUuid,
        promoCode
      },
      axiosConfig: { headers: { Authorization: token } }
    });

  const createWalletAddress = (token: any, currency: any, planUuid: any) =>
    post({
      client: client,
      url: buildUrl({ version: 'v3', url: '/bitgo/createWalletAddress' }),
      data: {
        currency,
        planUuid
      },
      axiosConfig: { headers: { Authorization: token } }
    });

  const getKryptonimPaymentStatus = (token: any, transactionUuid: string) =>
    get({
      client: client,
      url: buildUrl({ version: 'v3', url: `/kryptonim/transaction/${transactionUuid}/status` }),
      axiosConfig: { headers: { Authorization: token } }
    });

  const getPaymentPlans = (offer: any) =>
    get({
      client: client,
      url: buildUrl({ version: false, url: '/straal/getPaymentPlans' }),
      params: { offer_identifier: offer }
    });

  const getCouponPaymentPlans = (coupon: any) =>
    get({
      client: client,
      url: buildUrl({ version: false, url: '/straal/getPaymentPlans' }),
      params: { coupon }
    });

  const processCoupon = (coupon: any) =>
    post({
      client: client,
      url: buildUrl({ version: 'v2', url: '/coupons/processCoupon' }),
      data: {
        ...coupon
      }
    });

  const processNotify = (data: any) =>
    post({
      client: client,
      url: buildUrl({ version: 'v3', url: '/notifai/payment/askForSubscription' }),
      data: {
        ...data
      }
    });

  const getPackets = (data: any) =>
    post({
      client: client,
      url: buildUrl({ version: 'v3', url: '/notifai/payment/packet' }),
      data: {
        ...data
      }
    });

  return {
    addCreditCardWithSubscription,
    getPaymentPlans,
    getPaymentUrl,
    getKryptonimPaymentPlans,
    getCouponPaymentPlans,
    processCoupon,
    processNotify,
    createWalletAddress,
    getKryptonimPaymentStatus,
    getPackets
  };
};

export default PaymentApi;
