import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const FishIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => (
  <svg
    opacity={opacity / 100}
    width={size}
    height={size}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10308_11054)">
      <path
        d="M29.1802 23.0798C29.8802 23.2698 30.6202 23.6398 31.3102 24.2498C31.9402 24.8798 32.5402 25.7198 32.9002 26.7998C33.1902 27.8698 33.3002 29.1198 33.0302 30.4898V30.5398C32.3502 33.5898 28.9502 35.1598 26.2002 33.6898L14.7702 27.5998C13.4702 26.9098 12.0802 28.3698 12.8402 29.6298L16.2702 35.3598C16.7702 36.1898 16.7702 37.2298 16.2702 38.0598L12.8402 43.7898C12.0802 45.0498 13.4702 46.5098 14.7702 45.8198L27.4302 39.0698C28.3702 38.5698 29.4202 38.2898 30.4902 38.2798C31.3002 38.2698 32.2602 38.2198 32.9202 38.0598C35.1502 37.5698 37.3402 36.3798 39.1802 34.6398C42.8402 31.1498 44.7202 25.1598 43.1402 19.4198C42.4002 16.5798 40.9102 13.8598 38.7602 11.6398C36.5902 9.42984 33.8002 7.72984 30.6702 6.85984C30.3702 6.77984 29.8402 6.67984 29.3102 6.58984"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4301 22.6095C23.7201 22.6095 23.8801 22.5895 23.8801 22.5895C28.3501 19.0395 28.4401 11.3195 24.2801 7.25949C23.9301 6.91949 23.4401 6.73949 22.9501 6.78949C22.2101 6.85949 20.9601 7.02949 19.4101 7.45949C16.3301 8.32949 12.0601 10.2495 8.37012 14.4995C11.9001 19.2995 16.2101 21.2695 19.4101 22.0795C21.2601 22.5495 22.7401 22.6195 23.4301 22.6195V22.6095ZM18.9601 12.5295C18.9601 11.9195 19.2901 11.3795 19.7901 11.0795C20.0401 10.9195 20.3301 10.8295 20.6601 10.8295C21.5901 10.8295 22.3601 11.5895 22.3601 12.5295C22.3601 13.4695 21.6001 14.2095 20.6601 14.2095C20.3301 14.2095 20.0401 14.1195 19.7901 13.9795C19.2901 13.6795 18.9601 13.1395 18.9601 12.5295Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10308_11054">
        <rect width="50.4" height="50.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FishIcon;
