import React from 'react';

import AppNavLinks from '@components/UIKit/AppNavLinks/AppNavLinks';
import styles from './AppNavigationFooterMobile.module.scss';

const AppNavigationFooterMobile = () => (
  <nav className={styles.appNavigationFooterMobileWrapper}>
    <AppNavLinks />
  </nav>
);

export default AppNavigationFooterMobile;
