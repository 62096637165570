import React from 'react';

const PLIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" fill="white" stroke="#DEDEDE" />
      <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="18" height="18">
        <circle cx="13" cy="13" r="9" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect y="13" width="24" height="13" fill="#DE2121" />
      </g>
    </svg>
  );
};

export default PLIcon;
