import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingEquipmentDataRequest: [],
  fetchTrainingEquipmentDataSuccess: ['trainingEquipment'],
  fetchTrainingEquipmentDataFailure: ['error']
});

export const TrainingEquipmentDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const trainingEquipmentDataSelectors = {
  getTrainingEquipmentData: (state: any) => state.trainingEquipment.data,
  isFetching: (state: any) => state.trainingEquipment.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingEquipmentData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchTrainingEquipmentData = (state: any, action: any) => {
  const { trainingEquipment } = action;

  return {
    fetching: false,
    error: null,
    data: trainingEquipment,
    timestamp: new Date().getTime()
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_EQUIPMENT_DATA_REQUEST]: requestFetchTrainingEquipmentData,
  [Types.FETCH_TRAINING_EQUIPMENT_DATA_SUCCESS]: successFetchTrainingEquipmentData,
  [Types.FETCH_TRAINING_EQUIPMENT_DATA_FAILURE]: failure
});
