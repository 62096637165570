import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  emptyDietQuestionnaireData: [],
  addDietQuestionnaireData: ['data'],
  proceedDietQuestionnaireRequest: ['data'],
  proceedDietQuestionnaireSuccess: ['data'],
  proceedDietQuestionnaireFailure: ['error'],
  cleanQuestionnaireError: [],
  setDietQuestionnaireSkipped: [],
  unsetDietQuestionnaireSkipped: []
});

export const DietQuestionnaireTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: null
});

/* ------------- Selectors ------------- */

export const DietQuestionnaireSelectors = {
  getQuestionnaireData: (state: any) => state.dietQuestionnaire.data,
  getError: (state: any) => (state.dietQuestionnaire.error ? state.dietQuestionnaire.error.message : null),
  isFetching: (state: any) => !!state.dietQuestionnaire.fetching
};

/* ------------- Reducers ------------- */

export const questionnaireDataEmpty = (state: any) => ({ ...state, fetching: true, error: null, data: null });

export const addQuestionnaireData = (state: any, action: any) => {
  const { data } = action;
  const mergedData = { ...state.data, ...data };

  return { ...state, fetching: true, error: null, data: mergedData };
};

export const successProceedQuestionnaire = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const requestProceedQuestionnaire = (state: any, action: any) => ({ ...state, fetching: true, error: null });

export const failureProceedQuestionnaire = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error
});
export const cleanError = (state: any) => ({ ...state, fetching: false, error: null });

export const setDietQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: true });
export const unsetDietQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: false });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EMPTY_DIET_QUESTIONNAIRE_DATA]: questionnaireDataEmpty,
  [Types.ADD_DIET_QUESTIONNAIRE_DATA]: addQuestionnaireData,
  [Types.PROCEED_DIET_QUESTIONNAIRE_REQUEST]: requestProceedQuestionnaire,
  [Types.PROCEED_DIET_QUESTIONNAIRE_SUCCESS]: successProceedQuestionnaire,
  [Types.PROCEED_DIET_QUESTIONNAIRE_FAILURE]: failureProceedQuestionnaire,
  [Types.CLEAN_QUESTIONNAIRE_ERROR]: cleanError,
  [Types.SET_DIET_QUESTIONNAIRE_SKIPPED]: setDietQuestionnaireSkippedRequest,
  [Types.UNSET_DIET_QUESTIONNAIRE_SKIPPED]: unsetDietQuestionnaireSkippedRequest
});
