export interface ITrainingAuthorDTO {
  id: string;
  avatar: string;
  username: string;
}

export interface ITrainingAuthor {
  id: string;
  avatar: string;
  username: string;
}

class LastTrainingAuthor implements ITrainingAuthor {
  constructor(public id: string, public avatar: string, public username: string) {}

  static build = (data: ITrainingAuthorDTO): ITrainingAuthor =>
    new LastTrainingAuthor(data.id, data.avatar, data.username);
}

export default LastTrainingAuthor;
