import React from 'react';

import colors from '@consts/colors';

const CarbsIcon = ({ size = 29, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.575"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M14.9036 15.4378C14.9036 15.4378 10.7651 12.1576 10.7651 8.11191C10.7651 4.06731 14.9036 0.787109 14.9036 0.787109C14.9036 0.787109 19.0431 4.06731 19.0431 8.11191C19.0431 12.1576 14.9036 15.4378 14.9036 15.4378Z" />
      <path d="M28.0034 13.1045C28.0034 13.1045 21.6287 13.1055 18.0032 17.0273C16.2968 18.8753 15.3786 20.6046 14.9075 22.103C14.4353 20.6046 13.5172 18.8753 11.8107 17.0273C8.18522 13.1055 1.81052 13.1045 1.81052 13.1045C1.81052 13.1045 1.29125 19.4853 4.90731 23.4155C8.52442 27.3467 14.9075 27.3404 14.9075 27.3404C14.9075 27.3404 21.2906 27.3467 24.9066 23.4155C28.5238 19.4853 28.0034 13.1045 28.0034 13.1045Z" />
    </g>
  </svg>
);

export default CarbsIcon;
