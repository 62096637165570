import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  processCouponRequest: ['coupon', 'isNewUser'],
  processCouponSuccess: [],
  processCouponFailure: ['error'],
  clean: []
});

export const CouponTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const requestProcessCoupon = (state: any) => ({ ...state, fetching: true, error: null });

export const successProcessCoupon = (state: any, action: any) => ({ fetching: false, error: null });

export const clean = (state: any, action: any) => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROCESS_COUPON_REQUEST]: requestProcessCoupon,
  [Types.PROCESS_COUPON_SUCCESS]: successProcessCoupon,
  [Types.CLEAN]: clean,
  [Types.PROCESS_COUPON_FAILURE]: failure
});
