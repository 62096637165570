import React from 'react';

const SubActiveIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.3797 31.5218H23.8057V22.9463"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0971 31.0833C9.49059 29.1918 6.24609 24.6603 6.24609 19.3713C6.24609 12.3813 11.9116 6.71582 18.9001 6.71582C25.8901 6.71582 31.5571 12.3813 31.5571 19.3713C31.5571 24.6198 28.3606 29.1243 23.8081 31.0413"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubActiveIcon;
