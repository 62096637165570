import { call, put, select } from '@redux-saga/core/effects';

import TrainingBasicItemsActions from '@redux/Fitney2.0/Trainings/trainingBasicItems';
import LatestTrainingsActions from '@redux/Fitney2.0/Trainings/latestTrainingData';
import AuthorDataActions from '@redux/ToCheck/trainings/authorData';
import BodyPartDataActions from '@redux/ToCheck/trainings/bodyPartData';
import TrainingDurationRangesDataActions from '@redux/Fitney2.0/Trainings/trainingDurationRangesData';
import DifficultyDataActions from '@redux/ToCheck/trainings/difficultyData';
import TrainingDataActions from '@redux/ToCheck/trainings/trainingData';
import LiveTrainingsActions from '@redux/Fitney2.0/Trainings/liveTrainings';
import TrainingEquipmentDataActions from '@redux/ToCheck/trainings/trainingEquipmentData';
import TrainingExerciseDataActions from '@redux/ToCheck/trainings/trainingExerciseData';
import WorkoutTypeDataActions from '@redux/ToCheck/trainings/workoutTypeData';
import TrainingPlanActions from '@redux/Fitney2.0/Trainings/trainingPlan';
import TrainingItemsActions from '@redux/Fitney2.0/Trainings/trainingItems';
import SuggestedTrainingsActions from '@redux/Fitney2.0/Trainings/suggestedTrainings';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import authSelectors from '@selectors/authSelectors';

export function* getAuthorDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getAuthorData, `Bearer ${token}`);

  if (response.ok) {
    yield put(AuthorDataActions.fetchAuthorDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      AuthorDataActions.fetchAuthorDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getBodyPartDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getBodyPartData, `Bearer ${token}`);

  if (response.ok) {
    yield put(BodyPartDataActions.fetchBodyPartDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      BodyPartDataActions.fetchBodyPartDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getTrainingDurationRangesDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getTrainingDurationRangesData, `Bearer ${token}`);

  if (response.ok) {
    yield put(TrainingDurationRangesDataActions.fetchTrainingDurationRangesDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingDurationRangesDataActions.fetchTrainingDurationRangesDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}



export function* getDifficultyDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getDifficultyData, `Bearer ${token}`);

  if (response.ok) {
    yield put(DifficultyDataActions.fetchDifficultyDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      DifficultyDataActions.fetchDifficultyDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getTrainingDataSaga(api: any, action: any): any {
  const token = yield select(authSelectors.token);

  const response = yield call(
    api.getTrainingData,
    action.page ? action.page : 1,
    action.per_page ? action.per_page : 10,
    action.query ? action.query : '',
    action.filters ? action.filters : '',
    `Bearer ${token}`
  );
  if (response.ok) {
    yield put(TrainingDataActions.fetchTrainingDataSuccess(response.data, action.page));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingDataActions.fetchTrainingDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getTrainingEquipmentDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getTrainingEquipmentData, `Bearer ${token}`);

  if (response.ok) {
    yield put(TrainingEquipmentDataActions.fetchTrainingEquipmentDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingEquipmentDataActions.fetchTrainingEquipmentDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getTrainingExerciseDataSaga(api: any, action: any): any {
  const token = yield select(authSelectors.token);

  const response = yield call(api.getTrainingExerciseData, action.trainingId, `Bearer ${token}`);
  if (response.ok) {
    yield put(TrainingExerciseDataActions.fetchTrainingExerciseDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingExerciseDataActions.fetchTrainingExerciseDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* markTrainingAsStarted(api: any, action: any): any {
  yield call(api.markTrainingAsStarted, action?.trainingId);
}

export function* markTrainingAsCompleted(api: any, action: any): any {
  yield call(api.markTrainingAsCompleted, action?.trainingId);
}

export function* getTrainingItemsSaga(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const { trainingId, scheduleItemId } = action;

  const response = yield call(api.getTrainingItemsData, trainingId, `Bearer ${token}`);
  if (response.ok) {
    yield put(TrainingItemsActions.fetchTrainingItemsSuccess(response.data));
    if (scheduleItemId) {
      yield put(TrainingItemsActions.fetchTrainingItemViewsRequest(scheduleItemId));
    }
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingItemsActions.fetchTrainingItemsFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getWorkoutTypeDataSaga(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getWorkoutTypeData, `Bearer ${token}`);

  if (response.ok) {
    yield put(WorkoutTypeDataActions.fetchWorkoutTypeDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      WorkoutTypeDataActions.fetchWorkoutTypeDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getTrainingExerciseBasicList(api: any, action: any): any {
  const token = yield select(authSelectors.token);

  const response = yield call(
    api.getTrainingExerciseBasicList,
    action.id,
    action.page ? action.page : 1,
    `Bearer ${token}`
  );

  if (response.ok) {
    yield put(TrainingBasicItemsActions.fetchTrainingBasicItemsSuccess(response.data, action.page));
  }
}

export function* getLatestTrainings(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.getLatestTrainings, `Bearer ${token}`);

  if (response.ok) {
    yield put(LatestTrainingsActions.fetchLatestTrainingsDataSuccess(response.data));
  }
}

export function* getTrainingPlanItems(api: any, action: any): any {
  const response = yield call(api.getNextTrainingPlanItems, action.date);

  if (response.ok) {
    yield put(TrainingPlanActions.fetchTrainingPlanSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data : 'Connection error.';
    yield put(
      TrainingPlanActions.fetchTrainingPlanFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getNextTrainingPlanItems(api: any, action: any): any {
  const response = yield call(api.getNextTrainingPlanItems, action.date);

  if (response.ok) {
    yield put(TrainingPlanActions.fetchNextTrainingPlanItemsSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingPlanActions.fetchTrainingPlanFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getPrevTrainingPlanItems(api: any, action: any): any {
  const response = yield call(api.getPrevTrainingPlanItems, action.date);

  if (response.ok) {
    yield put(TrainingPlanActions.fetchPrevTrainingPlanItemsSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingPlanActions.fetchTrainingPlanFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getRegeneratedTrainingPlan(api: any): any {
  const response = yield call(api.getRegeneratedTrainingPlan);

  if (response.ok) {
    yield put(TrainingPlanActions.fetchRegeneratedTrainingPlanSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      TrainingPlanActions.fetchTrainingPlanFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getItemsViews(api: any, action: any): any {
  const { trainingId } = action;
  const response = yield call(api.getItemsViews, trainingId);
  if (response.ok) {
    yield put(TrainingItemsActions.fetchTrainingItemViewsSuccess(response.data));
  } else {
    yield put(TrainingItemsActions.fetchTrainingItemViewsFailure(response.data));
  }
}

export function* markVideoWatchedSaga(api: any, action: any): any {
  const { data } = action;
  if (data.scheduleItem) {
    const { exercise, ...scheduleItemData } = data;
    yield call(api.markScheduleVideoWatched, scheduleItemData);
  } else if (data.exercise) {
    yield call(api.markTrainingVideoWatched, { exercise: data.exercise });
  }
}

export function* getSuggestedTrainings(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const workoutType = yield select(userDataSelectors.getWorkoutType);

  const response = yield call(
    api.getTrainingData,
    action.page || 1,
    action.per_page || 4,
    '',
    action.filters || `filter[workoutTypes]=${workoutType.id}`,
    `Bearer ${token}`
  );
  if (response.ok) {
    yield put(SuggestedTrainingsActions.fetchSuggestedTrainingsDataSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      SuggestedTrainingsActions.fetchSuggestedTrainingsDataFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}

export function* getLiveTrainings(api: any, action: any): any {
  const token = yield select(authSelectors.token);

  const response = yield call(api.getLiveTrainings, `Bearer ${token}`);
  if (response.ok) {
    yield put(LiveTrainingsActions.fetchLiveTrainingsSuccess(response.data));
  } else {
    const errorMessage = response.data ? response.data.error : 'Connection error.';
    yield put(
      LiveTrainingsActions.fetchLiveTrainingsFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}
