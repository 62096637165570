import { get, post } from '@services/api';
import {IApiBuilder} from "@services/types";

const UserApi = (props: IApiBuilder) => {
  const { client, buildUrl } = props;

  const fetchUserData = async (token: any) =>
    get({
      client,
      url: buildUrl({ version: false, url: `/user/getLoggedInUserData` }),
      axiosConfig: { headers: { Authorization: token } }
    });

  const saveEmailForNewsletter = (email: any) =>
    post({
      client,
      url: buildUrl({ version: false, url: `/newsletter/subscribe` }),
      data: { email, marketing_agreement: true }
    });

  const getSupportQuestions = () =>
    get({
      client,
      url: buildUrl({ version: false, url: `/support/getQuestions` })
    });

  const submitContactForm = (firstName: any, email: any, language: any, message: any) =>
    post({
      client,
      url: buildUrl({ version: false, url: `/support/submitContactForm` }),
      data: { firstName, email, language, message }
    });

  return {
    fetchUserData,
    saveEmailForNewsletter,
    getSupportQuestions,
    submitContactForm
  };
};

export default UserApi;
