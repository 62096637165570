import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useLazy } from '@hooks';
import { componentLoader } from '@helpers/general/componentLoader';
import WebViewLayout from '@components/layouts/WebViewLayout';
import ErrorPageLayout from '@components/layouts/ErrorPageLayout/ErrorPageLayout';

const WebViewRoutes = ({ location }: any) => {
  const PrivacyPolicy = useLazy('PrivacyPolicy', () =>
    componentLoader<typeof import('@pages/PrivacyPolicy')>(() => import('@pages/PrivacyPolicy'))
  );
  const TermsOfService = useLazy('TermsOfService', () =>
    componentLoader<typeof import('@pages/TermsOfService')>(() => import('@pages/TermsOfService'))
  );
  const Support = useLazy('Support', () =>
    componentLoader<typeof import('@pages/Support')>(() => import('@pages/Support'))
  );
  const TermsOfUse = useLazy('TermsOfUse', () =>
    componentLoader<typeof import('@pages/TermsOfUse')>(() => import('@pages/TermsOfUse'))
  );
  const BillingPolicy = useLazy('BillingPolicy', () =>
    componentLoader<typeof import('@pages/BillingPolicy')>(() => import('@pages/BillingPolicy'))
  );

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path={['/web-view/support/:lang', '/web-view/support/ios/:lang', '/web-view/support/android/:lang']}
          render={() => (
            <WebViewLayout>
              <Support location={location} />
            </WebViewLayout>
          )}
        />
        <Route
          path={[
            '/web-view/terms-of-service/:lang',
            '/web-view/terms-of-service/ios/:lang',
            '/web-view/terms-of-service/android/:lang'
          ]}
          render={() => (
            <WebViewLayout>
              <TermsOfService />
            </WebViewLayout>
          )}
        />
        <Route
          path={[
            '/web-view/privacy-policy/:lang',
            '/web-view/privacy-policy/ios/:lang',
            '/web-view/privacy-policy/android/:lang'
          ]}
          render={() => (
            <WebViewLayout>
              <PrivacyPolicy />
            </WebViewLayout>
          )}
        />
        <Route
          path={[
            '/web-view/terms-of-use/:lang',
            '/web-view/terms-of-use/ios/:lang',
            '/web-view/terms-of-use/android/:lang'
          ]}
          render={() => (
            <WebViewLayout>
              <TermsOfUse />
            </WebViewLayout>
          )}
        />
        <Route
          path={[
            '/web-view/billing-policy/:lang',
            '/web-view/billing-policy/ios/:lang',
            '/web-view/billing-policy/android/:lang'
          ]}
          render={() => (
            <WebViewLayout>
              <BillingPolicy />
            </WebViewLayout>
          )}
        />
        <Route path="*" render={() => <ErrorPageLayout />} />
      </Switch>
    </Suspense>
  );
};

export default WebViewRoutes;
