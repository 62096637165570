import * as React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import paymentPlanActions from '@redux/Fitney2.0/Payment/Strall/paymentPlan';

const mapDispatchToProps = (dispatch: any) => ({
  setSpecialOffer: (offer: any) => dispatch(paymentPlanActions.setSpecialPaymentPlan(offer))
});

const specialOffer = (Component: any) =>
  connect(
    null,
    mapDispatchToProps
  )(({ location, setSpecialOffer, ...props }: any) => {
    const getOfferIdFromUrl = React.useCallback(() => {
      const { offer } = qs.parse(location?.search, { ignoreQueryPrefix: true });
      return { offer };
    }, [location]);

    React.useEffect(() => {
      getOfferIdFromUrl();
      const { offer } = getOfferIdFromUrl();

      if (!!offer) {
        setSpecialOffer(offer);
      }
    }, [getOfferIdFromUrl, setSpecialOffer]);

    return <Component {...props} location={location} />;
  });

export default specialOffer;
