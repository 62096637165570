import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  resetPasswordFormStart: [],
  resetPasswordRequest: ['email', 'password', 'passwordConfirm', 'token'],
  resetPasswordFailure: ['errors'],
  resetPasswordSuccess: []
});

export const ResetPasswordTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  errors: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const resetPasswordFormStart = (state: any) => ({ ...state, fetching: false, errors: [] });

export const requestResetPassword = (state: any) => ({ ...state, fetching: true, errors: null });

export const failureResetPassword = (state: any, { errors }: any) => ({ ...state, fetching: false, errors });

export const successResetPassword = (state: any) => ({ ...state, fetching: false, errors: null });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_PASSWORD_FORM_START]: resetPasswordFormStart,
  [Types.RESET_PASSWORD_REQUEST]: requestResetPassword,
  [Types.RESET_PASSWORD_FAILURE]: failureResetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: successResetPassword
});
