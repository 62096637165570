import { push, replace } from 'connected-react-router';
import { put, select } from '@redux-saga/core/effects';

import { hasDailyWorkout } from '@selectors/dailyWorkout';
import routes from '@routes';
import { toPath } from '@routes/utils';

export function* goToHome(): any {
  if (yield select(hasDailyWorkout)) {
    yield put(push(routes.dailyWorkout.path));
  } else {
    yield put(push(routes.trainings.path));
  }
}

export function* goToDailyWorkout(action: any) {
  yield put(push(routes.dailyWorkout.path));
}

export function* goToChooseSubscriptionPlan(action: any) {
  yield put(replace(routes.chooseSubscriptionPlan.path));
}

export function* goToTrainingPlans(action: any) {
  yield put(push(routes.trainings.path));
}

export function* goToTrainingPlan(action: any) {
  const { trainingPlanId } = action;
  yield put(push(toPath(routes.trainingPlan, { training_id: trainingPlanId })));
}

export function* goToLogin(action: any) {
  yield put(push(routes.login.path));
}

export function* goToRegister(action: any) {
  yield put(push(routes.register.path));
}

export function* goToDietPlan(action: any) {
  yield put(push(routes.recipes.path));
}

export function* goToSupport(action: any) {
  yield put(push(routes.support.path));
}

export function* goToBlog(action: any) {
  yield put(push(routes.blog.path));
}

export function* goToArticle(action: any) {
  const { articleId } = action;
  yield put(push(toPath(routes.article.path, { article_id: articleId })));
}

export function* goToRecipe(action: any) {
  const { recipeId, ratio } = action;
  let route = toPath(routes.recipe.path, { recipe_id: recipeId, ratio: ratio ? Math.round(ratio * 100) : undefined });
  yield put(push(route));
}

export function* goToResetPasswordSuccess() {
  yield put(push(routes.passwordResetSuccess.path));
}

export function* goToQuestionnaireTraining(action: any) {
  yield put(push(routes.questionnaireTraining.path));
}

export function* goToThankYou() {
  yield put(push(routes.thankYou.path));
}
