import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { RootReducerStateProps } from '@redux/types';
import { AppUIReducerProps } from './types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  toggleMobileNav: ['isMobileNavOpen']
});

export const AppUITypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isMobileNavOpen: false
});

/* ------------- Selectors ------------- */

export const appUISelectors = {
  isMobileNavOpen: (state: RootReducerStateProps) => state.appUI?.isMobileNavOpen
};

/* ------------- Reducers ------------- */

export const toggleMobileNav = (state: AppUIReducerProps, action: any) => {
  return { ...state, isMobileNavOpen: !state.isMobileNavOpen };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_MOBILE_NAV]: toggleMobileNav
});
