import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import AppStatusActions, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import renderCurrentYear from '@helpers/general/renderCurrentYear';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import styles from './FooterSignUpSignIn.module.scss';
import Box from "@material-ui/core/Box";

const FooterSignUpSignIn = ({isMultiLang, isInHalfScreenComponent, shouldRenderLanguage = true}: any) => {
  const intl = useIntl();
  return (
    <footer className={`${styles.footerWrapper} ${isInHalfScreenComponent ? styles.inHalfScreenComponent : ''}`}>
      <Box>{shouldRenderLanguage && isMultiLang && <LanguageDropdown />}</Box>
      <small className={styles.copyright}>
        © Fitney
        {renderCurrentYear({ dot: true })}
        {intl.formatMessage({ id: 'aav2.universal.rightReserved' })}
      </small>
    </footer>
  );
};

const mapStateToProps = (state: any) => ({
  language: appStatusSelectors.language(state),
  isMultiLang: userDataSelectors.isMultiLang()
});

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: any) => dispatch(AppStatusActions.setLanguage(language))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FooterSignUpSignIn));
