import React from 'react';

import FooterSignUpSignIn from '@components/footers/FooterSignUpSignIn/FooterSignUpSignIn';
import styles from './PaymentLayout.module.scss';

const PaymentLayout = ({ children }: any) => {
  return (
    <div className={styles.main}>
      {children}
      <FooterSignUpSignIn />
    </div>
  );
};

export default PaymentLayout;
