import React from 'react';

const LanguageIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75488 25.5498V31.5213"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7869 25.7665C22.4429 31.702 15.0974 19.174 7.75488 25.549V8.578C14.8289 2.4355 21.9044 13.84 28.9814 9.373C29.4434 9.082 30.0464 9.391 30.0464 9.937V25.219C30.0464 25.4275 29.9489 25.6345 29.7869 25.7665Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LanguageIcon;
