import React from 'react';
import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const StandardDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5903 36.68C24.3603 35.15 22.4803 32.45 18.9603 31.34C15.4403 30.23 11.4703 32.82 8.09026 31.26C6.80275 30.6404 5.76556 29.5996 5.15032 28.31C1.32032 20.92 4.93025 11.6 15.4603 8.62997C23.6003 6.32997 34.7603 7.63005 40.7503 12.4701C46.7403 17.3101 47.1503 24.5501 44.1003 30.4701C42.8703 32.8701 41.4502 35.47 38.3102 36.76C35.657 37.8123 32.7517 38.0559 29.9603 37.46C29.1453 37.2831 28.351 37.0217 27.5903 36.68V36.68Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.9702 24.04C45.9702 26.58 45.3202 33.7599 44.1102 36.1299C42.9002 38.4999 41.4602 41.13 38.3202 42.42C35.6669 43.4723 32.7616 43.7158 29.9702 43.1199C29.1545 42.9319 28.3604 42.6605 27.6002 42.31C24.3802 40.78 22.4902 38.08 18.9702 36.97C15.4502 35.86 11.4802 38.45 8.10022 36.89C6.80637 36.2551 5.76891 35.1964 5.16028 33.89C4.36768 32.3759 3.87846 30.7216 3.72021 29.02V22.52"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2599 28.38C36.2422 28.38 38.6599 26.4369 38.6599 24.04C38.6599 21.6431 36.2422 19.7 33.2599 19.7C30.2775 19.7 27.8599 21.6431 27.8599 24.04C27.8599 26.4369 30.2775 28.38 33.2599 28.38Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StandardDietIcon;
