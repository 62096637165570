import moment from 'moment';
import {HEIGHT_TYPE, WEIGHT_TYPE} from '@consts/consts';
import {UserDataSingleProps} from '@redux/Fitney2.0/UserData/types';
import {del, get, post, put} from "@services/api";
import {API_CLIENT_TYPES, IApiBuilder} from '@services/types';

const SettingsApi = (props: IApiBuilder[]) => {

    let diets: IApiBuilder;
    let trainings: IApiBuilder;

    props.forEach((prop: IApiBuilder) => {
        const { client, buildUrl, type } = prop;
        switch (type) {
            case API_CLIENT_TYPES.DIET:
                diets = {
                    client: client,
                    buildUrl: buildUrl,
                };
                break;
            case API_CLIENT_TYPES.TRAININGS:
                trainings = {
                    client: client,
                    buildUrl: buildUrl,
                };
                break;
        }
    });

    const uploadAvatar = (token: string, file: File) => {
        const form = new FormData();
        form.append('image', file);
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: token
        };
        return post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/upload/avatar' }),
            axiosConfig: { headers },
            data: {
                form
            },
        });
    };

    const updateUserFullName = (token: string, name: string) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateFullName' }),
            axiosConfig: { headers: { Authorization: token } },
            data: {
                name
            },
        });

    const updateUserBirthDate = (token: string, birthDate: Date) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateBirthDate' }),
            axiosConfig: { headers: { Authorization: token } },
            data: {
                birthDate: moment(String(birthDate)).format('Y-MM-DD')
            },
        });

    const updateGender = (gender: string) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateGender' }),
            data: {
                gender
            },
        });

    const updateBodyParts = (bodyParts: UserDataSingleProps[], dontRegenerate: boolean) => {
        const bodyPartsUuid: string[] = [];
        bodyParts.forEach((item) => item.uuid && bodyPartsUuid.push(item.uuid));
        return Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateBodyParts` }),
                data: { bodyPartsUuids: bodyPartsUuid, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { bodyParts: bodyPartsUuid }
            }),
        ]);
    };

    const updateDifficultyLevel = (difficultyLevelUUID: string, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateDifficultyLevel` }),
                data: { difficulty: difficultyLevelUUID, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { difficulty: difficultyLevelUUID }
            }),
        ]);

    const updateWorkoutType = (preferredWorkoutUUID: string, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateWorkoutType` }),
                data: { preferredWorkout: preferredWorkoutUUID, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { preferredWorkout: preferredWorkoutUUID }
            }),
        ]);

    const updateWeeklyIntensity = (trainingDays: number, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateWeeklyIntensity` }),
                data: { trainingDays, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { trainingDays }
            }),
        ]);

    const updateTrainingGoal = (trainingGoalUUID: string, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateTrainingGoal` }),
                data: { trainingGoal: trainingGoalUUID, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { trainingGoal: trainingGoalUUID }
            }),
        ]);

    const updateHeight = (height: number, heightUnit: HEIGHT_TYPE, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateHeight` }),
                data: { height, heightUnit, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { height, heightType: heightUnit }
            }),
        ]);

    const updateWeight = (weight: number, weightUnit: WEIGHT_TYPE, dontRegenerate: boolean) =>
        Promise.all([
            post({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/user/updateWeight` }),
                data: { weight, weightUnit, dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
                data: { weight, weightType: weightUnit }
            }),
        ]);

    const updateDietType = (dietTypeId: number) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateDietType' }),
            data: {
                dietTypeId
            },
        });

    const updateAllergies = (allergies: number[]) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateAllergies' }),
            data: {
                allergies
            },
        });

    const updateMealsCount = (mealsCount: number) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateMealsCount' }),
            data: {
                mealsCount
            },
        });

    const updatePassword = (token: string, oldPassword: string, newPassword: string, retypeNewPassword: string) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updatePassword' }),
            data: {
                oldPassword,
                newPassword,
                newPassword_confirmation: retypeNewPassword
            },
            axiosConfig: { headers: { Authorization: token } }
        });

    const deleteSubscription = (token: string) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/deleteSubscription' }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const deleteAccount = (token: string) =>
        del({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user' }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const regeneratePlan = (dontRegenerate: boolean) =>
        Promise.all([
            get({
                client: diets.client,
                url: diets.buildUrl({ version: false, url: `/regeneratePlan` }),
                params: { dontRegenerate }
            }),
            put({
                client: trainings.client,
                url: trainings.buildUrl({ version: 'v2', url: `/questionnaire` }),
            }),
        ]);

    const updateLanguage = (language: string) =>
        post({
            client: diets.client,
            url: diets.buildUrl({ version: false, url: '/user/updateLanguage' }),
            data: {
                language
            }
        });
    
    return {
        updateGender,
        updateDifficultyLevel,
        updateWorkoutType,
        updateWeeklyIntensity,
        updateTrainingGoal,
        updateHeight,
        updateWeight,
        updateDietType,
        updateAllergies,
        updateMealsCount,
        updatePassword,
        deleteSubscription,
        deleteAccount,
        regeneratePlan,
        updateLanguage,
        updateBodyParts,
        uploadAvatar,
        updateUserFullName,
        updateUserBirthDate
    }
}

export default SettingsApi;