import React from 'react';

const DietPlanIcon = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84 42C84 65.1958 65.1958 84 42 84C18.8051 84 0 65.1958 0 42C0 18.8033 18.8051 0 42 0C65.1958 0 84 18.8033 84 42Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.7161 58.7129C31.1211 60.6349 32.8441 62.1079 34.9651 63.0629C38.0621 64.4569 42.3871 64.2839 43.6731 64.2839"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.6731 29.1723C38.4761 25.2853 26.3511 26.7333 24.7161 38.3593"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.6731 29.1721C49.8181 24.5771 65.6451 27.4381 62.3721 45.7581C60.8411 54.3261 57.2841 60.8851 51.6431 63.4251C48.5471 64.8191 44.9591 64.2831 43.6731 64.2831"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.6731 29.4619C44.2521 21.4939 49.2911 18.2449 52.9441 17.0039"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3665 16.796C46.3005 20.551 44.4695 25.346 44.4695 25.346C44.4695 25.346 39.5015 24.052 37.5675 20.298C35.6345 16.543 37.4665 11.748 37.4665 11.748C37.4665 11.748 42.4325 13.041 44.3665 16.796Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6418 32.3594C41.3348 35.0264 46.3628 35.3344 49.4228 32.3594"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2795 54.5826H40.0555C40.9345 54.5826 41.6465 53.8706 41.6465 52.9916V44.2326C41.6465 43.3546 40.9345 42.6426 40.0555 42.6426H17.2795"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2796 42.6407C15.4256 42.6407 13.9236 41.1377 13.9236 39.2847C13.9236 37.4297 15.4256 35.9277 17.2796 35.9277H19.3086"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2796 54.5822C15.4256 54.5822 13.9236 53.0792 13.9236 51.2262V39.2852"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5188 54.5829V51.2539"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6448 54.5829V51.2539"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0813 54.5829V51.2539"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DietPlanIcon;
