import React from 'react';

const MuscleBuildingIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8023 32.2962C40.8023 24.3642 34.1483 17.9362 25.9363 17.9362C17.7263 17.9362 11.0703 24.3642 11.0703 32.2962C11.0703 35.8342 12.4003 39.0702 14.5983 41.5702C15.5103 42.6062 16.8123 43.2182 18.1923 43.2182H33.6943C34.9603 43.2182 36.2023 42.7422 37.0603 41.8102C39.3863 39.2762 40.8023 35.9482 40.8023 32.2962Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0677 28.1555C32.0677 31.5415 29.3237 34.2875 25.9377 34.2875C22.5497 34.2875 19.8057 31.5415 19.8057 28.1555C19.8057 24.7715 22.5497 22.0255 25.9377 22.0255C29.3237 22.0255 32.0677 24.7715 32.0677 28.1555Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9371 8.65552H19.0031C15.1171 8.65552 12.5631 12.7155 14.2491 16.2175C14.2491 16.2175 15.5531 18.7435 15.1331 22.4715"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9375 8.65552H32.8695C36.7575 8.65552 39.3115 12.7155 37.6235 16.2175C37.6235 16.2175 36.3215 18.7435 36.7395 22.4715"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MuscleBuildingIcon;
