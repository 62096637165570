import React from 'react';
import PropTypes from 'prop-types';

import styles from '@components/UIKit/Loader/Loader.module.scss';

interface LoaderProps {
  variant?: any;
  size?: any;
  className?: string;
}

const Loader = ({ variant = 'light', size }: LoaderProps) => {
  return (
    <div className={`${styles.ldsEllipsis} ${styles[variant]} ${styles[size]}`}>
      <div className={styles.ldsEllipsisItem} />
      <div className={styles.ldsEllipsisItem} />
      <div className={styles.ldsEllipsisItem} />
      <div className={styles.ldsEllipsisItem} />
    </div>
  );
};

Loader.propTypes = {
  variant: PropTypes.oneOf(['primary', 'light'])
};

export default Loader;
