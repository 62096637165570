import React from 'react';

const FlameBigIcon = ({ size = 64, color, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.8999 10.1998C21.6999 6.39984 18.5999 4.79984 18.4999 4.69984C18.1999 4.49984 17.7999 4.59984 17.5999 4.79984C17.2999 4.99984 17.1999 5.29984 17.2999 5.59984C19.1999 11.3998 17.9999 14.8998 16.1999 16.1998C15.2999 16.8998 14.2999 16.9998 13.3999 16.5998C11.6999 15.6998 12.4999 13.0998 12.4999 13.0998C12.5999 12.7998 12.4999 12.3998 12.1999 12.1998C11.8999 11.9998 11.4999 11.9998 11.1999 12.1998C9.0999 13.6998 6.8999 17.0998 7.2999 21.5998C7.7999 26.6998 12.2999 30.3998 17.9999 30.3998C23.7999 30.3998 28.3999 26.1998 28.5999 20.5998C28.7999 17.0998 27.3999 13.2998 24.8999 10.1998Z"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={opacity / 100}
    />
  </svg>
);

export default FlameBigIcon;
