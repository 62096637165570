import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';

import { getFlows } from '@helpers/general/extraData';
import { RootReducerStateProps } from '@redux/types';
import { UserDataReducer } from './types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getLoggedInUserDataRequest: [],
  getLoggedInUserDataSuccess: ['userData'],
  getLoggedInUserDataFailure: ['error'],
  fetchUserDataGoogleRequest: [],
  fetchUserDataGoogleSuccess: ['userDataGoogle'],
  fetchUserDataGoogleFailure: ['error'],
  setImage: ['image'],
  setHasDietQuestionnaire: [''],
  setSubscriptionCancelled: []
});

export const UserDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  userData: null,
  userDataGoogle: null,
  body_parts: [],
  allergies: [],
  language: 'en'
});

/* ------------- Selectors ------------- */

export const userDataSelectors = {
  isFreemium: (state: RootReducerStateProps) =>
    state?.userData?.flows ? state.userData.flows.includes('freemium') : false,
  isSkippedQuestionnare: (state: RootReducerStateProps) => {
    if (state?.userData?.flows) {
      return state.userData.flows.includes('email');
    } else if (getFlows() && Array.isArray(getFlows())) {
      return getFlows().includes('email');
    } else {
      return false;
    }
  },
  isMultiLang: () => {
    return true;
    // if (getFlows() && Array.isArray(getFlows())) {
    //   return getFlows().includes('multilang');
    // }
    // else {
    //   return false;
    // }
  },
  getUserDataGoogle: (state: RootReducerStateProps) =>
    state?.userData?.userDataGoogle ? state.userData.userDataGoogle : null,
  hasDietQuestionnaire: (state: RootReducerStateProps) =>
    state.userData ? state.userData.has_diet_questionnaire : false,
  getWorkoutType: (state: RootReducerStateProps) => state.userData && state.userData.workout_type
};

/* ------------- Reducers ------------- */

export const getLoggedInUserDataRequest = (state: UserDataReducer) => ({ ...state, fetching: true, error: null });

export const getLoggedInUserDataSuccess = (state: UserDataReducer, action: any) => {
  const { userData } = action;

  return { ...state, fetching: false, error: null, ...userData };
};

export const successFetchUserDataGoogle = (state: UserDataReducer, action: any) => {
  const { userDataGoogle } = action;
  return { fetching: false, error: null, userDataGoogle };
};

export const failure = (state: UserDataReducer, action: any) => ({ ...state, fetching: false, error: action.error });

export const setImageReducer = (state: UserDataReducer, action: any) => ({ ...state, avatar: action.image });

export const setHasDietQuestionnaireReducer = (state: UserDataReducer) => ({ ...state, has_diet_questionnaire: true });

export const setSubscriptionCancelled = (state: UserDataReducer) => {
  const now = moment().format('YYYY-MM-DD HH:mm:ss');
  const subscription = state.subscription;
  return { ...state, subscription: { ...subscription, cancelled_at: now } };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LOGGED_IN_USER_DATA_REQUEST]: getLoggedInUserDataRequest,
  [Types.GET_LOGGED_IN_USER_DATA_SUCCESS]: getLoggedInUserDataSuccess,
  [Types.GET_LOGGED_IN_USER_DATA_FAILURE]: failure,
  [Types.FETCH_USER_DATA_GOOGLE_REQUEST]: getLoggedInUserDataRequest,
  [Types.FETCH_USER_DATA_GOOGLE_SUCCESS]: successFetchUserDataGoogle,
  [Types.FETCH_USER_DATA_GOOGLE_FAILURE]: failure,
  [Types.SET_IMAGE]: setImageReducer,
  [Types.SET_HAS_DIET_QUESTIONNAIRE]: setHasDietQuestionnaireReducer,
  [Types.SET_SUBSCRIPTION_CANCELLED]: setSubscriptionCancelled
});
