import { ACCESS } from '@consts/consts';
import BlogLayout from '@components/layouts/BlogLayout';

export const blogRoutes = {
  blog: {
    exact: true,
    path: '/blog',
    pageName: 'Blog',
    layout: BlogLayout,
    access: ACCESS.ALL
  },
  article: {
    exact: true,
    path: '/blog/:article_id',
    pageName: 'Article',
    layout: BlogLayout,
    access: ACCESS.ALL
  }
};
