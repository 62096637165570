import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import routes from '@routes';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import renderCurrentYear from '@helpers/general/renderCurrentYear';
import { useCompanyContext } from '@providers/CompanyProvider';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import styles from '@components/navigation/Footer/Footer.module.css';

const FitneyFooter = ({ isMultiLang }: any) => {
  const companyData: any = useCompanyContext();
  return (
    <footer className={`d-flex justify-content-between ${styles.main}`}>
      <div className={styles.copyrightWrapper}>
        <div className={styles.copyright}>
          <FormattedMessage id="footer.copyright" defaultMessage="Copyright ©" description="Footer - copyright" />
          {renderCurrentYear({ dot: false })}
          <FormattedMessage id="footer.fitney" defaultMessage="Fitney" description="Footer - Fitney" />
        </div>
        <div className={styles.copyright}>
          {companyData.entity}, {companyData.address},<a href="mailto:support@fitney.co">support@fitney.co</a>
        </div>
      </div>
      <div className={`d-flex justify-content-end align-items-center ${styles.linksAndDropdownWrapper}`}>
        <ul className={`d-flex ${styles.links}`}>
          <li>
            <Link to={routes.privacyPolicy.path}>
              <FormattedMessage
                id="footer.link-privacy-policy"
                defaultMessage="Privacy Policy"
                description="Footer - link to privacy policy"
              />
            </Link>
          </li>
          <li>
            <Link to={routes.termsOfUse.path}>
              {' '}
              <FormattedMessage
                id="footer.link-terms"
                defaultMessage="Terms of Service"
                description="Footer - link to terms of service"
              />
            </Link>
          </li>
          <li>
            <Link to={routes.support.path}>
              <FormattedMessage
                id="footer.link-support"
                defaultMessage="Support"
                description="Footer - link to support"
              />
            </Link>
          </li>
          <li>
            <Link to={routes.billingPolicy.path}>
              <FormattedMessage
                id="footer.link-billing-policy"
                defaultMessage="Billing Policy"
                description="Footer - link to billing policy"
              />
            </Link>
          </li>
        </ul>
        <div className={`${styles.languageSelect}`}>{isMultiLang && <LanguageDropdown />}</div>
      </div>
    </footer>
  );
};

// FitneyFooter.propTypes = {
//   language: PropTypes.string,
// }

const mapStateToProps = () => ({
  isMultiLang: userDataSelectors.isMultiLang()
});

export default connect(mapStateToProps)(FitneyFooter);
