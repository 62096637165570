import React from 'react';

const SadFaceIcon = () => (
  <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M40.7471 38.8131C35.2805 34.9865 28.7205 32.7998 21.6138 32.7998C14.5071 32.7998 7.94714 34.9865 2.48047 38.8131"
        stroke="#979797"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6338 2.18652V6.55986"
        stroke="#979797"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4141 2.18652V6.55986"
        stroke="#979797"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7075 15.3057L18.8809 22.6857H22.7075"
        stroke="#979797"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="42.3667" height="41" fill="white" transform="translate(0.566406)" />
      </clipPath>
    </defs>
  </svg>
);

export default SadFaceIcon;
