import { defineMessages } from 'react-intl';

const translatedLanding = (intl: any) => {
  const messages: any = defineMessages({
    goToApp: {
      id: 'coronasummer.go-to-app',
      defaultMessage: 'go to app',
      description: 'coronasummer landing, go to app'
    },
    linkFormula: {
      id: 'coronasummer.link-formula',
      defaultMessage: 'Success formula',
      description: 'coronasummer landing, link - formula for success'
    },
    linkApp: {
      id: 'coronasummer.link-app',
      defaultMessage: 'Our app',
      description: 'coronasummer landing, link - our app'
    },
    linkTrainingsAndTrainers: {
      id: 'coronasummer.link-trainings-and-trainers',
      defaultMessage: 'Trainings and trainers',
      description: 'coronasummer landing, link - trainings and trainers'
    },
    linkCommunity: {
      id: 'coronasummer.link-community',
      defaultMessage: 'Fitney community',
      description: 'coronasummer landing, link - our community'
    },
    linkPrivacyPolicy: {
      id: 'coronasummer.link-privacy-policy',
      defaultMessage: 'Privacy policy',
      description: 'coronasummer landing, link - privacy policy'
    },
    linkBillingPolicy: {
      id: 'coronasummer.link-billing-policy',
      defaultMessage: 'Billing policy',
      description: 'coronasummer landing, link - billing policy'
    },
    linkBlog: {
      id: 'coronasummer.link-blog',
      defaultMessage: 'Blog',
      description: 'coronasummer landing, link - blog'
    },
    linkTermsOfService: {
      id: 'coronasummer.link-terms-of-service',
      defaultMessage: 'Terms of service',
      description: 'coronasummer landing, link - terms of service'
    },
    socialMediaLinksTitle: {
      id: 'coronasummer.social-media-links-title',
      defaultMessage: 'Follow us',
      description: 'coronasummer landing, social media links title'
    },
    buttonStartNow: {
      id: 'coronasummer.button-start-now',
      defaultMessage: 'Start now',
      description: 'coronasummer landing, button start now'
    },
    buttonJoinChallenge: {
      id: 'coronasummer.button-join-challenge',
      defaultMessage: 'Join the #SplititUp challenge!',
      description: 'coronasummer landing, button join challenge'
    },
    buttonLogin: {
      id: 'coronasummer.button-log-in',
      defaultMessage: 'Sign in',
      description: 'coronasummer landing, button sign in'
    },
    buttonTryForFree: {
      id: 'coronasummer.button-try-free',
      defaultMessage: 'Start for free',
      description: 'coronasummer landing, button try for free'
    },
    buttonTrainWithUs: {
      id: 'coronasummer.button-train-with-us',
      defaultMessage: 'Train with us',
      description: 'coronasummer landing, button train with us'
    },
    heroTitle: {
      id: 'coronasummer.hero.title',
      defaultMessage: 'Fitney. Your personal trainer – always with you',
      description: 'coronasummer landing, hero title'
    },
    threePillarsTitle: {
      id: 'coronasummer.three-pillars.title',
      defaultMessage: '3 pillars on which you base your success',
      description: 'coronasummer landing, three pillars section title'
    },
    threeTabsTitle: {
      id: 'coronasummer.three-tabs.title',
      defaultMessage: 'An application that makes success easier',
      description: 'coronasummer landing, three tabs section title'
    },
    threeTabsSubtitle: {
      id: 'coronasummer.three-tabs.subtitle',
      defaultMessage: 'See, how easy it is to use Fitney',
      description: 'coronasummer landing, three tabs section subtitle'
    },
    threeTabsParagraph: {
      id: 'coronasummer.three-tabs.paragraph',
      defaultMessage: 'We give you the tools – now your move. The first week completely for free',
      description: 'coronasummer landing, three tabs section paragraph'
    },
    tabTraining: {
      id: 'coronasummer.three-tabs.tab-training',
      defaultMessage: 'Workouts – you release endorphins.',
      description: 'coronasummer landing, three tabs section - tab training'
    },
    tabTrainingMobile: {
      id: 'coronasummer.three-tabs.tab-training-mobile',
      defaultMessage: 'Workouts',
      description: 'coronasummer landing, three tabs section - tab training mobile'
    },
    tabStats: {
      id: 'coronasummer.three-tabs.tab-stats',
      defaultMessage: 'Statistics – you check if it’s ok.',
      description: 'coronasummer landing, three tabs section - tab stats'
    },
    tabStatsMobile: {
      id: 'coronasummer.three-tabs.tab-stats-mobile',
      defaultMessage: 'Statistics',
      description: 'coronasummer landing, three tabs section - tab stats mobile'
    },
    tabDiet: {
      id: 'coronasummer.three-tabs.tab-diet',
      defaultMessage: 'Diet – you improve your metabolism.',
      description: 'coronasummer landing, three tabs section - tab diet'
    },
    tabDietMobile: {
      id: 'coronasummer.three-tabs.tab-diet-mobile',
      defaultMessage: 'Diet',
      description: 'coronasummer landing, three tabs section - tab diet mobile'
    },
    seeWhatYouGainSectionTitle: {
      id: 'coronasummer.see-what-you-gain.title',
      defaultMessage: 'See what you gain by training with Fitney under the watchful eye of your personal trainer',
      description: 'coronasummer landing, see what you gain section title'
    },
    ourTrainersSectionTitle: {
      id: 'coronasummer.our-trainers.title',
      defaultMessage: 'Our trainers and trainings',
      description: 'coronasummer landing, our trainers section title'
    },
    seeWhatYouGainSectionSubtitle: {
      id: 'coronasummer.see-what-you-gain.subtitle',
      defaultMessage:
        'Do you dream about having a personal trainer? With Fitney you have it in the package without leaving home. Together you define your needs and goals. You choose the right exercises. You follow the progress. And your satisfaction increases with each training.',
      description: 'coronasummer landing, see what you gain section subtitle'
    },
    gainConsultationTitle: {
      id: 'coronasummer.see-what-you-gain.gain-consultation-title',
      defaultMessage: 'Consultation with a trainer for FREE!',
      description: 'coronasummer landing, see what you gain section - gain consultation title'
    },
    gainConsultationParagraph: {
      id: 'coronasummer.see-what-you-gain.gain-consultation-paragraph',
      defaultMessage:
        'Everyone is different, has different possibilities, needs and expectations. That is why, together with the trainer, you set your goals as well as the time and way to achieve them: type of training, its frequency, level of advancement, supporting diet.',
      description: 'coronasummer landing, see what you gain section - gain consultation paragraph'
    },
    gainConsultationButton: {
      id: 'coronasummer.see-what-you-gain.gain-consultation-button',
      defaultMessage: 'Make an appointment',
      description: 'coronasummer landing, see what you gain section - gain consultation button'
    },
    gainConsultationCheckpoint: {
      id: 'coronasummer.see-what-you-gain.gain-consultation-checkpoint',
      defaultMessage: 'Registration',
      description: 'coronasummer landing, see what you gain section - gain consultation checkpoint'
    },
    gainPlanTitle: {
      id: 'coronasummer.see-what-you-gain.gain-plan-title',
      defaultMessage: 'Individual training plan',
      description: 'coronasummer landing, see what you gain section - gain plan title'
    },
    gainPlanParagraph: {
      id: 'coronasummer.see-what-you-gain.gain-plan-paragraph',
      defaultMessage:
        'This is the most effective plan for you. From now on, everything is in your... muscles. For 2 weeks you test the plan and decide if it is OK. Based on your opinion we will change it if necessary.',
      description: 'coronasummer landing, see what you gain section - gain plan paragraph'
    },
    gainPlanCheckpoint: {
      id: 'coronasummer.see-what-you-gain.gain-plan-checkpoint',
      defaultMessage: 'First training',
      description: 'coronasummer landing, see what you gain section - gain plan checkpoint'
    },
    gainMonitoringTitle: {
      id: 'coronasummer.see-what-you-gain.gain-monitoring-title',
      defaultMessage: 'Progress monitoring',
      description: 'coronasummer landing, see what you gain section - gain monitoring title'
    },
    gainMonitoringParagraph: {
      id: 'coronasummer.see-what-you-gain.gain-monitoring-paragraph',
      defaultMessage:
        'Do you lose weight at the pace that suits you? Do you want to strengthen your abdominal muscles? In the app you will see how you are doing. Your trainer will also contact you to correct your workout plan, diet or give you tips.',
      description: 'coronasummer landing, see what you gain section - gain monitoring paragraph'
    },
    gainMonitoringCheckpoint: {
      id: 'coronasummer.see-what-you-gain.gain-monitoring-checkpoint',
      defaultMessage: 'After 2 weeks',
      description: 'coronasummer landing, see what you gain section - gain monitoring checkpoint'
    },
    communitySectionTitle: {
      id: 'coronasummer.community.title',
      defaultMessage: "Fitney Community - with us it's worth it!",
      description: 'coronasummer landing, community section title'
    },
    instaProfilesSectionTitle: {
      id: 'coronasummer.instaprofiles.title',
      defaultMessage: 'Take care of your body. Join us. Let’s do it together!',
      description: 'coronasummer landing, instagram profiles section title'
    },
    yourTrainerSectionTitle: {
      id: 'coronasummer.your-trainer.title',
      defaultMessage: 'Fitney. For those who demand much more from a workout.',
      description: 'coronasummer landing, your trainer profiles section title'
    },
    yourTrainerSectionSubtitle: {
      id: 'coronasummer.your-trainer.subtitle',
      defaultMessage: 'No matter where you are. Regardless of when you want to exercise. Fitney is always with you.',
      description: 'coronasummer landing, your trainer section subtitle'
    },
    yourTrainerTrainingTabTitle: {
      id: 'coronasummer.your-trainer.training-tab-title',
      defaultMessage: 'Training',
      description: 'coronasummer landing, your trainer section - training tab title'
    },
    yourTrainerTrainingTabSubtitle: {
      id: 'coronasummer.your-trainer.training-tab-subtitle',
      defaultMessage: 'Your daily dose of activity',
      description: 'coronasummer landing, your trainer section - training tab subtitle'
    },
    yourTrainerDietTabTitle: {
      id: 'coronasummer.your-trainer.diet-tab-title',
      defaultMessage: 'Diet',
      description: 'coronasummer landing, your trainer section - diet tab title'
    },
    yourTrainerDietTabSubtitle: {
      id: 'coronasummer.your-trainer.diet-tab-subtitle',
      defaultMessage: 'You eat healthy and tasty from today',
      description: 'coronasummer landing, your trainer section - diet tab subtitle'
    },
    yourTrainerProgressTabTitle: {
      id: 'coronasummer.your-trainer.progress-tab-title',
      defaultMessage: 'Progress tracking',
      description: 'coronasummer landing, your trainer section - progress tab title'
    },
    yourTrainerProgressTabSubtitle: {
      id: 'coronasummer.your-trainer.progress-tab-subtitle',
      defaultMessage: 'You know where you are in your path',
      description: 'coronasummer landing, your trainer section - progress tab subtitle'
    },
    yourTrainerTrainerTabTitle: {
      id: 'coronasummer.your-trainer.training-trainer-title',
      defaultMessage: 'Trainer care',
      description: 'coronasummer landing, your trainer section - trainer tab title'
    },
    yourTrainerTrainerTabSubtitle: {
      id: 'coronasummer.your-trainer.trainer-tab-subtitle',
      defaultMessage: "The trainer's motivation will not let you give up",
      description: 'coronasummer landing, your trainer section - trainer tab subtitle'
    },
    newsletterPlaceholder: {
      id: 'coronasummer.footer.newsletter-placeholder',
      defaultMessage: 'Your e-mail address',
      description: 'coronasummer landing, footer, newsletter placeholder'
    },
    newsletterError: {
      id: 'coronasummer.footer.newsletter-error',
      defaultMessage: 'Please enter valid email address.',
      description: 'coronasummer landing, footer, newsletter error'
    },
    newsletterSubtitle: {
      id: 'coronasummer.footer.newsletter-subtitle',
      defaultMessage: 'Sign up and get 30 fit recipes for FREE',
      description: 'coronasummer landing, footer, newsletter subtitle'
    },
    newsletterSuccess: {
      id: 'coronasummer.footer.newsletter-success',
      defaultMessage: 'Your email has been saved',
      description: 'coronasummer landing, footer, newsletter success message'
    },
    externalLinksTitle: {
      id: 'coronasummer.footer.external-link-title',
      defaultMessage: 'Resources',
      description: 'coronasummer landing, footer, external links title'
    },
    partnerProgram: {
      id: 'coronasummer.footer.partner-program',
      defaultMessage: 'Affiliates Program',
      description: 'coronasummer landing, footer, partner program'
    },
    contact: {
      id: 'coronasummer.footer.contact-link',
      defaultMessage: 'Contact',
      description: 'coronasummer landing, footer, contact'
    },
    copyright: {
      id: 'coronasummer.footer.copyright',
      defaultMessage: '©Fitney',
      description: 'coronasummer landing, footer, copyright'
    },
    rightsReserved: {
      id: 'coronasummer.footer.rights-reserved',
      defaultMessage: 'All rights reserved',
      description: 'coronasummer landing, footer, rights reserved'
    }
  });
  Object.keys(messages).forEach((key) => {
    messages[key] = intl.formatMessage(messages[key]);
  });
  return messages;
};

export default translatedLanding;
