import React from 'react';

const FiltersIcon = ({ size = 64 }: any) => (
  <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#1A1818" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path d="M26.4889 12.9082H18.5529" />
      <path d="M12.238 12.9082H9.75" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.238 12.9082C12.238 14.6512 13.652 16.0652 15.396 16.0652C17.14 16.0652 18.553 14.6512 18.553 12.9082C18.553 11.1642 17.14 9.75024 15.396 9.75024C13.652 9.75024 12.238 11.1642 12.238 12.9082Z"
      />
      <path d="M9.74969 22.6953H17.6857" />
      <path d="M24.0007 22.6953H26.4887" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0007 22.6953C24.0007 20.9513 22.5867 19.5383 20.8437 19.5383C19.0997 19.5383 17.6857 20.9513 17.6857 22.6953C17.6857 24.4393 19.0997 25.8533 20.8437 25.8533C22.5867 25.8533 24.0007 24.4393 24.0007 22.6953Z"
      />
    </g>
  </svg>
);

export default FiltersIcon;
