import React from 'react';

const LevelAdvancedIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.146 21.7163V41.6203C20.146 42.2083 20.622 42.6843 21.21 42.6843H27.74C28.33 42.6843 28.806 42.2083 28.806 41.6203V17.2363C28.806 16.6583 28.176 16.3003 27.678 16.5963L20.664 20.8023C20.342 20.9963 20.146 21.3423 20.146 21.7163Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41162 29.9467V41.6207C6.41162 42.2087 6.88762 42.6847 7.47562 42.6847H13.6716C14.2596 42.6847 14.7356 42.2087 14.7356 41.6207V25.6687C14.7356 25.0887 14.1056 24.7327 13.6096 25.0307L6.92962 29.0327C6.60962 29.2267 6.41162 29.5727 6.41162 29.9467Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7474 8.16544L34.7314 12.3714C34.4114 12.5614 34.2134 12.9094 34.2134 13.2834V41.6214C34.2134 42.2074 34.6914 42.6854 35.2814 42.6854H41.8094C42.3974 42.6854 42.8734 42.2074 42.8734 41.6214V8.80344C42.8734 8.22544 42.2434 7.86944 41.7474 8.16544Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LevelAdvancedIcon;
