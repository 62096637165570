import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';

import routes from '@routes';
import colors from '@consts/colors';
import { PORTALS } from '@consts/consts';
import { UserAvatarIcon } from '@assets/icons';
import AppNavLinks from '@components/UIKit/AppNavLinks/AppNavLinks';
import Logo from '@components/UIKit/Logo';
import styles from './AppHeader.module.scss';

const AppHeader = ({ title, isMobile, pathInfo, withShadow }: any) => {
  const intl = useIntl();

  return (
    <header className={`${styles.header} ${withShadow ? styles.withShadow : ''}`}>
      <div className={styles.headerWrapper}>
        {isMobile && title && !!title.length ? (
          <h1 className={styles.title}>
            {intl.formatMessage({
              id: `appHeader.mobileTitle.${camelCase(title)}`,
              defaultMessage: title,
              description: 'fitney 2 - internal navigation links'
            })}
          </h1>
        ) : (
          <Logo className={styles.logo} to={routes.dailyWorkout.path} />
        )}
        {!isMobile && <AppNavLinks />}
        <div className={styles.elementsWrapper}>
          <div className={styles.headerLinks}>
            <div id={PORTALS.APP_HEADER_EL} />

            <Link
              to={routes.userSettings.path}
              className={`${styles.iconCircle} ${pathInfo.pathname === routes.userSettings.path && styles.active}`}
            >
              <UserAvatarIcon
                size={26}
                color={pathInfo.pathname === routes.userSettings.path ? colors.lightPink : colors.pink}
              />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

// AppHeader.propTypes = {
//   isMobile: PropTypes.bool,
//   title: PropTypes.string
// };

const mapStateToProps = (state: any) => ({
  isMobile: state.appStatus.isMobile,
  pathInfo: state.router.location
});

export default connect(mapStateToProps)(withRouter(AppHeader));
