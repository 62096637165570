import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createCreditCardRequest: ['creditCardData', 'isNewUser', 'paymentPlanName', 'browserInfo'],
  createCreditCardSuccess: [],
  createCreditCardFailure: ['error'],
  clearCreditCard: []
});

export const CreditCardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

export const creditCardSelectors = {
  fetching: (state: any) => state.creditCard.fetching,
  error: (state: any) => state.creditCard.error
};

/* ------------- Reducers ------------- */

export const requestCreateCreditCard = (state: any) => ({ ...state, fetching: true, error: null });

export const successCreateCreditCard = (state: any) => ({ ...state, fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

export const clear = (state: any) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_CREDIT_CARD_REQUEST]: requestCreateCreditCard,
  [Types.CREATE_CREDIT_CARD_SUCCESS]: successCreateCreditCard,
  [Types.CREATE_CREDIT_CARD_FAILURE]: failure,
  [Types.CLEAR_CREDIT_CARD]: clear
});
