import { getAfId } from '@helpers/general/extraData';
import {post} from '@services/api';
import {IApiBuilder} from '@services/types';

const SignUpApi = (props: IApiBuilder) => {
    const {client, buildUrl} = props;

    const signUpByEmail = (
        email: string,
        password: string,
        password_confirmation: string,
        phone: string,
        marketing_agreement: boolean,
        landing_trainer_id: string,
        training_type_id: string,
        referrer: boolean = false
    ) => {
        return post({
            client: client,
            url: buildUrl({ version: false, url: '/register/email' }),
            data: {
                email,
                password,
                password_confirmation,
                phone,
                marketing_agreement,
                landing_trainer_id,
                training_type_id,
                referrer,
                appsflyer_uid: getAfId()
            }
        });
    }


    const signUpByFacebook = (access_token: string, phone: string, marketing_agreement: boolean) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/register/facebook' }),
            data: {
                access_token,
                phone,
                marketing_agreement
            }
        });

    return {
        signUpByEmail,
        signUpByFacebook
    }
}

export default SignUpApi;