import { call, put } from '@redux-saga/core/effects';

import DietActions from '@redux/Fitney2.0/Diet/diet';

import DietRecipe, { IDietRecipesDTO } from '@models/Diet/Recipes/DietRecipe';
import DietType, { IDietTypeDTO } from '@models/Diet/Filter/Type/DietType';
import { IDietAPI } from '@api/target/DietApi';
import { ApiResponse } from 'apisauce';
import DietAllergyRecipes, { IDietAllergyDTO } from '@models/Diet/Filter/Allergy/DietAllergy';
import DietMeal, { IDietMealDTO } from '@models/Diet/Filter/Meal/DietMeal';

export function* getDiet(api: IDietAPI): any {
  const response: ApiResponse<IDietRecipesDTO> = yield call(api.getDiet);

  if (response.ok && response.data) {
    yield put(DietActions.fetchDietSuccess(DietRecipe.build(response.data)));
  } else {
    const errorMessage = 'Problem with fetching diet data';
    yield put(
      DietActions.fetchDietFailure({
        status: response.status,
        problem: errorMessage,
        message: errorMessage
      })
    );
  }
}

export function* getDietTypes(api: IDietAPI): any {
  const response: ApiResponse<Array<IDietTypeDTO>> = yield call(api.getDietTypes);
  if (response.ok && response.data) {
    yield put(DietActions.fetchDietTypesSuccess(response.data.map((dietType) => DietType.build(dietType))));
  } else {
    const errorMessage = 'Problem with fetching diet data';
    yield put(
      DietActions.fetchDietTypesFailure({ status: response.status, problem: errorMessage, message: errorMessage })
    );
  }
}

export function* getQuestionnaireTypes(api: IDietAPI): any {
  const response: ApiResponse<Array<IDietTypeDTO>> = yield call(api.getQuestionnaireDietTypes);
  if (response.ok && response.data) {
    yield put(DietActions.fetchDietTypesSuccess(response.data.map((dietType) => DietType.build(dietType))));
  } else {
    const errorMessage = 'Problem with fetching questionnaire diet data';
    yield put(
      DietActions.fetchDietTypesFailure({ status: response.status, problem: errorMessage, message: errorMessage })
    );
  }
}

export function* getMealTypes(api: IDietAPI): any {
  const response: ApiResponse<Array<IDietMealDTO>> = yield call(api.getMealTypes);

  if (response.ok && response.data) {
    yield put(DietActions.fetchMealTypesSuccess(response.data.map((mealType) => DietMeal.build(mealType))));
  } else {
    const errorMessage = 'Problem with fetching diet data';
    yield put(
      DietActions.fetchMealTypesFailure({ status: response.status, problem: errorMessage, message: errorMessage })
    );
  }
}

export function* getAllergies(api: IDietAPI): any {
  const response: ApiResponse<Array<IDietAllergyDTO>> = yield call(api.getAllergies);
  if (response.ok && response.data) {
    yield put(
      DietActions.fetchAllergiesSuccess(response.data.map((allergyType) => DietAllergyRecipes.build(allergyType)))
    );
  } else {
    const errorMessage = 'Problem with fetching diet data';
    yield put(
      DietActions.fetchAllergiesFailure({ status: response.status, problem: errorMessage, message: errorMessage })
    );
  }
}
