import React from 'react';

const WorkoutLevelIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3896 15.5116V29.7286C14.3896 30.1496 14.7306 30.4896 15.1506 30.4896H19.8146C20.2346 30.4896 20.5756 30.1496 20.5756 29.7286V12.3116C20.5756 11.8986 20.1246 11.6436 19.7706 11.8556L14.7586 14.8596C14.5306 14.9956 14.3896 15.2436 14.3896 15.5116Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5791 21.3905V29.7285C4.5791 30.1495 4.9201 30.4895 5.3411 30.4895H9.7651C10.1851 30.4895 10.5251 30.1495 10.5251 29.7285V18.3345C10.5251 17.9215 10.0751 17.6655 9.7211 17.8785L4.9491 20.7385C4.7201 20.8755 4.5791 21.1235 4.5791 21.3905Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8195 5.83212L24.8085 8.83612C24.5795 8.97412 24.4395 9.22112 24.4395 9.48812V29.7281C24.4395 30.1491 24.7795 30.4891 25.2005 30.4891H29.8645C30.2845 30.4891 30.6255 30.1491 30.6255 29.7281V6.28912C30.6255 5.87512 30.1735 5.62012 29.8195 5.83212Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorkoutLevelIcon;
