import React from 'react';

const ChartLineIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M14.72 21.79c-.065 0-.132-.001-.198-.006-1.272-.093-2.24-1.044-2.355-2.314L10.823 4.66c-.063-.691-.572-.962-1.026-.984-.454-.02-.987.196-1.12.878l-1.47 7.789c-.204 1.06-1.17 1.828-2.298 1.828H2.048c-.404 0-.732-.327-.732-.732 0-.404.328-.731.732-.731h2.86c.428 0 .79-.27.86-.638L7.24 4.28c.247-1.282 1.3-2.135 2.629-2.067 1.302.065 2.294 1.017 2.413 2.315l1.344 14.81c.06.677.558.953 1.002.985.42.035.976-.167 1.135-.83l1.213-5.056c.245-1.018 1.182-1.73 2.278-1.73h2.698c.405 0 .733.328.733.732 0 .405-.328.732-.733.732h-2.698c-.41 0-.77.256-.854.608l-1.213 5.056c-.282 1.175-1.281 1.956-2.467 1.956"
          transform="translate(-182 -414) translate(182 414)"
        />
      </svg>
    </React.Fragment>
  );
};

export default ChartLineIcon;
