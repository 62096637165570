export interface LanguageDropdownProps {
  isDarkBackground?: boolean;
  alignRight?: boolean;
  navigation?: boolean;
  language: string;
  setLanguage?: any;
  updateLanguage?: any;
  isLoadingUpdateLanguage: boolean;
}

export enum FlagCodes {
  GB = 'GB',
  PL = 'PL',
  ES = 'ES'
}
