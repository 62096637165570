import React from 'react';

const FitneyLogoIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg width={size} height={size} viewBox="0 0 16 16">
        <path
          fill={color}
          d="M11.3,13.5L11.3,13.5c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.3-0.3-0.7-0.5-1.1-0.7  C10.1,8.4,10,8.1,10.2,8l1.2-1.2c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-1.2-1.2-3.2-1.1-4.4,0.1L2.5,6.9  C2.2,7.2,2.1,7.6,2.1,8c0,0.4,0.1,0.8,0.4,1.1l2.2,2.2l0,0l2.2,2.2C8.1,14.7,10.1,14.7,11.3,13.5z M3.8,8.2c-0.1-0.1-0.1-0.3,0-0.4  l4.2-4.2C8.6,3,9.6,2.9,10.2,3.5c0.3,0.3,0.5,0.7,0.5,1.1v0c0,0.4-0.2,0.8-0.5,1.1L7,9c0,0-0.1,0-0.1,0.1l-1,1  c-0.1,0.1-0.3,0.1-0.4,0L3.8,8.2z M7.9,12.4L7,11.5c-0.1-0.1-0.1-0.3,0-0.4l1-1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1.1,0.5  c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.1C9.6,13.1,8.6,13,7.9,12.4z"
        />
      </svg>
    </React.Fragment>
  );
};

export default FitneyLogoIcon;
