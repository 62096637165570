import React from 'react';

import colors from '@consts/colors';

const ProteinsIcon = ({ size = 28, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 31 28" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.575"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M13.0317 16.9147H3.00112C2.329 16.9147 1.78564 16.3677 1.78564 15.6946V11.6469C1.78564 10.9738 2.329 10.4268 3.00112 10.4268H13.0317C13.7038 10.4268 14.2482 10.9738 14.2482 11.6469V15.6946C14.2482 16.3677 13.7038 16.9147 13.0317 16.9147Z" />
      <path d="M28.7417 16.9147H18.7101C18.038 16.9147 17.4946 16.3677 17.4946 15.6946V11.6469C17.4946 10.9738 18.038 10.4268 18.7101 10.4268H28.7417C29.4138 10.4268 29.9572 10.9738 29.9572 11.6469V15.6946C29.9572 16.3677 29.4138 16.9147 28.7417 16.9147Z" />
      <path d="M20.8037 7.27506H10.7732C10.101 7.27506 9.55664 6.72801 9.55664 6.05601V2.00721C9.55664 1.33416 10.101 0.787109 10.7732 0.787109H20.8037C21.4758 0.787109 22.0192 1.33416 22.0192 2.00721V6.05601C22.0192 6.72801 21.4758 7.27506 20.8037 7.27506Z" />
      <path d="M20.8037 20.0664H10.7732C10.101 20.0664 9.55664 20.6124 9.55664 21.2855V25.3343C9.55664 26.0073 10.101 26.5533 10.7732 26.5533H20.8037C21.4758 26.5533 22.0192 26.0073 22.0192 25.3343V21.2855C22.0192 20.6124 21.4758 20.0664 20.8037 20.0664Z" />
    </g>
  </svg>
);

export default ProteinsIcon;
