import React from 'react';

const CloseIcon = ({ size = 14, color = 100 }: any) => (
  <React.Fragment>
    <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.00024L14.1802 14.1789"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1797 1.00024L0.999512 14.1789"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </React.Fragment>
);

export default CloseIcon;
