import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { RootReducerStateProps } from '@redux/types';
import { TrainingsUserStateType } from './types';
import { ITrainingsUser } from '@models/TrainingsUser/TrainingsUser';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getTrainingsUserRequest: ['id'],
  getTrainingsUserSuccess: ['data'],
  getTrainingsUserFailure: ['error']
});

export const TrainingsUserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: null
});

/* ------------- Selectors ------------- */

export const trainingsUserSelectors = {
  isManuallyAssignedChallenge: (state: RootReducerStateProps): boolean =>
    !!(state.trainingsUser?.data?.manuallyAssigned && state.trainingsUser?.data?.trainingPlan?.challenge)
};

/* ------------- Reducers ------------- */

export const getTrainingsUserRequest = (state: TrainingsUserStateType) => ({ ...state, fetching: true, error: null });

export const getTrainingsUserSuccess = (state: TrainingsUserStateType, action: { data: ITrainingsUser }) => ({
  ...state,
  fetching: false,
  error: null,
  data: action.data
});

export const getTrainingsUserFailure = (state: TrainingsUserStateType, action: { error: Error }) => ({
  ...state,
  fetching: false,
  error: action.error
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TRAININGS_USER_REQUEST]: getTrainingsUserRequest,
  [Types.GET_TRAININGS_USER_SUCCESS]: getTrainingsUserSuccess,
  [Types.GET_TRAININGS_USER_FAILURE]: getTrainingsUserFailure
});
