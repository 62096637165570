import React from 'react';
import { Link } from 'react-router-dom';

import logo from '@assets/logo/logo_horizontal.svg';
import logoInverted from '@assets/logo/logo_horizontal_white.svg';
import routes from '@routes';

const Logo = ({ className, inverted, to = routes.main.path }: any) => (
  <Link to={to} className={className}>
    <img src={inverted ? logoInverted : logo} alt="Fitney logo" />
  </Link>
);

export default Logo;
