import React from 'react';

const BodyFocusIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1421 7.49902H1.68809C1.19009 7.49902 0.788086 7.90252 0.788086 8.39902V9.31402C0.788086 9.90202 1.20809 10.406 1.78559 10.514L11.4336 12.2975L9.73859 31.025C9.65609 32.0105 11.0376 32.3225 11.3841 31.3955L15.6996 19.8515L20.0151 31.3955C20.3616 32.3225 21.7446 32.0105 21.6606 31.025L19.9671 12.2975L29.6136 10.514C30.1911 10.406 30.6111 9.90202 30.6111 9.31402V8.39902C30.6111 7.90252 30.2091 7.49902 29.7111 7.49902H21.4746"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.795 3.88259C18.795 5.59259 17.409 6.97709 15.7005 6.97709C13.9905 6.97709 12.6045 5.59259 12.6045 3.88259C12.6045 2.17259 13.9905 0.788086 15.7005 0.788086C17.409 0.788086 18.795 2.17259 18.795 3.88259Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BodyFocusIcon;
