import {get} from "@services/api";
import { IApiBuilder } from "@services/types";

const RecipeApi = (props: IApiBuilder) => {
    const {client, buildUrl} = props;

    const getRecipes = (page: any, per_page: any, query: any, filters: any) =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/diets/recipes?page=${page}&per_page=${per_page}&query=${query}&${filters}` }),
        });

    const getRecipesByCategory = (categoryId: any) =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/diets/categoryRecipes/${categoryId}` }),
        });

    const getRecipeCategories = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/diets/recipeCategories` }),
        });

    const getRecipeById = (recipeId: any) =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/diets/recipe/${recipeId}` }),
        });

    return {
        getRecipes,
        getRecipesByCategory,
        getRecipeCategories,
        getRecipeById
    }
}

export default RecipeApi;