import { call, put } from '@redux-saga/core/effects';

import articlesSagas from '@redux/ToCheck/blog/articles';
import topArticlesActions from '@redux/ToCheck/blog/topArticles';
import categoriesActions from '@redux/ToCheck/blog/categories';

export function* getArticle(api: any, action: any): any {
  const { articleId } = action;
  try {
    const response = yield call(api.getArticle, articleId);
    if (response.ok) {
      yield put(articlesSagas.fetchArticleSuccess(response.data));
    } else {
      yield put(articlesSagas.fetchArticleFailure(response.data.errors));
    }
  } catch (e) {
    yield put(articlesSagas.fetchArticleFailure(e));
  }
}

export function* getArticles(api: any, action: any): any {
  const { categories } = action;
  try {
    const response = yield call(api.getArticles, categories);
    if (response.ok) {
      yield put(articlesSagas.fetchArticlesSuccess(response.data.data));
    } else {
      yield put(articlesSagas.fetchArticlesFailure(response.data.errors));
    }
  } catch (e) {
    yield put(articlesSagas.fetchArticlesFailure(e));
  }
}

export function* getTopArticles(api: any, action: any): any {
  const { categories } = action;
  try {
    const response = yield call(api.getTopArticles, categories);
    if (response.ok) {
      yield put(topArticlesActions.fetchTopArticlesSuccess(response.data));
    } else {
      yield put(topArticlesActions.fetchTopArticlesFailure(response.data.errors));
    }
  } catch (e) {
    yield put(topArticlesActions.fetchTopArticlesFailure(e));
  }
}

export function* getCategories(api: any): any {
  try {
    const response = yield call(api.getCategories);
    if (response.ok) {
      yield put(categoriesActions.fetchCategoriesSuccess(response.data));
    } else {
      yield put(categoriesActions.fetchCategoriesFailure(response.data.errors));
    }
  } catch (e) {
    yield put(categoriesActions.fetchCategoriesFailure(e));
  }
}

export function* incrementArticleViews(api: any, action: any): any {
  const { articleId } = action;
  yield call(api.incrementArticleViews, articleId);
}
