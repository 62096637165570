import React from 'react';

import colors from '@consts/colors';

const EquipmentIcon = ({ size = 36, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M15.1004 31.4004H12.8004C12.3004 31.4004 11.9004 31.0004 11.9004 30.5004V21.8004C11.9004 21.3004 12.3004 20.9004 12.8004 20.9004H15.1004C15.6004 20.9004 16.0004 21.3004 16.0004 21.8004V30.5004C16.0004 31.0004 15.6004 31.4004 15.1004 31.4004Z" />
      <path d="M28.6998 31.4004H30.9998C31.4998 31.4004 31.8998 31.0004 31.8998 30.5004V21.8004C31.8998 21.3004 31.4998 20.9004 30.9998 20.9004H28.6998C28.1998 20.9004 27.7998 21.3004 27.7998 21.8004V30.5004C27.7998 31.0004 28.1998 31.4004 28.6998 31.4004Z" />
      <path d="M25.1002 24.5996H18.7002" />
      <path d="M25.1002 27.7998H18.7002" />
      <path d="M14.3996 12C8.99961 12 4.59961 16.4 4.59961 21.9C4.59961 24.3 5.49961 26.6 6.89961 28.3" />
      <path d="M24.1004 20.5C23.4004 15.7 19.3004 12 14.4004 12" />
      <path d="M14.4002 5.59961H9.60017C6.90017 5.59961 5.20017 8.39961 6.30017 10.7996C6.30017 10.7996 7.20017 12.4996 6.90017 15.0996" />
      <path d="M14.4004 5.59961H19.2004C21.9004 5.59961 23.6004 8.39961 22.5004 10.7996C22.5004 10.7996 21.6004 12.4996 21.9004 15.0996" />
    </g>
  </svg>
);

export default EquipmentIcon;
