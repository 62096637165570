import { ApiResponse } from 'apisauce';
import DietRecipesDTO from '@models/Diet/Recipes/DietRecipe';
import DietTypeRecipesDTO from '@models/Diet/Filter/Type/DietType';
import DietMealRecipesDTO from '@models/Diet/Filter/Meal/DietMeal';
import DietAllergyRecipesDTO from '@models/Diet/Filter/Allergy/DietAllergy';
import {get} from "@services/api";
import {IApiBuilder} from "@services/types";

export interface IDietAPI {
  getDiet(): Promise<ApiResponse<DietRecipesDTO>>;
  getDietTypes(): Promise<ApiResponse<Array<DietTypeRecipesDTO>>>;
  getQuestionnaireDietTypes(): Promise<ApiResponse<Array<DietTypeRecipesDTO>>>;
  getMealTypes(): Promise<ApiResponse<Array<DietMealRecipesDTO>>>;
  getAllergies(): Promise<ApiResponse<Array<DietAllergyRecipesDTO>>>;
  getQuestionnaireAllergies(): Promise<ApiResponse<Array<DietAllergyRecipesDTO>>>;
}

const DietApi = (props: IApiBuilder) => {
  const {client, buildUrl} = props;

  const getDiet = () => get<DietRecipesDTO>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/diet' })
  });

  const getDietTypes = () => get<Array<DietTypeRecipesDTO>>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/dietTypes/forFilters' })
  });

  const getQuestionnaireDietTypes = () => get<Array<DietTypeRecipesDTO>>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/dietTypes/forQuestionnaire' })
  });

  const getMealTypes = () => get<Array<DietMealRecipesDTO>>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/mealTypes' })
  });

  const getAllergies = () => get<Array<DietAllergyRecipesDTO>>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/allergies/forFilters' })
  });

  const getQuestionnaireAllergies = () => get<Array<DietAllergyRecipesDTO>>({
    client: client,
    url: buildUrl({ version: false, url: '/diets/allergies' })
  });
  
  return {
    getDiet,
    getDietTypes,
    getQuestionnaireDietTypes,
    getMealTypes,
    getAllergies,
    getQuestionnaireAllergies
  }
}

export default DietApi;