import {ApisauceInstance} from "apisauce";
import {AxiosRequestConfig} from "axios";

export interface IApiProps {
    config: {
        baseURL: string;
        defaultApiVersion: string;
        apiUrlSuffix: string;
        responseType?: string;
        cookie: {
            amount: number;
            unit: string;
        };
    };
    monitors: (response: any) => void;
}

export interface IApiActionProps {
    client: ApisauceInstance,
    params?: any
    url: string,
    axiosConfig?: AxiosRequestConfig,
}

export enum API_CLIENT_TYPES {
    DIET = 'DIET',
    TRAININGS = 'TRAININGS'
}

export interface IApiBuilder {
    client: ApisauceInstance,
    buildUrl: (props: Omit<IApiBuilderProps, 'config'>) => string
    type?: API_CLIENT_TYPES;
}

export interface IApiBuilderProps extends Omit<IApiProps, 'monitors'> {
    version: string | boolean | undefined,
    url: string,
}

export interface IApiActionDataProps extends IApiActionProps {
    data?: any
}