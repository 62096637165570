import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchLastTrainingPlanTrainingViewRequest: ['trainingPlanId'],
  fetchLastTrainingPlanTrainingViewSuccess: ['newView'],
  fetchLastTrainingPlanTrainingViewFailure: ['error'],
  setTrainingExerciseViewedRequest: ['exerciseId', 'trainingId'],
  setTrainingExerciseViewedSuccess: ['newView'],
  setTrainingExerciseViewedFailure: ['error'],
  setTrainingPlanTrainingViewedRequest: ['trainingId'],
  setTrainingPlanTrainingViewedSuccess: ['newView'],
  setTrainingPlanTrainingViewedFailure: ['error']
});

export const UserViewsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  views: [],
  lastTrainingViews: []
});

/* ------------- Selectors ------------- */

export const userViewsSelectors = {
  isExerciseViewed: (state: any, exerciseId: any, trainingId: any) => {
    if (!state.userViews.views) {
      return false;
    }
    let viewIndex = state.userViews.views.findIndex(
      (view: any) => view.exercise_id === exerciseId && view.training_id === trainingId
    );
    return viewIndex !== -1;
  },
  getCurrentTrainingPlanDay: (state: any) => {
    if (!state.userViews.lastTrainingViews) {
      return 1;
    }
    if (!state.trainingPlan.TrainingPlan) {
      return 1;
    }
    const lastView = state.userViews.lastTrainingViews.find(
      (view: any) => view.trainingPlanId === state.trainingPlan.TrainingPlan.id
    );

    if (!lastView) {
      return 1;
    }

    if (moment(lastView.created_at) < moment().startOf('day')) {
      return lastView.day + 1;
    } else {
      return lastView.day;
    }
  }
};

/* ------------- Reducers ------------- */

export const startFetching = (state: any) => ({ ...state, fetching: true, error: null });

export const saveTrainingViewsViews = (state: any, action: any) => {
  const { newView } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    lastTrainingViews: newView ? [newView] : []
  };
};

export const successSetTrainingPlanTrainingViewed = (state: any, action: any) => {
  const { newView } = action;
  const { lastTrainingViews } = state;

  let updatedViews = lastTrainingViews ? JSON.parse(JSON.stringify(lastTrainingViews)) : [];
  let viewIndex = updatedViews.findIndex((view: any) => view.trainingPlanId === newView.trainingPlanId);

  const isDuplicated = viewIndex !== -1;

  if (isDuplicated) {
    updatedViews[viewIndex] = newView;
  } else {
    updatedViews.push(newView);
  }

  return { ...state, fetching: false, error: null, lastTrainingViews: updatedViews };
};

export const successSetExerciseViewed = (state: any, action: any) => {
  const { newView } = action;
  const { views } = state;

  let updatedViews = views ? JSON.parse(JSON.stringify(views)) : [];
  let viewIndex = updatedViews.findIndex(
    (view: any) => view.exercise_id === newView.exercise_id && view.training_id === newView.training_id
  );

  const isDuplicated = viewIndex !== -1;

  if (isDuplicated) {
    updatedViews[viewIndex] = newView;
  } else {
    updatedViews.push(newView);
  }

  return { ...state, fetching: false, error: null, views: updatedViews };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_LAST_TRAINING_PLAN_TRAINING_VIEW_REQUEST]: startFetching,
  [Types.FETCH_LAST_TRAINING_PLAN_TRAINING_VIEW_SUCCESS]: saveTrainingViewsViews,
  [Types.FETCH_LAST_TRAINING_PLAN_TRAINING_VIEW_FAILURE]: failure,
  [Types.SET_TRAINING_EXERCISE_VIEWED_SUCCESS]: successSetExerciseViewed,
  [Types.SET_TRAINING_EXERCISE_VIEWED_FAILURE]: failure,
  [Types.SET_TRAINING_PLAN_TRAINING_VIEWED_SUCCESS]: successSetTrainingPlanTrainingViewed,
  [Types.SET_TRAINING_PLAN_TRAINING_VIEWED_FAILURE]: failure
});
