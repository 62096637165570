import DietDaysRecipe, { IDietDaysSnackRecipes, IDietDaysSnackRecipesDTO } from './DietDaysRecipe';

export interface IDietRecipesDTO {
  id: string;
  meals_count: string;
  fat_target: string;
  proteins_target: string;
  carbs_target: string;
  calories_target: string;
  diet_days: IDietDaysSnackRecipesDTO[];
}

export interface IDietRecipes {
  id: string;
  meals_count: string;
  fat_target: string;
  proteins_target: string;
  carbs_target: string;
  calories_target: string;
  diet_days: IDietDaysSnackRecipes[];
}

class DietRecipe implements IDietRecipes {
  constructor(
    public id: string,
    public meals_count: string,
    public fat_target: string,
    public proteins_target: string,
    public carbs_target: string,
    public calories_target: string,
    public diet_days: IDietDaysSnackRecipes[]
  ) {}

  static build = (data: IDietRecipesDTO): IDietRecipes => {
    return new DietRecipe(
      data.id,
      data.meals_count,
      data.fat_target,
      data.proteins_target,
      data.carbs_target,
      data.calories_target,
      data.diet_days.map((dietDay) => DietDaysRecipe.build(dietDay))
    );
  };
}

export default DietRecipe;
