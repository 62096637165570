import React from 'react';

const WorkoutTypeIcon = ({ size = 38 }: any) => {
  return (
    <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1316 20.776C31.1316 27.4885 25.6911 32.929 18.9786 32.929C12.2676 32.929 6.82715 27.4885 6.82715 20.776C6.82715 14.065 12.2676 8.62305 18.9786 8.62305C25.6911 8.62305 31.1316 14.065 31.1316 20.776Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.32 16.1279L18.9785 20.7764"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48535 17.073C4.31935 12.996 6.78385 9.51296 10.1694 7.33496"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9785 8.62374V7.33524"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5508 4.87207H21.4093"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorkoutTypeIcon;
