/* ------------- Types and Action Creators ------------- */

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  fetchNotifyPacketRequest: ['packetSlug'],
  fetchNotifyPacketSuccess: ['packetId'],
  fetchNotifyPacketFailure: ['error']
});

export const PacketTypes = Types;
export default Creators;

/* ------------- Selectors ------------- */

export const packetSelectors = {
  getPacket: (state: any) => {
    return state?.paymentPacket?.packetId?.data;
  },
  isFetching: (state: any) => {
    return state?.paymentPacket?.fetching;
  }
};

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Reducers ------------- */

export const requestGetNotifyPacket = (state: any) => ({
  ...INITIAL_STATE,
  fetching: true
});

export const successGetNotifyPacket = (state: any, action: any) => {
  const { packetId } = action;
  return { ...state, fetching: false, error: {}, packetId };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_NOTIFY_PACKET_REQUEST]: requestGetNotifyPacket,
  [Types.FETCH_NOTIFY_PACKET_SUCCESS]: successGetNotifyPacket,
  [Types.FETCH_NOTIFY_PACKET_FAILURE]: failure
});
