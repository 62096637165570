import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { LANGUAGES } from '@consts/consts';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { appUISelectors } from '@redux/Fitney2.0/AppStatus/appUI';

const mapStateToProps = (state: any) => ({
  lang: appStatusSelectors.language(state),
  isMobileMenuOpen: appUISelectors.isMobileNavOpen(state)
});

const withChatbot = (Component: any) =>
  connect(
    mapStateToProps,
    null
  )(({ lang, chat, isMobileMenuOpen, ...props }: any) => {
    const showChatBot = chat === true;

    const insertChatBotScript = useCallback(() => {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//code.tidio.co/ye3cpkyvet4iwvvlmzrlo3fjx0ncx1ol.js';
      script.async = true;

      head.appendChild(script);
    }, []);

    useEffect(() => {
      const chatBot = document.getElementById('tidio-chat');
      if (chatBot) {
        if (lang === LANGUAGES.PL && showChatBot && !isMobileMenuOpen) {
          chatBot.style.display = 'block';
        } else {
          chatBot.style.display = 'none';
        }
      } else if (lang === LANGUAGES.PL && showChatBot) {
        insertChatBotScript();
      }
    }, [lang, showChatBot, insertChatBotScript, isMobileMenuOpen]);

    return <Component {...props} />;
  });

export default withChatbot;
