import apisauce from 'apisauce';
import {getCookie, setCookieNewOptions} from '@services/cookiesService';
import {unidentifiedCookieName, utmCookieName} from '@HOCs/utmParams/utmParams';
import { addToDate } from '@helpers/general/general';
import {
  fulfilledRequestInterceptor,
  fulfilledResponseInterceptor,
  rejectedResponseInterceptor
} from '@services/apiInterceptorService';
import _ from 'lodash';
import {IApiActionDataProps, IApiActionProps, IApiBuilderProps, IApiProps} from './types';

const defaultConfig = {
  responseType: 'json',
  cookie: {
    amount: 30,
    unit: 'minutes'
  }
};

const getValue = (variable: string, config: any) => {
  const neededVariable = _.get(config, variable);
  if (neededVariable) return neededVariable;
  return _.get(defaultConfig, variable);
};

const buildClient = (props: IApiProps) => {
  const client = apisauce.create({
    baseURL: props.config.baseURL,
    responseType: props.config.responseType,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  client.addMonitor(props.monitors);
  client.axiosInstance.interceptors.request.use((request) => {
    // request.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCookieNewOptions(
      utmCookieName,
      { expires: addToDate(new Date(), getValue('cookie.amount', props.config), getValue('cookie.unit', props.config)).toDate() },
      () => {
        const utmCookie = getCookie(utmCookieName);
        if(utmCookie !== undefined) request.headers[utmCookieName] = getCookie(utmCookieName);
      }
    );

    setCookieNewOptions(
        unidentifiedCookieName,
        { expires: addToDate(new Date(), getValue('cookie.amount', props.config), getValue('cookie.unit', props.config)).toDate() },
        () => {
          const unidentifiedCookie = getCookie(unidentifiedCookieName);
          if(unidentifiedCookie !== undefined) request.headers[unidentifiedCookieName] = getCookie(unidentifiedCookieName);
        }
    );


    return fulfilledRequestInterceptor(request);
  });

  client.axiosInstance.interceptors.response.use(fulfilledResponseInterceptor, rejectedResponseInterceptor);
  return client;
};

export const buildRawUrl = (props: IApiBuilderProps) => {
  return typeof props.version === 'boolean' && !props.version ? `${props.config.apiUrlSuffix}${props.url}` :
      `${typeof props.version === 'undefined' ? props.config.defaultApiVersion : props.version}${props.config.apiUrlSuffix}${props.url}`;
};

export const get = <T,>({client, params, url, axiosConfig}: IApiActionProps) => {
  return client.get<T>(url, params === undefined ? {} : params, axiosConfig);
};

export const post = <T,>({client, url, data, axiosConfig}: IApiActionDataProps) => {
  return client.post<T>(url, data, axiosConfig);
};

export const put = <T,>({client, url, data, axiosConfig}: IApiActionDataProps) => {
  return client.put<T>(url, data, axiosConfig);
};

export const del = <T,>({client, url, axiosConfig}: IApiActionProps) => {
  return client.delete<T>(url, axiosConfig);
};

export default buildClient;