import React, { Component } from 'react';
import routes from '@routes';
// @ts-ignore
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { authSelectors } from '@redux/Fitney2.0/Auth/auth';
import RouteActions from '@redux/route/routeActions';
import {goToApp, goToBlog} from '@helpers/general/landingHelpers';
import translatedTexts from '@containers/landing/Landing.lang';
import ButtonArrow from '@components/UIKit/ButtonArrow/ButtonArrow';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import styles from './FitneyLoginRegisterTopBar.module.css';

class FitneyLoginRegisterTopBar extends Component<any, any> {
  appUrl: any;
  constructor(props: any) {
    super(props);

    this.appUrl = process.env.REACT_APP_URL;
  }

  goToRegister = (e: any) => {
    e.preventDefault();
    goToApp(routes.register.path);
  };

  goToLogIn = (e: any) => {
    e.preventDefault();
    goToApp(routes.login.path);
  };

  handleLogoClicked = (e: any) => {
    e.preventDefault();
    this.props.logoClicked();
  };

  goToMagazine = () => {
    goToBlog(this.props.lang);
  };

  render() {
    return (
      <nav className={styles.fitneyTopBar}>
        <div className={`${styles.header} d-flex justify-content-between flex-column flex-sm-row`}>
          {this.props.isMagazine ? (
            <div className="d-flex justify-content-center align-items-center">
              <a onClick={this.handleLogoClicked} className="d-flex" href={routes.blog.path}>
                <img className={styles.logo} src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} alt="Fitney" />
              </a>

              <LanguageDropdown navigation />

              <p className={styles.linkBlog} onClick={this.goToMagazine}>
                Fitney Blog
              </p>
            </div>
          ) : (
            <a onClick={this.handleLogoClicked} className="d-flex" href="/">
              <img className={styles.logo} src={`${this.appUrl}/images/logo/logo.png`} alt="Fitney" />
            </a>
          )}
          <div className="d-flex justify-content-center align-items-center mt-3 mt-sm-0">
            {this.props.isLoggedIn ? (
              <ButtonArrow
                isButtonSmaller
                isGreyBordered
                onClick={this.props.userLoggedInAction}
                loading={this.props.userDataFetching}
              >
                {translatedTexts(this.props.intl).goToApp}
              </ButtonArrow>
            ) : (
              <>
                <button className={styles.loginBtn} onClick={this.goToLogIn}>
                  <FormattedMessage
                    id="login-register-topbar.login-button"
                    defaultMessage="Log in"
                    description="log in text in login/register top bar"
                  />
                </button>

                <ButtonArrow
                  isButtonSmaller
                  isGreyBordered
                  onClick={this.goToRegister}
                  className={`${styles.signUpBtn} ripple d-flex justify-content-center align-items-center`}
                >
                  <FormattedMessage
                    id="login-layout.sign-up-button"
                    defaultMessage="Sign Up"
                    description="Sign up button text"
                  />
                </ButtonArrow>
              </>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state: any) => ({
  lang: appStatusSelectors.language(state),
  isMobile: appStatusSelectors.isMobile(state),
  pathName: state.router.location.pathname,
  isLoggedIn: authSelectors.isLoggedIn(state),
  userDataFetching: state.userData.fetching
});

const mapDispatchToProps = (dispatch: any) => ({
  logoClicked: () => dispatch(RouteActions.logoClicked()),
  userLoggedInAction: () => dispatch(RouteActions.userLoggedInAction())
});

export default compose<any>(connect(mapStateToProps, mapDispatchToProps), injectIntl)(FitneyLoginRegisterTopBar);
