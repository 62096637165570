import React from 'react';
import { connect } from 'react-redux';

import FooterSignUpSignIn from '@components/footers/FooterSignUpSignIn/FooterSignUpSignIn';
import styles from './QuestionnaireLayout.module.scss';

const QuestionnaireLayout = ({ children, isMobile }: any) => {
  return (
    <div className={styles.questionnaireLayout}>
      {children}
      {!isMobile && (
        <div className={styles.footer}>
          <FooterSignUpSignIn />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isMobile: state.appStatus.isMobile
});

export default connect(mapStateToProps)(QuestionnaireLayout);
