import Cookies, { CookieAttributes } from 'js-cookie';

export const getAllCookies = (): { [key: string]: string } => Cookies.get();

export const getCookie = (name: string): string | undefined => Cookies.get(name);

export const setCookie = (name: string, value: string, options?: CookieAttributes): string | undefined =>
  Cookies.set(name, value, options);

export const removeCookie = (name: string, options?: CookieAttributes): void => Cookies.remove(name, options);

export const setCookieNewOptions = (name: string, options?: CookieAttributes, onChangeAction?: () => void) => {
  const utmData = getCookie(name);

  if (utmData) {
    setCookie(name, utmData, options);

    if (typeof onChangeAction === 'function') {
      onChangeAction();
    }
  }
};
