import React from 'react';

const WeightLossIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.93066 25.2122H10.3787"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8877 20.7219L10.3777 25.2119L5.8877 29.6999"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9441 25.2122H41.4961"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.9871 20.7219L41.4971 25.2119L45.9871 29.6999"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8548 43.6362C11.0608 31.8542 20.1888 21.1882 15.8488 8.23816"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1401 43.6362C40.9341 31.8542 31.8061 21.1882 36.1461 8.23816"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WeightLossIcon;
