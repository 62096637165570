import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { RootReducersKeys, RootReducerStateProps } from '@redux/types';
import { NotificationsReducerProps } from './types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  enqueueSnackbar: ['']
});

export const NotificationsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  snackBarData: null
});

/* ------------- Selectors ------------- */
export const notificationsSelectors = {
  notifications: (state: RootReducerStateProps) => state[RootReducersKeys.notifications]
};

export const enqueueSnackbar = (state: NotificationsReducerProps, action: any) => {
  return { ...state, snackBarData: action.payload };
};

export const refreshTokenFailure = () => INITIAL_STATE;

export const clearErrors = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ENQUEUE_SNACKBAR]: enqueueSnackbar
});
