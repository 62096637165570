import React, { Suspense } from 'react';
import { useLazy } from '@hooks';

import { componentLoader } from '@helpers/general/componentLoader';

const DynamicPageLoader = ({ pathToPage, ...props }: any) => {
  const LazyComponent = useLazy(pathToPage, () =>
    componentLoader<any>(() => import(`../../pages/${pathToPage}/index`))
  );

  return (
    <Suspense fallback={<div />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

// DynamicPageLoader.propTypes = {
//   pathToPage: PropTypes.string.isRequired,
//   preloadNS: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.array
//   ])
// }

export default DynamicPageLoader;
