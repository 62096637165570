import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { LatestTrainingsDataTypes } from '@redux/Fitney2.0/Trainings/latestTrainingData';
import { TrainingItemsTypes } from '@redux/Fitney2.0/Trainings/trainingItems';
import { LogInTypes } from '@redux/Fitney2.0/Auth/auth';

// !! FITNEY 2.0 IMPORTS START

// SignIn
import {
  logInRequest,
  logInFacebook,
  resetPassword,
  logOutUser,
  refreshToken,
  refreshTokenFailure,
  forgotPassword
} from './Fitney2.0/Auth/SignInSagas';
import { ResetPasswordTypes } from '@redux/Fitney2.0/Auth/resetPassword';
// SignUp
import { signUpByEmail, signUpByFacebook } from './Fitney2.0/Auth/SignUpSagas';
// UserData
import { getLoggedInUserData } from './Fitney2.0/UserData/UserDataSagas';
// Straal Payments
import {
  createCardWithSubscription,
  fetchCoupon,
  getPaymentPlans,
  processCoupon,
  getCurrentPaymentPlan,
  getKryptonimPaymentPlans,
  createWalletAddress,
  getPaymentUrl,
  getKryptonimPaymentStatus
} from './Fitney2.0/Payment/Strall/StraalPaymentsSagas';

// Mobile Payments
import { getPacket, processNotify } from './Fitney2.0/Payment/Mobile/MobilePaymentsSagas';

// User Settings
import {
  uploadUserAvatar,
  updateUserFullName,
  updateUserBirthDate,
  updateWeight,
  updateGender,
  updateDietType,
  updateBodyParts,
  updateHeight,
  updateDifficultyLevel,
  updatePassword,
  deleteSubscription,
  deleteAccount,
  updateWorkoutType,
  updateWeeklyIntensity,
  updateTrainingGoal,
  updateAllergies,
  updateMealsPerDay,
  regeneratePlan,
  updateLanguage
} from './Fitney2.0/Settings/SettingsSagas';
// Questionnaire
import {
  proceedQuestionnaireDiet,
  proceedQuestionnaireTraining
} from './Fitney2.0/Training/TrainingQuestionnaireSagas';
// Diet
import { getDiet, getDietTypes, getMealTypes, getAllergies } from './Fitney2.0/Diet/DietSagas';
import {
  getRecipeCategories,
  getRecipesByCategory,
  getRecipeById,
  getRecipes,
  getRecipe
} from './Fitney2.0/Diet/RecipeSagas';
// Training
import {
  getTrainingDataSaga,
  getTrainingItemsSaga,
  getTrainingExerciseDataSaga,
  getWorkoutTypeDataSaga,
  getBodyPartDataSaga,
  getDifficultyDataSaga,
  getAuthorDataSaga,
  getTrainingEquipmentDataSaga,
  getTrainingExerciseBasicList,
  getLatestTrainings,
  getNextTrainingPlanItems,
  getPrevTrainingPlanItems,
  getTrainingPlanItems,
  getRegeneratedTrainingPlan,
  getItemsViews,
  markVideoWatchedSaga,
  getSuggestedTrainings,
  getLiveTrainings,
  markTrainingAsStarted,
  markTrainingAsCompleted,
  getTrainingDurationRangesDataSaga
} from './Fitney2.0/Training/TrainingSagas';

// Stats
import {
  getStats,
  getTrainersStats,
  getChartStats,
  getOverallUserStats,
  getTrainingPlanCompletion,
  logEventToBackend
} from './Fitney2.0/Stats/StatsSagas';

// Types
import { UploadImageTypes } from '@redux/Fitney2.0/Settings/uploadImage';
import { UpdateFullNameTypes } from '@redux/Fitney2.0/Settings/updateFullName';
import { PaymentPlanTypes } from '@redux/Fitney2.0/Payment/Strall/paymentPlan';
import { CouponTypes } from '@redux/Fitney2.0/Payment/Strall/coupon';
import { CreditCardTypes } from '@redux/Fitney2.0/Payment/Strall/creditCard';
import { UserDataTypes } from '@redux/Fitney2.0/UserData/userData';
import { UpdateBirthDateTypes } from '@redux/Fitney2.0/Settings/updateBirthDate';
import { UpdateWeightTypes } from '@redux/Fitney2.0/Settings/updateWeight';
import { UpdateGenderTypes } from '@redux/Fitney2.0/Settings/updateGender';
import { UpdateAllergiesTypes } from '@redux/Fitney2.0/Settings/updateAllergies';
import { UpdateMealsPerDayTypes } from '@redux/Fitney2.0/Settings/updateMealsPerDay';
import { UpdateBodyPartsTypes } from '@redux/Fitney2.0/Settings/updateBodyParts';
import { UpdateDietTypeTypes } from '@redux/Fitney2.0/Settings/updateDietType';

import { UpdateWeeklyIntensityTypes } from '@redux/Fitney2.0/Settings/updateWeeklyIntensity';
import { UpdateDifficultyLevelTypes } from '@redux/Fitney2.0/Settings/updateDifficultyLevel';
import { UpdateTrainingGoalTypes } from '@redux/Fitney2.0/Settings/updateTrainingGoal';
import { UpdateHeightTypes } from '@redux/Fitney2.0/Settings/updateHeight';
import { UpdatePasswordTypes } from '@redux/Fitney2.0/Settings/updatePassword';
import { RegisterQuestionnaireTypes } from '@redux/Fitney2.0/Trainings/trainingQuestionnaire';
import { DietTypes } from '@redux/Fitney2.0/Diet/diet';
import { RecipesTypes } from '@redux/Fitney2.0/Diet/recipes';
import { TrainingDataTypes } from '@redux/Fitney2.0/Trainings/trainingData';
import { LiveTrainingsTypes } from '@redux/Fitney2.0/Trainings/liveTrainings';
import { TrainingExerciseDataTypes } from '@redux/Fitney2.0/Trainings/trainingExerciseData';
import { WorkoutTypeDataTypes } from '@redux/Fitney2.0/Trainings/workoutTypeData';
import { BodyPartDataTypes } from '@redux/Fitney2.0/Trainings/bodyPartData';
import { TrainingDurationRangesDataTypes } from '@redux/Fitney2.0/Trainings/trainingDurationRangesData';
import { DifficultyDataTypes } from '@redux/Fitney2.0/Trainings/difficultyData';
import { AuthorDataTypes } from '@redux/Fitney2.0/Trainings/authorData';
import { TrainingEquipmentDataTypes } from '@redux/Fitney2.0/Trainings/trainingEquipmentData';
import { TrainingBasicItemsTypes } from '@redux/Fitney2.0/Trainings/trainingBasicItems';

// !! FITNEY 2.0 IMPORTS STOP

import { TrainingPlanTypes } from '@redux/Fitney2.0/Trainings/trainingPlan';
import { StatsTypes } from '@redux/Fitney2.0/Stats/stats';
import { TrainersStatsTypes } from '@redux/Fitney2.0/Stats/trainersStats';
import { ChartStatsTypes } from '@redux/Fitney2.0/Stats/chartStats';

import { DeleteSubscriptionTypes } from '@redux/Fitney2.0/Settings/deleteSubscription';
import { DeleteAccountTypes } from '@redux/Fitney2.0/Settings/deleteAccount';
import { RegeneratePlanTypes } from '@redux/Fitney2.0/Settings/regeneratePlan';

import { UpdateWorkoutTypeTypes } from '@redux/Fitney2.0/Settings/updateWorkoutType';

import { RouteRedirectsTypes } from '@redux/route/routeRedirects';
import {
  goToChooseSubscriptionPlan,
  goToDailyWorkout,
  goToHome,
  goToLogin,
  goToRegister,
  goToSupport,
  goToTrainingPlan,
  goToTrainingPlans,
  goToArticle,
  goToBlog,
  goToDietPlan,
  goToRecipe,
  goToResetPasswordSuccess,
  goToQuestionnaireTraining,
  goToThankYou
} from './Route/sagaRouteRedirects';
import { ArticlesTypes } from '@redux/ToCheck/blog/articles';
import { TopArticlesTypes } from '@redux/ToCheck/blog/topArticles';
import { RouteActionsTypes } from '@redux/route/routeActions';
import {
  getArticle,
  getArticles,
  getCategories,
  getTopArticles,
  incrementArticleViews
} from '@sagas/Fitney2.0/UserData/ArticlesSagas';
import {
  articleClicked,
  articleVisited,
  changeDailyWorkoutAction,
  changedTrainingAction,
  chooseSubscriptionPlanAction,
  dailyWorkoutChosenAction,
  dailyWorkoutLinkClicked,
  dietPlanLinkClicked,
  emailNotVerifiedError,
  hasNoPermission,
  loginButtonClicked,
  logoClicked,
  logOutAction,
  logOutActionNoRedirect,
  navbarTrainingClicked,
  noActiveDailyWorkoutError,
  noActiveSubscriptionError,
  openRecipeAction,
  openTrainingPlanAction,
  questionnaireNotCompletedError,
  questionnaireProceededAction,
  shopPromoPurchaseSucceeded,
  shopPurchaseSucceeded,
  signUpButtonClicked,
  skipQuestionnaireAction,
  subscriptionCreatedAction,
  supportLinkClicked,
  trainingPlansLinkClicked,
  userAlreadySubscribed,
  userIsNotLoggedIn,
  userLoggedInAction,
  userLoggedOutAction,
  userPasswordResetedAction,
  userRegisteredAction
} from '@sagas/Route/sagaRoutes';

import { DietQuestionnaireTypes } from '@redux/Fitney2.0/Diet/dietQuestionnaire';

import { subscribeForNewsletter } from './Fitney2.0/UserData/NewsletterSagas';
import { NewsletterTypes } from '@redux/ToCheck/newsletter/newsletter';
import { SuggestedTrainingsDataTypes } from '@redux/Fitney2.0/Trainings/suggestedTrainings';
import { UpdateLanguageTypes } from '@redux/Fitney2.0/Settings/updateLanguage';
import { TrainingsUserTypes } from '@redux/Fitney2.0/TrainingsUser/trainingsUser';
import { getTrainingUser } from '@sagas/Fitney2.0/TrainingsUser/TrainingsUserSagas';
import { CategoriesTypes } from '@redux/ToCheck/blog/categories';
import { SupportTypes } from '@redux/ToCheck/support/support';
import { getSupportQuestions, submitContactForm } from '@sagas/Fitney2.0/UserData/SupportSagas';
import { NotifyTypes } from '@redux/Fitney2.0/Payment/Mobile/payment';
import { PacketTypes } from '@redux/Fitney2.0/Payment/Mobile/packet';
import api from '@api/api';
import { KryptonimPaymentPlanTypes } from '@redux/Fitney2.0/Payment/Kryptonim/paymentPlan';
import { KryptonimCreateWalletTypes } from '@redux/Fitney2.0/Payment/Kryptonim/walletAddress';

export default function* root(): any {
  // yield fork(router, history, routes);
  yield all([
    // some sagas only receive an action
    // (StartupTypes.STARTUP, startup),

    // !! FITNEY 2.0 START

    // Sign In
    takeLatest(LogInTypes.LOG_IN_REQUEST, logInRequest, api.signIn),
    takeLatest(LogInTypes.LOG_IN_FACEBOOK_REQUEST, logInFacebook, api.signIn),
    takeLatest(LogInTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api.signIn),
    takeLatest(ResetPasswordTypes.RESET_PASSWORD_REQUEST, resetPassword, api.signIn),
    // @ts-ignore
    takeLatest(LogInTypes.LOG_OUT_REQUEST, logOutUser, api.signIn),
    // @ts-ignore
    takeLatest(LogInTypes.REFRESH_TOKEN, refreshToken, api.signIn),
    takeLatest(LogInTypes.REFRESH_TOKEN_FAILURE, refreshTokenFailure),
    // Sign Up
    takeLatest(LogInTypes.REGISTER_REQUEST, signUpByEmail, api.signUp),
    takeLatest(LogInTypes.REGISTER_FACEBOOK_REQUEST, signUpByFacebook, api.signUp),
    // User Data
    // @ts-ignore
    takeEvery(UserDataTypes.GET_LOGGED_IN_USER_DATA_REQUEST, getLoggedInUserData, api.user),
    takeLatest(NewsletterTypes.SAVE_EMAIL_FOR_NEWSLETTER_REQUEST, subscribeForNewsletter, api.user),
    takeEvery(SupportTypes.GET_SUPPORT_QUESTIONS_REQUEST, getSupportQuestions, api.user),
    takeLatest(SupportTypes.SUBMIT_CONTACT_FORM_REQUEST, submitContactForm, api.user),
    takeLatest(RouteActionsTypes.SUPPORT_LINK_CLICKED, supportLinkClicked, api.user),

    // StraalPayments
    takeLatest(CreditCardTypes.CREATE_CREDIT_CARD_REQUEST, createCardWithSubscription, api.payment),
    takeLatest(PaymentPlanTypes.GET_PAYMENT_PLANS_REQUEST, getPaymentPlans, api.payment),
    takeLatest(PaymentPlanTypes.GET_CURRENT_PAYMENT_PLAN_REQUEST, getCurrentPaymentPlan, api.payment),
    takeLatest(PaymentPlanTypes.FETCH_COUPON_REQUEST, fetchCoupon, api.payment),
    takeLatest(CouponTypes.PROCESS_COUPON_REQUEST, processCoupon, api.payment),

    // KryptonimPayment
    takeLatest(KryptonimCreateWalletTypes.CREATE_WALLET_ADDRESS_REQUEST, createWalletAddress, api.payment),
    takeLatest(KryptonimPaymentPlanTypes.GET_KRYPTONIM_PAYMENT_PLANS_REQUEST, getKryptonimPaymentPlans, api.payment),
    takeLatest(KryptonimPaymentPlanTypes.GET_KRYPTONIM_PAYMENT_URL_REQUEST, getPaymentUrl, api.payment),
    takeLatest(KryptonimPaymentPlanTypes.GET_KRYPTONIM_PAYMENT_STATUS_REQUEST, getKryptonimPaymentStatus, api.payment),

    // MobilePayments
    takeLatest(NotifyTypes.PROCESS_NOTIFY_REQUEST, processNotify, api.payment),
    takeLatest(PacketTypes.FETCH_NOTIFY_PACKET_REQUEST, getPacket, api.payment),

    // User Settings
    takeLatest(UploadImageTypes.UPLOAD_IMAGE_REQUEST, uploadUserAvatar, api.settings),
    takeLatest(UpdateFullNameTypes.UPDATE_NAME_REQUEST, updateUserFullName, api.settings),
    takeLatest(UpdateBirthDateTypes.UPDATE_BIRTH_DATE_REQUEST, updateUserBirthDate, api.settings),
    takeLatest(UpdateWeightTypes.UPDATE_WEIGHT_REQUEST, updateWeight, api.settings),
    takeLatest(UpdateGenderTypes.UPDATE_GENDER_REQUEST, updateGender, api.settings),
    takeLatest(UpdateBodyPartsTypes.UPDATE_BODY_PARTS_REQUEST, updateBodyParts, api.settings),
    takeLatest(UpdateDietTypeTypes.UPDATE_DIET_TYPE_REQUEST, updateDietType, api.settings),
    takeLatest(UpdateAllergiesTypes.UPDATE_ALLERGIES_REQUEST, updateAllergies, api.settings),
    takeLatest(UpdateMealsPerDayTypes.UPDATE_MEALS_PER_DAY_REQUEST, updateMealsPerDay, api.settings),
    takeLatest(UpdateHeightTypes.UPDATE_HEIGHT_REQUEST, updateHeight, api.settings),
    takeLatest(UpdateDifficultyLevelTypes.UPDATE_DIFFICULTY_LEVEL_REQUEST, updateDifficultyLevel, api.settings),
    takeLatest(UpdateWorkoutTypeTypes.UPDATE_WORKOUT_TYPE_REQUEST, updateWorkoutType, api.settings),
    takeLatest(UpdateWeeklyIntensityTypes.UPDATE_WEEKLY_INTENSITY_REQUEST, updateWeeklyIntensity, api.settings),
    takeLatest(UpdateTrainingGoalTypes.UPDATE_TRAINING_GOAL_REQUEST, updateTrainingGoal, api.settings),
    takeLatest(UpdatePasswordTypes.UPDATE_PASSWORD_REQUEST, updatePassword, api.settings),
    takeLatest(DeleteSubscriptionTypes.DELETE_SUBSCRIPTION_REQUEST, deleteSubscription, api.settings),
    takeLatest(DeleteAccountTypes.DELETE_ACCOUNT_REQUEST, deleteAccount, api.settings),

    // @ts-ignore
    takeEvery(RegeneratePlanTypes.REGENERATE_PLAN_REQUEST, regeneratePlan, api.settings),
    takeLatest(UpdateLanguageTypes.UPDATE_LANGUAGE_REQUEST, updateLanguage, api.settings),
    // Questionnaire
    takeLatest(
      RegisterQuestionnaireTypes.PROCEED_QUESTIONNAIRE_REQUEST,
      proceedQuestionnaireTraining,
      api.questionnaire
    ),
    takeLatest(DietQuestionnaireTypes.PROCEED_DIET_QUESTIONNAIRE_REQUEST, proceedQuestionnaireDiet, api.questionnaire),
    // Diet
    takeLatest(DietTypes.FETCH_DIET_REQUEST, getDiet, api.diet),
    takeLatest(DietTypes.FETCH_DIET_TYPES_REQUEST, getDietTypes, api.diet),
    takeLatest(DietTypes.FETCH_MEAL_TYPES_REQUEST, getMealTypes, api.diet),
    takeLatest(DietTypes.FETCH_ALLERGIES_REQUEST, getAllergies, api.diet),
    // Training
    takeLatest(TrainingDataTypes.FETCH_TRAINING_DATA_REQUEST, getTrainingDataSaga, api.trainings),
    takeLatest(LiveTrainingsTypes.FETCH_LIVE_TRAININGS_REQUEST, getLiveTrainings, api.trainings),
    takeLatest(TrainingItemsTypes.FETCH_TRAINING_ITEMS_REQUEST, getTrainingItemsSaga, api.trainings),
    takeLatest(TrainingItemsTypes.FETCH_TRAINING_ITEM_VIEWS_REQUEST, getItemsViews, api.trainings),
    takeLatest(TrainingItemsTypes.MARK_VIDEO_WATCHED, markVideoWatchedSaga, api.trainings),
    takeLatest(
      TrainingExerciseDataTypes.FETCH_TRAINING_EXERCISE_DATA_REQUEST,
      getTrainingExerciseDataSaga,
      api.trainings
    ),
    takeLatest(TrainingExerciseDataTypes.MARK_TRAINING_AS_STARTED, markTrainingAsStarted, api.trainings),
    takeLatest(TrainingExerciseDataTypes.MARK_TRAINING_AS_COMPLETED, markTrainingAsCompleted, api.trainings),

    // @ts-ignore
    takeEvery(WorkoutTypeDataTypes.FETCH_WORKOUT_TYPE_DATA_REQUEST, getWorkoutTypeDataSaga, api.trainings),
    // @ts-ignore
    takeEvery(BodyPartDataTypes.FETCH_BODY_PART_DATA_REQUEST, getBodyPartDataSaga, api.trainings),
    // @ts-ignore
    takeEvery(
      TrainingDurationRangesDataTypes.FETCH_TRAINING_DURATION_RANGES_DATA_REQUEST,
      getTrainingDurationRangesDataSaga,
      api.trainings
    ),
    // @ts-ignore
    takeEvery(DifficultyDataTypes.FETCH_DIFFICULTY_DATA_REQUEST, getDifficultyDataSaga, api.trainings),
    // @ts-ignore
    takeEvery(AuthorDataTypes.FETCH_AUTHOR_DATA_REQUEST, getAuthorDataSaga, api.trainings),
    takeLatest(
      TrainingEquipmentDataTypes.FETCH_TRAINING_EQUIPMENT_DATA_REQUEST,
      getTrainingEquipmentDataSaga,
      // @ts-ignore
      api.trainings
    ),
    takeLatest(TrainingBasicItemsTypes.FETCH_TRAINING_BASIC_ITEMS, getTrainingExerciseBasicList, api.trainings),
    takeLatest(TrainingPlanTypes.FETCH_TRAINING_PLAN_REQUEST, getTrainingPlanItems, api.trainings),
    takeLatest(TrainingPlanTypes.FETCH_NEXT_TRAINING_PLAN_ITEMS_REQUEST, getNextTrainingPlanItems, api.trainings),
    takeLatest(TrainingPlanTypes.FETCH_PREV_TRAINING_PLAN_ITEMS_REQUEST, getPrevTrainingPlanItems, api.trainings),
    // @ts-ignore
    takeEvery(TrainingPlanTypes.FETCH_REGENERATED_TRAINING_PLAN_REQUEST, getRegeneratedTrainingPlan, api.trainings),
    // @ts-ignore
    takeEvery(LatestTrainingsDataTypes.FETCH_LATEST_TRAININGS_DATA_REQUEST, getLatestTrainings, api.trainings),
    takeLatest(
      SuggestedTrainingsDataTypes.FETCH_SUGGESTED_TRAININGS_DATA_REQUEST,
      getSuggestedTrainings,
      api.trainings
    ),
    // Trainings User
    takeEvery(TrainingsUserTypes.GET_TRAININGS_USER_REQUEST, getTrainingUser, api.trainingsUser),

    //Stats
    // @ts-ignore
    takeEvery(StatsTypes.FETCH_STATS_REQUEST, getStats, api.stats),
    takeEvery(StatsTypes.FETCH_OVERALL_USER_STATS_REQUEST, getOverallUserStats, api.stats),
    takeEvery(StatsTypes.FETCH_TRAINING_PLAN_COMPLETION_REQUEST, getTrainingPlanCompletion, api.stats),
    // @ts-ignore
    takeEvery(TrainersStatsTypes.FETCH_TRAINERS_STATS_REQUEST, getTrainersStats, api.stats),
    takeEvery(StatsTypes.LOG_EVENT_TO_BACKEND, logEventToBackend, api.stats),
    takeLatest(ChartStatsTypes.FETCH_CHART_STATS_REQUEST, getChartStats, api.stats),

    //Recipe
    takeLatest(RecipesTypes.FETCH_RECIPE_CATEGORIES_REQUEST, getRecipeCategories, api.recipe),
    takeEvery(RecipesTypes.FETCH_RECIPES_BY_CATEGORY_REQUEST, getRecipesByCategory, api.recipe),
    takeEvery(RecipesTypes.FETCH_RECIPES_REQUEST, getRecipes, api.recipe),
    takeLatest(RecipesTypes.FETCH_RECIPE_TO_DISPLAY_REQUEST, getRecipeById, api.recipe),
    takeLatest(RecipesTypes.FETCH_RECIPE_REQUEST, getRecipe, api.recipe),

    //Articles
    takeLatest(ArticlesTypes.FETCH_ARTICLES_REQUEST, getArticles, api.articles),
    takeLatest(ArticlesTypes.FETCH_ARTICLE_REQUEST, getArticle, api.articles),
    takeLatest(ArticlesTypes.INCREMENT_ARTICLE_VIEWS_REQUEST, incrementArticleViews, api.articles),
    takeLatest(TopArticlesTypes.FETCH_TOP_ARTICLES_REQUEST, getTopArticles, api.articles),
    takeEvery(CategoriesTypes.FETCH_CATEGORIES_REQUEST, getCategories, api.articles),

    //@ACTIONS
    takeEvery(RouteActionsTypes.USER_LOGGED_IN_ACTION, userLoggedInAction),
    takeEvery(RouteActionsTypes.USER_LOGGED_OUT_ACTION, userLoggedOutAction),
    takeLatest(RouteActionsTypes.LOG_OUT_ACTION, logOutAction, {}),
    takeLatest(RouteActionsTypes.LOG_OUT_ACTION_NO_REDIRECT, logOutActionNoRedirect, {}),
    takeLatest(RouteActionsTypes.USER_REGISTERED_ACTION, userRegisteredAction, {}),
    takeEvery(RouteActionsTypes.QUESTIONNAIRE_PROCEEDED_ACTION, questionnaireProceededAction),
    takeEvery(RouteActionsTypes.USER_PASSWORD_RESETED_ACTION, userPasswordResetedAction),
    takeEvery(RouteActionsTypes.CHANGED_TRAINING_ACTION, changedTrainingAction),
    takeLatest(RouteActionsTypes.SUBSCRIPTION_CREATED_ACTION, subscriptionCreatedAction, {}),
    takeEvery(RouteActionsTypes.DAILY_WORKOUT_CHOSEN_ACTION, dailyWorkoutChosenAction),
    takeLatest(RouteActionsTypes.HAS_NO_PERMISSION, hasNoPermission, {}),
    takeLatest(RouteActionsTypes.USER_IS_NOT_LOGGED_IN, userIsNotLoggedIn, {}),
    takeLatest(RouteActionsTypes.EMAIL_NOT_VERIFIED_ERROR, emailNotVerifiedError, {}),
    takeLatest(RouteActionsTypes.NO_ACTIVE_SUBSCRIPTION_ERROR, noActiveSubscriptionError, {}),
    takeLatest(RouteActionsTypes.QUESTIONNAIRE_NOT_COMPLETED_ERROR, questionnaireNotCompletedError, {}),
    takeLatest(RouteActionsTypes.NO_ACTIVE_DAILY_WORKOUT_ERROR, noActiveDailyWorkoutError, {}),
    takeLatest(RouteActionsTypes.SUPPORT_LINK_CLICKED, supportLinkClicked, {}),
    takeLatest(RouteActionsTypes.USER_ALREADY_SUBSCRIBED, userAlreadySubscribed, {}),
    takeLatest(RouteActionsTypes.SIGN_UP_BUTTON_CLICKED, signUpButtonClicked, {}),
    takeLatest(RouteActionsTypes.LOGIN_BUTTON_CLICKED, loginButtonClicked, {}),
    takeLatest(RouteActionsTypes.LOGO_CLICKED, logoClicked, {}),
    takeLatest(RouteActionsTypes.NAVBAR_TRAINING_CLICKED, navbarTrainingClicked, {}),
    takeLatest(RouteActionsTypes.SKIP_QUESTIONNAIRE_ACTION, skipQuestionnaireAction, {}),
    takeLatest(RouteActionsTypes.TRAINING_PLANS_LINK_CLICKED, trainingPlansLinkClicked, {}),
    takeLatest(RouteActionsTypes.DAILY_WORKOUT_LINK_CLICKED, dailyWorkoutLinkClicked, {}),
    takeLatest(RouteActionsTypes.DIET_PLAN_LINK_CLICKED, dietPlanLinkClicked, {}),
    takeLatest(RouteActionsTypes.CHANGE_DAILY_WORKOUT_ACTION, changeDailyWorkoutAction, {}),
    takeLatest(RouteActionsTypes.OPEN_TRAINING_PLAN_ACTION, openTrainingPlanAction, {}),
    takeLatest(RouteActionsTypes.CHOOSE_SUBSCRIPTION_PLAN_ACTION, chooseSubscriptionPlanAction, {}),
    takeLatest(RouteActionsTypes.ARTICLE_CLICKED, articleClicked, {}),
    takeLatest(RouteActionsTypes.ARTICLE_VISITED, articleVisited, {}),
    takeLatest(RouteActionsTypes.SHOP_PURCHASE_SUCCEEDED, shopPurchaseSucceeded, {}),
    takeLatest(RouteActionsTypes.SHOP_PROMO_PURCHASE_SUCCEEDED, shopPromoPurchaseSucceeded, {}),
    takeLatest(RouteActionsTypes.OPEN_RECIPE_ACTION, openRecipeAction, {}),
    //!@ACTIONS

    //@REDIRECTS
    takeLatest(RouteRedirectsTypes.GO_TO_DAILY_WORKOUT, goToDailyWorkout),
    takeLatest(RouteRedirectsTypes.GO_TO_CHOOSE_SUBSCRIPTION_PLAN, goToChooseSubscriptionPlan),
    takeLatest(RouteRedirectsTypes.GO_TO_TRAINING_PLANS, goToTrainingPlans),
    takeLatest(RouteRedirectsTypes.GO_TO_TRAINING_PLAN, goToTrainingPlan),
    takeLatest(RouteRedirectsTypes.GO_TO_LOGIN, goToLogin),
    takeLatest(RouteRedirectsTypes.GO_TO_REGISTER, goToRegister),
    takeLatest(RouteRedirectsTypes.GO_TO_QUESTIONNAIRE_TRAINING, goToQuestionnaireTraining),
    takeLatest(RouteRedirectsTypes.GO_TO_HOME, goToHome),
    takeLatest(RouteRedirectsTypes.GO_TO_DIET_PLAN, goToDietPlan),
    takeLatest(RouteRedirectsTypes.GO_TO_SUPPORT, goToSupport),
    takeLatest(RouteRedirectsTypes.GO_TO_BLOG, goToBlog),
    takeLatest(RouteRedirectsTypes.GO_TO_ARTICLE, goToArticle),
    takeLatest(RouteRedirectsTypes.GO_TO_RECIPE, goToRecipe),
    takeLatest(RouteRedirectsTypes.GO_TO_RESET_PASSWORD_SUCCESS, goToResetPasswordSuccess),
    takeLatest(RouteRedirectsTypes.GO_TO_THANK_YOU, goToThankYou)
    //!@REDIRECTS

    //api V2
  ]);
}
