export enum TRAINING_TYPE {
  NORMAL = 'normal',
  LIVE = 'live'
}

export const TRAINING_ITEM_TYPES = {
  EXERCISE: 'Exercise',
  BREAK: 'Pause',
  SERIES: 'Series'
} as const;

export const ACCESS = {
  ALL: 'all',
  LOGGED_IN: 'loggedIn'
};

export const PORTALS = {
  LOGIN_HEADER: 'login_header_cta',
  LOGIN_HERO_CTA: 'login_hero_cta',
  APP_HEADER_EL: 'app_header_element'
};

export const DIFFICULTY_LEVEL_IDS = {
  EASY: '2f574e9b-46a5-4f46-8b76-6a3a1ebf052d',
  MID: '8b897b00-8d65-420d-ae21-b0fd011fb304',
  HARD: '446d04ff-da97-4e3d-bd62-2d4b9f408deb'
};

export const TRAINING_GOALS_IDS = {
  LOSE_WEIGHT: '2360c4ff-fce8-46ad-a8eb-70aa20ff9915',
  BUILD_MUSCLE: '03b040a9-32e2-4822-b9c5-6feca915e229',
  GAIN_WEIGHT: '359b3b6b-4c01-480b-8347-61ece76ddfc3',
  BE_HEALTHIER: 'b923b83a-27d7-4940-8cdd-fca20ff9c988'
};

// Use BCP 47 Language Tags https://www.techonthenet.com/js/language_tags.php
export const LANGUAGES = {
  ES: 'es',
  PL: 'pl',
  EN: 'en',
  AE: 'ae',
  SA: 'sa'
} as const;

export enum WEIGHT_TYPE {
  kg = 'kg',
  lbs = 'lbs'
}

export enum HEIGHT_TYPE {
  cm = 'cm',
  inch = 'inch'
}

export enum GENDER {
  male = 'male',
  female = 'female'
}

export enum DIET_ALLERGY_SLUG {
  cowMilk = 'cow-milk',
  eggs = 'eggs',
  fish = 'fish',
  gluten = 'gluten',
  honey = 'honey',
  lactose = 'lactose',
  nuts = 'nuts',
  soy = 'soy'
}

export enum DIET_TYPE_SLUG {
  hashimoto = 'hashimoto',
  lowIG = 'low-glycemic-index',
  standard = 'standard',
  vegan = 'vegan',
  vegetarian = 'vegetarian',
  vegetarianFish = 'vegetarian-fish'
}
