import { call, put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import LogInActions from '@redux/Fitney2.0/Auth/auth';
import RouteRedirectsActions from '@redux/route/routeRedirects';
import { AuthReducer } from '@redux/Fitney2.0/Auth/types';
import { RootReducersKeys } from '@redux/types';
import routes from '@routes';
import { userRegistered } from '@helpers/general/dataLayer';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import { setCookie } from '@services/cookiesService';
import { addToDate } from '@helpers/general/general';

export function* signUpByEmail(api: any, action: any): any {
  const { email, password, password_confirmation, phone_number = undefined, marketing_agreement } = action;

  const response = yield call(
    api.signUpByEmail,
    email,
    password,
    password_confirmation,
    phone_number,
    marketing_agreement
  );
  if (response.ok) {
    const stringedDataObject: AuthReducer = { authData: response.data };
    setCookie('refresh_token', response.data.refresh_token, { expires: addToDate(new Date(), 120, 'days').toDate() });
    localeStoreHelpers.setData([
      {
        key: RootReducersKeys.auth,
        stringedData: JSON.stringify(stringedDataObject)
      }
    ]);
    yield put(LogInActions.logInSuccess(response.data));
    yield put(push(routes.chooseSubscriptionPlan.path));
    userRegistered();
  } else {
    const errorMessage = response.data ? response.data.message : 'Connection error.';
    yield put(
      LogInActions.logInFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage,
        errors: response.data.errors
      })
    );
  }
}

export function* signUpByFacebook(api: any, action: any): any {
  const { token, phone, marketing_agreement } = action;

  const response = yield call(api.signUpByFacebook, token, phone, marketing_agreement);
  if (response.ok) {
    yield put(LogInActions.logInSuccess(response.data));
    userRegistered();
    yield put(RouteRedirectsActions.goToChooseSubscriptionPlan());
  } else {
    const errorMessage = response.data ? response.data.message : 'Connection error.';
    yield put(
      LogInActions.logInFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage,
        errors: response.data.errors
      })
    );
  }
}
