import { call, put, select, take } from '@redux-saga/core/effects';

import authSelectors from '@selectors/authSelectors';
import CreditCardActions from '@redux/Fitney2.0/Payment/Strall/creditCard';
import PaymentPlanActions, { paymentPlanSelectors } from '@redux/Fitney2.0/Payment/Strall/paymentPlan';
import couponActions from '@redux/Fitney2.0/Payment/Strall/coupon';
import RouteActions from '@redux/route/routeActions';
import LoginActions, { LogInTypes } from '@redux/Fitney2.0/Auth/auth';
import { formatStraalErrors, getErrorMessage } from '@helpers/helpers';
import { RootReducerStateProps } from '@redux/types';
import Creators, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { logFbAndTtqEvent } from '@helpers/pixels/logEvents';
import KryptonimPaymentActions from '@redux/Fitney2.0/Payment/Kryptonim/paymentPlan';
import KryptonimCreateWalletActions from '@redux/Fitney2.0/Payment/Kryptonim/walletAddress';

export function* createCardWithSubscription(api: any, action: any): any {
  try {
    const language = yield select((state: RootReducerStateProps) => appStatusSelectors.language(state));
    yield put(Creators.setLanguage(language));
    const token = yield select(authSelectors.token);
    const { creditCardData, isNewUser, paymentPlanName, browserInfo } = action;

    const response = yield call(api.addCreditCardWithSubscription, creditCardData, browserInfo, `Bearer ${token}`);
    if (response.ok) {
      logFbAndTtqEvent('Subscribe');
      yield put(CreditCardActions.createCreditCardSuccess());
      yield put(LoginActions.refreshToken());
      yield take(LogInTypes.LOG_IN_SUCCESS);
      yield put(RouteActions.subscriptionCreatedAction(isNewUser, paymentPlanName));
    } else {
      const error =
        response.status === 406
          ? 'aav2.payment.creditcardDetailsFormPaymentFraud'
          : formatStraalErrors(response?.data?.errors);

      yield put(
        CreditCardActions.createCreditCardFailure({
          msg: getErrorMessage(language, error),
          status: response?.status
        })
      );
    }
  } catch (e) {
    yield put(CreditCardActions.createCreditCardFailure(e));
  }
}

export function* getPaymentUrl(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const { currency, planUuid, promoCode } = action;
  try {
    const response = yield call(api.getPaymentUrl, token, currency, planUuid, promoCode);
    if (response.ok) {
      yield put(KryptonimPaymentActions.getKryptonimPaymentUrlSuccess(response.data));
    } else {
      yield put(KryptonimPaymentActions.getKryptonimPaymentUrlFailure(response.data));
    }
  } catch (e) {
    yield put(KryptonimPaymentActions.getKryptonimPaymentUrlFailure(e));
  }
}
export function* createWalletAddress(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const { currency, planUuid } = action;
  try {
    const response = yield call(api.createWalletAddress, token, currency, planUuid);
    if (response.ok) {
      yield put(KryptonimCreateWalletActions.createWalletAddressSuccess(currency, planUuid, response.data));
    } else {
      yield put(KryptonimCreateWalletActions.createWalletAddressFailure(response.data));
    }
  } catch (e) {
    yield put(KryptonimCreateWalletActions.createWalletAddressFailure(e));
  }
}

export function* getKryptonimPaymentPlans(api: any, action: any): any {
  const { language } = action;

  const token = yield select(authSelectors.token);
  try {
    const response = yield call(api.getKryptonimPaymentPlans, token);
    if (response.ok) {
      yield put(KryptonimPaymentActions.getKryptonimPaymentPlansSuccess(language, response.data));
    } else {
      yield put(KryptonimPaymentActions.getKryptonimPaymentPlansFailure(response.data));
    }
  } catch (e) {
    yield put(KryptonimPaymentActions.getKryptonimPaymentPlansFailure(e));
  }
}

export function* getKryptonimPaymentStatus(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  try {
    const response = yield call(api.getKryptonimPaymentStatus, token, action.transactionUuid);
    if (response.ok) {
      yield put(KryptonimPaymentActions.getKryptonimPaymentStatusSuccess(response.data));
    } else {
      yield put(KryptonimPaymentActions.getKryptonimPaymentStatusFailure(response.data));
    }
  } catch (e) {
    yield put(KryptonimPaymentActions.getKryptonimPaymentStatusFailure(e));
  }
}

export function* getPaymentPlans(api: any, action: any): any {
  const { offer } = action;
  try {
    const response = yield call(api.getPaymentPlans, offer);
    if (response.ok) {
      yield put(PaymentPlanActions.getPaymentPlansSuccess(response.data));
    } else {
      yield put(PaymentPlanActions.getPaymentPlansFailure(response.data));
    }
  } catch (e) {
    yield put(PaymentPlanActions.getPaymentPlansFailure(e));
  }
}

export function* fetchCoupon(api: any, action: any): any {
  const { coupon } = action;
  try {
    const response = yield call(api.getCouponPaymentPlans, coupon);
    if (response.ok) {
      const isArray = Array.isArray(response.data);
      if (!isArray) {
        yield put(PaymentPlanActions.fetchCouponSuccess(response.data));
      } else {
        yield put(PaymentPlanActions.fetchCouponFailure({ status: response.status, error: 'error' }));
      }
    } else {
      yield put(PaymentPlanActions.fetchCouponFailure(response.data?.errors?.coupon[0]));
    }
  } catch (e) {
    yield put(PaymentPlanActions.fetchCouponFailure(e));
  }
}

export function* processCoupon(api: any, action: any): any {
  const { coupon, isNewUser } = action;
  const response = yield call(api.processCoupon, coupon);
  if (response.ok) {
    yield put(couponActions.processCouponSuccess(response.data));
    yield put(RouteActions.subscriptionCreatedAction(isNewUser));
  } else {
    yield put(couponActions.processCouponFailure(response.data.errors));
  }
}

export function* getCurrentPaymentPlan(api: any, action: any): any {
  const { planId } = action;
  const plans = yield select(paymentPlanSelectors.paymentPlansSelector);

  if (plans) {
    const currentPlan = plans.find((plan: any) => plan.id === Number(planId)) || {};
    yield put(PaymentPlanActions.getCurrentPaymentPlanSuccess(currentPlan));
  } else {
    yield put(PaymentPlanActions.getPaymentPlansRequest(planId));
  }
}
