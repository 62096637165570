import React from 'react';

const AvatarIconEdit = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#E0325F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9226 16.3949L7.34558 16.4879L7.43858 12.9109L14.0546 6.29395L17.5386 9.77895L10.9226 16.3949Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AvatarIconEdit;
