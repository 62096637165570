import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { RootReducerStateProps } from '@redux/types';
import { StatsReducer } from '@redux/Fitney2.0/Stats/types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchStatsRequest: [],
  fetchStatsSuccess: ['data'],
  fetchStatsFailure: ['error'],
  fetchOverallUserStatsRequest: [],
  fetchOverallUserStatsSuccess: ['data'],
  fetchOverallUserStatsFailure: ['error'],
  fetchTrainingPlanCompletionRequest: [],
  fetchTrainingPlanCompletionSuccess: ['data'],
  fetchTrainingPlanCompletionFailure: ['error'],
  logEventToBackend: ['eventName']
});

export const StatsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: {}
});

/* ------------- Selectors ------------- */

export const statsSelectors = {
  getStats: (state: RootReducerStateProps) => state.stats?.data,
  getOverallUserStats: (state: RootReducerStateProps) => state.stats?.data,
  getTrainingPlanCompletion: (state: RootReducerStateProps) => state.stats?.data,
  isFetching: (state: any) => state.fetching
};

/* ------------- Reducers ------------- */

export const fetchStatsRequest = (state: StatsReducer) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const fetchStatsSuccess = (state: StatsReducer, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data: {
      ...state.data,
      ...data
    }
  };
};

export const fetchStatsFailure = (state: StatsReducer, action: any) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error
  };
};
export const fetchOverallUserStatsRequest = (state: StatsReducer) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const fetchOverallUserStatsSuccess = (state: StatsReducer, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data: {
      ...state.data,
      ...data
    }
  };
};

export const fetchOverallUserStatsFailure = (state: StatsReducer, action: any) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error
  };
};
export const fetchTrainingPlanCompletionRequest = (state: StatsReducer) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const fetchTrainingPlanCompletionSuccess = (state: StatsReducer, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data: {
      ...state.data,
      ...data
    }
  };
};

export const fetchTrainingPlanCompletionFailure = (state: StatsReducer, action: any) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_STATS_REQUEST]: fetchStatsRequest,
  [Types.FETCH_STATS_SUCCESS]: fetchStatsSuccess,
  [Types.FETCH_STATS_FAILURE]: fetchStatsFailure,
  [Types.FETCH_OVERALL_USER_STATS_REQUEST]: fetchOverallUserStatsRequest,
  [Types.FETCH_OVERALL_USER_STATS_SUCCESS]: fetchOverallUserStatsSuccess,
  [Types.FETCH_OVERALL_USER_STATS_FAILURE]: fetchOverallUserStatsFailure,
  [Types.FETCH_TRAINING_PLAN_COMPLETION_REQUEST]: fetchTrainingPlanCompletionRequest,
  [Types.FETCH_TRAINING_PLAN_COMPLETION_SUCCESS]: fetchTrainingPlanCompletionSuccess,
  [Types.FETCH_TRAINING_PLAN_COMPLETION_FAILURE]: fetchTrainingPlanCompletionFailure
});
