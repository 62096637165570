import React from 'react';

const YogaIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6982 21.8113C29.6982 27.9093 25.0942 32.8533 25.0942 32.8533C25.0942 32.8533 20.4902 27.9093 20.4902 21.8113C20.4902 15.7133 25.0942 10.7693 25.0942 10.7693C25.0942 10.7693 29.6982 15.7133 29.6982 21.8113Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6927 38.1511C29.3927 38.1511 25.0947 32.8551 25.0947 32.8551C25.0947 32.8551 29.3927 27.5571 34.6927 27.5571C39.9907 27.5571 44.2887 32.8551 44.2887 32.8551C44.2887 32.8551 39.9907 38.1511 34.6927 38.1511Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4974 38.1511C10.1974 38.1511 5.89941 32.8551 5.89941 32.8551C5.89941 32.8551 10.1974 27.5571 15.4974 27.5571C20.7974 27.5571 25.0954 32.8551 25.0954 32.8551C25.0954 32.8551 20.7974 38.1511 15.4974 38.1511Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6806 24.3845C9.59064 20.2225 9.74064 15.2365 9.74064 15.2365C9.74064 15.2365 14.0526 15.0645 17.6626 17.4505"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5074 24.3845C40.5994 20.2225 40.4494 15.2365 40.4494 15.2365C40.4494 15.2365 36.1354 15.0645 32.5254 17.4505"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default YogaIcon;
