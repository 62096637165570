import React from 'react';

const SupportIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9675 13.8995C26.9675 21.1175 21.1175 26.9675 13.8995 26.9675C6.68451 26.9675 0.833008 21.1175 0.833008 13.8995C0.833008 6.68301 6.68451 0.833008 13.8995 0.833008C21.1175 0.833008 26.9675 6.68301 26.9675 13.8995Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4558 13.8993C20.4558 17.5203 17.5203 20.4558 13.8993 20.4558C10.2783 20.4558 7.34277 17.5203 7.34277 13.8993C7.34277 10.2783 10.2783 7.34277 13.8993 7.34277C17.5203 7.34277 20.4558 10.2783 20.4558 13.8993Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.833008 13.8994H7.34301"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4561 13.8994H26.9676"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8994 0.833008V7.34301"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8994 20.4561V26.9676"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;
