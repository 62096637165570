import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingBasicItems: ['id', 'page'],
  fetchTrainingBasicItemsSuccess: ['items', 'page'],
  fetchTrainingBasicItemsFailure: ['error'],
  resetTrainingBasicItems: []
});

export const TrainingBasicItemsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: [],
  meta: {}
});

/* ------------- Selectors ------------- */

export const trainingBasicItemsSelector = {
  getTrainingBasicItems: (state: any) => state.trainingBasicItems.data,
  getTrainingBasicItemsMeta: (state: any) => state.trainingBasicItems.meta,
  isFetching: (state: any) => !!state.trainingBasicItems.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingBasicItems = (state: any) => ({ ...state, fetching: true, error: null });

export const successTrainingBasicItems = (state: any, action: any) => {
  const {
    items: { data, meta },
    page
  } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: page && page > 1 ? [...state.data, ...data] : data,
    meta
  };
};

export const resetTrainingBasicItems = (state: any) => ({
  ...state,
  // items: INITIAL_STATE.items,
  //todo this should be an array?
  items: []
});

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_BASIC_ITEMS]: requestFetchTrainingBasicItems,
  [Types.FETCH_TRAINING_BASIC_ITEMS_SUCCESS]: successTrainingBasicItems,
  [Types.FETCH_TRAINING_BASIC_ITEMS_FAILURE]: failure,
  [Types.RESET_TRAINING_BASIC_ITEMS]: resetTrainingBasicItems
});
