export interface IDietFilterBase {
  id: number;
  name: string;
}

export interface IDietFilter<T extends string> extends IDietFilterBase {
  slug?: T;
}

abstract class DietFilterRecipe implements IDietFilter<string> {
  protected constructor(public id: number, public name: string, public slug?: string) {
    this.id = id;
    this.name = name;
    this.slug = slug;
  }
}

export default DietFilterRecipe;
