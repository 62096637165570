import QuestionnaireLayout from '@components/layouts/QuestionnaireLayout';
import AppLayout from '@components/layouts/AppLayout';
import PlayerLayout from '@components/layouts/PlayerLayout';
import { ACCESS } from '@consts/consts';

export const appRoutes = {
  dailyWorkout: {
    path: '/app/daily-workout',
    pageName: 'DailyWorkout',
    layout: AppLayout,
    exact: true,
    access: ACCESS.LOGGED_IN
  },
  trainings: {
    exact: true,
    path: '/app/trainings',
    pageName: 'Trainings',
    layout: AppLayout,
    layoutProps: {
      withShadow: true,
      title: 'Trainings'
    },
    access: ACCESS.LOGGED_IN
  },
  trainingPlan: {
    exact: true,
    path: '/app/training-player/:training_id/:exercise_id?',
    pageName: 'TrainingPlayer',
    layout: PlayerLayout,
    access: ACCESS.LOGGED_IN
  },
  dailyTraining: {
    exact: true,
    path: '/app/daily-training/:schedule_item_id/:training_id/:exercise_id?',
    pageName: 'TrainingPlayer',
    layout: PlayerLayout
  },
  training: {
    exact: true,
    path: '/app/training/:training_id',
    pageName: 'Training',
    layout: AppLayout,
    layoutProps: {
      hasMobileHeader: false
    },
    access: ACCESS.LOGGED_IN
  },
  liveTrainings: {
    exact: true,
    path: '/app/live-trainings',
    pageName: 'LiveTrainings',
    layout: AppLayout,
    layoutProps: {
      withShadow: true,
      title: 'Live trainings'
    },
    access: ACCESS.LOGGED_IN
  },
  liveTraining: {
    exact: true,
    path: '/app/live-trainings/:live_training_id',
    pageName: 'LiveTraining',
    layout: PlayerLayout,
    access: ACCESS.LOGGED_IN
  },
  recipes: {
    exact: true,
    path: '/app/recipes',
    pageName: 'Recipes',
    layout: AppLayout,
    layoutProps: {
      title: 'Diet Plan',
      withShadow: true
    },
    access: ACCESS.LOGGED_IN
  },
  recipe: {
    exact: true,
    path: '/app/recipe/:recipe_id/:ratio?',
    pageName: 'Recipe',
    layout: AppLayout,
    layoutProps: {
      hasMobileHeader: false
    },
    access: ACCESS.LOGGED_IN
  },
  questionnaireTraining: {
    path: '/questionnaire-training',
    pageName: 'QuestionnaireTraining',
    layout: QuestionnaireLayout,
    access: ACCESS.LOGGED_IN,
    exact: true
  },
  questionnaireDiet: {
    path: '/questionnaire-diet',
    pageName: 'QuestionnaireDiet',
    layout: QuestionnaireLayout,
    access: ACCESS.LOGGED_IN,
    exact: true
  },
  userSettings: {
    path: '/user-settings',
    pageName: 'Settings',
    layout: AppLayout,
    layoutProps: {
      hasMobileHeader: false
    },
    access: ACCESS.LOGGED_IN,
    exact: true
  },
  stats: {
    path: '/app/stats',
    pageName: 'Stats',
    layout: AppLayout,
    access: ACCESS.LOGGED_IN,
    exact: true
  }
};
