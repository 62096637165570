import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '@routes';
import errorImg from '@assets/images/error-page.svg';
import { AppHeader } from '@components/headers';
import { FooterApp } from '@components/footers';
import Header from '@components/Header/Header';
import styles from './ErrorPageLayout.module.scss';

const ErrorPageLayout = ({ userId, isMobile }: any) => {
  const redirect = userId ? routes.dailyWorkout.path : routes.main.path;

  return (
    <div className={styles.wrapper}>
      {userId ? <AppHeader title={'title'} withShadow={true} /> : <Header />}
      <div className={styles.innerWrapper}>
        <img className={styles.img} src={errorImg} alt="404 graphic" />
        <span className={styles.title}>Oops!</span>
        <span className={styles.text}>Something went wrong :(</span>
      </div>
      {isMobile ? (
        <div className={styles.linksWrapper}>
          <Link to={redirect} className={styles.goToHome}>
            Go to Home
          </Link>
          <button className={styles.reload} onClick={() => window.location.reload()}>
            Reload
          </button>
        </div>
      ) : (
        <FooterApp />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.userData?.id,
  isMobile: state.appStatus.isMobile
});

export default connect(mapStateToProps)(ErrorPageLayout);
