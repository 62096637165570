import { call, put, select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import RouteActions from '@redux/route/routeActions';
import LogInActions from '@redux/Fitney2.0/Auth/auth';
import { RootReducersKeys } from '@redux/types';
import ResetPasswordActions from '@redux/Fitney2.0/Auth/resetPassword';
import { AuthReducer } from '@redux/Fitney2.0/Auth/types';
import RouteRedirectsActions from '@redux/route/routeRedirects';
import AppStatusActions from '@redux/Fitney2.0/AppStatus/appStatus';
import authSelectors from '@selectors/authSelectors';
import { clearExtraData } from '@helpers/general/extraData';
import { userRegistered } from '@helpers/general/dataLayer';
import { defaultLanguage } from '@helpers/language';
import routes from '@routes';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import {getCookie, removeCookie, setCookie} from '@services/cookiesService';
import { addToDate } from '@helpers/general/general';

export function* logInRequest(api: any, action: any): any {
  const { username, password, extra_data } = action;
  const response = yield call(api.login, username, password, extra_data);
  if (response.ok) {
    const stringedDataObject: AuthReducer = { authData: response.data };
    setCookie('refresh_token', response.data.refresh_token, { expires: addToDate(new Date(), 120, 'days').toDate() });
    localeStoreHelpers.setData([
      {
        key: RootReducersKeys.auth,
        stringedData: JSON.stringify(stringedDataObject)
      }
    ]);
    yield put(LogInActions.logInSuccess(response.data));
    yield put(RouteActions.userLoggedInAction());
    yield put(LogInActions.clearFetching());
  } else {
    const errorMessage = response?.data ? response.data.message : 'Connection error.';
    yield put(
      LogInActions.logInFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage,
        errors: response.data?.errors,
        fallbackAction: { type: action.type }
      })
    );
  }
}

export function* logInFacebook(api: any, action: any): any {
  const { token } = action;
  const response = yield call(api.loginFacebook, token);
  if (response.ok) {
    yield put(LogInActions.logInSuccess(response.data));
    if (response.data.newUser) {
      userRegistered();
    }
    yield put(RouteActions.userLoggedInAction());
    yield put(LogInActions.clearFetching());
  } else {
    try {
      yield put(LogInActions.logInFacebookFailure(response.data.message));
    } catch (e) {
      const errorMessage = response.data ? response.data.message : 'Connection error.';
      yield put(
        LogInActions.logInFacebookFailure({
          status: response.status,
          problem: response.problem,
          message: errorMessage
        })
      );
    }
  }
}

export function* forgotPassword(api: any, action: any): any {
  const response = yield call(api.forgotPassword, action.email);

  if (response.ok) {
    yield put(LogInActions.forgotPasswordSuccess(response.data.message));
  } else {
    const errorMessage = response.data ? response.data.message : 'Connection error.';
    yield put(
      LogInActions.forgotPasswordFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage,
        errors: response.data.errors
      })
    );
  }
}

export function* resetPassword(api: any, action: any): any {
  const response = yield call(api.resetPassword, action.email, action.password, action.passwordConfirm, action.token);
  if (response.ok) {
    yield put(ResetPasswordActions.resetPasswordSuccess());
    yield put(RouteActions.userPasswordResetedAction());
  } else {
    const errorMessage = response.data ? response.data.message : 'Connection error.';
    yield put(
      ResetPasswordActions.resetPasswordFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage,
        errors: response.data.errors
      })
    );
  }
}

export function* logOutUser(api: any): any {
  const token = yield select(authSelectors.token);
  removeCookie('refresh_token');
  yield call(api.logout, `Bearer ${token}`);
  yield put(LogInActions.logOutSuccess());
  yield put(push(routes.login.path));
  clearExtraData();
  yield put(AppStatusActions.setLanguage(defaultLanguage));
}

export function* refreshToken(api: any): any {
  const accessToken = getCookie('refresh_token');
  const response = yield call(api.refresh, accessToken);

  if (response.ok) {
    yield put(LogInActions.logInSuccess(response.data));
  } else {
    yield put(LogInActions.refreshTokenFailure());
  }
}


export function* refreshTokenFailure() {
  yield put(RouteRedirectsActions.goToLogin());
}
