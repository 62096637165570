import React from 'react';
import { connect } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';
import classNames from 'classnames';

import { RootReducerStateProps } from '@redux/types';
import AppStatusActions, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import styles from '@components/UIKit/LanguageDropdown/LanguageDropdown.module.scss';
import { FlagCodes, LanguageDropdownProps } from './types';

const countries = [FlagCodes.GB, FlagCodes.PL, FlagCodes.ES];
const customLabels = { GB: 'English', PL: 'Polski', ES: 'Español' };

const LanguageDropdown = (props: LanguageDropdownProps) => {
  const defaultLang = props.language && props.language.toUpperCase();

  const onSelectFlag = (countryCode: any) => {
    const lang = countryCode && countryCode.toLowerCase();
    props.setLanguage(lang);
  };

  return (
    <ReactFlagsSelect
      countries={countries}
      selected={defaultLang === 'EN' ? 'GB' : defaultLang}
      customLabels={customLabels}
      showSelectedLabel={false}
      onSelect={onSelectFlag}
      disabled={props.isLoadingUpdateLanguage}
      placeholder=""
      className={classNames(styles.languageDropdownWrapper, {
        [styles.isDarkBackground]: props.isDarkBackground,
        [styles.alignRight]: props.alignRight,
        [styles.navigation]: props.navigation
      })}
    />
  );
};

const mapStateToProps = (state: RootReducerStateProps) => ({
  language: appStatusSelectors.language(state),
  isLoadingUpdateLanguage: state.updateLanguage.fetching
});

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: string) => dispatch(AppStatusActions.setLanguage(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
