import React from 'react';
import { useHistory } from 'react-router-dom';

import { CloseIcon } from '@assets/icons';
import colors from '@consts/colors';
import routes from '@routes';
import Logo from '@components/UIKit/Logo';
import styles from './PlayerHeader.module.scss';

const PlayerHeader = () => {
  const history = useHistory();

  const handleClose = () => {
    if (history.action === 'POP') {
      history.push(routes.dailyWorkout.path);
    } else {
      history.goBack();
    }
  };

  return (
    <header className={styles.playerHeader}>
      <Logo inverted to={routes.dailyWorkout.path} />
      <div className="d-flex">
        <div className={styles.closeButton} onClick={handleClose}>
          <CloseIcon size={18} color={colors.white} opacity={50} />
        </div>
      </div>
    </header>
  );
};

// PlayerHeader.propTypes = {
//   isMobile: PropTypes.bool,
//   showSpotify: PropTypes.func,
// }
//
// PlayerHeader.defaultProps = {
//   isMobile: false,
//   showSpotify: () => {},
// }

export default PlayerHeader;
