import React from 'react';

const AppleIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M16.749 14.95c-.33 2.58-1.802 4.9-3.84 6.056-1.001.568-2.146.464-2.832.402-.194-.02-.353-.031-.47-.031-.116 0-.275.012-.469.03-.686.063-1.832.167-2.833-.401-2.038-1.156-3.51-3.476-3.839-6.056-.321-2.505.422-4.451 2.092-5.478 1.654-1.017 3.625-.836 4.638-.15.002.003.004.003.006.003.063.042.134.066.205.086.208.06.428.033.606-.086l.006-.002c1.011-.686 2.983-.868 4.637.149 1.671 1.027 2.414 2.973 2.093 5.478m-1.326-6.725c-1.791-1.103-4.153-1.215-5.816-.341-1.663-.873-4.022-.761-5.816.34-1.269.78-3.315 2.716-2.778 6.912.387 3.027 2.138 5.765 4.569 7.144.922.523 1.883.643 2.659.643.403 0 .756-.033 1.029-.057.14-.013.254-.025.337-.025.083 0 .198.012.337.025.274.024.627.057 1.03.057.776 0 1.736-.12 2.658-.643 2.43-1.378 4.181-4.116 4.57-7.144.537-4.197-1.511-6.131-2.779-6.911"
          transform="translate(-143 -414) translate(143 414) translate(3)"
        />
        <path
          fill={color}
          d="M7.901 3.067c.574.478.848 1.237.977 1.803-.58-.024-1.376-.154-1.95-.631-.575-.477-.848-1.237-.977-1.802.58.023 1.376.153 1.95.63m.973 3.3c0 .008-.004.014-.004.022-.03.413.28.772.693.803l.056.001c.39 0 .72-.3.747-.695.173-2.381 1.604-3.355 2.774-3.753.393-.133.603-.559.47-.951-.135-.393-.558-.604-.952-.469-.794.27-1.922.845-2.746 1.998-.24-.504-.569-1.007-1.052-1.409C7.336.646 5.075.964 4.98.979c-.379.055-.654.387-.64.769.003.096.104 2.378 1.628 3.645.913.758 2.087.947 2.906.974"
          transform="translate(-143 -414) translate(143 414) translate(3)"
        />
      </svg>
    </React.Fragment>
  );
};

export default AppleIcon;
