import { call, put } from '@redux-saga/core/effects';

import NotifyActions from '@redux/Fitney2.0/Payment/Mobile/payment';
import PacketsActions from '@redux/Fitney2.0/Payment/Mobile/packet';

export function* getPacket(api: any, action: any): any {
  const { packetSlug } = action;
  const response = yield call(api.getPackets, { packetSlug });
  if (response.ok) {
    yield put(PacketsActions.fetchNotifyPacketSuccess(response.data));
  } else {
    yield put(PacketsActions.fetchNotifyPacketFailure(response.data));
  }
}

export function* processNotify(api: any, action: any): any {
  const { packetUuid, extraData, phoneNumber, withPaymentSms, marketingAgreement } = action;
  const response = yield call(api.processNotify, {
    packetUuid,
    extraData,
    phoneNumber,
    withPaymentSms,
    marketingAgreement
  });

  if (response.ok) {
    yield put(NotifyActions.processNotifySuccess(response.data));
  } else {
    yield put(NotifyActions.processNotifyFailure(response.data));
  }
}
