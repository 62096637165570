import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchArticlesRequest: ['categories'],
  fetchArticlesSuccess: ['articles'],
  fetchArticlesFailure: ['error'],
  fetchArticleRequest: ['articleId'],
  fetchArticleSuccess: ['article'],
  fetchArticleFailure: ['error'],
  incrementArticleViewsRequest: ['articleId']
});

export const ArticlesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const articlesSelectors = {
  selectArticle: (state: any, id: any) => {
    let pathnameArray = state.router.location.pathname.split('/');
    let articleId = parseInt(pathnameArray.slice()[2]);

    return state.articles.data.find((article: any) => article.id === (id ? parseInt(id) : articleId));
  },

  selectArticleContent: (state: any, id: any) => {
    let pathnameArray = state.router.location.pathname.split('/');
    let articleId = parseInt(pathnameArray.slice()[2]);
    let article = state.articles.data.find((article: any) => article.id === (id ? parseInt(id) : articleId));
    if (article && article.content) {
      return JSON.parse(article.content);
    }

    return { blocks: [] };
  },
  isFetching: (state: any) => {
    return state.articles.fetching;
  }
};

/* ------------- Reducers ------------- */

export const setFetching = (state: any) => {
  return { ...state, fetching: true, error: null };
};

export const saveArticles = (state: any, action: any) => {
  const { articles } = action;
  return { ...state, fetching: false, error: null, data: articles };
};

export const saveArticle = (state: any, action: any) => {
  const { article } = action;
  const { data } = state;

  let updatedArticles = data ? JSON.parse(JSON.stringify(data)) : [];
  let articleIndex = updatedArticles.findIndex((oldArticle: any) => oldArticle.id === article.id);

  const isDuplicated = articleIndex !== -1;

  if (isDuplicated) {
    updatedArticles[articleIndex] = article;
  } else {
    updatedArticles.push(article);
  }

  return { ...state, fetching: false, error: null, data: updatedArticles };
};

export const setError = (state: any, action: any) => {
  const { error } = action;
  return { ...state, fetching: false, error: error };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ARTICLES_REQUEST]: setFetching,
  [Types.FETCH_ARTICLES_SUCCESS]: saveArticles,
  [Types.FETCH_ARTICLES_FAILURE]: setError,
  [Types.FETCH_ARTICLE_REQUEST]: setFetching,
  [Types.FETCH_ARTICLE_SUCCESS]: saveArticle,
  [Types.FETCH_ARTICLE_FAILURE]: setError
});
