import { call, put, select, take } from '@redux-saga/core/effects';

import authSelectors from '@selectors/authSelectors';
import trainingQuestionnaireFormActions from '@redux/Fitney2.0/Trainings/trainingQuestionnaire';
import UserDataActions from '@redux/Fitney2.0/UserData/userData';
import DietQuestionnaireFormActions from '@redux/Fitney2.0/Diet/dietQuestionnaire';
import {getError} from "@helpers/error/getError";
import {UpdateQuestionnaireActions} from "@redux/Fitney2.0/Settings";
import SnackBarActions from "@redux/Fitney2.0/Common/snackBar/snackBar";

export function* proceedQuestionnaireTraining(api: any, action: any): any {
    const { data } = action;
    const token = yield select(authSelectors.token);
    const response = yield call(api.proceedQuestionnaire, data, token, true);
    const responseDiet = response[0];
    const responseTrainings = response[1];
    const isDietSuccess = responseDiet && responseDiet.ok;
    const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

    if(isDietSuccess) {
      yield put(UserDataActions.getLoggedInUserDataRequest());
      yield take('GET_LOGGED_IN_USER_DATA_SUCCESS');
      yield put(trainingQuestionnaireFormActions.proceedQuestionnaireSuccess(data));
    } else {
      let errors = getError(response);
      const errorMessage = !errors ? 'Connection error.' : errors;
      yield put(trainingQuestionnaireFormActions.proceedQuestionnaireFailure(errorMessage));
    }

    if(isTrainingsSuccess) {
      yield put(UserDataActions.getLoggedInUserDataRequest());
      yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
    } else {
      let errors = getError(responseDiet);
      const errorMessage = !errors ? 'Connection error.' : errors;
      yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
    }
}

export function* proceedQuestionnaireDiet(api: any, action: any): any {
    const token = yield select(authSelectors.token);
    const { data } = action;

    const response = yield call(api.saveDietQuestionnaire, data, `Bearer ${token}`);
    if (response.ok) {
      yield put(UserDataActions.getLoggedInUserDataRequest());
      yield take('GET_LOGGED_IN_USER_DATA_SUCCESS');
      yield put(DietQuestionnaireFormActions.proceedDietQuestionnaireSuccess(data));
    } else {
      yield put(SnackBarActions.showSnackBar('Cannot save diet questionnaire.'));
      const errorMessage = response.message
          ? response.data
              ? response.data.error
              : 'Connection error.'
          : 'Connection error.';
      yield put(
          DietQuestionnaireFormActions.proceedDietQuestionnaireFailure({
            status: response.status,
            problem: response.problem,
            message: errorMessage
          })
      );
    }
}

