import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingPlanRequest: ['date'],
  fetchTrainingPlanSuccess: ['trainingPlan'],
  fetchTrainingPlanFailure: ['error'],
  fetchNextTrainingPlanItemsRequest: ['date'],
  fetchNextTrainingPlanItemsSuccess: ['trainingPlan'],
  fetchPrevTrainingPlanItemsRequest: ['date'],
  fetchPrevTrainingPlanItemsSuccess: ['trainingPlan'],
  fetchRegeneratedTrainingPlanRequest: [''],
  fetchRegeneratedTrainingPlanSuccess: ['trainingPlan'],
  resetTrainingPlanItems: ['']
});

export const TrainingPlanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  fetchingMore: false,
  fetchingLess: false,
  error: null,
  data: null,
  prevDataCount: 0,
  hasMore: true,
  hasLess: false
});

/* ------------- Selectors ------------- */

export const trainingPlanSelectors = {
  getTrainingPlanData: (state: any) => state.trainingPlan.data,
  getTrainingPlanError: (state: any) => state.trainingPlan.error,
  getPrevDataCount: (state: any) => state.trainingPlan.prevDataCount,
  isFetching: (state: any) => state.trainingPlan.fetching,
  isFetchingMore: (state: any) => state.trainingPlan.fetchingMore,
  isFetchingLess: (state: any) => state.trainingPlan.fetchingLess,
  hasMore: (state: any) => state.trainingPlan.hasMore,
  hasLess: (state: any) => state.trainingPlan.hasLess
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingPlanData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchTrainingPlanData = (state: any, action: any) => {
  const { trainingPlan } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: trainingPlan,
    hasMore: true,
    hasLess: trainingPlan[0]?.dayOfPlan > 1
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

export const requestFetchNextTrainingPlanItems = (state: any) => ({ ...state, fetchingMore: true, error: null });

export const successFetchNextTrainingPlanItems = (state: any, action: any) => {
  const { trainingPlan } = action;

  return {
    ...state,
    fetchingMore: false,
    error: null,
    data: [...state.data, ...trainingPlan],
    hasMore: !!trainingPlan.length
  };
};

export const requestFetchPrevTrainingPlanItems = (state: any) => ({
  ...state,
  fetchingLess: true,
  error: null,
  prevDataCount: 0
});

export const successFetchPrevTrainingPlanItems = (state: any, action: any) => {
  const { trainingPlan } = action;

  return {
    ...state,
    fetchingLess: false,
    error: null,
    data: [...trainingPlan, ...state.data],
    prevDataCount: trainingPlan.length || 0,
    hasLess: trainingPlan[0]?.dayOfPlan > 1
  };
};

export const requestFetchRegeneratedTrainingPlan = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchRegeneratedTrainingPlan = (state: any, action: any) => {
  const { trainingPlan } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: trainingPlan,
    hasMore: true
  };
};

export const resetTrainingPlanItems = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_PLAN_REQUEST]: requestFetchTrainingPlanData,
  [Types.FETCH_TRAINING_PLAN_SUCCESS]: successFetchTrainingPlanData,
  [Types.FETCH_TRAINING_PLAN_FAILURE]: failure,
  [Types.FETCH_NEXT_TRAINING_PLAN_ITEMS_REQUEST]: requestFetchNextTrainingPlanItems,
  [Types.FETCH_NEXT_TRAINING_PLAN_ITEMS_SUCCESS]: successFetchNextTrainingPlanItems,
  [Types.FETCH_PREV_TRAINING_PLAN_ITEMS_REQUEST]: requestFetchPrevTrainingPlanItems,
  [Types.FETCH_PREV_TRAINING_PLAN_ITEMS_SUCCESS]: successFetchPrevTrainingPlanItems,
  [Types.FETCH_REGENERATED_TRAINING_PLAN_REQUEST]: requestFetchRegeneratedTrainingPlan,
  [Types.FETCH_REGENERATED_TRAINING_PLAN_SUCCESS]: successFetchRegeneratedTrainingPlan,
  [Types.RESET_TRAINING_PLAN_ITEMS]: resetTrainingPlanItems
});
