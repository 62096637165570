import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchRecipeCategoriesRequest: [],
  fetchRecipeCategoriesSuccess: ['recipeCategories'],
  fetchRecipeCategoriesFailure: ['error'],
  fetchRecipesByCategoryRequest: ['categoryId', 'activeCategoriesName'],
  fetchRecipesByCategorySuccess: ['recipesByCategory'],
  fetchRecipesByCategoryFailure: ['error'],
  fetchRecipesRequest: ['page', 'per_page', 'query', 'filters'],
  fetchRecipesSuccess: ['recipes', 'page'],
  fetchRecipesFailure: ['error'],
  fetchRecipeToDisplayRequest: ['recipeId'],
  fetchRecipeToDisplaySuccess: ['recipeToDisplay'],
  fetchRecipeToDisplayFailure: ['error'],
  fetchRecipeRequest: ['recipeId'],
  fetchRecipeSuccess: ['recipe'],
  fetchRecipeFailure: ['error'],
  resetRecipe: [],
  setRecipesFilters: ['filters'],
  resetRecipesFilters: ['']
});

export const RecipesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  recipeCategories: null,
  recipesByCategory: [],
  recipes: [],
  displayRecipe: null,
  recipe: null,
  recipeTags: [],
  filters: {
    mealTypes: [],
    preparationTime: [],
    dietTypes: [],
    allergies: []
  }
});

/* ------------- Selectors ------------- */

export const recipeSelectors = {
  getRecipeCategories: (state: any) => {
    return state.recipes.recipeCategories;
  },
  getActiveRecipeCategories: (state: any) => {
    return state.recipes.recipeCategories
      ? state.recipes.recipeCategories.filter((category: any) => category.is_highlighted)
      : '';
  },
  getRecipes: (state: any) => {
    return state.recipes.recipes;
  },
  getRecipesFilters: (state: any) => state.recipes.filters,
  getRecipesToDisplayInCarousel: (state: any) => {
    return state.recipes.recipesByCategory;
  },
  isFetching: (state: any) => {
    return state.recipes.fetching;
  },
  recipeToDisplay: (state: any) => {
    return state.recipes.displayRecipe;
  },
  recipeTags: (state: any) => {
    return state.recipes.recipeTags;
  },
  recipe: (state: any) => state.recipes.recipe
};

/* ------------- Reducers ------------- */

export const setFetchingRecipeCategories = (state: any) => ({
  ...state,
  fetching: true,
  error: null,
  recipeCategories: [],
  recipesByCategory: []
});

export const setFetchingRecipesByCategory = (state: any) => ({
  ...state,
  fetching: true,
  error: null,
  recipesByCategory: []
});

export const setFetchingRecipes = (state: any) => ({
  ...state,
  fetching: true,
  error: null
});

export const setFetchingRecipeToDisplay = (state: any) => ({
  ...state,
  fetching: true,
  error: null,
  recipesByCategory: [],
  displayRecipe: null
});

export const setFetchingRecipe = (state: any) => ({
  ...state,
  fetching: true,
  error: null,
  recipe: null
});

export const successFetchRecipeCategories = (state: any, action: any) => {
  const { recipeCategories } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    recipesByCategory: [],
    recipeCategories
  };
};

export const successFetchRecipesByCategory = (state: any, action: any) => {
  const { recipesByCategory: newRecipes } = action;
  let recipes = [...state.recipesByCategory, newRecipes];

  return {
    ...state,
    fetching: false,
    error: null,
    recipesByCategory: recipes
  };
};

export const successFetchRecipes = (state: any, action: any) => {
  const { recipes, page } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    recipes: {
      data: page && page !== 1 ? [...state.recipes.data, ...recipes.data] : recipes.data,
      meta: recipes.meta
    }
  };
};

export const successFetchRecipeToDisplay = (state: any, action: any) => {
  const { recipeToDisplay } = action;
  const recipeCategories = recipeToDisplay.recipeCategories;
  const recipeDietTypes = recipeToDisplay.recipeDietTypes;

  let recipeTags: any = [];

  if (recipeCategories) {
    recipeCategories.map((category: any) => recipeTags.push(category.name));
  }
  if (recipeDietTypes) {
    recipeDietTypes.map((type: any) => recipeTags.push(type.name));
  }

  return {
    ...state,
    fetching: false,
    error: null,
    recipesByCategory: [],
    displayRecipe: recipeToDisplay,
    recipeCategories,
    recipeTags
  };
};

export const successFetchRecipe = (state: any, action: any) => {
  const { recipe } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    recipe
  };
};

export const resetRecipe = (state: any) => {
  return {
    ...state,
    fetching: false,
    error: null,
    recipe: null
  };
};

export const failure = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error
});

export const setRecipesFilters = (state: any, action: any) => {
  const { filters } = action;

  return {
    ...state,
    filters
  };
};

export const resetRecipesFilters = (state: any) => ({
  ...state,
  filters: INITIAL_STATE.filters
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_RECIPE_CATEGORIES_REQUEST]: setFetchingRecipeCategories,
  [Types.FETCH_RECIPE_CATEGORIES_SUCCESS]: successFetchRecipeCategories,
  [Types.FETCH_RECIPE_CATEGORIES_FAILURE]: failure,
  [Types.FETCH_RECIPES_BY_CATEGORY_REQUEST]: setFetchingRecipesByCategory,
  [Types.FETCH_RECIPES_BY_CATEGORY_SUCCESS]: successFetchRecipesByCategory,
  [Types.FETCH_RECIPES_BY_CATEGORY_FAILURE]: failure,
  [Types.FETCH_RECIPES_REQUEST]: setFetchingRecipes,
  [Types.FETCH_RECIPES_SUCCESS]: successFetchRecipes,
  [Types.FETCH_RECIPES_FAILURE]: failure,
  [Types.FETCH_RECIPE_TO_DISPLAY_REQUEST]: setFetchingRecipeToDisplay,
  [Types.FETCH_RECIPE_TO_DISPLAY_SUCCESS]: successFetchRecipeToDisplay,
  [Types.FETCH_RECIPE_TO_DISPLAY_FAILURE]: failure,
  [Types.FETCH_RECIPE_REQUEST]: setFetchingRecipe,
  [Types.FETCH_RECIPE_SUCCESS]: successFetchRecipe,
  [Types.RESET_RECIPE]: resetRecipe,
  [Types.FETCH_RECIPE_FAILURE]: failure,
  [Types.SET_RECIPES_FILTERS]: setRecipesFilters,
  [Types.RESET_RECIPES_FILTERS]: resetRecipesFilters
});
