import React from 'react';

const MealsPerDayIcon = () => {
  return (
    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.984 1.15367V6.45917C9.984 9.00917 7.917 11.0762 5.367 11.0762C2.817 11.0762 0.75 9.00917 0.75 6.45917V1.15367"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.36719 1.15338V7.98438"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19045 11.0765H6.54395L7.60145 25.8695C7.68395 27.1595 6.65945 28.25 5.36645 28.25C4.07495 28.25 3.05045 27.1595 3.13145 25.8695L4.19045 11.0765Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6643 15.3508V27.6328H20.2558C18.0598 27.6328 16.2793 25.8538 16.2793 23.6578V16.5253C16.2793 15.8773 16.8043 15.3508 17.4523 15.3508H18.0028C18.7048 15.3508 19.2583 14.7493 19.1968 14.0473L18.2518 3.16631C18.1708 2.07431 19.1953 1.15331 20.4883 1.15331C21.7798 1.15331 22.8043 2.07431 22.7233 3.16631L21.6643 15.3508Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MealsPerDayIcon;
