import React from 'react';

const AgeIcon = () => {
  return (
    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8226 4.67871H22.7271C24.5961 4.67871 26.1096 6.19371 26.1096 8.06271V25.3367C26.1096 27.2072 24.5961 28.7207 22.7271 28.7207H4.17209C2.30309 28.7207 0.788086 27.2072 0.788086 25.3367V8.06271C0.788086 6.19371 2.30309 4.67871 4.17209 4.67871H5.28509"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1963 4.67871H15.6993"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.09961 0.788086V8.03609"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7988 0.788086V8.03609"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4195 15.3423C11.4195 16.8978 10.1595 18.1578 8.60404 18.1578C7.05004 18.1578 5.79004 16.8978 5.79004 15.3423C5.79004 13.7883 7.05004 12.5283 8.60404 12.5283C10.1595 12.5283 11.4195 13.7883 11.4195 15.3423Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AgeIcon;
