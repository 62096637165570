import { AuthReducer } from '@redux/Fitney2.0/Auth/types';
import { AppStatusReducerProps, AppUIReducerProps } from '@redux/Fitney2.0/AppStatus/types';
import { UserDataReducer } from '@redux/Fitney2.0/UserData/types';
import { NotificationsReducerProps } from '@redux/Fitney2.0/Notifications/types';
import { LiveTrainingsReducerProps } from '@redux/Fitney2.0/Trainings/types';
import { StatsReducer } from '@redux/Fitney2.0/Stats/types';
import { TrainingsUserStateType } from '@redux/Fitney2.0/TrainingsUser/types';
import { QuestionnaireReducer } from '@redux/Fitney2.0/Questionnaire/types';

export enum RootReducersKeys {
  appStatus = 'appStatus',
  appUI = 'appUI',
  articles = 'articles',
  auth = 'auth',
  author = 'author',
  bodyPart = 'bodyPart',
  categories = 'categories',
  chartStats = 'chartStats',
  diet = 'diet',
  dietQuestionnaire = 'dietQuestionnaire',
  difficulty = 'difficulty',
  coupon = 'coupon',
  creditCard = 'creditCard',
  deleteSubscription = 'deleteSubscription',
  generalData = 'generalData',
  latestTrainings = 'latestTrainings',
  liveTrainings = 'liveTrainings',
  newsletter = 'newsletter',
  notifications = 'notifications',
  kryptonimWallets = 'kryptonimWallets',
  kryptonimPaymentPlans = 'kryptonimPaymentPlans',
  paymentPlans = 'paymentPlans',
  paymentMobile = 'paymentMobile',
  paymentPacket = 'paymentPacket',
  recipes = 'recipes',
  regeneratePlan = 'regeneratePlan',
  resetPasswordForm = 'resetPasswordForm',
  router = 'router',
  snackBar = 'snackBar',
  stats = 'stats',
  subscription = 'subscription',
  suggestedTrainings = 'suggestedTrainings',
  support = 'support',
  topArticles = 'topArticles',
  trainersList = 'trainersList',
  trainersStats = 'trainersStats',
  training = 'training',
  trainingBasicItems = 'trainingBasicItems',
  trainingDurationRanges = 'trainingDurationRanges',
  trainingEquipment = 'trainingEquipment',
  trainingExercise = 'trainingExercise',
  trainingExercises = 'trainingExercises',
  trainingItems = 'trainingItems',
  trainingPlan = 'trainingPlan',
  trainingQuestionnaire = 'trainingQuestionnaire',
  trainingsUser = 'trainingsUser',
  updateAllergies = 'updateAllergies',
  updateBirthDate = 'updateBirthDate',
  updateBodyParts = 'updateBodyParts',
  updateDietType = 'updateDietType',
  updateDifficultyLevel = 'updateDifficultyLevel',
  updateFullName = 'updateFullName',
  updateGender = 'updateGender',
  updateHeight = 'updateHeight',
  updateLanguage = 'updateLanguage',
  updateMealsPerDay = 'updateMealsPerDay',
  updatePassword = 'updatePassword',
  updateTrainingGoal = 'updateTrainingGoal',
  updateWeeklyIntensity = 'updateWeeklyIntensity',
  updateWeight = 'updateWeight',
  updateQuestionnaire = 'updateQuestionnaire',
  updateWorkoutType = 'updateWorkoutType',
  uploadUserImage = 'uploadUserImage',
  userData = 'userData',
  userViews = 'userViews',
  workoutType = 'workoutType'
}

export interface RootReducerStateProps {
  [RootReducersKeys.appStatus]?: AppStatusReducerProps;
  [RootReducersKeys.appUI]?: AppUIReducerProps;
  [RootReducersKeys.articles]?: any;
  [RootReducersKeys.auth]?: AuthReducer;
  [RootReducersKeys.author]?: any;
  [RootReducersKeys.bodyPart]?: any;
  [RootReducersKeys.categories]?: any;
  [RootReducersKeys.chartStats]?: any;
  [RootReducersKeys.diet]?: any;
  [RootReducersKeys.dietQuestionnaire]?: any;
  [RootReducersKeys.difficulty]?: any;
  [RootReducersKeys.coupon]?: any;
  [RootReducersKeys.creditCard]?: any;
  [RootReducersKeys.deleteSubscription]?: any;
  [RootReducersKeys.latestTrainings]?: any;
  [RootReducersKeys.liveTrainings]?: LiveTrainingsReducerProps;
  [RootReducersKeys.newsletter]?: any;
  [RootReducersKeys.notifications]?: NotificationsReducerProps;
  [RootReducersKeys.kryptonimWallets]?: any;
  [RootReducersKeys.kryptonimPaymentPlans]?: any;
  [RootReducersKeys.paymentPlans]?: any;
  [RootReducersKeys.paymentMobile]?: any;
  [RootReducersKeys.paymentPacket]?: any;
  [RootReducersKeys.recipes]?: any;
  [RootReducersKeys.regeneratePlan]?: any;
  [RootReducersKeys.resetPasswordForm]?: any;
  [RootReducersKeys.router]?: any;
  [RootReducersKeys.snackBar]?: any;
  [RootReducersKeys.stats]?: StatsReducer;
  [RootReducersKeys.subscription]?: any;
  [RootReducersKeys.suggestedTrainings]?: any;
  [RootReducersKeys.support]?: any;
  [RootReducersKeys.trainersStats]?: any;
  [RootReducersKeys.training]?: any;
  [RootReducersKeys.trainingBasicItems]?: any;
  [RootReducersKeys.trainingEquipment]?: any;
  [RootReducersKeys.trainingExercise]?: any;
  [RootReducersKeys.trainingItems]?: any;
  [RootReducersKeys.trainingPlan]?: any;
  [RootReducersKeys.trainingQuestionnaire]?: any;
  [RootReducersKeys.trainingsUser]?: TrainingsUserStateType;
  [RootReducersKeys.updateAllergies]?: any;
  [RootReducersKeys.updateBirthDate]?: any;
  [RootReducersKeys.updateBodyParts]?: any;
  [RootReducersKeys.updateDietType]?: any;
  [RootReducersKeys.updateDifficultyLevel]?: any;
  [RootReducersKeys.updateFullName]?: any;
  [RootReducersKeys.updateGender]?: any;
  [RootReducersKeys.updateHeight]?: any;
  [RootReducersKeys.updateLanguage]?: any;
  [RootReducersKeys.updateMealsPerDay]?: any;
  [RootReducersKeys.updatePassword]?: any;
  [RootReducersKeys.updateTrainingGoal]?: any;
  [RootReducersKeys.updateWeeklyIntensity]?: any;
  [RootReducersKeys.updateWeight]?: any;
  [RootReducersKeys.updateQuestionnaire]?: QuestionnaireReducer;
  [RootReducersKeys.updateWorkoutType]?: any;
  [RootReducersKeys.uploadUserImage]?: any;
  [RootReducersKeys.userData]?: UserDataReducer;
  [RootReducersKeys.userViews]?: any;
  [RootReducersKeys.workoutType]?: any;
}
