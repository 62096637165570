import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';

import { isFetching } from '@selectors/helperSelectors';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import FitneyFooterDarkAffilliate from '@containers/FitneyFooterDarkAffilliate/FitneyFooterDarkAffilliate';
import FitneyLoginRegisterTopBar from '@containers/FitneyLoginRegisterTopBar/FitneyLoginRegisterTopBar';
import styles from './BlogLayout.module.scss';

class BlogLayout extends Component<any, any> {
  render() {
    const { props } = this;

    moment.locale(`${props.language}`);

    return (
      <div id="landing_2_0_Wrapper" className={`d-flex flex-column justify-content-between ${styles.magazineWrapper}`}>
        <FitneyLoginRegisterTopBar isMagazine={true} />
        <div className={`container ${styles.magazineContainer}`}>{props.children}</div>
        <FitneyFooterDarkAffilliate pathInfo={props.pathInfo} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  fetching: isFetching(state),
  pathInfo: state.router.location,
  language: appStatusSelectors.language(state)
});

export default connect(mapStateToProps)(BlogLayout);
