import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createWalletAddressRequest: ['currency', 'planUuid'],
  createWalletAddressSuccess: ['currency', 'planUuid', 'response'],
  createWalletAddressFailure: ['error'],
  resetWallets: []
});

export const KryptonimCreateWalletTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  wallets: {},
  fetching: false,
  error: null
});

/* ------------- Selectors ------------- */

export const kryptonimCreateWalletAddressSelectors = {
  walletsPlanError: (state: any) => state.kryptonimWallets.error,
  getWallets: (state: any) => state.kryptonimWallets.wallets,
  isFetching: (state: any) => state.kryptonimWallets.fetching
};

/* ------------- Reducers ------------- */

export const requestCreateWalletAddress = (state: any, { currency, planUuid }: any) => {
  return {
    ...state,
    fetching: true
  };
};

export const successCreateWalletAddress = (state: any, { currency, planUuid, response }: any) => {
  return {
    ...state,
    fetching: false,
    error: null,
    wallets: {
      ...state.wallets,
      [`${currency}_${planUuid}`]: response
    }
  };
};

export const failure = (state: any, action: any) => ({
  ...state,
  error: action.error,
  fetching: false
});

export const resetWallets = (state: any) => ({
  ...state,
  wallets: {}
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_WALLET_ADDRESS_REQUEST]: requestCreateWalletAddress,
  [Types.CREATE_WALLET_ADDRESS_SUCCESS]: successCreateWalletAddress,
  [Types.CREATE_WALLET_ADDRESS_FAILURE]: failure,
  [Types.RESET_WALLETS]: resetWallets
});
