const colors = {
  black: '#000000',
  green: '#29C400',
  orange: '#FE5E1B',
  greyViolet: '#9a94a1',
  greyLight: '#dfdfdf',
  greyDark: '#7e7e7e',
  white: '#ffffff',
  pink: '#e0325f',
  lightPink: '#fff2f5'
};

export default colors;
