import qs from 'qs';
import { pick } from 'lodash';

export const isFetching = (state: any) =>
  (state.auth && state.auth.fetching) ||
  (state.resetPasswordForm && state.resetPasswordForm.fetching) ||
  (state.userData && state.userData.fetching) ||
  (state.uploadUserImage && state.uploadUserImage.fetching) ||
  (state.trainingExercises && state.trainingExercises.fetching) ||
  (state.trainingPlans && state.trainingPlans.fetching) ||
  (state.trainingPlan && state.trainingPlan.fetching) ||
  (state.updateFullName && state.updateFullName.fetching) ||
  (state.trainingList && state.trainingList.fetching) ||
  (state.creditCard && state.creditCard.fetching) ||
  (state.paymentPlans && state.paymentPlans.fetching) ||
  (state.support && state.support.fetching);

export const shouldUpdateState = (state: any, stateName: any) => {
  if (!state[stateName]) {
    return true;
  }

  const { timestamp } = state[stateName];
  if (!timestamp || new Date().getTime() - timestamp > 5000) {
    return true;
  }
  return false;
};

export const getSignatureAndEmail = () => {
  let queryString: any = window.location.search;
  queryString = qs.parse(queryString, { ignoreQueryPrefix: true });
  const allowedUtm = ['email', 'signature', 'uid'];

  return pick(queryString, allowedUtm);
};
