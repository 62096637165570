import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  showSnackBar: ['message'],
  hideSnackBar: []
});

export const SnackBarTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  open: false,
  message: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const show = (state: any, action: any) => ({ ...state, open: true, message: action.message });

export const hide = (state: any) => ({ ...state, open: false, message: null });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_SNACK_BAR]: show,
  [Types.HIDE_SNACK_BAR]: hide
});
