import React from 'react';

const InfoIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9235 9.21112C17.9235 14.0231 14.0235 17.9231 9.21151 17.9231C4.40051 17.9231 0.499512 14.0231 0.499512 9.21112C0.499512 4.40012 4.40051 0.500122 9.21151 0.500122C14.0235 0.500122 17.9235 4.40012 17.9235 9.21112Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="19">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 18.4231H18.423V0.00012207H0V18.4231Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path d="M9.23767 12.903V6.90295H6.88867" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.17383 5.02598V4.49819" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5112 12.903H6.88916" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default InfoIcon;
