import TrainingsUserPlan, { ITrainingsUserPlan, ITrainingsUserPlanDTO } from '@models/TrainingsUser/TrainingsUserPlan';

export interface ITrainingsUserDTO {
  id: string;
  email: string;
  manuallyAssigned: boolean;
  trainingPlan: ITrainingsUserPlanDTO | null;
}

export interface ITrainingsUser {
  id: string;
  email: string;
  manuallyAssigned: boolean;
  trainingPlan: ITrainingsUserPlan | null;
}

class TrainingsUser implements ITrainingsUser {
  constructor(
    public id: string,
    public email: string,
    public manuallyAssigned: boolean,
    public trainingPlan: ITrainingsUserPlan | null
  ) {}

  static build = (data: ITrainingsUserDTO): ITrainingsUser =>
    new TrainingsUser(
      data.id,
      data.email,
      data.manuallyAssigned,
      data.trainingPlan ? TrainingsUserPlan.build(data.trainingPlan) : null
    );
}

export default TrainingsUser;
