export function componentLoader<T>(lazyComponent: () => Promise<T>, attemptsLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
