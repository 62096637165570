import React, { createContext, useContext } from 'react';

import { useIpContext } from './IpProvider';

interface ICompanyData {
  [key: string]: {
    entity: string;
    address: string;
  };
}

export interface CompanyContextProps {
  entity: string;
  address: string;
}

export const CompanyContext = createContext<CompanyContextProps>({
  entity: '',
  address: ''
});
const companyData: ICompanyData = {
  PL: {
    entity: 'InterSpace Limited',
    address: 'Suite 20, The Penthouse, 4th Floor, Europa Business Centre, Dun Karm Street, Birkirkara, BKR9034, Malta'
  },
  SA: {
    entity: 'Codeways Consulting FZE',
    address: 'Business Center, Al Shmookh Building, UAQ, United Arab Emirates'
  },
  AE: {
    entity: 'Codeways Consulting FZE',
    address: 'Business Center, Al Shmookh Building, UAQ, United Arab Emirates'
  },
  US: {
    entity: 'Tec Star Media Inc.',
    address: '467 S 380 E, American Fork, UT 84003'
  },
  CO: {
    entity: 'Amadil Inc.',
    address: '2330 PONCE DE LEON BLVD, CORAL GABLE, FL 33131, USA'
  },
  // USA: {
  // 	entity: 'Avento Solutions Inc.',
  // 	address: '16192 Coastal Highway, Lewes, DE19958, County of Sussex, Delaware, United States',
  // },
  SG: {
    entity: 'Green Mile International Ventures Pte. Ltd.',
    address: '68 Circular Road #02-01049422, Singapore'
  },
  DEFAULT: {
    entity: 'InterSpace Limited',
    address: 'Suite 20, The Penthouse, 4th Floor, Europa Business Centre, Dun Karm Street, Birkirkara, BKR9034, Malta'
  }
};

export const CompanyProvider = ({ children }: any) => {
  const ipData = useIpContext() as any;
  const countryCode = ipData.country_code;

  return (
    <CompanyContext.Provider value={companyData[countryCode] ?? companyData.DEFAULT}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
