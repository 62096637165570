import React from 'react';
import { injectIntl } from 'react-intl';

import {goToApp, goToBlog} from '@helpers/general/landingHelpers';
import translatedTexts from '@containers/landing/Landing.lang';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { RootReducerStateProps } from '@redux/types';
import { connect } from 'react-redux';

const ExternalLinks = ({ intl, lang }: any) => {
  const goToPrivacyPolicy = () => {
    goToApp('/privacy-policy');
  };

  const goToBillingPolicy = () => {
    goToApp('/billing-policy');
  };

  const goToTermsOfService = () => {
    goToApp('/terms-of-use');
  };

  return (
    <React.Fragment>
      <li onClick={() => goToPrivacyPolicy()}>{translatedTexts(intl).linkPrivacyPolicy}</li>
      <li onClick={() => goToBillingPolicy()}>{translatedTexts(intl).linkBillingPolicy}</li>
      <li onClick={() => goToTermsOfService()}>{translatedTexts(intl).linkTermsOfService}</li>
      <li onClick={() => goToBlog(lang)}>{translatedTexts(intl).linkBlog}</li>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootReducerStateProps) => ({
  lang: appStatusSelectors.language(state),
  isLoadingUpdateLanguage: state.updateLanguage.fetching
});

export default connect(mapStateToProps)(injectIntl(ExternalLinks));
