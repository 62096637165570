import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingDurationRangesDataRequest: [],
  fetchTrainingDurationRangesDataSuccess: ['trainingDurationRanges'],
  fetchTrainingDurationRangesDataFailure: ['error']
});

export const TrainingDurationRangesDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const trainingDurationRangesDataSelectors = {
  getTrainingDurationRangesData: (state: any) => state.trainingDurationRanges.data,
  isFetching: (state: any) => state.trainingDurationRanges.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingDurationRangesData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchTrainingDurationRangesData = (state: any, action: any) => {
  const { trainingDurationRanges } = action;

  return {
    fetching: false,
    error: null,
    data: trainingDurationRanges
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_DURATION_RANGES_DATA_REQUEST]: requestFetchTrainingDurationRangesData,
  [Types.FETCH_TRAINING_DURATION_RANGES_DATA_SUCCESS]: successFetchTrainingDurationRangesData,
  [Types.FETCH_TRAINING_DURATION_RANGES_DATA_FAILURE]: failure
});
