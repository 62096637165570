import React from 'react';
import colors from '@consts/colors';

const PilatesIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      d="M5.52997 9.25005L13.86 8.92003C15.2997 8.84975 16.7363 9.10972 18.06 9.68004C18.533 9.88733 18.9076 10.2696 19.1052 10.7468C19.3027 11.2239 19.3081 11.759 19.1201 12.24L16.1201 19.5201C15.8535 20.2028 15.4 20.7967 14.8115 21.2335C14.2229 21.6704 13.5231 21.9325 12.7924 21.9899C12.0617 22.0473 11.3297 21.8977 10.6801 21.5581C10.0306 21.2185 9.48994 20.7028 9.12006 20.0701L1.76996 7.98003C1.6718 7.79669 1.64351 7.584 1.69012 7.38133C1.73674 7.17867 1.85523 6.99976 2.02362 6.87773C2.19202 6.75571 2.39879 6.69883 2.6059 6.71764C2.813 6.73645 3.00643 6.82966 3.15009 6.98003L3.72992 7.67003"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M7.06 11.3799L11.93 16.6499L13.64 12.5499"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M19.29 7.95001C20.9303 7.95001 22.26 6.6203 22.26 4.98001C22.26 3.33972 20.9303 2.01001 19.29 2.01001C17.6497 2.01001 16.32 3.33972 16.32 4.98001C16.32 6.6203 17.6497 7.95001 19.29 7.95001Z"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PilatesIcon;
