/* ------------- Types and Action Creators ------------- */

import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
    processNotifyRequest: ['packetUuid', 'extraData', 'phoneNumber', 'withPaymentSms', 'marketingAgreement'],
    processNotifySuccess: ['data'],
    processNotifyFailure: ['error'],
});

export const NotifyTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetching: null,
    error: null
});

/* ------------- Selectors ------------- */

export const mobilePaymentSelectors = {
    getPaymentMobile: (state: any) => state?.paymentMobile?.data,
    isFetching: (state: any) => state?.paymentMobile?.fetching
};

/* ------------- Reducers ------------- */

export const requestProcessNotify = (state: any) => ({ ...state, fetching: true, error: null });

export const successProcessNotify = (state: any, action: any) => {
    return ({ fetching: false, error: null, data: action?.data?.data });
}

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROCESS_NOTIFY_REQUEST]: requestProcessNotify,
    [Types.PROCESS_NOTIFY_SUCCESS]: successProcessNotify,
    [Types.PROCESS_NOTIFY_FAILURE]: failure
});
