import { GENDER, HEIGHT_TYPE, WEIGHT_TYPE } from '@consts/consts';

const mails = {
  turbospalanie: 'program@fitney.co'
};

const phoneNumber = {
  turbospalanie: '22 256 54 38'
};

const shop = {
  url: 'https://sklep.fitney.pl',
  languages: ['pl']
};

const socialLinks = {
  facebookPl: 'https://www.facebook.com/groups/2492188934412086'
};

export { mails, phoneNumber, socialLinks, shop };

export const weightExtremeValue = {
  [WEIGHT_TYPE.kg]: {
    min: 40,
    max: 220
  },
  [WEIGHT_TYPE.lbs]: {
    min: 88,
    max: 485
  }
};

export const heightExtremeValue = {
  [HEIGHT_TYPE.cm]: {
    min: 140,
    max: 250
  },
  [HEIGHT_TYPE.inch]: {
    min: 55,
    max: 99
  }
};

export const weightDefaultValue = {
  [GENDER.male]: {
    [WEIGHT_TYPE.kg]: 80,
    [WEIGHT_TYPE.lbs]: 176
  },
  [GENDER.female]: {
    [WEIGHT_TYPE.kg]: 65,
    [WEIGHT_TYPE.lbs]: 143
  }
};

export const heightDefaultValue = {
  [GENDER.male]: {
    [HEIGHT_TYPE.cm]: 175,
    [HEIGHT_TYPE.inch]: 69
  },
  [GENDER.female]: {
    [HEIGHT_TYPE.cm]: 165,
    [HEIGHT_TYPE.inch]: 65
  }
};

export enum APP_EVENT {
  appStarted = 'app-started',
  musicMuted = 'music-muted',
  instructionSingleSkipped = 'instruction-skipped',
  instructionsAllSkipped = 'training-instructions-turned-off'
}
