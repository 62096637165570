import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath, NavLink } from 'react-router-dom';
import * as H from 'history';
import { IntlShape, useIntl } from 'react-intl';
import { LANGUAGES } from '@consts/consts';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { RootReducerStateProps } from '@redux/types';
import { AppleIcon, JumpingRopeIcon, StopwatchIcon } from '@assets/icons';
import routes from '@routes';
import ChartLineIcon from '@components/Icons/ChartLineIcon/ChartLineIcon';
import translatedTexts from './AppNavLinks.lang';
import styles from './AppNavLinks.module.scss';
import LiveIcon from '@assets/icons/LiveIcon';
import cn from 'classnames';

type NavLinkType = {
  icon: JSX.Element;
  text: string;
  url: string;
  highlightUrls: string[];
  isVisible: boolean;
};

const navLinks = (intl: IntlShape, userLanguage: string): NavLinkType[] => [
  {
    icon: <JumpingRopeIcon size={22} />,
    text: translatedTexts(intl).dailyWorkout,
    url: routes.dailyWorkout.path,
    highlightUrls: [routes.dailyWorkout.path],
    isVisible: true
  },
  {
    icon: <AppleIcon size={21} />,
    text: translatedTexts(intl).diets,
    url: routes.recipes.path,
    highlightUrls: [routes.recipes.path, routes.recipe.path],
    isVisible: true
  },
  {
    icon: <StopwatchIcon size={24} />,
    text: translatedTexts(intl).trainings,
    url: routes.trainings.path,
    highlightUrls: [routes.trainings.path, routes.training.path],
    isVisible: true
  },
  {
    icon: <ChartLineIcon size={24} />,
    text: translatedTexts(intl).statistics,
    url: routes.stats.path,
    highlightUrls: [routes.stats.path],
    isVisible: true
  },
  {
    icon: <LiveIcon className={styles.live} />,
    text: translatedTexts(intl).liveTrainings,
    url: routes.liveTrainings.path,
    highlightUrls: [routes.liveTrainings.path],
    isVisible: userLanguage === LANGUAGES.PL
  }
];

type AppNavLinksPropsType = {
  pathInfo: H.Location;
  lang: string;
};

const AppNavLinks = ({ pathInfo, lang }: AppNavLinksPropsType) => {
  const intl = useIntl();
  return (
    <ul className={styles.navLinksWrapper}>
      {navLinks(intl, lang)
        .filter((link) => link.isVisible)
        .map((link) => {
          const isActive = !!link.highlightUrls.find((path) => matchPath(pathInfo.pathname, path));

          return (
            <li key={link.url} className={cn(styles.navLink, { [styles.navActive]: isActive })}>
              <NavLink to={link.url}>
                {link.icon}
                <span>{link.text}</span>
              </NavLink>
            </li>
          );
        })}
    </ul>
  );
};

const mapStateToProps = (state: RootReducerStateProps) => ({
  pathInfo: state.router.location,
  lang: appStatusSelectors.language(state)
});

export default compose(connect(mapStateToProps), withRouter)(AppNavLinks);
