import React from 'react';

const DocsIcon = () => {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.353 1.23828H4.64996C2.92346 1.23828 1.52246 2.63928 1.52246 4.36578V23.4338C1.52246 25.1603 2.92346 26.5613 4.64996 26.5613H19.149C20.877 26.5613 22.2765 25.1603 22.2765 23.4338V10.1618L13.353 1.23828Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3525 1.23828V8.21178C13.3525 9.28878 14.2255 10.1618 15.3025 10.1618H22.276"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocsIcon;
