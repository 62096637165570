import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const SoyIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => (
  <svg
    opacity={opacity / 100}
    width={size}
    height={size}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10308_11057)">
      <path
        d="M42.32 12.8905C41.36 10.6405 40.65 7.68047 40.28 5.48047C38.48 6.80047 35.91 8.42047 33.65 9.36047C31.99 10.0205 30.59 11.3205 29.86 13.1005C29.13 14.9005 29.23 16.8205 29.97 18.4605C30.24 19.0505 29.74 19.6905 29.12 19.5305C26.51 18.8605 23.63 19.5505 21.59 21.5905C19.8 23.3805 19.05 25.8105 19.34 28.1405C19.46 29.1305 18.57 29.9505 17.62 29.6605C16.19 29.2205 14.6 29.2605 13.11 29.8705C11.33 30.6005 10.03 32.0005 9.36999 33.6605C8.43999 35.9205 6.80999 38.5005 5.48999 40.3005C7.68999 40.6605 10.65 41.3705 12.9 42.3405C14.57 43.0905 16.54 43.1705 18.37 42.3705C20.72 41.3505 22.21 38.9705 22.38 36.4105C22.49 34.8105 21.51 32.6605 21.59 32.7405C24.67 35.8205 29.65 35.8205 32.73 32.7405C35.81 29.6605 35.81 24.6805 32.73 21.6005C32.65 21.5205 34.85 22.5205 36.51 22.3705C39.06 22.1505 41.41 20.6605 42.4 18.3005C43.16 16.5005 43.07 14.5605 42.33 12.9005L42.32 12.8905Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10308_11057">
        <rect width="50.4" height="50.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SoyIcon;
