import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchLatestTrainingsDataRequest: [''],
  fetchLatestTrainingsDataSuccess: ['latestTrainings'],
  fetchLatestTrainingsDataFailure: ['error']
});

export const LatestTrainingsDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const latestTrainingDataSelectors = {
  getLatestTrainingsData: (state: any) => state.latestTrainings.data,
  isFetching: (state: any) => state.training.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchLatestTrainingsData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchLatestTrainingsData = (state: any, action: any) => {
  const { latestTrainings } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: latestTrainings
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_LATEST_TRAININGS_DATA_REQUEST]: requestFetchLatestTrainingsData,
  [Types.FETCH_LATEST_TRAININGS_DATA_SUCCESS]: successFetchLatestTrainingsData,
  [Types.FETCH_LATEST_TRAININGS_DATA_FAILURE]: failure
});
