import React from 'react';

const AvatarIcon = () => {
  return (
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0029 56.8662H58.4689"
        stroke="#321B44"
        strokeWidth="4.00725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0029 40.1221H58.4689"
        stroke="#321B44"
        strokeWidth="4.00725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0029 23.3779H58.4689"
        stroke="#321B44"
        strokeWidth="4.00725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="81" height="81" rx="40.5" fill="#FFF2F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5358 33.993C52.5358 40.6508 47.1403 46.0462 40.4826 46.0462C33.8248 46.0462 28.4248 40.6508 28.4248 33.993C28.4248 27.3352 33.8248 21.9375 40.4826 21.9375C47.1403 21.9375 52.5358 27.3352 52.5358 33.993Z"
        stroke="#E0325F"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.024 58.0728C53.6532 54.3738 47.2947 52.2363 40.4817 52.2363C33.6642 52.2363 27.308 54.3738 21.9395 58.0728"
        stroke="#E0325F"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AvatarIcon;
