import React from 'react';

const DropdownArrow = ({ size = 10 }: any) => {
  return (
    <svg width={size} height={size} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1L4.99974 5L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DropdownArrow;
