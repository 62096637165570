export const goToApp = (route: any, domain = process.env.REACT_APP_URL) => {
  const temp: any = window;
  temp.dataLayer.push({ event: 'click', registration: 'go to fitney' });
  temp.location.href = domain + route;
};

export const goToExternalPage = (domain = process.env.REACT_APP_URL) => {
  const temp: any = window;
  temp.location.href = domain;
};

export const goToBlog = (lang: string) => {
  if (lang.toUpperCase() === 'PL') {
    goToExternalPage(process.env.REACT_APP_BLOG_PL_URL);
  } else {
    goToApp('/blog', process.env.REACT_APP_URL);
  }
};
