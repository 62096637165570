import React from 'react';

const PlayIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="45" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7129 43.583C64.3434 44.7819 64.3434 47.2181 62.7129 48.417L36.7772 67.4874C34.796 68.9441 32 67.5295 32 65.0704L32 26.9296C32 24.4705 34.796 23.0559 36.7772 24.5126L62.7129 43.583Z"
        fill="#E0325F"
      />
    </svg>
  );
};

export default PlayIcon;
