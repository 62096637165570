import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import PlansPricing from '../../../../mocks/plans-pricing.json';
import { Plan } from '@containers/Kryptonim/Payment/KryptonimPaymentContainer';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getKryptonimPaymentStatusRequest: ['transactionUuid'],
  getKryptonimPaymentStatusSuccess: ['processed'],
  getKryptonimPaymentStatusFailure: ['error'],
  getKryptonimPaymentUrlRequest: ['currency', 'planUuid', 'promoCode'],
  getKryptonimPaymentUrlSuccess: ['url', 'convertedCurrency', 'convertedAmount'],
  getKryptonimPaymentUrlFailure: ['error'],
  getKryptonimPaymentPlansRequest: ['language'],
  getKryptonimPaymentPlansSuccess: ['language', 'response'],
  getKryptonimPaymentPlansFailure: ['error'],
  setKryptonimPaymentProcessedStatus: ['processed'],
  resetPlans: [''],
  resetUrl: [''],
  setPromoCode: ['promoCode']
});

export const KryptonimPaymentPlanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  plans: null,
  url: null,
  promoCode: null,
  convertedAmount: null,
  convertedCurrency: null,
  status: null,
  fetching: {
    plans: false,
    url: false,
    status: false
  },
  error: null
});

/* ------------- Selectors ------------- */

export const kryptonimPaymentPlanSelectors = {
  paymentPlanError: (state: any) => state.kryptonimPaymentPlans.error,
  paymentStatusSelector: (state: any) => state.kryptonimPaymentPlans.status,
  paymentUrlSelector: (state: any) => state.kryptonimPaymentPlans.url,
  paymentPlansSelector: (state: any) => state.kryptonimPaymentPlans.plans,
  isPaymentPlansFetching: (state: any) => state.kryptonimPaymentPlans.fetching.plans,
  isPaymentUrlFetching: (state: any) => state.kryptonimPaymentPlans.fetching.url,
  getPaymentConvertedAmount: (state: any) => state.kryptonimPaymentPlans.convertedAmount,
  getPaymentConvertedCurrency: (state: any) => state.kryptonimPaymentPlans.convertedCurrency,
  getPromoCode: (state: any) => state.kryptonimPaymentPlans.promoCode
};

/* ------------- Reducers ------------- */
export const requestPaymentPlans = (state: any) => {
  return {
    ...state,
    fetching: {
      ...state.fetching,
      plans: true
    }
  };
};

export const setPromoCode = (state: any, action: any) => {
  return {
    ...state,
    promoCode: action.promoCode
  };
};

export const requestPaymentUrl = (state: any, action: any) => {
  return {
    ...state,
    fetching: {
      ...state.fetching,
      url: true
    },
    promoCode: action.promoCode
  };
};

export const requestPaymentStatus = (state: any) => {
  return {
    ...state,
    fetching: {
      ...state.fetching,
      status: true
    }
  };
};

export const successPaymentPlans = (state: any, { language, response }: any) => {
  const updatedPlans = response.map((plan: Plan) => {
    const pricingPlan = PlansPricing.plans.find((pricing) => pricing.uuid === plan.uuid);
    return {
      ...plan,
      stepType: pricingPlan?.step_type,
      price: pricingPlan && language ? pricingPlan.price[language as 'en' | 'es' | 'pl'] : null
    };
  });

  return {
    ...state,
    fetching: {
      ...state.fetching,
      plans: false
    },
    error: {},
    plans: updatedPlans
  };
};

export const successPaymentUrl = (state: any, action: any) => {
  const url = action.url.url as string;
  const convertedCurrency = url.split('convertedCurrency=')[1].split('&')[0];
  const convertedAmount = url.split('convertedAmount=')[1].split('&')[0];
  return {
    ...state,
    fetching: {
      ...state.fetching,
      url: false
    },
    error: {},
    url: action.url,
    convertedCurrency: convertedCurrency,
    convertedAmount: convertedAmount
  };
};

export const successPaymentStatus = (state: any, action: any) => {
  return {
    ...state,
    fetching: {
      ...state.fetching,
      status: false
    },
    error: {},
    status: action.processed
  };
};

export const failurePaymentPlans = (state: any, action: any) => ({
  ...state,
  error: action.error,
  fetching: {
    ...state.fetching,
    plans: false
  },
  plans: null
});

export const failurePaymentUrl = (state: any, action: any) => ({
  ...state,
  error: action.error,
  fetching: {
    ...state.fetching,
    url: false
  },
  url: null
});

export const failurePaymentStatus = (state: any, action: any) => ({
  ...state,
  error: action.error,
  fetching: {
    ...state.fetching,
    status: false
  }
});

export const setProcessedStatus = (state: any, action: any) => {
  return {
    ...state,
    status: {
      processed: action.processed
    }
  };
};

export const resetPlans = (state: any) => ({
  ...state,
  plans: null
});

export const resetUrl = (state: any) => ({
  ...state,
  url: null,
  convertedCurrency: null,
  convertedAmount: null
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_KRYPTONIM_PAYMENT_STATUS_REQUEST]: requestPaymentStatus,
  [Types.GET_KRYPTONIM_PAYMENT_STATUS_SUCCESS]: successPaymentStatus,
  [Types.GET_KRYPTONIM_PAYMENT_STATUS_FAILURE]: failurePaymentStatus,
  [Types.GET_KRYPTONIM_PAYMENT_URL_SUCCESS]: successPaymentUrl,
  [Types.GET_KRYPTONIM_PAYMENT_URL_FAILURE]: failurePaymentPlans,
  [Types.GET_KRYPTONIM_PAYMENT_PLANS_REQUEST]: requestPaymentPlans,
  [Types.GET_KRYPTONIM_PAYMENT_PLANS_SUCCESS]: successPaymentPlans,
  [Types.GET_KRYPTONIM_PAYMENT_PLANS_FAILURE]: failurePaymentUrl,
  [Types.SET_KRYPTONIM_PAYMENT_PROCESSED_STATUS]: setProcessedStatus,
  [Types.RESET_PLANS]: resetPlans,
  [Types.RESET_URL]: resetUrl,
  [Types.SET_PROMO_CODE]: setPromoCode,
  [Types.GET_KRYPTONIM_PAYMENT_URL_REQUEST]: requestPaymentUrl
});
