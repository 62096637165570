import { appRoutes } from './appRoutes';
import { mainRoutes } from './mainRoutes';
import { utilsRoutes } from './utilsRoutes';
import { blogRoutes } from './blogRoutes';
import { customRoutes } from './customRoutes';

const routes = {
  ...appRoutes,
  ...mainRoutes,
  ...utilsRoutes,
  ...blogRoutes,
  ...customRoutes
}

export default routes;
