import React from 'react';

import colors from '@consts/colors';

const HourglassIcon = ({ size = 18, color = colors.white, opacity = 40 }: any) => (
  <svg width={size - 1} height={size} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={opacity / 100}
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.108 3.25H4.35205C3.13172 3.25 2.51175 4.72565 3.46489 5.5313L7.80397 9.19923L3.46507 12.8678C2.51237 13.673 3.1306 15.149 4.35131 15.149H13.1073C14.3276 15.149 14.9476 13.6733 13.9945 12.8677L9.65539 9.19977L13.9943 5.53124C14.947 4.72595 14.3288 3.25 13.108 3.25ZM12.5934 4.75L8.72941 8.018L4.86441 4.75H12.5934ZM8.72941 10.381L12.5934 13.648H4.86541L8.72941 10.381Z"
    />
  </svg>
);

export default HourglassIcon;
