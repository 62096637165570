import React from 'react';

const GenderIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4135 23.592C16.4135 26.681 13.9095 29.185 10.8205 29.185C7.73252 29.185 5.22852 26.681 5.22852 23.592C5.22852 20.503 7.73252 18 10.8205 18C13.9095 18 16.4135 20.503 16.4135 23.592Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8213 18.0004V6.81543"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.73438 11.9024L10.8204 6.81543L15.9074 11.9024"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0625 12.4074C20.0625 9.31943 22.5665 6.81543 25.6555 6.81543C28.7435 6.81543 31.2475 9.31943 31.2475 12.4074C31.2475 15.4964 28.7435 18.0004 25.6555 18.0004C22.5665 18.0004 20.0625 15.4964 20.0625 12.4074Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25.6553 18V29.185" stroke="#E0325F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.6164 24.2314H21.6934"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GenderIcon;
