import React from 'react';

const RestDayIcon = () => {
  return (
    <svg width="45" height="47" viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.311 10.5485H2.282C1.574 10.5485 1 11.1225 1 11.8295V13.1315C1 13.9685 1.597 14.6865 2.42 14.8385L16.149 17.3785L13.738 44.0265C13.618 45.4295 15.585 45.8725 16.079 44.5535L22.219 28.1285L28.361 44.5535C28.854 45.8725 30.821 45.4295 30.701 44.0265L28.29 17.3785L42.019 14.8385C42.841 14.6865 43.439 13.9685 43.439 13.1315V11.8295C43.439 11.1225 42.866 10.5485 42.158 10.5485H30.436"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6235 5.404C26.6235 7.836 24.6515 9.808 22.2195 9.808C19.7875 9.808 17.8145 7.836 17.8145 5.404C17.8145 2.971 19.7875 1 22.2195 1C24.6515 1 26.6235 2.971 26.6235 5.404Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestDayIcon;
