import dietService from '@services/apiDietService';
import trainingsService from '@services/apiTrainingsService';
import trainingsUserApi from '@api/target/TrainingsUserApi';
import UserApi from '@api/target/UserApi';
import SignInApi from '@api/target/SignInApi';
import SignUpApi from '@api/target/SignUpApi';
import DietApi from '@api/target/DietApi';
import RecipeApi from '@api/target/RecipeApi';
import PaymentApi from '@api/target/PaymentApi';
import TrainingsV1Api from '@api/target/TrainingsV1Api';
import TrainingsApi from '@api/target/TrainingsApi';
import ArticlesApi from '@api/target/ArticlesApi';
import StatsApi from '@api/target/StatsApi';
import QuestionnaireApi from '@api/target/QuestionnaireApi';
import SettingsApi from '@api/target/SettingsApi';

const api = {
    /** Diets API **/
    user: UserApi(dietService),
    signIn: SignInApi(dietService),
    signUp: SignUpApi(dietService),
    diet: DietApi(dietService),
    recipe: RecipeApi(dietService),
    payment: PaymentApi(dietService),
    trainingsV1: TrainingsV1Api(dietService),
    articles: ArticlesApi(dietService),
    /** Trainings API **/
    trainings: TrainingsApi(trainingsService),
    trainingsUser: trainingsUserApi(trainingsService),
    stats: StatsApi(trainingsService),
    /** Both API **/
    questionnaire: QuestionnaireApi([dietService, trainingsService]),
    settings: SettingsApi([dietService, trainingsService])
};

export default api;
