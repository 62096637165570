import React from 'react';
import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const VeganDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2933 33.1128C23.2933 33.1128 19.7293 20.0948 25.4433 13.1108C31.1593 6.12478 43.9913 7.81678 43.9913 7.81678C43.9913 7.81678 47.5553 20.8308 41.8373 27.8188C36.1233 34.8048 23.2933 33.1128 23.2933 33.1128Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9184 16.1674C27.4624 24.7174 21.1744 36.7974 19.9204 43.0534"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0646 37.0619C18.0646 37.0619 20.4006 29.3539 17.1006 25.0839C13.7986 20.8139 6.11258 21.6019 6.11258 21.6019C6.11258 21.6019 3.77858 29.3079 7.07858 33.5779C10.3786 37.8479 18.0646 37.0619 18.0646 37.0619Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2012 26.7084C15.7092 31.9784 19.2712 39.2984 19.9192 43.0544"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1918 21.7748C30.9518 20.3328 30.8078 16.7928 31.6738 14.4728"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1504 26.1595C29.9944 26.2695 33.6404 26.1635 36.9184 23.7975"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VeganDietIcon;
