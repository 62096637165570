import React from 'react';
import colors from '@consts/colors';

type LiveIconPropsType = {
  size?: number;
  color?: keyof typeof colors;
  className?: string;
};

const LiveIcon = ({ size = 21, color = 'black', className }: LiveIconPropsType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.82422 1.01249C3.32864 2.248 0.824219 5.58171 0.824219 9.50037C0.824219 13.419 3.32864 16.7527 6.82422 17.9883M12.7887 1C16.3028 2.22541 18.8242 5.56843 18.8242 9.50037C18.8242 13.4323 16.3028 16.7753 12.7887 18.0007"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.8242 13.5007C14.0387 12.5885 14.8242 11.1362 14.8242 9.50037C14.8242 7.86455 14.0387 6.41221 12.8242 5.5M6.82422 13.5007C5.60977 12.5885 4.82422 11.1362 4.82422 9.50037C4.82422 7.86455 5.60977 6.41221 6.82422 5.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="9.82422" cy="9.5" r="1" fill={color} />
    </svg>
  );
};

export default LiveIcon;
