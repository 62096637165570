import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const VegetarianFishDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10356_10997)">
        <path
          d="M17.8701 43.3809C17.8701 43.3809 19.3901 45.0609 25.7701 45.0609"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.86011 27.8105C8.86011 37.2805 16.6401 36.7305 17.8301 43.3805"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.2 27.8105C43.2 37.2805 35.42 36.7305 34.24 43.3805"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.19 43.3809C34.19 43.3809 32.17 45.0609 25.78 45.0609"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.2001 27.8105C43.2001 28.9605 35.5101 31.3705 26.0301 31.3705C16.5501 31.3705 8.86011 28.9605 8.86011 27.8105"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.96 24.5994C13.14 24.2694 13.24 23.8194 13.25 23.4394C13.28 22.6794 12.78 22.3294 12.14 22.4194C11.25 22.5394 10.51 22.7294 9.91995 21.7594C9.66995 21.3494 9.36995 20.7294 9.44995 20.2094C9.69995 18.5194 11.19 16.6294 12.73 16.4194C13.62 16.2994 14.87 16.4194 15.4 17.3594C15.47 17.4894 15.53 17.6294 15.64 17.7394C15.87 17.9694 16.23 17.8994 16.55 17.9094"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6401 27.3595C20.2301 26.8395 20.4101 25.9695 19.5601 25.6295C19.2001 25.4795 18.8001 25.5395 18.4301 25.4195C18.0201 25.2795 17.6401 25.0195 17.3701 24.6895C16.6901 23.8595 17.5701 23.4195 18.0901 22.8695C18.7401 22.1695 19.0601 21.4195 20.0901 21.1595C20.7001 21.0095 21.4001 21.0795 21.9701 21.3495C22.2601 21.4895 22.5501 21.6795 22.7201 21.9595C22.9001 22.2295 22.8801 22.5795 23.1601 22.7795C23.3401 22.9095 23.5701 22.9595 23.7801 23.0295C24.5901 23.2795 25.0101 24.1695 24.7701 24.9695C24.5801 25.6195 24.1601 25.7895 23.7701 26.2695C23.5801 26.4895 23.4601 26.7895 23.5001 27.0795"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.81 25.8395C37.68 22.8895 35.99 20.1395 34.03 17.6095L36.1 5.85948C36.36 4.36948 34.58 3.38948 33.46 4.40948L28.04 9.36948L20.7 9.12948C19.18 9.07948 18.47 10.9795 19.64 11.9495L29.34 19.9295C29.36 20.9995 29.34 22.0595 29.22 23.0995C29.04 24.7695 28.69 26.4095 28.22 27.9595"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10356_10997">
          <rect width="50.4" height="50.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VegetarianFishDietIcon;
