import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { IDietType } from '@models/Diet/Filter/Type/DietType';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchDietRequest: [],
  fetchDietSuccess: ['diet'],
  fetchDietFailure: ['error'],
  fetchDietTypesRequest: [],
  fetchDietTypesSuccess: ['dietTypes'],
  fetchDietTypesFailure: ['error'],
  fetchMealTypesRequest: [],
  fetchMealTypesSuccess: ['mealTypes'],
  fetchMealTypesFailure: ['error'],
  fetchAllergiesRequest: [],
  fetchAllergiesSuccess: ['allergies'],
  fetchAllergiesFailure: ['error']
});

export const DietTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  diet: [],
  dietTypes: [],
  mealTypes: [],
  allergies: []
});

/* ------------- Selectors ------------- */

export const dietSelectors = {
  getDiet: (state: any) => {
    return state.diet.diet ? state.diet.diet : [];
  },
  getDietTypes: (state: any) => {
    let types: any = [];
    if (state.diet.dietTypes) {
      state.diet.dietTypes.map((type: any) => types.push(type));
    }
    return types;
  },
  getMealTypes: (state: any) => state.diet.mealTypes,
  getDietDays: (state: any) => {
    return state.diet.diet ? state.diet.diet.diet_days : [];
  },
  getAllergies: (state: any) => {
    let allergies: any = [];
    if (state.diet.allergies) {
      state.diet.allergies.map((allergy: any) => allergies.push(allergy));
    }
    return allergies;
  },
  isFetching: (state: any) => {
    return state.diet.fetching;
  }
};

/* ------------- Reducers ------------- */

export const requestFetchDietData = (state: any) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const successFetchDietData = (state: any, action: any) => {
  const { diet } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    diet
  };
};

export const successFetchDietTypesData = (state: any, action: { dietTypes: Array<IDietType> }) => {
  const { dietTypes } = action;
  return {
    ...state,
    error: null,
    dietTypes
  };
};

export const successFetchMealTypesData = (state: any, action: any) => {
  const { mealTypes } = action;
  return {
    ...state,
    error: null,
    mealTypes
  };
};

export const successFetchAllergiesData = (state: any, action: any) => {
  const { allergies } = action;
  return {
    ...state,
    error: null,
    allergies
  };
};

export const failure = (state: any, action: any) => ({
  ...state,
  error: action.error
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_DIET_REQUEST]: requestFetchDietData,
  [Types.FETCH_DIET_SUCCESS]: successFetchDietData,
  [Types.FETCH_DIET_FAILURE]: failure,
  [Types.FETCH_DIET_TYPES_SUCCESS]: successFetchDietTypesData,
  [Types.FETCH_DIET_TYPES_FAILURE]: failure,
  [Types.FETCH_MEAL_TYPES_SUCCESS]: successFetchMealTypesData,
  [Types.FETCH_MEAL_TYPES_FAILURE]: failure,
  [Types.FETCH_ALLERGIES_SUCCESS]: successFetchAllergiesData,
  [Types.FETCH_ALLERGIES_FAILURE]: failure
});
