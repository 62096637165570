// @ts-ignore
import { applyMiddleware, compose, createStore } from 'redux';
import * as Sentry from '@sentry/react';

import rootReducer from '@redux/index';
import { middleware, sagaMiddleware } from '@redux/middlewares';
import root from './sagas';

const initialState = {};
const enhancers = [];

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === ('staging' as any)) {
  const temp: any = window;
  const devToolsExtension = temp.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

if (process.env.NODE_ENV === 'production') {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  if (typeof sentryReduxEnhancer === 'function') {
    enhancers.push(sentryReduxEnhancer);
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

sagaMiddleware.run(root);

export default store;
