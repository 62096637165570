import React from 'react';

const KettlebellThickIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg width={size} height={size} viewBox="0 0 36 36">
        <path
          fill={color}
          d="M24.24,32.79H11.77a4.85,4.85,0,0,1-3.64-1.66A12.32,12.32,0,0,1,5.05,23,12.77,12.77,0,0,1,18,10.47,12.77,12.77,0,0,1,31,23a12.31,12.31,0,0,1-3.27,8.33A4.68,4.68,0,0,1,24.24,32.79ZM18,12.47A10.76,10.76,0,0,0,7.05,23a10.3,10.3,0,0,0,2.59,6.8,2.87,2.87,0,0,0,2.13,1H24.24a2.67,2.67,0,0,0,2-.81A10.27,10.27,0,0,0,29,23,10.76,10.76,0,0,0,18,12.47Z"
        />
        <path
          fill={color}
          d="M18,25.61a5.93,5.93,0,1,1,5.93-5.93A5.94,5.94,0,0,1,18,25.61Zm0-9.85a3.93,3.93,0,1,0,3.93,3.92A3.93,3.93,0,0,0,18,15.76Z"
        />
        <path
          fill={color}
          d="M9.32,16.11H9.2A1,1,0,0,1,8.32,15a8.59,8.59,0,0,0-.61-4.46A5.24,5.24,0,0,1,12.43,3H18a1,1,0,0,1,0,2H12.43A3.23,3.23,0,0,0,9.51,9.65a10.58,10.58,0,0,1,.8,5.58A1,1,0,0,1,9.32,16.11Z"
        />
        <path
          fill={color}
          d="M26.68,16.11a1,1,0,0,1-1-.88,10.41,10.41,0,0,1,.82-5.6A3.24,3.24,0,0,0,23.57,5H18a1,1,0,0,1,0-2h5.57a5.24,5.24,0,0,1,4.73,7.51A8.58,8.58,0,0,0,27.68,15a1,1,0,0,1-.88,1.11Z"
        />
      </svg>
    </React.Fragment>
  );
};

export default KettlebellThickIcon;
