import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const EggsIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => (
  <svg
    opacity={opacity / 100}
    width={size}
    height={size}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10308_11058)">
      <path
        d="M22.2499 29.7194C21.8699 21.4394 27.7399 10.8594 34.0699 10.8594C40.4099 10.8594 46.0399 21.4494 45.8899 29.7194C45.7499 37.5994 40.5999 41.9194 34.0699 41.9194C27.5399 41.9194 22.6099 37.5894 22.2499 29.7194V29.7194Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.92 41.051C19.68 41.971 17.13 42.121 14.52 41.501C8.15997 40.011 4.34997 34.671 5.79997 26.921C7.31997 18.771 15.46 9.81097 21.63 11.261C22.76 11.531 23.78 12.131 24.69 12.991"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10308_11058">
        <rect width="50.4" height="50.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EggsIcon;
