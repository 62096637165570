import React from 'react';

const StopwatchIcon = ({ size = 18, color, opacity = 100 }: any) => (
  <React.Fragment>
    <svg width={size} height={size} viewBox="0 0 36 36" fill={color}>
      <g opacity={opacity / 100}>
        <path d="M18.08,31.36A12.58,12.58,0,1,1,30.65,18.79,12.59,12.59,0,0,1,18.08,31.36Zm0-23.15A10.58,10.58,0,1,0,28.65,18.79,10.6,10.6,0,0,0,18.08,8.21Z" />
        <path d="M18.08,19.79A1,1,0,0,1,17.42,18l5.09-4.43a1,1,0,0,1,1.41.1,1,1,0,0,1-.1,1.41l-5.09,4.43A1,1,0,0,1,18.08,19.79Z" />
        <path d="M3.32,16.26l-.2,0a1,1,0,0,1-.78-1.18,15.61,15.61,0,0,1,6.8-9.92,1,1,0,1,1,1.09,1.69A13.54,13.54,0,0,0,4.3,15.46,1,1,0,0,1,3.32,16.26Z" />
        <path d="M18.08,8.21a1,1,0,0,1-1-1V6a1,1,0,0,1,2,0V7.21A1,1,0,0,1,18.08,8.21Z" />
        <path d="M20.39,4.64H15.76a1,1,0,0,1,0-2h4.63a1,1,0,1,1,0,2Z" />
      </g>
    </svg>
  </React.Fragment>
);

export default StopwatchIcon;
