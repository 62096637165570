import React from 'react';

import colors from '@consts/colors';

const ProgressArrow = ({ size = 10, color = colors.pink, className }: any) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 0.750122H8.466V8.46612"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.46565 0.750122L0.889648 8.32612"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProgressArrow;
