import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPaymentPlansRequest: ['offer'],
  getPaymentPlansSuccess: ['plans'],
  getPaymentPlansFailure: ['error'],
  fetchCouponRequest: ['coupon'],
  fetchCouponSuccess: ['couponPlan'],
  fetchCouponFailure: ['error'],
  getCurrentPaymentPlanRequest: ['planId'],
  getCurrentPaymentPlanSuccess: ['currentPlan'],
  applyCouponPlan: ['couponPlan', 'paymentPlan'],
  setSpecialPaymentPlan: ['specialPaymentPlan'],
  removeCoupon: [''],
  resetPlans: ['']
});

export const PaymentPlanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  plans: null,
  fetching: false,
  error: null,
  couponError: null,
  fetchingCoupon: false,
  currentPlan: {},
  specialPaymentPlan: null
});

/* ------------- Selectors ------------- */

export const paymentPlanSelectors = {
  selectSpecialPaymentPlan: (state: any) => state.paymentPlans.specialPaymentPlan,
  paymentPlanError: (state: any) => state.paymentPlans.error,
  paymentPlansSelector: (state: any) => state.paymentPlans.plans,
  isFetching: (state: any) => state.paymentPlans.fetching,
  isFetchingSelector: (state: any) => state.paymentPlans.fetchingCoupon,
  currentPlanSelector: (state: any) => state.paymentPlans.currentPlan,
  couponErrorSelector: (state: any) => state.paymentPlans.couponError
};

/* ------------- Reducers ------------- */

export const requestGetPaymentPlans = (state: any) => {
  return {
    ...INITIAL_STATE,
    fetching: true
  };
};

export const successGetPaymentPlans = (state: any, action: any) => {
  const { plans } = action;
  return { ...state, fetching: false, error: {}, plans };
};

export const failure = (state: any, action: any) => ({
  ...state,
  fetchingCoupon: false,
  error: action.error,
  plans: null
});

export const requestApplyCoupon = (state: any) => ({
  ...state,
  fetchingCoupon: true,
  couponError: null
});

export const successApplyCoupon = (state: any, action: any) => {
  const { couponPlan } = action;
  return {
    ...state,
    fetchingCoupon: false,
    couponError: null,
    currentPlan: {
      ...state.currentPlan,
      couponPlan
    }
  };
};

export const applyCoupon = (state: any, action: any) => {
  const { couponPlan, paymentPlan } = action;
  if (!couponPlan) {
    return { ...state };
  }
  let plans = JSON.parse(JSON.stringify(state.plans));

  plans = plans.map((e: any) => {
    if (e.id === paymentPlan.id) {
      if (e.step_type === couponPlan.step_type) {
        if (e.amount !== couponPlan.amount) {
          e.new_amount = couponPlan.amount;
        }
        if (e.currency !== couponPlan.currency) {
          e.new_currency = couponPlan.currency;
        }
        if (e.trial_days !== couponPlan.trial_days) {
          e.new_trial_days = couponPlan.trial_days;
        }
      } else {
        e.amount = couponPlan.amount;
        e.trial_days = couponPlan.trial_days;
      }
      e.straal_id = couponPlan.straal_id;
      e.step_type = couponPlan.step_type;
      e.coupon = couponPlan.coupon;
    }
    return e;
  });
  return { ...state, plans };
};

export const couponFailure = (state: any, action: any) => {
  return {
    ...state,
    fetchingCoupon: false,
    couponError: action.error
  };
};

export const setSpecialPaymentPlanReducer = (state: any, action: any) => {
  const { specialPaymentPlan } = action;
  return {
    ...state,
    specialPaymentPlan
  };
};

export const getCurrentPaymentPlanRequest = (state: any) => ({
  ...state,
  fetching: true,
  error: null,
  currentPlan: {},
  couponError: null
});

export const getCurrentPaymentPlanSuccess = (state: any, action: any) => {
  const { currentPlan } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    currentPlan
  };
};

export const removeCoupon = (state: any) => ({
  ...state,
  currentPlan: {
    ...state.currentPlan,
    couponPlan: {}
  }
});

export const resetPlans = (state: any) => ({
  ...state,
  plans: null
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAYMENT_PLANS_REQUEST]: requestGetPaymentPlans,
  [Types.GET_PAYMENT_PLANS_SUCCESS]: successGetPaymentPlans,
  [Types.GET_PAYMENT_PLANS_FAILURE]: failure,
  [Types.FETCH_COUPON_REQUEST]: requestApplyCoupon,
  [Types.FETCH_COUPON_SUCCESS]: successApplyCoupon,
  [Types.FETCH_COUPON_FAILURE]: couponFailure,
  [Types.APPLY_COUPON_PLAN]: applyCoupon,
  [Types.GET_CURRENT_PAYMENT_PLAN_REQUEST]: getCurrentPaymentPlanRequest,
  [Types.GET_CURRENT_PAYMENT_PLAN_SUCCESS]: getCurrentPaymentPlanSuccess,
  [Types.SET_SPECIAL_PAYMENT_PLAN]: setSpecialPaymentPlanReducer,
  [Types.REMOVE_COUPON]: removeCoupon,
  [Types.RESET_PLANS]: resetPlans
});
