import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getSupportQuestionsRequest: [],
  getSupportQuestionsSuccess: ['questions'],
  getSupportQuestionsFailure: ['error'],
  clearContactFormRequest: [],
  submitContactFormRequest: ['firstName', 'email', 'language', 'message', 'onSuccess', 'onError'],
  submitContactFormSuccess: ['success'],
  submitContactFormFailure: ['errors'],
  goToSupport: ['']
});

export const SupportTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  supportQuestions: [],
  fetching: null,
  contactFormFetching: null,
  error: null,
  contactFormErrors: null,
  contactFormSuccess: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const clearContactFormRequest = (state: any) => ({
  ...state,
  contactFormFetching: false,
  contactFormErrors: null,
  contactFormSuccess: null
});

export const requestSubmitContactForm = (state: any) => ({
  ...state,
  contactFormFetching: true,
  contactFormErrors: null
});

export const successSubmitContactForm = (state: any, action: any) => {
  const { success } = action;
  return {
    ...state,
    contactFormFetching: false,
    contactFormErrors: null,
    contactFormSuccess: success
  };
};

export const failureSubmitContactForm = (state: any, action: any) => ({
  ...state,
  contactFormFetching: false,
  contactFormErrors: action.errors
});

export const requestGetSupportQuestions = (state: any) => ({ ...state, fetching: true, error: null });
export const successGetSupportQuestions = (state: any, action: any) => {
  const { questions } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    supportQuestions: [...questions],
    timestamp: new Date().getTime()
  };
};
export const failure = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error,
  supportQuestions: []
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_CONTACT_FORM_REQUEST]: clearContactFormRequest,
  [Types.SUBMIT_CONTACT_FORM_REQUEST]: requestSubmitContactForm,
  [Types.SUBMIT_CONTACT_FORM_FAILURE]: failureSubmitContactForm,
  [Types.SUBMIT_CONTACT_FORM_SUCCESS]: successSubmitContactForm,
  [Types.GET_SUPPORT_QUESTIONS_REQUEST]: requestGetSupportQuestions,
  [Types.GET_SUPPORT_QUESTIONS_SUCCESS]: successGetSupportQuestions,
  [Types.GET_SUPPORT_QUESTIONS_FAILURE]: failure
});
