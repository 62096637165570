import React from 'react';
import { connect } from 'react-redux';

import { AppNavigationFooterMobile, FooterApp } from '@components/footers';
import { AppHeader } from '@components/headers';
import styles from './AppLayout.module.scss';

const AppLayout = ({ children, isMobile, title, withShadow, hasMobileHeader = true }: any) => {
  return (
    <div className={styles.appWrapper}>
      {!isMobile || (isMobile && hasMobileHeader) ? <AppHeader title={title} withShadow={withShadow} /> : null}
      <main className={styles.mainComponentWrapper}>{children}</main>
      {isMobile ? <AppNavigationFooterMobile /> : <FooterApp />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isMobile: state.appStatus.isMobile
});

export default connect(mapStateToProps)(AppLayout);
