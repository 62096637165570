declare const window: any;

export const logFbAndTtqEvent = (event: string) => {
  if (window.ttq && window.ttIDFromUrlParam) {
    window.ttq.track(event);
  }
  if (window.fbq && window.fbIDFromUrlParam) {
    window.fbq('track', event);
  }
};
