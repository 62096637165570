import _ from "lodash";
import {UserDataSingleProps} from "@redux/Fitney2.0/UserData/types";
import {post} from "@services/api";
import {API_CLIENT_TYPES, IApiBuilder} from "@services/types";

interface QuestionnaireData {
  birthDate: string
  gender: string
  height: string
  heightType: string
  weight: string
  weightType: string
  trainingType: number
  bodyParts: UserDataSingleProps[],
  trainingGoal: UserDataSingleProps
  weeklyIntensity:  UserDataSingleProps
  workoutLevel: UserDataSingleProps
  workoutType: UserDataSingleProps
}

const QuestionnaireApi = (props: IApiBuilder[]) => {
  
  let diets: IApiBuilder;
  let trainings: IApiBuilder;

  props.forEach((prop: IApiBuilder) => {
    const { client, buildUrl, type } = prop;
    switch (type) {
      case API_CLIENT_TYPES.DIET:
        diets = {
          client: client,
          buildUrl: buildUrl,
        };
        break;
      case API_CLIENT_TYPES.TRAININGS:
        trainings = {
          client: client,
          buildUrl: buildUrl,
        };
        break;
    }
  });

  const proceedQuestionnaire = async (data: QuestionnaireData, token: string, dontRegenerate: boolean) => {
    let reducedDataDiet = _.omit(data, 'bodyParts', 'weeklyIntensity', 'trainingGoal', 'workoutType', 'workoutLevel');
    let reducedDataTrainings = _.pick(data, 'height', 'heightType', 'weight', 'weightType');

    const dataDiet = {
      ...reducedDataDiet,
      bodyParts: data.bodyParts?.map(part => part.id),
      weeklyIntensity: data.weeklyIntensity.id,
      workoutType: data.workoutType.id,
      workoutLevel: data.workoutLevel.id,
      trainingGoal: data.trainingGoal.id,
    }

    const dataTrainings = {
      ...reducedDataTrainings,
      bodyParts: data.bodyParts?.map(part => part.uuid),
      trainingDays: data.weeklyIntensity.days,
      difficulty: data.workoutLevel.uuid,
      preferredWorkout: data.workoutType.uuid,
      trainingGoal: data.trainingGoal.uuid,
    }

    return Promise.all([
      post({
        client: diets.client,
        url: diets.buildUrl({ version: false, url: '/forms/proceedRegisterQuestionnaire' }),
        data: {
          ...dataDiet,
          dontRegenerate
        },
        axiosConfig: { headers: { Authorization: token }}
      }),
      post({
        client: trainings.client,
        url: trainings.buildUrl({ version: 'v2', url: '/questionnaire' }),
        data: {
          ...dataTrainings
        },
        axiosConfig: { headers: { Authorization: token }}
      })
    ])
  }

  const saveDietQuestionnaire = (data: []) => post({
    client: diets.client,
    url: diets.buildUrl({ version: false, url: '/diets/saveQuestionnaire' }),
    data: {
      ...data
    }
  })

  return {
    proceedQuestionnaire,
    saveDietQuestionnaire
  }
}

export default QuestionnaireApi;