import React from 'react';

import styles from './Badge.module.scss';

interface Props {
  children: string;
  type?: string;
}
function Badge({ children, type }: Props) {
  const badge = `${styles.badge} ${type === 'error' ? styles.error : ''}`
  return <div className={badge}>{children}</div>;
}

export default Badge;
