import React from 'react';

import colors from '@consts/colors';

const GlutenIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M16.498 10.9829C16.498 10.9829 13.9096 8.97253 13.9096 6.49073C13.9096 4.0118 16.498 2 16.498 2C16.498 2 19.0864 4.0118 19.0864 6.49073C19.0864 8.97253 16.498 10.9829 16.498 10.9829Z" />
      <path d="M24.6918 10.3715C24.6918 10.3715 20.7057 10.3715 18.438 12.7773C17.3697 13.9093 16.7955 14.9697 16.5011 15.8896C16.2052 14.9697 15.631 13.9093 14.5641 12.7773C12.2964 10.3715 8.30888 10.3715 8.30888 10.3715C8.30888 10.3715 7.98387 14.2833 10.2458 16.6934C12.5077 19.105 16.5011 19.0993 16.5011 19.0993C16.5011 19.0993 20.4929 19.105 22.7549 16.6934C25.0168 14.2833 24.6918 10.3715 24.6918 10.3715Z" />
      <path d="M25.9813 19.898C25.9813 19.898 21.3671 19.8994 18.7422 22.6821C17.5078 23.9933 16.8418 25.2213 16.5007 26.2845C16.1597 25.2213 15.4951 23.9933 14.2592 22.6821C11.6344 19.8994 7.01873 19.898 7.01873 19.898C7.01873 19.898 6.64271 24.426 9.26025 27.2159C11.8792 30.0057 16.5007 30 16.5007 30C16.5007 30 21.1208 30.0057 23.7398 27.2159C26.3573 24.426 25.9813 19.898 25.9813 19.898Z" />
    </g>
  </svg>
);

export default GlutenIcon;
