import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const HashimotoDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10308_11013)">
        <path
          d="M38.63 6.65039C38.63 16.9404 29.56 25.2804 25.31 25.2804C21.06 25.2804 12 16.9504 12 6.65039"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.1699 28.1504C29.7299 32.1304 31.7199 36.3104 40.1199 38.5904"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4501 28.1504C20.8901 32.1304 18.4801 37.0804 9.3501 38.7104"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 10.1309C26 10.1309 25.81 14.3909 23.72 16.6909C23.72 16.6909 24.87 17.1209 26.71 17.0009"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7801 39.1706C15.5101 40.4506 10.8801 37.5406 7.81012 39.1706C4.74012 40.8006 3.87012 44.5306 3.87012 44.5306"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.8501 39.1706C35.1201 40.4506 39.7501 37.5406 42.8201 39.1706C45.8801 40.8006 46.7601 44.5306 46.7601 44.5306"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5 8.07031C31.48 8.82031 33.23 8.80031 34.36 8.07031"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.99 8.07031C17.97 8.82031 19.72 8.80031 20.85 8.07031"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10308_11013">
          <rect width="50.4" height="50.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HashimotoDietIcon;
