import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import RefreshTokenQueue from '@services/refreshTokenQueueService';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import store from 'src/store';
import authSelectors from '@selectors/authSelectors';

export const fulfilledRequestInterceptor = (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  return new Promise<AxiosRequestConfig>(async (resolve) => {
    const isCredentialNotNeeded = RefreshTokenQueue.urlWithoutCredential.find((item) => request.url?.includes(item));
    request.headers['accept-language'] = appStatusSelectors.language(store.getState());

    if (isCredentialNotNeeded) {
      resolve(request);
    } else {
      const token = authSelectors.token(store.getState());

      if (RefreshTokenQueue.isRefreshing) {
        RefreshTokenQueue.addRequestToQueue((newToken) => {
          request.headers.Authorization = `Bearer ${newToken}`;
          resolve(request);
        });
      } else if (token && !authSelectors.isTokenExpired(token, RefreshTokenQueue.expireAccessTokenMargin)) {
        request.headers.Authorization = `Bearer ${token}`;
        resolve(request);
      } else {
        RefreshTokenQueue.addRequestToQueue((newToken) => {
          request.headers.Authorization = `Bearer ${newToken}`;
          resolve(request);
        });

        await RefreshTokenQueue.handleRefreshToken();
      }
    }
  });
};

export const fulfilledResponseInterceptor = async (response: AxiosResponse) => response;

export const rejectedResponseInterceptor = async (error: AxiosError) => {
  const { config, response: { status } = {} } = error;

  if (status === 401) {
    if (!RefreshTokenQueue.isRefreshing) {
      await RefreshTokenQueue.handleRefreshToken();
      new Promise((resolve) => {
        RefreshTokenQueue.addRequestToQueue((newToken) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          resolve(config);
        });
      });
    } else {
      return error;
    }
  } else {
    return error;
  }
};
