import React from 'react';

const UKIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" fill="white" stroke="#DEDEDE" />
      <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="18" height="18">
        <circle cx="13" cy="13" r="9" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path d="M31 21.5716V4.42871L16.375 13.0001L31 21.5716Z" fill="#00237A" />
        <path
          d="M29.3126 1.45714C28.8626 1.17143 28.3001 1 27.6814 1H-1.7374C-2.2999 1 -2.8624 1.17143 -3.36865 1.45714L13.0001 11L29.3126 1.45714Z"
          fill="#00237A"
        />
        <path
          d="M-3.36865 24.5429C-2.8624 24.8286 -2.2999 25 -1.7374 25H27.7376C28.3001 25 28.8626 24.8286 29.3689 24.6L13.0001 15L-3.36865 24.5429Z"
          fill="#00237A"
        />
        <path d="M-5 4.42871V21.5716L9.625 13.0001L-5 4.42871Z" fill="#00237A" />
        <path
          d="M10.1875 12.6569L-4.94375 3.7998C-5 3.91409 -5 4.08552 -5 4.25695V4.42838L9.625 12.9998L10.1875 12.6569Z"
          fill="white"
        />
        <path
          d="M15.8125 13.3429L30.9437 22.2571C30.9437 22.0857 31 21.9714 31 21.8V21.5714L16.375 13L15.8125 13.3429Z"
          fill="white"
        />
        <path
          d="M30.6063 23.3431L14.6875 13.9717L13 14.9431L29.3688 24.5431C29.875 24.2574 30.325 23.8574 30.6063 23.3431Z"
          fill="white"
        />
        <path
          d="M-4.6062 2.65703L11.3126 12.0285L13 11.057L-3.3687 1.45703C-3.87495 1.74275 -4.2687 2.14275 -4.6062 2.65703Z"
          fill="white"
        />
        <path
          d="M-4.60635 2.65723C-4.7751 3.00008 -4.8876 3.40008 -4.94385 3.80008L10.1874 12.6572L11.3124 11.9715L-4.60635 2.65723Z"
          fill="#CF142B"
        />
        <path
          d="M14.6875 13.9713L30.6062 23.2856C30.775 22.9428 30.8875 22.5999 30.9437 22.1999L15.8125 13.3428L14.6875 13.9713Z"
          fill="#CF142B"
        />
        <path
          d="M12.4375 14.6572L-3.875 24.2001C-3.70625 24.3144 -3.5375 24.4287 -3.36875 24.5429L13 15.0001L12.4375 14.6572Z"
          fill="white"
        />
        <path
          d="M-4.60625 23.3429L11.3125 13.9714L9.625 13L-5 21.5714V21.7429C-5 22.3143 -4.83125 22.8286 -4.60625 23.3429Z"
          fill="white"
        />
        <path
          d="M13.5625 11.3427L29.875 1.79989C29.7062 1.6856 29.5375 1.57132 29.3687 1.45703L13 10.9999L13.5625 11.3427Z"
          fill="white"
        />
        <path
          d="M30.6062 2.71387L14.6875 12.0282L16.375 12.9996L31 4.42815V4.25672C31 3.6853 30.8312 3.17101 30.6062 2.71387Z"
          fill="white"
        />
        <path
          d="M-4.6062 23.3426C-4.43745 23.6855 -4.1562 23.9712 -3.87495 24.2569L12.4375 14.714L11.3126 14.0283L-4.6062 23.3426Z"
          fill="#CF142B"
        />
        <path
          d="M13 13L12.4375 13.9714L11.875 13.6857L11.3125 13.9714L12.4375 14.6571L13.5625 13.9714L13 13Z"
          fill="#CF142B"
        />
        <path d="M12.4375 13.9714L13 13L11.875 13.6857L12.4375 13.9714Z" fill="#CF142B" />
        <path
          d="M30.6063 2.71409C30.4375 2.37123 30.1563 2.08552 29.875 1.7998L13.5625 11.3427L14.6875 12.0284L30.6063 2.71409Z"
          fill="#CF142B"
        />
        <path
          d="M13 12.9999L13.5625 12.0285L14.125 12.3142L14.6875 12.0285L13.5625 11.3428L12.4375 12.0285L13 12.9999Z"
          fill="#CF142B"
        />
        <path d="M13 12.9997L14.125 12.314L13.5625 12.0283L13 12.9997Z" fill="#CF142B" />
        <path
          d="M31 10.1429H15.8125V1H10.1875V10.1429H-5V15.8571H10.1875V25H15.8125V15.8571H31V10.1429Z"
          fill="white"
        />
        <path
          d="M31 11.2857H14.6875V1H11.3125V11.2857H-5V14.7143H11.3125V25H14.6875V14.7143H31V11.2857Z"
          fill="#CF142B"
        />
      </g>
    </svg>
  );
};

export default UKIcon;
