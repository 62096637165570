import {APP_EVENT} from '@consts/staticData';
import {get} from "@services/api";
import {IApiBuilder} from "@services/types";

const StatsApi = (props: IApiBuilder) => {
    const {client, buildUrl} = props;

    const getStats = () =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: '/statistics/schedule' }),
        });
    const getOverallUserStats = () =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: '/statistics/overallUserStats' }),
        });
    const getTrainingPlanCompletion = () =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: '/statistics/trainingPlanCompletion' }),
        });
    const getTrainersStats = () =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: '/statistics/trainers' }),
        });
    const getChartStats = (startDate: any, endDate: any) =>
        get({
            client: client,
            url: buildUrl({ version: 'v2', url: '/statistics/daily' }),
            params: { startDate, endDate }
        });
    const logEventToBackend = (eventName: APP_EVENT) =>
      get({
        client: client,
        url: buildUrl({ version: '', url: `/logEvent?action=${eventName}` }),
      });

    return {
        getStats,
        getOverallUserStats,
        getTrainingPlanCompletion,
        getTrainersStats,
        getChartStats,
        logEventToBackend
    }
}

export default StatsApi;
