import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateDifficultyLevelRequest: ['difficultyLevelUuid'],
  updateDifficultyLevelSuccess: [],
  updateDifficultyLevelFailure: ['error']
});

export const UpdateDifficultyLevelTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const requestDifficultyLevelUpdate = (state: any) => ({ ...state, fetching: true, error: null });

export const successDifficultyLevelUpdate = () => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_DIFFICULTY_LEVEL_REQUEST]: requestDifficultyLevelUpdate,
  [Types.UPDATE_DIFFICULTY_LEVEL_SUCCESS]: successDifficultyLevelUpdate,
  [Types.UPDATE_DIFFICULTY_LEVEL_FAILURE]: failure
});
