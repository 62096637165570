import React from 'react';

const BeingHealthyIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8855 18.5891C35.9315 17.8391 44.1135 21.3231 41.8835 32.0331C40.5775 38.2971 37.5435 43.0931 32.7335 44.9511C30.0935 45.9691 27.0335 45.5771 25.9355 45.5771"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9876 18.5891C15.9416 17.8391 7.7596 21.3231 9.9896 32.0331C11.2956 38.2971 14.3296 43.0931 19.1396 44.9511C21.7796 45.9691 24.8416 45.5771 25.9376 45.5771"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9248 19.978C26.0068 13.772 29.7648 11.022 32.5408 9.89197"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5457 9.27073C27.4797 11.9207 26.6477 15.8207 26.6477 15.8207C26.6477 15.8207 22.6797 15.4227 20.7477 12.7727C18.8137 10.1227 19.6457 6.22473 19.6457 6.22473C19.6457 6.22473 23.6117 6.62073 25.5457 9.27073Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.251 22.4682C24.121 24.5402 28.027 24.7802 30.403 22.4682"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BeingHealthyIcon;
