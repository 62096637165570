import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchSuggestedTrainingsDataRequest: ['workoutTypeId'],
  fetchSuggestedTrainingsDataSuccess: ['suggestedTrainings'],
  fetchSuggestedTrainingsDataFailure: ['error']
});

export const SuggestedTrainingsDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const suggestedTrainingDataSelectors = {
  getSuggestedTrainingsData: (state: any) => state.suggestedTrainings?.data?.data || [],
  isFetching: (state: any) => state.suggestedTrainings.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchSuggestedTrainingsData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchSuggestedTrainingsData = (state: any, action: any) => {
  const { suggestedTrainings } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: suggestedTrainings
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_SUGGESTED_TRAININGS_DATA_REQUEST]: requestFetchSuggestedTrainingsData,
  [Types.FETCH_SUGGESTED_TRAININGS_DATA_SUCCESS]: successFetchSuggestedTrainingsData,
  [Types.FETCH_SUGGESTED_TRAININGS_DATA_FAILURE]: failure
});
