import { useEffect } from 'react';

const useOutsideClick = (ref: any, callback: any, excludedRef: any) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (excludedRef?.current && excludedRef.current.contains(e.target)) {
        return;
      }

      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }

      ref.current && !ref.current.contains(e.target) && callback();
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, callback, excludedRef]);
};

export default useOutsideClick;
