import { call, put } from '@redux-saga/core/effects';

import NewsletterActions from '@redux/ToCheck/newsletter/newsletter';

export function* subscribeForNewsletter(api: any, action: any): any {
  console.log('subscribeForNewsletter');
  const { email } = action;

  const response = yield call(api.saveEmailForNewsletter, email);
  if (response.ok) {
    yield put(NewsletterActions.saveEmailForNewsletterSuccess());
  } else {
    const errorMessage: string = response?.data?.errors
      ? Object.values<string[]>(response.data.errors)
          .reduce((singleTypeMessages: string[], allMessages) => [...singleTypeMessages, ...allMessages], [])
          .join(', ')
      : '';

    yield put(
      NewsletterActions.saveEmailForNewsletterFailure({
        status: response.status,
        problem: response.problem,
        message: errorMessage
      })
    );
  }
}
