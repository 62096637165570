import DietFilterRecipe, { IDietFilter } from '@models/Diet/Filter/DietFilterRecipe';
import { DIET_ALLERGY_SLUG } from '@consts/consts';

export interface IDietAllergyDTO extends IDietFilter<string> {}

export interface IDietAllergy extends IDietFilter<DIET_ALLERGY_SLUG> {}

class DietAllergy extends DietFilterRecipe implements IDietAllergy {
  constructor(public id: number, public name: string, public slug: DIET_ALLERGY_SLUG) {
    super(id, name, slug);
  }

  static build = (data: IDietAllergyDTO): IDietAllergy =>
    new DietAllergy(data.id, data.name, data.slug as DIET_ALLERGY_SLUG);
}

export default DietAllergy;
