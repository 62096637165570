import { getAfId } from '@helpers/general/extraData';
import {post} from '@services/api';
import {IApiBuilder} from "@services/types";

const SignInApi = (props: IApiBuilder) => {
    const {client, buildUrl} = props;

    const login = async (email: string, password: string, extra_data: { origin?: string }) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/login' }),
            data: {
                email,
                password,
                extra_data: {
                    ...extra_data
                }
            }
        });

    const loginFacebook = (token: string, landing_trainer_id: string, training_type_id: string, referrer: any) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/login/facebook' }),
            data: {
                access_token: token,
                landing_trainer_id,
                training_type_id,
                referrer,
                appsflyer_uid: getAfId()
            }
        });

    const forgotPassword = (email: string) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/forgot/password' }),
            data: {
                email
            }
        });

    const resetPassword = (email: string, password: string, password_confirmation: string, token: string) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/forgot/password/reset' }),
            data: {
                email,
                password,
                password_confirmation,
                token
            }
        });

    const logout = (token: string) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/logout' }),
            axiosConfig: { headers: { Authorization: token } }
        });

    const refresh = (token: string) =>
        post({
            client: client,
            url: buildUrl({ version: false, url: '/login/refresh' }),
            data: {
                refresh_token: token
            }
        });

    return {
        login,
        loginFacebook,
        forgotPassword,
        resetPassword,
        logout,
        refresh
    };
}

export default SignInApi;