import React from 'react';
import classNames from 'classnames';

import CircleLoader from '@components/UIKit/CircleLoader/CircleLoader';
import styles from '@components/UIKit/ButtonArrow/ButtonArrow.module.scss';

const ButtonArrow = ({
  children,
  isTransparent,
  isWhite,
  isButtonSmaller,
  isGreyBordered,
  isPinkBordered,
  noBorder,
  onClick,
  disabled,
  loading,
  className = ''
}: any) => {
  return (
    <button
      className={classNames(
        styles.buttonCoronaSummer,
        {
          [styles.buttonPink]: !isTransparent && !isWhite,
          [styles.buttonTransparent]: isTransparent,
          [styles.buttonNav]: isButtonSmaller,
          [styles.buttonWhite]: isWhite,
          [styles.pinkBordered]: isPinkBordered,
          [styles.greyBordered]: isGreyBordered,
          [styles.disabled]: disabled,
          [styles.noBorder]: noBorder,
          [styles.loading]: loading
        },
        className
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <div className={styles.buttonContentWrapper}>
        <span className={styles.buttonContent}>{children}</span>
      </div>
      {loading ? (
        <i>
          <CircleLoader size="extraSmall" type={isTransparent ? 'light' : 'dark'} />
        </i>
      ) : (
        <i className="icon-arrow-forward" />
      )}
    </button>
  );
};

export default ButtonArrow;
