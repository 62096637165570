import DietFilterRecipe, { IDietFilterBase } from '@models/Diet/Filter/DietFilterRecipe';

export interface IDietMealDTO extends IDietFilterBase {}

export interface IDietMeal extends IDietFilterBase {}

class DietMeal extends DietFilterRecipe implements IDietMeal {
  constructor(public id: number, public name: string) {
    super(id, name);
  }

  static build = (data: IDietMealDTO): IDietMeal => new DietMeal(data.id, data.name);
}

export default DietMeal;
