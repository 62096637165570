import React from 'react';

interface IArrowPrevIcon {
  className?: string;
  size?: string | number;
  color?: string;
  opacity: number;
}

const ArrowPrevIcon = ({ size = 17, color, opacity = 100, className }: IArrowPrevIcon) => (
  <svg width={size} height={size} viewBox="0 0 9 17" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M8.179 15.356L1 8.178L8.179 1" />
    </g>
  </svg>
);

export default ArrowPrevIcon;
