import ProgramLoginLayout from '@components/layouts/LoginLayout/ProgramLoginLayout';
import {ACCESS, LANGUAGES} from '@consts/consts';
import SubscriptionLoginLayout from "@components/layouts/LoginLayout/SubscriptionLoginLayout";

export const customRoutes = {
  programLogin: {
    path: '/program',
    exact: true,
    pageName: 'ProgramLogin',
    layout: ProgramLoginLayout,
    access: ACCESS.ALL
  },
  mobileSubscription: {
    path: '/subscription/mobile',
    exact: true,
    pageName: 'SubscriptionMobile',
    layout: SubscriptionLoginLayout,
    access: ACCESS.ALL,
    languages: [LANGUAGES.PL]
  }
};
