import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';

export function convertToAdverb(word: any) {
  if (word.substr(word.length - 1) === 'y') {
    return `${word.substring(0, word.length - 1)}ily`;
  }

  return `${word}ly`;
}

export function secondsToMinutes(seconds: any, format: any = 'mm:ss') {
  if (seconds >= 3600) {
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }
  return moment.utc(seconds * 1000).format(format);
}

export function addToDate(startDate: moment.MomentInput, amount: DurationInputArg1, unit: DurationInputArg2) {
  return moment(startDate, 'DD-MM-YYYY').add(amount, unit);
}
