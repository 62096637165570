import axios, { AxiosResponse } from 'axios';
import LogInActions from '@redux/Fitney2.0/Auth/auth';
import RouteActions from '@redux/route/routeActions';
import { getCookie, setCookie } from '@services/cookiesService';
import { getSignatureAndEmail } from '@selectors/helperSelectors';
import store from '../store';
import { AuthReducer } from '@redux/Fitney2.0/Auth/types';
import { addToDate } from '@helpers/general/general';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import { RootReducersKeys } from '@redux/types';

type RefreshTokenResponseType = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
};

class RefreshTokenQueue {
  expireAccessTokenMargin = 600000; // in milliseconds
  urlWithoutCredential = [
    '/login',
    '/login/refresh',
    '/register',
    '/forgot/password',
    '/forgot/password/reset',
    '/notifai/payment/packet',
    '/notifai/payment/askForSubscription'
  ];
  isRefreshing = false;
  private requestsQueue: Array<(param: string) => void> = [];

  addRequestToQueue(cb: (param: string) => void) {
    this.requestsQueue.push(cb);
  }

  async handleRefreshToken() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const { email, signature, uid }: any = getSignatureAndEmail();
      const refreshToken = getCookie('refresh_token');

      try {
        if (email && signature) {
          const response = await axios.get<RefreshTokenResponseType>(
            `${process.env.REACT_APP_DIET_API_URL}${process.env.REACT_APP_DIET_API_URL_SUFFIX}/autologin?email=${email}&signature=${signature}`
          );
          this.onRefreshTokenSuccess(response);
        } else if (uid && signature) {
          const response = await axios.get<RefreshTokenResponseType>(
            `${process.env.REACT_APP_DIET_API_URL}${process.env.REACT_APP_DIET_API_URL_SUFFIX}/autologin?uid=${uid}&signature=${signature}`
          );
          this.onRefreshTokenSuccess(response);
        } else if (refreshToken) {
          const response = await axios.post<RefreshTokenResponseType>(
            `${process.env.REACT_APP_DIET_API_URL}${process.env.REACT_APP_DIET_API_URL_SUFFIX}/login/refresh`,
            {
              refresh_token: refreshToken
            }
          );

          this.onRefreshTokenSuccess(response);
        } else {
          this.onRefreshTokenFailure();
        }
      } catch {
        this.onRefreshTokenFailure();
      }
    }
  }

  onRefreshTokenSuccess(response: AxiosResponse<RefreshTokenResponseType>) {
    const stringedDataObject: AuthReducer = {
      authData: {
        access_token: response.data.access_token,
        expires_in: response.data.expires_in
      }
    };

    setCookie('refresh_token', response.data.refresh_token, {
      expires: addToDate(new Date(), 120, 'days').toDate()
    });

    localeStoreHelpers.setData([
      {
        key: RootReducersKeys.auth,
        stringedData: JSON.stringify(stringedDataObject)
      }
    ]);

    store.dispatch(LogInActions.logInSuccess(response.data));
    this.isRefreshing = false;
    this.onAccessTokenFetched(response.data.access_token);
  }

  onRefreshTokenFailure() {
    this.requestsQueue = [];
    this.isRefreshing = false;
    store.dispatch(LogInActions.logOutSuccess());
    store.dispatch(RouteActions.refreshTokenFailed());
  }

  onAccessTokenFetched(token: string) {
    this.requestsQueue.forEach((cb) => {
      cb(token);
    });
  }
}

const Queue = new RefreshTokenQueue();

export default Queue;
