import {get} from "@services/api";
import { IApiBuilder } from "@services/types";

const TrainingsV1Api = (props: IApiBuilder) => {
    const { client, buildUrl } = props;

    const getDifficultyLevels = async () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/difficultyLevels` }),
        }) as any;

    const getTrainingGoals = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/trainingGoals` }),
        }) as any;

    const getWeeklyIntensities = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/weeklyIntensities` }),
        }) as any;

    const getWorkoutTypes = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/workoutTypes` }),
        }) as any;

    const getTrainingTypes = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/trainingTypes` }),
        }) as any;

    const getBodyParts = () =>
        get({
            client: client,
            url: buildUrl({ version: false, url: `/data/bodyParts` }),
        }) as any;

    return {
        getDifficultyLevels,
        getTrainingGoals,
        getWeeklyIntensities,
        getWorkoutTypes,
        getTrainingTypes,
        getBodyParts,
    };
};

export default TrainingsV1Api;