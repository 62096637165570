import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  validateQuestionnaireRequest: ['data', 'callback'],
  validateQuestionnaireSuccess: ['data'],
  proceedQuestionnaireRequest: ['data'],
  proceedQuestionnaireSuccess: ['data'],
  proceedQuestionnaireFailure: ['error'],
  setQuestionnaireSkipped: [],
  unsetQuestionnaireSkipped: [],
  clearErrorsRequest: []
});

export const RegisterQuestionnaireTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null
});

/* ------------- Reducers ------------- */

export const successValidateQuestionnaire = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const successProceedQuestionnaire = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const requestProceedQuestionnaire = (state: any) => ({ ...state, fetching: true, error: null });
export const requestValidateQuestionnaire = (state: any) => ({ ...state, fetching: true, error: null });

export const failureProceedQuestionnaire = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error
});
export const setQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: true });
export const unsetQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: false });
export const requestClearErrors = (state: any) => ({ ...state, error: false });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.VALIDATE_QUESTIONNAIRE_REQUEST]: requestValidateQuestionnaire,
  [Types.VALIDATE_QUESTIONNAIRE_SUCCESS]: successValidateQuestionnaire,
  [Types.PROCEED_QUESTIONNAIRE_REQUEST]: requestProceedQuestionnaire,
  [Types.PROCEED_QUESTIONNAIRE_SUCCESS]: successProceedQuestionnaire,
  [Types.PROCEED_QUESTIONNAIRE_FAILURE]: failureProceedQuestionnaire,
  [Types.CLEAR_ERRORS_REQUEST]: requestClearErrors,
  [Types.SET_QUESTIONNAIRE_SKIPPED]: setQuestionnaireSkippedRequest,
  [Types.UNSET_QUESTIONNAIRE_SKIPPED]: unsetQuestionnaireSkippedRequest
});
