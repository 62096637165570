import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchChartStatsRequest: ['startDate', 'endDate'],
  fetchChartStatsSuccess: ['data'],
  fetchChartStatsFailure: ['error']
});

export const ChartStatsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const chartStatsSelectors = {
  getChartStats: (state: any) => state.chartStats?.data?.map((item: any) => {
    return {...item, timeSum: Math.round(item.timeSum / 60)}
  }),
  isFetching: (state: any) => state.chartStats.fetching
};

/* ------------- Reducers ------------- */

export const fetchChartStatsRequest = (state: any) => {
  return {
    ...state,
    fetching: true,
    error: null
  };
};

export const fetchChartStatsSuccess = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const fetchChartStatsFailure = (state: any, action: any) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CHART_STATS_REQUEST]: fetchChartStatsRequest,
  [Types.FETCH_CHART_STATS_SUCCESS]: fetchChartStatsSuccess,
  [Types.FETCH_CHART_STATS_FAILURE]: fetchChartStatsFailure
});
