import React from 'react';

const ArrowIcon = ({ size = 16, color, opacity = 50, background }: any) => (
  <svg width={size} height={size} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <g
      opacity={opacity / 100}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.93652 1.25293L0.936523 8.58613L7.93652 15.9193" />
      <path d="M14.9365 8.58583H0.936523" />
    </g>
  </svg>
);

export default ArrowIcon;
