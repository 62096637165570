import { MiddlewareAction, MiddlewareState } from './types';

export const loggedUserDataMiddleware = (state: MiddlewareState) => (next: any) => (action: MiddlewareAction) => {
  const rootState = state.getState();
  if (
    rootState?.auth?.authData &&
    rootState?.userData &&
    !rootState.userData.fetching &&
    !rootState.userData.dataFetched &&
    action.type !== 'GET_LOGGED_IN_USER_DATA_REQUEST' &&
    action.type !== 'GET_LOGGED_IN_USER_DATA_SUCCESS'
  ) {
    state.dispatch({ type: 'GET_LOGGED_IN_USER_DATA_REQUEST' });
  }
  return next(action);
};
