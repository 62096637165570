import React from 'react';

const WeightIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.33 30.27H8.66998C7.03998 30.27 5.72998 28.95 5.72998 27.33V8.66998C5.72998 7.03998 7.04998 5.72998 8.66998 5.72998H27.33C28.96 5.72998 30.27 7.04998 30.27 8.66998V27.33C30.27 28.96 28.96 30.27 27.33 30.27Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.68 17.4398C24 17.4398 24.87 16.0998 24.38 14.8798C23.37 12.3598 20.9 10.5898 18.02 10.5898C15.14 10.5898 12.67 12.3698 11.66 14.8798C11.17 16.0998 12.04 17.4398 13.36 17.4398H22.68V17.4398Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.21 15.1499L19.91 13.4199"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WeightIcon;
