import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { history } from 'src/history';
import { validateLocationChange } from './validateLocationChange';
import { loggedUserDataMiddleware } from './loggedUserDataMiddleware';
import { notificationMiddleware } from './notificationMiddleware';

export const sagaMiddleware = createSagaMiddleware();

export const middleware = [
  validateLocationChange(history),
  loggedUserDataMiddleware,
  notificationMiddleware,
  thunk,
  routerMiddleware(history),
  sagaMiddleware
];
