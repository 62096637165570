import React from 'react';

const ThreeTimesPerWeekIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.459 17.148C16.003 14.862 20.109 13.33 24.581 12.8C32.501 11.862 40.043 14.262 45.817 18.876C41.165 25.034 43.125 22.76 39.525 27.22C32.997 19.488 24.875 17.808 16.687 19.458"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0766 29.8042C31.4906 31.6102 31.9826 35.0342 30.1766 37.4482C28.3686 39.8622 24.9466 40.3542 22.5306 38.5482C20.8486 37.2882 12.5626 27.7742 7.6926 22.1202C6.8286 21.1202 7.9286 19.6522 9.1326 20.1962C15.9306 23.2762 27.3926 28.5442 29.0766 29.8042Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThreeTimesPerWeekIcon;
