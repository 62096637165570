import * as React from 'react';
import qs, { ParsedQs } from 'qs';
import { RouteComponentProps } from 'react-router';
import { setCookie } from '@services/cookiesService';
import { addToDate } from '@helpers/general/general';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

export const utmCookieName = 'utm-data';
export const unidentifiedCookieName = 'unidentified-data';

const defaultUtmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
const additionalUtmParams = ['aid', 'pctx', 'var3', 'var4', 'utm_expid', 'ref_lang'];
const unidentifiedParams = ['sidng'];
const combinedParams = defaultUtmParams.concat(additionalUtmParams);

export type UtmParamsType = ParsedQs;

const utmParams =
  <P extends RouteComponentProps>(Component: React.ComponentType<P>) =>
  ({ ...props }: P) => {
    const history = useHistory();

    const filterParams = React.useCallback(
      (
        params: ParsedQs,
        filterNames: string[]
      ): Record<string, undefined | string | string[] | ParsedQs | ParsedQs[]> => {
        return Object.keys(params)
          .filter((key) => filterNames.findIndex((filterName) => key === filterName) >= 0)
          .reduce(
            (res, key) => ({ ...res, [key]: params[key] }),
            {} as Record<string, undefined | string | string[] | ParsedQs | ParsedQs[]>
          );
      },
      []
    );

    const getUrlParams = React.useCallback((): ParsedQs => {
      return qs.parse(history.location.search, { ignoreQueryPrefix: true }) || {};
    }, [history.location.search]);

    React.useEffect(() => {
      const utmParamsList = filterParams(getUrlParams(), combinedParams);
      const unidentifiedParamsList = filterParams(getUrlParams(), unidentifiedParams);

      if (!isEmpty(utmParamsList)) {
        const encodedUtmParams = window.btoa(JSON.stringify(utmParamsList));
        setCookie(utmCookieName, encodedUtmParams, { expires: addToDate(new Date(), 30, 'minutes').toDate() });
      }
      if(!isEmpty(unidentifiedParamsList)) {
          const encodedUnidentifiedParams = window.btoa(JSON.stringify(unidentifiedParamsList));
          setCookie(unidentifiedCookieName, encodedUnidentifiedParams, { expires: addToDate(new Date(), 30, 'minutes').toDate() });
      }
    }, [filterParams, getUrlParams]);

    return <Component {...props} />;
  };

export default utmParams;
