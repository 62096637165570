import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchTrainingDataRequest: ['page', 'per_page', 'query', 'filters'],
  fetchTrainingDataSuccess: ['training', 'page'],
  fetchTrainingDataFailure: ['error'],
  setTrainingDataFilters: ['filters'],
  resetTrainingDataFilters: ['']
});

export const TrainingDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  data: [],
  meta: null,
  filters: {
    workoutTypes: [],
    bodyParts: [],
    duration: [],
    difficulty: [],
    author: [],
    trainingEquipment: []
  }
});

/* ------------- Selectors ------------- */

export const trainingDataSelectors = {
  getTrainingData: (state: any) => state.training,
  isFetching: (state: any) => state.training.fetching,
  getTrainingFilters: (state: any) => state.training.filters
};

/* ------------- Reducers ------------- */

export const requestFetchTrainingData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchTrainingData = (state: any, action: any) => {
  const { training, page } = action;

  return {
    ...state,
    fetching: false,
    error: null,
    data: page && page !== 1 ? [...state.data, ...training.data] : training.data,
    meta: training.meta,
    timestamp: new Date().getTime()
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

export const setTrainingsFilters = (state: any, action: any) => {
  const { filters } = action;

  return {
    ...state,
    filters
  };
};

export const resetTrainingsFilters = (state: any) => ({
  ...state,
  filters: INITIAL_STATE.filters
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_TRAINING_DATA_REQUEST]: requestFetchTrainingData,
  [Types.FETCH_TRAINING_DATA_SUCCESS]: successFetchTrainingData,
  [Types.FETCH_TRAINING_DATA_FAILURE]: failure,
  [Types.SET_TRAINING_DATA_FILTERS]: setTrainingsFilters,
  [Types.RESET_TRAINING_DATA_FILTERS]: resetTrainingsFilters
});
