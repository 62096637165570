import LandingLayout from '@components/layouts/LandingLayout';
import LoginLayout from '@components/layouts/LoginLayout';
import PaymentLayout from '@components/layouts/PaymentLayout';
import SubscriptionPlansLayout from '@components/layouts/SubscriptionPlansLayout';
import { ACCESS } from '@consts/consts';

export const mainRoutes = {
  main: {
    path: '/',
    exact: true,
    pageName: 'Landing',
    layout: LandingLayout,
    access: ACCESS.ALL,
    chat: true
  },
  login: {
    path: '/login',
    exact: true,
    pageName: 'Login',
    layout: LoginLayout,
    access: ACCESS.ALL,
    chat: true
  },
  register: {
    path: '/register',
    exact: true,
    pageName: 'Register',
    layout: LoginLayout,
    access: ACCESS.ALL,
    chat: true
  },
  chooseSubscriptionPlan: {
    path: '/choose-subscription-plan',
    pageName: 'SubscriptionPlan',
    layout: SubscriptionPlansLayout,
    access: ACCESS.LOGGED_IN
  },
  payment: {
    path: '/payment/:planId',
    pageName: 'Payment',
    layout: PaymentLayout,
    access: ACCESS.LOGGED_IN
  },
  kryptonimPayment: {
    exact: true,
    path: '/kryptonim/payment/',
    pageName: 'Kryptonim/Payment',
    layout: PaymentLayout,
    access: ACCESS.LOGGED_IN
  },
  kryptonimPaymentFailure: {
    exact: true,
    path: '/kryptonim/payment/failure/:transactionUuid',
    pageName: 'Kryptonim/Payment/Failure',
    layout: PaymentLayout,
    access: ACCESS.LOGGED_IN
  },
  kryptonimPaymentSuccess: {
    exact: true,
    path: '/kryptonim/payment/success/:transactionUuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
    pageName: 'Kryptonim/Payment/Success',
    layout: PaymentLayout,
    access: ACCESS.LOGGED_IN
  }
};
