import { call, put, select } from '@redux-saga/core/effects';

import UserDataActions from '@redux/Fitney2.0/UserData/userData';
import AppStatusActions from '@redux/Fitney2.0/AppStatus/appStatus';
import { RootReducersKeys } from '@redux/types';
import { UserDataReducer } from '@redux/Fitney2.0/UserData/types';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import authSelectors from '@selectors/authSelectors';

export function* getLoggedInUserData(userAPI: any): unknown {
  const token = yield select(authSelectors.token);

  const userResponse = yield call(userAPI.fetchUserData, `Bearer ${token}`);
  if (userResponse.ok) {
    const { language } = userResponse?.data;
    yield put(
      UserDataActions.getLoggedInUserDataSuccess({
        ...userResponse.data,
        dataFetched: true
      })
    );
    yield put(AppStatusActions.setLanguage(language));

    const stringedDataObject: UserDataReducer = userResponse.data;

    localeStoreHelpers.setData([
      {
        key: RootReducersKeys.appStatus,
        stringedData: JSON.stringify({ language: stringedDataObject.language })
      },
      {
        key: RootReducersKeys.userData,
        stringedData: JSON.stringify({
          gender: stringedDataObject.gender,
          language: stringedDataObject.language
        })
      }
    ]);
  } else {
    yield put(UserDataActions.getLoggedInUserDataFailure('error'));
  }
}
