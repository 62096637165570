import React from 'react';
import { Helmet } from 'react-helmet';
// @ts-ignore
import { compose } from 'redux';
import { connect } from 'react-redux';
// @ts-ignore
import { Switch, Route } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { withSnackbar, ProviderContext, OptionsObject } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@styles/base/fontface.scss';
import '@styles/base/global.scss';
import '@styles/base/icons.css';

import routes from '@routes';
import AppStatusActions, { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import StatsActions from '@redux/Fitney2.0/Stats/stats';
import { RootReducersKeys, RootReducerStateProps } from '@redux/types';
import { SnackBarDataProps } from '@redux/Fitney2.0/types';
import { localeStoreHelpers } from '@hooks/useLocalStore';
import WebViewRoutes from '@containers/app/WebViewRoutes';
import ErrorPageLayout from '@components/layouts/ErrorPageLayout/ErrorPageLayout';
import Auth from './Auth';
import _ from "lodash";
import Badge from "@components/Badge/Badge";
import { APP_EVENT } from '@consts/staticData';

interface AppProps extends ProviderContext {
  isMobile: boolean;
  notifications: null | SnackBarDataProps;
  isMobileAction: (value: boolean) => void;
  lang: any;
  logEventToBackend: (eventName: APP_EVENT) => void;
}

class App extends React.Component<AppProps, any> {
  checkIfMobile = debounce(() => {
    if (this.props.isMobile !== window.innerWidth <= 1024) {
      this.props.isMobileAction(window.innerWidth <= 1024);
    }
  }, 100);

  componentDidMount() {
    localeStoreHelpers.validationData();
    window.addEventListener('resize', this.checkIfMobile);
    this.checkIfMobile();
    this.props.logEventToBackend(APP_EVENT.appStarted);
  }

  componentDidUpdate(prevProps: AppProps, prevState: any, snapshot: any) {
    if (this.props.notifications && prevProps.notifications !== this.props.notifications) {
      const isMessageString = typeof this.props.notifications.message === 'string';
      const isAdditionalErrorInfo = this.props.notifications.errors != null && this.props.notifications.errors.length > 0;
      let message = isMessageString ? this.props.notifications.message : '';
      let isConnectionError = false;
      switch (this.props.notifications.message) {
        case 'The given data was invalid.':
          message = 'errors.dataInvalid';
          break;
        case 'Connection error.':
          message = 'errors.connection';
          isConnectionError = true;
          break;
      }

      let rest: string[] = []
      this.props.notifications.errors?.forEach((error: any) => {
        const result = _.find(error, obj => obj.message?.length > 0);
        if(result) rest.push(result.message);
      });

      const snackbarMessage = (
        <div>
          {isMessageString && (
            <div onClick={() => this.props.closeSnackbar()} style={{ position: 'relative' }}>
              <Badge type={'error'}>
                {message}
              </Badge>

              <ul>
                { rest.map((message: string, index: number) => {
                  return (
                      <li key={index}>{message}</li>
                  )
                })}
              </ul>
            </div>
          )}
          {!isConnectionError && !isAdditionalErrorInfo && (
            <a href={routes.support.path}>
              <FormattedMessage id={'errors.contactSupport'} description={'errors.contactSupport'} />
            </a>
          )}
        </div>
      );

      const snackbarConfig: OptionsObject = {
        variant: this.props.notifications.type,
        preventDuplicate: true
      };
      this.props.enqueueSnackbar(snackbarMessage, snackbarConfig);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Fitney - Work out From Home or Anywhere You Want.</title>
          <link rel="canonical" href={process.env.REACT_APP_URL} />
        </Helmet>
        <Switch>
          {Object.entries(routes).map(([key, value], i) => {
            return <Auth {...value} key={`${key}-${i}`} />;
          })}
          <Route path="/web-view" children={({ location }: any) => <WebViewRoutes location={location} />} />
          <Route path="*">
            <ErrorPageLayout />
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state: RootReducerStateProps) => ({
  isMobile: state[RootReducersKeys.appStatus]?.isMobile,
  notifications: state[RootReducersKeys.notifications]?.snackBarData,
  lang: appStatusSelectors.language(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  isMobileAction: (isMobile: any) => dispatch(AppStatusActions.isMobile(isMobile)),
  logEventToBackend: (eventName: APP_EVENT) => dispatch(StatsActions.logEventToBackend(eventName))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withSnackbar)(App);
