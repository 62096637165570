import { call, put, select } from '@redux-saga/core/effects';

import RecipesAction from '@redux/Fitney2.0/Diet/recipes';
import { recipeSelectors } from '@redux/Fitney2.0/Diet/recipes';

export function* requestForRecipes(): any {
  try {
    const activeCategories = yield select(recipeSelectors.getActiveRecipeCategories);
    const activeCategoriesIds = activeCategories.map((category: any) => category.id);
    const activeCategoriesNames = activeCategories.map((category: any) => category.name);

    for (const id in activeCategoriesIds) {
      if (activeCategoriesIds.hasOwnProperty(id)) {
        yield put(RecipesAction.fetchRecipesByCategoryRequest(activeCategoriesIds[id], activeCategoriesNames[id]));
      }
    }
  } catch (e) {
    console.log('reguestForRecipes saga error: ', e);
  }
}

export function* getRecipesByCategory(api: any, action: any): any {
  const { categoryId, activeCategoriesName } = action;

  try {
    const response = yield call(api.getRecipesByCategory, categoryId);
    if (response.ok) {
      yield put(
        RecipesAction.fetchRecipesByCategorySuccess({
          id: categoryId,
          category: activeCategoriesName,
          categoryData: [response.data]
        })
      );
    } else {
      yield put(RecipesAction.fetchRecipesByCategoryFailure(response.data.errors));
    }
  } catch (e) {
    yield put(RecipesAction.fetchRecipesByCategoryFailure(e));
  }
}

export function* getRecipes(api: any, action: any): any {
  try {
    const response = yield call(
      api.getRecipes,
      action.page ? action.page : 1,
      action.per_page ? action.per_page : 10,
      action.query ? action.query : '',
      action.filters ? action.filters : ''
    );
    if (response.ok) {
      yield put(RecipesAction.fetchRecipesSuccess(response.data, action.page));
    } else {
      yield put(RecipesAction.fetchRecipesFailure(response.data.errors));
    }
  } catch (e) {
    yield put(RecipesAction.fetchRecipesFailure(e));
  }
}

export function* getRecipeCategories(api: any, action: any): any {
  try {
    const response = yield call(api.getRecipeCategories);
    if (response.ok) {
      yield put(RecipesAction.fetchRecipeCategoriesSuccess(response.data));
      yield requestForRecipes();
    } else {
      yield put(RecipesAction.fetchRecipeCategoriesFailure(response.data.errors));
    }
  } catch (e) {
    yield put(RecipesAction.fetchRecipeCategoriesFailure(e));
  }
}

export function* getRecipeById(api: any, action: any): any {
  try {
    const { recipeId } = action;

    const response = yield call(api.getRecipeById, recipeId);

    if (response.ok) {
      yield put(RecipesAction.fetchRecipeToDisplaySuccess(response.data));
      yield requestForRecipes();
    } else {
      yield put(RecipesAction.fetchRecipeToDisplayFailure(response.data.errors));
    }
  } catch (e) {
    yield put(RecipesAction.fetchRecipeToDisplayFailure(e));
  }
}

export function* getRecipe(api: any, action: any): any {
  try {
    const { recipeId } = action;

    const response = yield call(api.getRecipeById, recipeId);

    if (response.ok) {
      yield put(RecipesAction.fetchRecipeSuccess(response.data));
    } else {
      yield put(RecipesAction.fetchRecipeFailure(response.data.errors));
    }
  } catch (e) {
    yield put(RecipesAction.fetchRecipeFailure(e));
  }
}
