import React from 'react';
import { connect } from 'react-redux';

import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import RouteActions from '@redux/route/routeActions';
import FitneyFooter from '@containers/FitneyFooter';
import Logo from '@components/UIKit/Logo';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import styles from './DocsLayout.module.scss';

const DocsLayout = ({ isMobile, children }: any) => {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.header}>
          <Logo />

          <LanguageDropdown navigation />
        </div>
        <main className={styles.content}>{children}</main>
        {!isMobile && <FitneyFooter />}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  isMobile: appStatusSelectors.isMobile(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  skipQuestionnaireAction: () => dispatch(RouteActions.skipQuestionnaireAction()),
  logoClicked: () => dispatch(RouteActions.logoClicked())
});

export default connect(mapStateToProps, mapDispatchToProps)(DocsLayout);
