import React from 'react';

const SlimBellyIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg width={size} height={size} viewBox="0 0 77.32 77.32">
        <path fill={color} d="M13.78,38.39H4.27a1,1,0,1,1,0-2h9.51a1,1,0,1,1,0,2Z" />
        <path
          fill={color}
          d="M8.73,43.44A1,1,0,0,1,8,41.73l4.35-4.34L8,33a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l5.06,5.06a1,1,0,0,1,.29.71,1,1,0,0,1-.29.7L9.43,43.15A1,1,0,0,1,8.73,43.44Z"
        />
        <path
          fill={color}
          d="M18,67.3a1,1,0,0,1-1-.74c-2.36-8.82,0-17.35,2.25-25.6,2.57-9.38,5.23-19.07,1.27-29.59a1,1,0,0,1,.59-1.29,1,1,0,0,1,1.28.59c4.19,11.12,1.44,21.13-1.21,30.82C18.91,49.85,16.75,57.74,19,66a1,1,0,0,1-.71,1.22A.78.78,0,0,1,18,67.3Z"
        />
        <path
          fill={color}
          d="M38.66,60.56a1,1,0,0,1-1-.77c-2-8.31-11.3-14.52-18.28-14.52a1,1,0,0,1,0-2c7.74,0,17.94,6.59,20.22,16a1,1,0,0,1-.74,1.21A.91.91,0,0,1,38.66,60.56Z"
        />
        <path
          fill={color}
          d="M38.66,44.73c-3.35,0-9.26-.67-18.4-3.82a1,1,0,0,1-.62-1.28A1,1,0,0,1,20.91,39c8.88,3.06,14.55,3.71,17.75,3.71a1,1,0,0,1,0,2Z"
        />
        <path fill={color} d="M73,38.39h-9.5a1,1,0,0,1,0-2H73a1,1,0,0,1,0,2Z" />
        <path
          fill={color}
          d="M68.59,43.44a1,1,0,0,1-.71-.29l-5-5.06a1,1,0,0,1-.29-.7,1,1,0,0,1,.29-.71l5-5.06A1,1,0,1,1,69.3,33L65,37.39l4.35,4.34a1,1,0,0,1,0,1.42A1,1,0,0,1,68.59,43.44Z"
        />
        <path
          fill={color}
          d="M59.33,67.3a.75.75,0,0,1-.26,0A1,1,0,0,1,58.36,66c2.21-8.3,0-16.19-2.25-24.55-2.66-9.69-5.4-19.7-1.22-30.82a1,1,0,1,1,1.87.7C52.81,21.89,55.47,31.58,58,41c2.26,8.25,4.6,16.78,2.25,25.6A1,1,0,0,1,59.33,67.3Z"
        />
        <path
          fill={color}
          d="M38.66,60.56l-.24,0a1,1,0,0,1-.73-1.21c2.28-9.46,12.48-16,20.21-16a1,1,0,0,1,0,2c-7,0-16.27,6.21-18.27,14.52A1,1,0,0,1,38.66,60.56Z"
        />
        <path
          fill={color}
          d="M38.66,44.73a1,1,0,0,1,0-2c3.2,0,8.87-.65,17.74-3.71a1,1,0,0,1,1.27.61,1,1,0,0,1-.61,1.28C47.92,44.06,42,44.73,38.66,44.73Z"
        />
        <path fill={color} d="M38.66,38.39a1,1,0,0,1-1-1V37.1a1,1,0,1,1,2,0v.29A1,1,0,0,1,38.66,38.39Z" />
        <path fill={color} d="M38.66,64.73a1,1,0,0,1-1-1V59.56a1,1,0,0,1,2,0v4.17A1,1,0,0,1,38.66,64.73Z" />
      </svg>
    </React.Fragment>
  );
};

export default SlimBellyIcon;
