import React from 'react';

const AllergensIcon = () => {
  return (
    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3976 2.388L2.19307 20.0595C0.931566 22.245 2.50807 24.9765 5.03107 24.9765H25.4371C27.9601 24.9765 29.5366 22.245 28.2751 20.0595L18.0721 2.388C16.8106 0.204 13.6576 0.204 12.3976 2.388L2.19307 20.0595C0.931566 22.245 2.50807 24.9765 5.03107 24.9765H25.4371C27.9601 24.9765 29.5366 22.245 28.2751 20.0595L18.0721 2.388C16.8106 0.204 13.6576 0.204 12.3976 2.388Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9678 11.2578L19.4998 19.7898"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5007 11.2578L10.9688 19.7898"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AllergensIcon;
