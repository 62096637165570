import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  regeneratePlanRequest: [],
  regeneratePlanSuccess: [],
  regeneratePlanFailure: ['error'],
  regeneratePlanFailureConfirm: []
});

export const RegeneratePlanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Reducers ------------- */

export const regenerateRequest = (state: any) => ({
  ...state,
  fetching: true,
  error: null
});

export const regenerateSuccess = (state: any) => ({
  ...state,
  fetching: false,
  error: null
});

export const regenerateFailure = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REGENERATE_PLAN_REQUEST]: regenerateRequest,
  [Types.REGENERATE_PLAN_SUCCESS]: regenerateSuccess,
  [Types.REGENERATE_PLAN_FAILURE]: regenerateFailure,
  [Types.REGENERATE_PLAN_FAILURE_CONFIRM]: regenerateSuccess
});
