export interface IDietDishRecipesDTO {
  id: string;
  title: string;
  photo: string;
  kcal: string;
  time_required: string;
  meal_type: string;
  ratio: string;
}

export interface IDietDishRecipes {
  id: string;
  title: string;
  photo: string;
  kcal: string;
  time_required: string;
  meal_type: string;
  ratio: string;
}

class DietDishRecipe implements IDietDishRecipes {
  constructor(
    public id: string,
    public title: string,
    public photo: string,
    public kcal: string,
    public time_required: string,
    public meal_type: string,
    public ratio: string
  ) {}

  static build = (data: IDietDishRecipesDTO): IDietDishRecipes => {
    return new DietDishRecipe(
      data.id,
      data.title,
      data.photo,
      data.kcal,
      data.time_required,
      data.meal_type,
      data.ratio
    );
  };
}

export default DietDishRecipe;
