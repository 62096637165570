import React from 'react';
import colors from '@consts/colors';

const FootballIcon = ({ size = 32, color = colors.pink, opacity = 100 }: any) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21.9501C17.4953 21.9501 21.9501 17.4953 21.9501 12C21.9501 6.50482 17.4953 2.05005 12 2.05005C6.50482 2.05005 2.05005 6.50482 2.05005 12C2.05005 17.4953 6.50482 21.9501 12 21.9501Z"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.45996L8.54004 10.97L9.86004 15.04H14.14L15.46 10.97L12 8.45996Z"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.14 4.44995L12 5.79995L13.86 4.44995"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 8.46005V5.80005" stroke="#E0325F" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.28999 8.08008L6.06999 10.2501L4.23999 11.6501"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.60995 10.99L6.06995 10.25"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.20002 19.23L8.45002 17.05L6.15002 17.09"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.96995 14.8799L8.44995 17.0499"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8 19.23L15.55 17.05L17.85 17.09"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.03 14.8799L15.55 17.0499"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7101 8.08008L17.9301 10.2501L19.7601 11.6501"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.39 10.99L17.93 10.25"
      stroke="#E0325F"
      strokeWidth="1.05"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FootballIcon;
