import React from 'react';

const SevenTimesPerWeekIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.5904 17.2632C44.3584 17.7652 45.1024 18.3052 45.8184 18.8772C41.1664 25.0352 43.1264 22.7592 39.5264 27.2212"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6037 19.5112C20.4257 17.7532 12.6137 19.8412 5.3457 23.6632C9.8857 17.8272 16.6597 13.7372 24.5797 12.8012C27.8337 12.4152 31.0257 12.5932 34.0557 13.2572"
        stroke="#DFDFDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8988 37.6226C29.2188 40.1286 25.8268 40.7986 23.3208 39.1186C20.8148 37.4386 20.1468 34.0466 21.8268 31.5406C22.9968 29.7946 32.0668 21.0266 37.4588 15.8686C38.4148 14.9546 39.9388 15.9786 39.4568 17.2066C36.7348 24.1566 32.0688 35.8766 30.8988 37.6226Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SevenTimesPerWeekIcon;
