import React from 'react';
import { injectIntl } from 'react-intl';
import smoothscroll from 'smoothscroll-polyfill';

import translatedTexts from '@containers/landing/Landing.lang';

class InternalLinks extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    smoothscroll.polyfill();
  }

  elemClicked = (elem: any) => {
    elem.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });

    if (this.props.toggleMobileMenuVisibility) {
      this.props.toggleMobileMenuVisibility();
    }
  };

  render() {
    return (
      <React.Fragment>
        <li onClick={() => this.elemClicked(document.getElementById('threePillars'))}>
          {translatedTexts(this.props.intl).linkFormula}
        </li>
        <li onClick={() => this.elemClicked(document.getElementById('threeTabs'))}>
          {translatedTexts(this.props.intl).linkApp}
        </li>
        <li onClick={() => this.elemClicked(document.getElementById('trainers'))}>
          {translatedTexts(this.props.intl).linkTrainingsAndTrainers}
        </li>
        <li onClick={() => this.elemClicked(document.getElementById('community'))}>
          {translatedTexts(this.props.intl).linkCommunity}
        </li>
      </React.Fragment>
    );
  }
}

export default injectIntl(InternalLinks);
