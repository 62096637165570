import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateWeeklyIntensityRequest: ['weeklyIntensityId'],
  updateWeeklyIntensitySuccess: [],
  updateWeeklyIntensityFailure: ['error']
});

export const UpdateWeeklyIntensityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

export const requestWeeklyIntensityUpdate = (state: any) => ({ ...state, fetching: true, error: null });

export const successWeeklyIntensityUpdate = () => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_WEEKLY_INTENSITY_REQUEST]: requestWeeklyIntensityUpdate,
  [Types.UPDATE_WEEKLY_INTENSITY_SUCCESS]: successWeeklyIntensityUpdate,
  [Types.UPDATE_WEEKLY_INTENSITY_FAILURE]: failure
});
