import { createIntl, createIntlCache } from "react-intl";
import en from "@translations/en.json";
import pl from "@translations/pl.json";
import es from "@translations/es.json";

export const removeSpecialCharacters = (string: string) => {
  return string.replace(/[&\\#,+()$~%.'":*?<>{}-]/g, '');
};

export const formatStraalErrors = (errors: { [key: string]: string[] }) => {
  if (!errors) return '';
  let msg = '';
  Object.keys(errors).forEach((key: string, index: number, array: string[]) => {
    msg += errors[key].join('. ');
    if (index !== array.length - 1) {
      msg += '. ';
    }
  });
  return msg;
};


type Message = typeof en | typeof pl | typeof es;
interface Messages {
  [key: string]: Message;
}
const messages: Messages = {
  'en': en,
  'pl': pl,
  'es': es
}

export const getErrorMessage = (locale: string, error?: string): string => {
  const cache = createIntlCache();
  const intl = createIntl({locale, messages: messages[locale]}, cache); //locale and message can come from Redux or regular import
  return intl.formatMessage({
    defaultMessage:
        "Something went wrong. Please try again later.",
    id: error,
  });
}