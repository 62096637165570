import React from 'react';

const UserAvatarIcon = ({ size = 64, color }: any) => {
  return (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M8.992 0C5.62 0 2.883 2.735 2.883 6.108c0 3.373 2.736 6.107 6.11 6.107 3.372 0 6.106-2.734 6.106-6.107S12.365 0 8.992 0zm0 1.5c2.545 0 4.607 2.063 4.607 4.608 0 2.545-2.062 4.607-4.607 4.607-2.545 0-4.609-2.063-4.609-4.607S6.447 1.5 8.993 1.5zM8.992 13.466c3.112 0 6.095.955 8.666 2.727.341.234.428.701.193 1.043-.235.34-.702.427-1.044.192-2.324-1.601-5.012-2.462-7.815-2.462-2.805 0-5.492.86-7.816 2.462-.34.235-.808.149-1.043-.192-.235-.341-.149-.808.192-1.043 2.571-1.772 5.553-2.727 8.667-2.727z"
          transform="translate(-299 -414) translate(299 414) translate(3 3)"
        />
      </svg>
    </React.Fragment>
  );
};

export default UserAvatarIcon;
