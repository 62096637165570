import DietFilterRecipe, { IDietFilter } from '@models/Diet/Filter/DietFilterRecipe';
import { DIET_TYPE_SLUG } from '@consts/consts';

export interface IDietTypeDTO extends IDietFilter<DIET_TYPE_SLUG> {}

export interface IDietType extends IDietFilter<DIET_TYPE_SLUG> {}

class DietType extends DietFilterRecipe implements IDietType {
  constructor(public id: number, public name: string, public slug: DIET_TYPE_SLUG) {
    super(id, name, slug);
  }

  static build = (data: IDietTypeDTO): IDietType => new DietType(data.id, data.name, data.slug as DIET_TYPE_SLUG);
}

export default DietType;
