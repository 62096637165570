import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchWorkoutTypeDataRequest: [],
  fetchWorkoutTypeDataSuccess: ['workout'],
  fetchWorkoutTypeDataFailure: ['error']
});

export const WorkoutTypeDataTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  data: []
});

/* ------------- Selectors ------------- */

export const workoutTypeDataSelectors = {
  getWorkoutTypeData: (state: any) => state.workoutType.data,
  isFetching: (state: any) => state.workoutType.fetching
};

/* ------------- Reducers ------------- */

export const requestFetchWorkoutTypeData = (state: any) => ({ ...state, fetching: true, error: null });

export const successFetchWorkoutTypeData = (state: any, action: any) => {
  const { workout } = action;

  return {
    fetching: false,
    error: null,
    data: workout,
    timestamp: new Date().getTime()
  };
};

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_WORKOUT_TYPE_DATA_REQUEST]: requestFetchWorkoutTypeData,
  [Types.FETCH_WORKOUT_TYPE_DATA_SUCCESS]: successFetchWorkoutTypeData,
  [Types.FETCH_WORKOUT_TYPE_DATA_FAILURE]: failure
});
