import React from 'react';

const PlanTimeIcon = () => {
  return (
    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M14.8 42.1H3.5C2.1 42.1 1 41 1 39.7V8.90002C1 7.50002 2.1 6.40002 3.5 6.40002H4.8"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2998 6.40002H30.5998C31.9998 6.40002 33.0998 7.50002 33.0998 8.90002V19.8"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9998 3.4C18.9998 3.3 18.9998 3.1 18.9998 3C18.9998 1.9 18.0998 1 16.9998 1C15.8998 1 14.9998 1.9 14.9998 3C14.9998 3.1 14.9998 3.3 14.9998 3.4H8.2998V8.7H25.6998V3.4H18.9998Z"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4002 16.3L10.6002 20.1L8.7002 18.2001"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4002 28.7L10.6002 32.5L8.7002 30.5"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2998 18.2H24.8998"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.8998 48.2C37.5825 48.2 42.9998 42.7826 42.9998 36.1C42.9998 29.4174 37.5825 24 30.8998 24C24.2172 24 18.7998 29.4174 18.7998 36.1C18.7998 42.7826 24.2172 48.2 30.8998 48.2Z"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.9004 29.6V36.1L36.4004 38.4"
          stroke="#E0325F"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="44.1" height="49.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlanTimeIcon;
