import React from 'react';

const TrainingPlanIcon = ({ size = 84 }: { size?: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84 42C84 65.1958 65.1958 84 42 84C18.8051 84 0 65.1958 0 42C0 18.8033 18.8051 0 42 0C65.1958 0 84 18.8033 84 42"
        fill="#F4F4F4"
      />
      <path
        d="M33.2732 58.6306H21.9702C20.6012 58.6306 19.4922 57.5216 19.4922 56.1516V25.3676C19.4922 23.9996 20.6012 22.8906 21.9702 22.8906H23.2722"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8093 22.8906H49.0943C50.4633 22.8906 51.5733 23.9996 51.5733 25.3686V36.2506"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4918 19.9042C37.5188 19.7712 37.5348 19.6342 37.5348 19.4952C37.5348 18.3882 36.6378 17.4922 35.5318 17.4922C34.4268 17.4922 33.5298 18.3882 33.5298 19.4952C33.5298 19.6342 33.5458 19.7712 33.5728 19.9042H26.8218V25.2232H44.2428V19.9042H37.4918Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9352 32.7969L29.1192 36.6139L27.1492 34.6439"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9352 45.1797L29.1192 48.9957L27.1492 47.0267"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6744 41.0938H41.0434C39.7734 41.0938 38.7434 42.1227 38.7434 43.3937V61.1178C38.7434 62.3888 39.7734 63.4177 41.0434 63.4177H60.6744C61.9444 63.4177 62.9744 62.3888 62.9744 61.1178V43.3937C62.9744 42.1227 61.9444 41.0938 60.6744 41.0938Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0427 56.0242V48.4912C48.0427 47.8072 48.8137 47.4082 49.3717 47.8022L54.6997 51.5692C55.1747 51.9052 55.1747 52.6102 54.6997 52.9462L49.3717 56.7122C48.8137 57.1072 48.0427 56.7082 48.0427 56.0242Z"
        stroke="#E0325F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M38.7439 34.707H43.4219" stroke="#E0325F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TrainingPlanIcon;
