import { SnackbarProviderProps } from 'notistack';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';

import styles from './Snackbar.module.scss';

export const providerConfig: Omit<SnackbarProviderProps, 'children'> = {
  classes: { containerRoot: styles.root },
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  iconVariant: { error: <ErrorOutlinedIcon classes={{ root: styles.error_icon }} /> },
  autoHideDuration: 3000
};
