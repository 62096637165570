import React from 'react';

import Logo from '@components/UIKit/Logo';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <Logo />
      </div>
    </div>
  );
};

export default Header;
