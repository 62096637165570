import { createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userLoggedInAction: [''],
  userLoggedOutAction: [''],
  userRegisteredAction: ['trainingsQuestionnaireData', 'dietsQuestionnaireData'],
  questionnaireProceededAction: [''],
  userPasswordResetedAction: [''],
  changedTrainingAction: [''],
  subscriptionCreatedAction: ['isNewUser', 'paymentPlanName'],
  dailyWorkoutChosenAction: [''],
  hasNoPermission: ['permission'],
  userIsNotLoggedIn: [''],
  emailNotVerifiedError: [''],
  noActiveSubscriptionError: [''],
  questionnaireNotCompletedError: [''],
  noActiveDailyWorkoutError: [''],
  refreshTokenFailed: [''],
  supportLinkClicked: [''],
  userAlreadySubscribed: [''],
  signUpButtonClicked: [''],
  loginButtonClicked: [''],
  logoClicked: [''],
  navbarTrainingClicked: ['trainingPlanId'],
  skipQuestionnaireAction: [''],
  logOutAction: [''],
  logOutActionNoRedirect: [''],
  trainingPlansLinkClicked: [''],
  dailyWorkoutLinkClicked: [''],
  dietPlanLinkClicked: [''],
  changeDailyWorkoutAction: [''],
  openTrainingPlanAction: ['trainingPlanId'],
  chooseSubscriptionPlanAction: [''],
  trainingLoaded: ['trainingId'],
  trainingPlanLoaded: ['trainingPlanId'],
  articleClicked: ['articleId'],
  articleVisited: ['articleId'],
  shopPurchaseSucceeded: [],
  shopPromoPurchaseSucceeded: [],
  openRecipeAction: ['recipeId', 'ratio']
});

export const RouteActionsTypes = Types;
export default Creators;
