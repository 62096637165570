import store from '../store';
import RouteActions from '@redux/route/routeActions';
import buildClient, {buildRawUrl} from '@services/api';
import {API_CLIENT_TYPES, IApiBuilderProps} from "@services/types";

const config = {
  baseURL: process.env.REACT_APP_DIET_API_URL as string,
  defaultApiVersion: process.env.REACT_APP_DIET_API_URL_DEFAULT_VERSION as string,
  apiUrlSuffix: process.env.REACT_APP_DIET_API_URL_SUFFIX as string,
  responseType: 'json',
  cookie: {
    amount: 30,
    unit: 'minutes'
  }
};

const monitors = (response: any) => {
  switch (response.status) {
      case 403:
          switch (response.data.message) {
              case 'Your email address is not verified.':
                  store.dispatch(RouteActions.emailNotVerifiedError());
                  break;
              case 'No active subscription.':
                  store.dispatch(RouteActions.noActiveSubscriptionError());
                  break;
              case 'Questionnaire is not completed.':
                  store.dispatch(RouteActions.questionnaireNotCompletedError());
                  break;
          }
          break;
  }
};

export const buildUrl = (props: Omit<IApiBuilderProps, 'config'>) => {
    return buildRawUrl({config, ...props});
};

const apiClient = {client: buildClient({config, monitors}), buildUrl, type: API_CLIENT_TYPES.DIET};

export default apiClient;