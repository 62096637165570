import React from 'react';
import { connect } from 'react-redux';

import { isFetching } from '@selectors/helperSelectors';
import styles from './PlayerLayout.module.scss';

const PlayerLayout = ({ children }: any) => {
  return (
    <div className={styles.playerWrapper}>
      <main className={styles.mainComponentWrapper}>{children}</main>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  fetching: isFetching(state),
  isMobile: state.appStatus.isMobile
});

export default connect(mapStateToProps)(PlayerLayout);
