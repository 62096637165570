import { createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  goToRegister: ['queryParams'],
  goToHome: ['queryParams'],
  goToDietPlan: ['queryParams'],
  goToLogin: ['queryParams'],
  goToDailyWorkout: ['queryParams'],
  goToChooseSubscriptionPlan: ['queryParams'],
  goToTrainingPlans: ['queryParams'],
  goToTrainingPlan: ['trainingPlanId', 'queryParams'],
  goToSupport: ['queryParams'],
  goToBlog: ['queryParams'],
  goToArticle: ['articleId', 'queryParams'],
  goToPromoPurchaseSuccess: ['queryParams'],
  goToRecipe: ['recipeId', 'ratio', 'queryParams'],
  goToResetPasswordSuccess: ['queryParams'],
  goToQuestionnaireTraining: ['queryParams'],
  goToThankYou: []
});

export const RouteRedirectsTypes = Types;
export default Creators;
