import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import routes from '@routes';
import { appStatusSelectors } from '@redux/Fitney2.0/AppStatus/appStatus';
import { userDataSelectors } from '@redux/Fitney2.0/UserData/userData';
import { CompanyContext } from '@providers/CompanyProvider';
import { goToApp } from '@helpers/general/landingHelpers';
import renderCurrentYear from '@helpers/general/renderCurrentYear';
import LanguageDropdown from '@components/UIKit/LanguageDropdown/LanguageDropdown';
import styles from './FitneyFooterDarkAffilliate.module.css';

class FitneyFooterDarkAffilliate extends Component<any> {
  static contextType = CompanyContext;

  goMagazine = () => {
    goToApp(routes.blog.path, process.env.REACT_APP_URL);
  };

  goSupport = () => {
    goToApp(routes.support.path);
  };

  goToTerms = () => {
    goToApp('/terms-of-use');
  };

  goToPrivacyPolicy = () => {
    goToApp('/privacy-policy');
  };

  goToBillingPolicy = () => {
    goToApp('/billing-policy');
  };

  goToShop = () => {
    goToApp('/shop/pack-1');
  };

  goTo = (e: any, url: any) => {
    e.preventDefault();
    switch (url) {
      case routes.blog.path:
        if (this.props.pathInfo.pathname !== url) {
          this.goMagazine();
        }
        break;
      case routes.support.path:
        if (this.props.pathInfo.pathname !== url) {
          this.goSupport();
        }
        break;
      case '/terms-of-use':
        if (this.props.pathInfo.pathname !== url) {
          this.goToTerms();
        }
        break;
      case '/privacy-policy':
        if (this.props.pathInfo.pathname !== url) {
          this.goToPrivacyPolicy();
        }
        break;
      case '/billing-policy':
        if (this.props.pathInfo.pathname !== url) {
          this.goToBillingPolicy();
        }
        break;
      case '/shop/pack-1':
        if (this.props.pathInfo.pathname !== url) {
          this.goToShop();
        }
        break;
      default:
        break;
    }
  };

  render() {
    const companyData = this.context;
    return (
      <div className={styles.footerDarkTheme}>
        <footer className={styles.main}>
          <div className={`d-flex ${styles.footerTop}`}>
            <div className={`d-flex flex-row ${styles.logoAndDropdownWrapper}`}>
              <img src={`${process.env.PUBLIC_URL}/images/footer/logoFitneyWhite.svg`} alt="Logo Fitney" />
              {this.props.isMultiLang && (
                <div className={styles.dropdownWrapper}>
                  <LanguageDropdown isDarkBackground={true} />
                </div>
              )}
            </div>
            <div className={styles.socialMedia}>
              <span className={styles.followUs}>
                <FormattedMessage
                  id="footer-affiliate.follow-us"
                  defaultMessage="Follow us"
                  description="Footer affiliate - follow us text"
                />
              </span>
              <a href="https://www.facebook.com/Fitney.co/">
                <img src={`${process.env.PUBLIC_URL}/images/footer/logoFbWhite.svg`} alt="Logo Facebook" />
              </a>
              <a href="https://www.instagram.com/fitney.official/">
                <img src={`${process.env.PUBLIC_URL}/images/footer/logoInstagramWhite.svg`} alt="Logo Instagram" />
              </a>
            </div>
          </div>
          <div className={styles.footerBottom}>
            <div className={styles.linksWrapper}>
              <span className={styles.listTitle}>Fitney.co</span>
              <ul>
                <li>
                  <a
                    onClick={(e: any) => {
                      this.goTo(e, '/shop/pack-1');
                    }}
                    href={`${process.env.REACT_APP_URL}/shop/pack-1`}
                  >
                    <FormattedMessage
                      id="landing-ver-2.footer.link-shop"
                      defaultMessage="Shop"
                      description="Footer - link to shop"
                    />
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e: any) => {
                      this.goTo(e, routes.blog.path);
                    }}
                    href={`${process.env.REACT_APP_URL}${routes.blog.path}`}
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e: any) => {
                      this.goTo(e, routes.support.path);
                    }}
                    href={`${process.env.REACT_APP_URL}` + routes.support.path}
                  >
                    <FormattedMessage
                      id="landing-ver-2.footer.link-support"
                      defaultMessage="Support"
                      description="Footer - link to support"
                    />
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e: any) => {
                      this.goTo(e, '/terms-of-use');
                    }}
                    href={`${process.env.REACT_APP_URL}/terms-of-use`}
                  >
                    <FormattedMessage
                      id="landing-ver-2.footer.link-terms"
                      defaultMessage="Terms of use"
                      description="Footer - link to terms of use"
                    />
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e: any) => {
                      this.goTo(e, '/privacy-policy');
                    }}
                    href={`${process.env.REACT_APP_URL}/privacy-policy`}
                  >
                    <FormattedMessage
                      id="landing-ver-2.footer.link-privacy-policy"
                      defaultMessage="Privacy Policy"
                      description="Footer - link to privacy policy"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <span className={styles.listTitle}>
                <FormattedMessage
                  id="footer-affiliate.contact"
                  defaultMessage="Contact"
                  description="Footer affiliate - contact"
                />
              </span>
              <a className={styles.emailLink} href="mailto:support@fitney.co">
                support@fitney.co
              </a>
              <p className={styles.address}>
                {companyData.entity}
                <br />
                {companyData.address}
              </p>
            </div>
            <div>
              <span className={styles.listTitle}>
                <FormattedMessage
                  id="footer-affiliate.partners"
                  defaultMessage="Let's be partners"
                  description="Footer affiliate - partners"
                />
              </span>
              <p className={styles.promoteText}>
                <FormattedMessage
                  id="footer-affiliate.promote"
                  defaultMessage="Do you want to promote Fitney?{break}Become an affiliate: {email}"
                  description="Footer affiliate - promote"
                  values={{
                    break: <br />,
                    email: (
                      <a className={styles.emailLink} href="mailto:affiliate@fitney.co">
                        affiliate@fitney.co
                      </a>
                    )
                  }}
                />
              </p>
            </div>
            <div className={styles.appLinksWrapper}>
              <span className={styles.listTitle}>
                <FormattedMessage
                  id="footer-affiliate.download-app"
                  defaultMessage="Download Official App"
                  description="Footer affiliate - download app"
                />
              </span>
              <div className={styles.appStoresLinks}>
                <a
                  href="https://apps.apple.com/us/app/fitney-fitness-yoga/id1476385818"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/footer/logoAppStore.svg`} alt="Logo App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=co.fitney"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/footer/logoGooglePlay.svg`} alt="Logo Google Play" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.copyright}>
            <FormattedMessage
              id="landing-ver-2.footer.copyright"
              defaultMessage="Copyright @ Fitney"
              description="Footer - copyright"
            />
            {renderCurrentYear({ dot: true })}
            <FormattedMessage
              id="landing-ver-2.footer.rights-reserved"
              defaultMessage="All rights reserved"
              description="Footer - rights reserved"
            />
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isMobile: appStatusSelectors.isMobile(state),
  isMultiLang: userDataSelectors.isMultiLang()
});

export default connect(mapStateToProps)(FitneyFooterDarkAffilliate);
