import { useRef, lazy } from 'react';

const useLazy = (name: any, callToImport: any) => {
  const lazyComponentsStore: any = useRef({}).current;
  if (!lazyComponentsStore[name]) {
    lazyComponentsStore[name] = lazy(callToImport);
  }
  return lazyComponentsStore[name];
};

export default useLazy;
