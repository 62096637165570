import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  emptyTrainingQuestionnaireData: [],
  addTrainingQuestionnaireData: ['data'],
  validateTrainingQuestionnaireRequest: ['data'],
  validateTrainingQuestionnaireSuccess: ['data'],
  validateTrainingQuestionnaireFailure: ['error'],
  proceedTrainingQuestionnaireRequest: [],
  proceedTrainingQuestionnaireSuccess: [],
  proceedTrainingQuestionnaireFailure: ['error'],
  cleanQuestionnaireError: [],
  setTrainingQuestionnaireSkipped: [],
  unsetTrainingQuestionnaireSkipped: []
});

export const TrainingQuestionnaireTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  data: null
});

/* ------------- Selectors ------------- */

export const TrainingQuestionnaireSelectors = {
  getError: (state: any) => (state.trainingQuestionnaire.error ? state.trainingQuestionnaire.error.message : null),
  getTrainingQuestionnaireData: (state: any) => state.trainingQuestionnaire.data
};

/* ------------- Reducers ------------- */

export const questionnaireDataEmpty = (state: any) => ({ ...state, fetching: true, error: null, data: null });

export const addQuestionnaireData = (state: any, action: any) => {
  const { data } = action;
  const mergedData = { ...state.data, ...data };

  return { ...state, fetching: true, error: null, data: mergedData };
};

export const requestValidateQuestionnaire = (state: any) => ({ ...state, fetching: true, error: null });
export const successValidateQuestionnaire = (state: any, action: any) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    data
  };
};

export const requestProceedQuestionnaire = (state: any) => ({ ...state, fetching: true, error: null });
export const successProceedQuestionnaire = (state: any) => ({ ...state, fetching: false, error: null });
export const failureProceedQuestionnaire = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error
});
export const cleanError = (state: any) => ({ ...state, fetching: false, error: null });

export const setTrainingQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: true });
export const unsetTrainingQuestionnaireSkippedRequest = (state: any) => ({ ...state, skipped: false });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EMPTY_TRAINING_QUESTIONNAIRE_DATA]: questionnaireDataEmpty,
  [Types.ADD_TRAINING_QUESTIONNAIRE_DATA]: addQuestionnaireData,
  [Types.VALIDATE_TRAINING_QUESTIONNAIRE_REQUEST]: requestValidateQuestionnaire,
  [Types.VALIDATE_TRAINING_QUESTIONNAIRE_SUCCESS]: successValidateQuestionnaire,
  [Types.VALIDATE_TRAINING_QUESTIONNAIRE_FAILURE]: failureProceedQuestionnaire,
  [Types.PROCEED_TRAINING_QUESTIONNAIRE_REQUEST]: requestProceedQuestionnaire,
  [Types.PROCEED_TRAINING_QUESTIONNAIRE_SUCCESS]: successProceedQuestionnaire,
  [Types.PROCEED_TRAINING_QUESTIONNAIRE_FAILURE]: failureProceedQuestionnaire,
  [Types.CLEAN_QUESTIONNAIRE_ERROR]: cleanError,
  [Types.SET_TRAINING_QUESTIONNAIRE_SKIPPED]: setTrainingQuestionnaireSkippedRequest,
  [Types.UNSET_TRAINING_QUESTIONNAIRE_SKIPPED]: unsetTrainingQuestionnaireSkippedRequest
});
