import React from 'react';
import colors from '@consts/colors';
import { IconProperties } from '@models/IconProperties';

const VegetarianDietIcon = ({ size = 51, color = colors.pink, opacity = 100 }: IconProperties) => {
  return (
    <svg
      opacity={opacity / 100}
      width={size}
      height={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.813 41.4194C16.813 41.4194 18.331 43.1014 24.717 43.1014"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79736 25.8472C7.79736 35.3172 15.5754 34.7632 16.7634 41.4192"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1412 25.8472C42.1412 35.3172 34.3632 34.7632 33.1772 41.4192"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1273 41.4194C33.1273 41.4194 31.1053 43.1014 24.7173 43.1014"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1414 25.8472C42.1414 26.9932 34.4534 29.4052 24.9694 29.4052C15.4874 29.4052 7.79736 26.9932 7.79736 25.8472"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69985 21.3218C9.92585 20.8998 10.0599 20.3138 10.0739 19.8258C10.1079 18.8438 9.46585 18.4038 8.64585 18.5158C7.49985 18.6738 6.55185 18.9118 5.79785 17.6698C5.47785 17.1438 5.08985 16.3398 5.18985 15.6758C5.51385 13.5018 7.42385 11.0738 9.40385 10.8078C10.5439 10.6538 12.1559 10.8098 12.8399 12.0158C12.9359 12.1818 13.0119 12.3658 13.1459 12.4978C13.4479 12.7978 13.9079 12.7078 14.3099 12.7158"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0825 24.9018C19.5805 24.0238 19.4285 22.8838 18.2505 22.8318C17.7485 22.8078 17.2905 23.0478 16.7885 23.0538C16.2305 23.0618 15.6665 22.9038 15.1925 22.6118C14.0105 21.8918 14.8945 20.9938 15.2905 20.0958C15.7865 18.9638 15.8505 17.9278 16.9965 17.1738C17.6725 16.7298 18.5545 16.5218 19.3605 16.6078C19.7685 16.6498 20.2005 16.7678 20.5325 17.0238C20.8585 17.2738 20.9905 17.7098 21.4085 17.8298C21.6865 17.9078 21.9805 17.8758 22.2685 17.8638C23.3545 17.8258 24.2405 18.7298 24.2925 19.7958C24.3365 20.6598 23.9025 21.0498 23.6225 21.7938C23.4905 22.1458 23.4605 22.5538 23.6305 22.8878C23.9905 23.5958 26.1505 23.7678 25.6425 24.8858"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7112 23.898C38.2892 23.636 38.1692 23.192 38.3852 22.7C38.4552 22.54 38.5792 22.358 38.8212 22.23C38.9952 22.136 39.1952 22.09 39.3872 22.046C40.5852 21.774 41.8452 21.56 42.8732 20.868C43.5172 20.434 43.6512 19.478 43.4552 18.776C42.9632 17.022 41.0092 18.996 40.1132 19.06C38.0992 19.204 37.6212 17.718 35.3572 19.292C34.7872 19.69 34.6492 21.04 33.9892 21.254C33.1832 21.512 31.8872 20.61 31.1492 21.022C30.4732 21.398 30.0092 22.446 30.3372 23.194C30.5772 23.734 32.0872 23.928 31.7792 24.592"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4731 13.3334C22.2791 13.5194 23.0091 12.6754 23.0871 11.8514C23.1671 11.0274 22.8231 10.2294 22.6111 9.4294C22.5151 9.0574 22.4431 8.6814 22.4031 8.2994C22.3831 8.1294 22.3711 7.9514 22.4491 7.7974C22.5531 7.5914 22.7911 7.4914 23.0111 7.4094C23.6471 7.1694 24.4331 6.9514 24.9511 7.3934C25.2411 7.6434 25.3551 8.0414 25.5991 8.3374C26.2251 9.0874 27.4051 8.9314 28.3431 8.6554C29.2791 8.3814 30.3851 8.0834 31.1551 8.6854C31.3971 8.8734 31.5831 9.1654 31.5451 9.4674C31.4851 9.9554 30.9231 10.1994 30.6651 10.6174C30.3511 11.1254 30.5631 11.8294 31.0031 12.2314C31.4411 12.6354 32.0491 12.7994 32.6391 12.8934C33.5191 13.0354 34.4211 13.0494 35.2971 13.2154C36.1771 13.3794 37.0591 13.7174 37.6591 14.3794"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VegetarianDietIcon;
