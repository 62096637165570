import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteSubscriptionRequest: [],
  deleteSubscriptionSuccess: [],
  deleteSubscriptionFailure: ['error']
});

export const DeleteSubscriptionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Selectors ------------- */

// export const anySelectors = {
//     selectSomething: (state: any) => state.logIn.something
// }

/* ------------- Reducers ------------- */

export const requestDeleteSubscription = (state: any) => ({ ...state, fetching: true, error: null });

export const successDeleteSubscription = (state: any, action: any) => ({ fetching: false, error: null });

export const failure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_SUBSCRIPTION_REQUEST]: requestDeleteSubscription,
  [Types.DELETE_SUBSCRIPTION_SUCCESS]: successDeleteSubscription,
  [Types.DELETE_SUBSCRIPTION_FAILURE]: failure
});
