import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateLanguageRequest: ['language', 'getUserData', 'regeneratePlan'],
  updateLanguageSuccess: [],
  updateLanguageFailure: ['error']
});

export const UpdateLanguageTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null
});

/* ------------- Reducers ------------- */
export const request = (state: any) => ({ ...state, fetching: true, error: null });

export const requestSuccess = () => ({ fetching: false, error: null });

export const requestFailure = (state: any, action: any) => ({ ...state, fetching: false, error: action.error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_LANGUAGE_REQUEST]: request,
  [Types.UPDATE_LANGUAGE_SUCCESS]: requestSuccess,
  [Types.UPDATE_LANGUAGE_FAILURE]: requestFailure
});
