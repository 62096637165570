import TrainingAuthor, { ITrainingAuthor, ITrainingAuthorDTO } from '@models/Training/TrainingAuthor';

export interface ITrainingsUserPlanDTO {
  id: string;
  author: ITrainingAuthorDTO;
  challenge: boolean;
  duration: number;
  enabled: boolean;
  kcal: number;
  lang: string;
  name: string;
}

export interface ITrainingsUserPlan {
  id: string;
  author: ITrainingAuthor;
  challenge: boolean;
  duration: number;
  enabled: boolean;
  kcal: number;
  lang: string;
  name: string;
}

class TrainingsUserPlan implements ITrainingsUserPlan {
  constructor(
    public id: string,
    public author: ITrainingAuthor,
    public challenge: boolean,
    public duration: number,
    public enabled: boolean,
    public kcal: number,
    public lang: string,
    public name: string
  ) {}

  static build = (data: ITrainingsUserPlanDTO): ITrainingsUserPlan =>
    new TrainingsUserPlan(
      data.id,
      TrainingAuthor.build(data.author),
      data.challenge,
      data.duration,
      data.enabled,
      data.kcal,
      data.lang,
      data.name
    );
}

export default TrainingsUserPlan;
