import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';

import RecipesActions, { recipeSelectors } from '@redux/Fitney2.0/Diet/recipes';

const mapStateToProps = (state: any) => ({
  recipesFilters: recipeSelectors.getRecipesFilters(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  resetRecipesFilters: () => dispatch(RecipesActions.resetRecipesFilters()),
  getRecipes: (page: any, per_page: any, query: any, filters: any) =>
    dispatch(RecipesActions.fetchRecipesRequest(page, per_page, query, filters))
});

const withRecipesFilters = (Component: any, path: any) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ recipesFilters, resetRecipesFilters, getRecipes, ...props }: any) => {
    const hasFilters =
      recipesFilters &&
      !!Object.entries(recipesFilters)
        .map((filter) => (typeof filter[1] === 'string' ? [...filter[1]] : filter[1]))
        .flat().length;

    const handleChangeRoute = (location: any) => {
      if (hasFilters && !location.pathname.startsWith(path)) {
        getRecipes(1, props.isMobile ? 10 : 12, '', '');
        resetRecipesFilters();
      }
      return true;
    };

    return (
      <>
        <Prompt message={handleChangeRoute} />
        <Component hasFilters={hasFilters} {...props} />
      </>
    );
  });

export default withRecipesFilters;
