import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveEmailForNewsletterRequest: ['email'],
  saveEmailForNewsletterSuccess: [''],
  saveEmailForNewsletterFailure: ['error'],
  clean: []
});

export const NewsletterTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  isEmailSubscribed: false
});

/* ------------- Selectors ------------- */

export const newsletterSelectors = {
  isEmailSubscribed: (state: any) => state.newsletter.isEmailSubscribed,
  isFetching: (state: any) => state.newsletter.fetching,
  error: (state: any) => state.newsletter.error
};

/* ------------- Reducers ------------- */

export const requestSaveEmail = (state: any) => ({ ...state, fetching: true, error: null, isEmailSubscribed: false });

export const successSaveEmail = (state: any, action: any) => ({
  fetching: false,
  error: null,
  isEmailSubscribed: true
});

export const failure = (state: any, action: any) => ({
  ...state,
  fetching: false,
  error: action.error,
  isEmailSubscribed: false
});

export const clean = (state: any, action: any) => ({ fetching: false, error: null, isEmailSubscribed: false });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_EMAIL_FOR_NEWSLETTER_REQUEST]: requestSaveEmail,
  [Types.SAVE_EMAIL_FOR_NEWSLETTER_SUCCESS]: successSaveEmail,
  [Types.SAVE_EMAIL_FOR_NEWSLETTER_FAILURE]: failure,
  [Types.CLEAN]: clean
});
