import { call, put, select } from '@redux-saga/core/effects';
import {
  UploadImageActions,
  UpdateFullNameActions,
  UpdateBirthDateActions,
  UpdateWeightActions,
  UpdateGenderActions,
  UpdateBodyPartsActions,
  UpdateDietTypeActions,
  UpdateAllergiesActions,
  UpdateMealsPerDayActions,
  UpdateHeightActions,
  UpdateDifficultyLevelActions,
  UpdateTrainingGoalActions,
  UpdatePasswordActions,
  UpdateWorkoutTypeActions,
  UpdateWeeklyIntensityActions,
  DeleteSubscriptionActions,
  DeleteAccountActions,
  RegeneratePlanActions,
  UpdateLanguageActions,
  UpdateQuestionnaireActions
} from '@redux/Fitney2.0/Settings';
import UserDataActions from '@redux/Fitney2.0/UserData/userData';
import SnackBarActions from '@redux/Fitney2.0/Common/snackBar/snackBar';
import LogInActions from '@redux/Fitney2.0/Auth/auth';
import RouteActions from '@redux/route/routeActions';
import AppStatusActions from '@redux/Fitney2.0/AppStatus/appStatus';
import authSelectors from '@selectors/authSelectors';
import { getError } from '@helpers/error/getError';

export function* uploadUserAvatar(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.uploadAvatar, `Bearer ${token}`, action.image);

  if (response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UploadImageActions.uploadImageSuccess());
    yield put(SnackBarActions.showSnackBar('Avatar updated successfully.'));
  } else {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(SnackBarActions.showSnackBar('Avatar updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'The image must be between 0 and 512 kilobytes.' : error;

    yield put(UploadImageActions.uploadImageFailure(errorMessage));
  }
}

export function* updateUserFullName(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.updateUserFullName, `Bearer ${token}`, action.name);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateFullNameActions.updateNameSuccess());
    yield put(UpdateFullNameActions.cleanNameError());
    yield put(SnackBarActions.showSnackBar('Full name updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Full name updating error.'));
    if (response?.data?.errors) {
      yield put(UpdateFullNameActions.updateNameFailure(response.data.errors));
    }
  }
}

export function* updateUserBirthDate(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.updateUserBirthDate, `Bearer ${token}`, action.birthDate);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateBirthDateActions.updateBirthDateSuccess());
    yield put(SnackBarActions.showSnackBar('Birth date updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Birth date updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;

    yield put(UpdateBirthDateActions.updateBirthDateFailure(errorMessage));
  }
}

export function* updateGender(api: any, action: any): any {
  const response = yield call(api.updateGender, action.gender);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateGenderActions.updateGenderSuccess());
    yield put(SnackBarActions.showSnackBar('Gender updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Gender updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateGenderActions.updateGenderFailure(errorMessage));
  }
}

export function* updateBodyParts(api: any, action: any): any {
  const response = yield call(api.updateBodyParts, action.bodyParts, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateBodyPartsActions.updateBodyPartsSuccess());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateBodyPartsActions.updateBodyPartsFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Body parts updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Body parts updating error.'));
  }
}

export function* updateWeight(api: any, action: any): any {
  const response = yield call(api.updateWeight, action.weight, action.weightUnit, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateWeightActions.updateWeightSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateWeightActions.updateWeightFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Weight updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Weight updating error.'));
  }
}

export function* updateDifficultyLevel(api: any, action: any): any {
  const response = yield call(api.updateDifficultyLevel, action.difficultyLevelUuid, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateDifficultyLevelActions.updateDifficultyLevelSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateDifficultyLevelActions.updateDifficultyLevelFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Workout level updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Workout level updating error.'));
  }
}

export function* updateWorkoutType(api: any, action: any): any {
  const response = yield call(api.updateWorkoutType, action.workoutTypeUuid, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateWorkoutTypeActions.updateWorkoutTypeSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateWorkoutTypeActions.updateWorkoutTypeFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Workout type updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Workout type updating error.'));
  }
}

export function* updateWeeklyIntensity(api: any, action: any): any {
  const response = yield call(api.updateWeeklyIntensity, action.weeklyIntensityId, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateWeeklyIntensityActions.updateWeeklyIntensitySuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateWeeklyIntensityActions.updateWeeklyIntensityFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Workout intensity updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Workout intensity updating error.'));
  }
}

export function* updateTrainingGoal(api: any, action: any): any {
  const response = yield call(api.updateTrainingGoal, action.trainingGoalUuid, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateTrainingGoalActions.updateTrainingGoalSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateTrainingGoalActions.updateTrainingGoalFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Training goal updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Training goal updating error.'));
  }
}

export function* updateHeight(api: any, action: any): any {
  const response = yield call(api.updateHeight, action.height, action.heightUnit, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateHeightActions.updateHeightSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateHeightActions.updateHeightFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(SnackBarActions.showSnackBar('Height updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Height updating error.'));
  }
}

export function* updateDietType(api: any, action: any): any {
  const response = yield call(api.updateDietType, action.dietTypeId);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateDietTypeActions.updateDietTypeSuccess());
    yield put(SnackBarActions.showSnackBar('Diet type updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Diet type updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;

    yield put(UpdateDietTypeActions.updateDietTypeFailure(errorMessage));
  }
}

export function* updateAllergies(api: any, action: any): any {
  const response = yield call(api.updateAllergies, action.allergies);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateAllergiesActions.updateAllergiesSuccess());
    yield put(SnackBarActions.showSnackBar('Allergens updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Allergens updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;

    yield put(UpdateAllergiesActions.updateAllergiesFailure(errorMessage));
  }
}

export function* updateMealsPerDay(api: any, action: any): any {
  const response = yield call(api.updateMealsCount, action.mealsCount);

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateMealsPerDayActions.updateMealsPerDaySuccess());
    yield put(SnackBarActions.showSnackBar('Meals per day updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Meals per day updating error.'));
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateAllergiesActions.updateMealsPerDayFailure(errorMessage));
  }
}

export function* updatePassword(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(
    api.updatePassword,
    `Bearer ${token}`,
    action.oldPassword,
    action.newPassword,
    action.retypeNewPassword
  );

  if (response && response.ok) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdatePasswordActions.updatePasswordSuccess());
    yield put(UpdatePasswordActions.cleanPasswordError());
    yield put(SnackBarActions.showSnackBar('Password updated successfully.'));
  } else {
    yield put(SnackBarActions.showSnackBar('Password updating error.'));
    if (response?.data?.errors) {
      yield put(UpdatePasswordActions.updatePasswordFailure(response.data.errors));
    }
  }
}

export function* deleteSubscription(api: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.deleteSubscription, `Bearer ${token}`);

  if (response && response.ok) {
    yield put(UserDataActions.setSubscriptionCancelled());
    yield put(SnackBarActions.showSnackBar('Subscription deleted successfully.'));
    yield put(DeleteSubscriptionActions.deleteSubscriptionSuccess());
  } else {
    yield put(SnackBarActions.showSnackBar('Subscription deleting error.'));
    yield put(DeleteSubscriptionActions.deleteSubscriptionFailure('Subscription deleting error.'));
  }
}

export function* deleteAccount(api: any, action: any): any {
  const token = yield select(authSelectors.token);
  const response = yield call(api.deleteAccount, `Bearer ${token}`);

  if (response && response.ok) {
    yield put(SnackBarActions.showSnackBar('Account deleted successfully.'));
    yield put(DeleteAccountActions.deleteAccountSuccess());
    action.modalRef.current.hide();
    yield put(LogInActions.logOutSuccess());
    yield put(RouteActions.userLoggedOutAction());
  } else {
    yield put(SnackBarActions.showSnackBar('Account deleting error.'));
    yield put(DeleteAccountActions.deleteAccountFailure('Account deleting error.'));
  }
}

export function* regeneratePlan(api: any): any {
  const response = yield call(api.regeneratePlan, true);
  const responseDiet = response[0];
  const responseTrainings = response[1];
  const isDietSuccess = responseDiet && responseDiet.ok;
  const isTrainingsSuccess = responseTrainings && responseTrainings.ok;

  if (isDietSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateHeightActions.updateHeightSuccess());
  } else {
    let error = getError(response);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(RegeneratePlanActions.regeneratePlanFailure(errorMessage));
  }

  if (isTrainingsSuccess) {
    yield put(UserDataActions.getLoggedInUserDataRequest());
    yield put(UpdateQuestionnaireActions.updateQuestionnaireSuccess());
  } else {
    let error = getError(responseDiet);
    const errorMessage = !error ? 'Connection error.' : error;
    yield put(UpdateQuestionnaireActions.updateQuestionnaireFailure(errorMessage));
  }

  if (isDietSuccess && isTrainingsSuccess) {
    yield put(RegeneratePlanActions.regeneratePlanSuccess());
  } else {
    yield put(SnackBarActions.showSnackBar('Regenerate plan error'));
  }
}

export function* updateLanguage(api: any, action: any): any {
  const { language, getUserData, regeneratePlan } = action;
  const response = yield call(api.updateLanguage, language);

  if (response?.ok) {
    yield put(UpdateLanguageActions.updateLanguageSuccess());
    yield put(AppStatusActions.setLanguage(language));

    if (regeneratePlan) {
      yield put(UpdateQuestionnaireActions.updateQuestionnaireRequest());
      yield put(RegeneratePlanActions.regeneratePlanRequest());
    }

    if (getUserData) {
      yield put(UserDataActions.getLoggedInUserDataRequest());
    }
  } else {
    yield put(UpdateLanguageActions.updateLanguageFailure('Language updating error'));
  }
}
