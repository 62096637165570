import React from 'react';

const WeeklyIntensityIcon = () => {
  return (
    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4127 18.9023C18.8247 21.0863 16.5762 22.3793 14.3922 21.7913C12.2082 21.2033 10.9152 18.9563 11.5032 16.7708C11.9127 15.2483 16.2387 6.83484 18.8232 1.87134C19.2807 0.992339 20.6112 1.34934 20.5662 2.33934C20.3082 7.93134 19.8222 17.3798 19.4127 18.9023Z"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5625 10.9006C24.1115 11.3896 24.1085 11.4241 24.6545 11.9761L28.9715 6.6346C27.683 5.1811 26.1665 3.9601 24.497 3.0166"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.29883 9.74401C5.96533 6.94501 9.21583 5.79001 12.5383 5.88751"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.787109 9.56413C2.89161 5.86213 6.35211 2.91763 10.7381 1.54963C12.1121 1.12063 13.4996 0.871633 14.8736 0.786133"
        stroke="#E0325F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WeeklyIntensityIcon;
