import PropTypes from 'prop-types';
import React from 'react';

const Flame2Icon = ({ size, color, opacity }: any) => (
  <svg
    fill={color}
    width={size}
    height={size}
    opacity={opacity / 100}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47737 9.07651C6.34937 10.1995 5.28737 12.2835 5.55037 15.0165C5.87637 18.3855 8.60837 20.8315 12.0434 20.8315C15.5844 20.8315 18.3534 18.0365 18.4834 14.3295C18.5724 11.7915 17.6874 9.12251 16.0564 7.00851C14.5134 5.00851 13.0444 3.87451 12.3584 3.40451C13.8954 8.94351 11.8884 11.0905 11.2204 11.6255C10.3664 12.3105 9.32737 12.4395 8.50937 11.9635C7.34637 11.2865 7.36337 9.86751 7.47737 9.07651ZM12.0434 22.3315C7.81937 22.3315 4.45937 19.3155 4.05837 15.1605C3.70437 11.4965 5.39937 8.76251 6.95737 7.53451C7.41137 7.17651 8.03737 7.15451 8.51537 7.48551C8.98737 7.80951 9.19437 8.39951 9.02937 8.95151C8.95137 9.22651 8.75537 10.3705 9.26537 10.6675C9.53337 10.8255 9.92437 10.7415 10.2834 10.4545C11.2674 9.66651 11.9904 7.33751 10.7864 3.37151C10.6284 2.85851 10.7964 2.30451 11.2164 1.96351C11.6314 1.62151 12.2074 1.57151 12.6824 1.83351C12.7744 1.88551 14.9464 3.11451 17.2434 6.09251C19.0844 8.47851 20.0834 11.5005 19.9834 14.3825C19.8244 18.9135 16.4104 22.3315 12.0434 22.3315Z"
    />
  </svg>
);

Flame2Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number
};

Flame2Icon.defaultProps = {
  size: 64,
  opacity: 100
};

export default Flame2Icon;
